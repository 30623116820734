import {
  ComponentWithAs,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  ThemeTypings,
} from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import { getCurrencyMask } from '../../../../utility/money'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

type Props = {
  field: string
  label: string
  placeHolder?: string
  isRequired?: boolean
  type?: 'text' | 'number' | 'currency' | 'percent'
  inputLeft?: ReactNode
  size?: ThemeTypings['components']['Input']['sizes']
  additionalOnChange?: (value: string) => void
  number?: boolean
}

const TextInput: ComponentWithAs<'input', InputWrapperProps & Props & InputProps> = (
  props: InputWrapperProps & Props & InputProps,
) => {
  const type = props.type || 'text'
  const formik = useFormikContext<FormikValues>()

  const currencyProps: (InputProps & MaskedInputProps) | InputProps =
    type === 'currency'
      ? {
          as: MaskedInput,
          mask: getCurrencyMask(),
        }
      : {}
  const numberProps: InputProps =
    type === 'percent' || type === 'number' || type === 'currency'
      ? {
          onChange: (e) => {
            console.log(e.target.value, parseFloat(e.target.value))
            const value = e.target.value.replace(/[^\d.,]+/g, '').replace(',', '.')
            formik.setFieldValue(props.field, props.number ? parseFloat(value) : value)
            props.additionalOnChange && props.additionalOnChange(e.target.value)
          },
        }
      : {}

  return (
    <InputWrapper
      field={props.field}
      label={props.label}
      isRequired={props.isRequired}
      size={props.size}
    >
      {props.inputLeft}
      <InputGroup>
        {type === 'percent' && <InputLeftElement pointerEvents='none' children='%' />}
        <Input
          placeholder={props.placeHolder || ''}
          id={props.field}
          onChange={(e) => {
            console.log(e.target.value)
            formik.handleChange(e)
            props.additionalOnChange && props.additionalOnChange(e.target.value)
          }}
          onBlur={formik.handleBlur}
          value={formik.values[props.field] || ''}
          {...props}
          {...currencyProps}
          {...numberProps}
        />
      </InputGroup>
    </InputWrapper>
  )
}

export default TextInput
