import React, { useState } from 'react'
import {
  Box,
  Button,
  Code,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import FileIcon from './FileIcon'
import Regular from '../../../components/Typo/Regular'
import { FilesTableRow } from './FilesTable'
import { DownloadSimple, TrashSimple } from '@phosphor-icons/react'
import ConfirmButton from '../../../../shared/components/ConfirmButton'
import Bold from '../../../components/Typo/Bold'
import { AxiosError } from 'axios'
import { useQueryClient, useMutation } from 'react-query'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { DeleteUserFileQueryInputs, deleteUserFileQuery } from './queries/deleteUserFile'
import { UserResponseSingleUser } from '../../queries/userQuery'
import { forceDownload } from '../../../../utility/other'

type Props = { value: string; data: FilesTableRow; user: UserResponseSingleUser }

const FilesTableActions = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()
  const { successToast, errorToast } = useCustomToast()

  const deleteMutation = useMutation(
    (deleteInputs: DeleteUserFileQueryInputs) => deleteUserFileQuery(deleteInputs),
    {
      onError: (error: AxiosError, variables, context) => {
        errorToast(error)
        setIsLoading(false)
      },
      onSuccess: (data) => {
        setIsLoading(false)
        queryClient.invalidateQueries(['userFiles', { id: props.user.id }])
        successToast({
          description: 'All good',
        })
      },
    },
  )

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer le document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Regular>
              Êtes-vous sûr de vouloir supprimer <Code>{props.data.name}</Code> ?<br />
            </Regular>
            <Regular>Vous ne pourrez plus le récupérer une fois supprimé.</Regular>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' onClick={onClose} mr={3}>
              Annuler
            </Button>
            <Button
              type='submit'
              isLoading={isLoading}
              colorScheme={'red'}
              textOverflow='ellipsis'
              onClick={() => {
                setIsLoading(true)
                deleteMutation.mutate({ id: props.data.id })
              }}
            >
              <Text isTruncated maxWidth='300px'>
                Supprimer {props.data.name}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex alignItems='center' justifyContent='flex-end' h='100%' gap='16px' color='neutral.grey'>
        <DownloadSimple
          cursor='pointer'
          size='20px'
          onClick={() => {
            forceDownload(props.data.name, props.data.downloadUrl)
          }}
        />
        <TrashSimple cursor='pointer' size='20px' onClick={onOpen} />
      </Flex>
    </>
  )
}

export default FilesTableActions
