import { authenticatedPutQuery } from '../../../utility/queries/authenticatedPutQuery'
import { AssetAdStatus } from '../../../interfaces/prisma'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export type MADataType = {
  type: 'MA'
  demographicGrowth: string
  above25percent: string
  unemployedPercent: string
  houseInformations: {
    highPricePerM2: string
    mediumPricePerM2: string
    confidenceScore: number
    lowPricePerM2: string
  }
  appartmentInformations: {
    highPricePerM2: string
    mediumPricePerM2: string
    confidenceScore: number
    lowPricePerM2: string
  }
  medianAge: string
  minus25percent: string
  title: string
  trends: any[]
  medianSalary: string
  population: string
}

export type castorusChange = {
  date: string
  content: string
}

export type castorusFeature = {
  date: string
  prix: number
}

export type castorusData = {
  priceChanges?: castorusFeature[]
  features?: string[]
  description: string
  changes: castorusChange[]
  url: string
  type: 'CASTORUS'
}

export type ScrapData = {
  data: MADataType | castorusData
  updatedAt: string
  url: string
}

export type ScrapDataMAOnly = {
  data: MADataType
  updatedAt: string
  url: string
}

export type ScrapDataCastorusOnly = {
  data: castorusData
  updatedAt: string
  url: string
}

export type InputJsonObject = { [Key in string]?: InputJsonValue | null }

export type InputJsonArray = InputJsonValue[] | null

export type InputJsonValue = string | number | boolean | InputJsonObject | InputJsonArray

export const getScrapData = async (id: string) => {
  return await authenticatedGetQuery(`/admin/assetAds/${id}/scrap/`, {}).then(
    (data: ScrapData[]) => {
      return data
    },
  )
}
