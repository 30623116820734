import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'

export interface DeleteUserFileQueryInputs {
  id?: string
}

export const deleteUserFileQuery = async (deleteUserFileQuery: DeleteUserFileQueryInputs) => {
  return await authenticatedDeleteQuery(`/admin/files/${deleteUserFileQuery.id}/`, {
    ...deleteUserFileQuery,
    id: undefined,
  })
}
