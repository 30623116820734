import React from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import DashboardLayout from '../DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Header from '../components/Typo/Header'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { useCustomToast } from '../../utility/hooks/toats/useCustomToasts'
import GenerateMessage from './GenerateMessage'

const Tools = () => {
  const navigate = useNavigate()
  const { errorToast, successToast } = useCustomToast()

  // const identifyAllMutation = useMutation(() => identifyAllUsers(), {
  //   onError: (error: AxiosError) => {
  //     errorToast(error)
  //   },
  //   onSuccess: (data) => {
  //     successToast({ description: 'IdentifyAll launched' })
  //   },
  // })

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <Flex direction='column' gap='48px' mt='48px'>
        <Flex direction='column' gap='24px' width={['100%', '400px']}>
          <Header>Tools</Header>
        </Flex>
        <Flex direction='row' gap='8px'>
          <GenerateMessage />
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default Tools
