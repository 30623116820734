import React, { useEffect, useState } from 'react'
import Searchkit, { MultiMatchQuery } from '@searchkit/sdk'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  useToast,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon, ViewIcon } from '@chakra-ui/icons'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { getScrapData, MADataType, ScrapData, ScrapDataMAOnly } from '../queries/getScrapData'
import { useAtom } from 'jotai'
import { pageAtom } from '../../../atoms/usersList'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import Header from '../../components/Typo/Header'
import Bold from '../../components/Typo/Bold'
import HelperSmall from '../../components/Typo/HelperSmall'
import Regular from '../../components/Typo/Regular'
const MADataDisplay = ({ MAData }: { MAData: ScrapDataMAOnly[] | undefined }) => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()
  const { errorToast, successToast } = useCustomToast()
  const [page, setPage] = useAtom(pageAtom)

  if (!MAData || MAData.length <= 0) {
    return (
      <Alert status='info' maxWidth={['100%', '800px']}>
        <AlertIcon />
        Pas encore de scraping meilleurs agents ! <br />
        Après avoir lancé la première synchronisation, cela peut prendre jusqu'à 2 minutes.
      </Alert>
    )
  }

  // const MAScrapData: MAData[] = scrapData.data.filter(d => d.type)

  return (
    <Flex>
      <Flex w='100%' direction='column' gap='16px' padding='12px'>
        <Bold>Données meilleurs agents</Bold>
        <Accordion allowToggle allowMultiple>
          {MAData.map((singleMAData: ScrapDataMAOnly) => (
            <AccordionItem w='100%'>
              <AccordionButton w='100%'>
                <Flex justifyContent='space-between' w='100%'>
                  {singleMAData.data.title}{' '}
                  <HelperSmall>
                    Dernier scrap : {dayjs(singleMAData.updatedAt).format('DD/MM/YY (HH:MM)')}
                  </HelperSmall>
                </Flex>

                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Flex gap='16px' direction='column'>
                  <Link color='HestiaForestGreen' target='_blank' href={singleMAData.url}>
                    Voir la page MA
                  </Link>
                  <Flex>
                    <Flex w='100%' direction='column'>
                      <b>Maisons</b>
                      <Regular>
                        Prix m2 moyen : {singleMAData.data.houseInformations.mediumPricePerM2}
                      </Regular>
                      <Regular>
                        Prix m2 fourchette : {singleMAData.data.houseInformations.lowPricePerM2} -{' '}
                        {singleMAData.data.houseInformations.highPricePerM2}
                      </Regular>
                      <Regular>
                        Indice de confiance {singleMAData.data.houseInformations.confidenceScore}/5
                      </Regular>
                    </Flex>
                    <Flex w='100%' direction='column'>
                      <b>Appartements</b>
                      <Regular>
                        Prix m2 moyen : {singleMAData.data.appartmentInformations.mediumPricePerM2}
                      </Regular>
                      <Regular>
                        Prix m2 fourchette :{' '}
                        {singleMAData.data.appartmentInformations.lowPricePerM2} -{' '}
                        {singleMAData.data.appartmentInformations.highPricePerM2}
                      </Regular>
                      <Regular>
                        Indice de confiance{' '}
                        {singleMAData.data.appartmentInformations.confidenceScore}
                        /5
                      </Regular>
                    </Flex>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  )
}

export default MADataDisplay
