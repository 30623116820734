import { Divider, Flex } from '@chakra-ui/react'
import { Plus } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomCard from '../../../../components/CustomCard'
import {
  getIncomeFields,
  getIncomeItems,
  getIncomeTitle,
  incomeInitialValues,
} from '../../../../utility/discovery/discoveryFields.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import { DiscoveryBuyer } from './discovery.types'
import DiscoveryLineItem from './DiscoveryLineItem'
import {
  createDiscoveryBuyerIncome,
  deleteDiscoveryBuyerIncome,
  getDiscoveryBuyerIncomes,
  updateDiscoveryBuyerIncome,
  UpdateDiscoveryBuyerIncomePayload,
} from './queries/DiscoveryBuyerIncomeQueries'

type Props = {
  buyer?: DiscoveryBuyer | undefined
}

const DiscoveryIncome = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const currentData = useQuery(
    ['discoveryBuyerIncomes', { id: userId, buyerId: props.buyer?.id }],
    () => getDiscoveryBuyerIncomes(userId || '', props.buyer?.id || ''),
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryBuyerIncomePayload) =>
      updateDiscoveryBuyerIncome(userId || '', props.buyer?.id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerIncome'])
        queryClient.invalidateQueries(['discoveryBuyerIncomes'])
        successToast({ description: 'Revenu modifié' })
      },
    },
  )

  const deleteMutation = useMutation(
    ({ id }: { id: string }) => deleteDiscoveryBuyerIncome(userId || '', props.buyer?.id || '', id),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerIncome'])
        queryClient.invalidateQueries(['discoveryBuyerIncomes'])
        successToast({ description: 'Revenu supprimé' })
      },
    },
  )

  const createMutation = useMutation(
    () => createDiscoveryBuyerIncome(userId || '', props.buyer?.id || ''),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerIncome'])
        queryClient.invalidateQueries(['discoveryBuyerIncomes'])
        successToast({ description: 'Revenu ajouté' })
      },
    },
  )

  console.log(currentData.data)

  return (
    <CustomCard light title={`${props.buyer?.firstName} ${props.buyer?.lastName}`}>
      <Flex flexDirection='column' gap='16px' w='100%'>
        {currentData.data?.map((income) => (
          <>
            <DiscoveryLineItem
              initialValues={incomeInitialValues}
              getFieldsFunction={getIncomeFields}
              title={getIncomeTitle(income) || 'Revenu'}
              getItemsFunction={getIncomeItems}
              updateMutation={updateMutation}
              currentdata={income}
              canDelete
              deleteMutation={deleteMutation}
            />
            <Divider />
          </>
        ))}

        <CustomButton
          variant='outline'
          leftIconComponent={Plus}
          onClick={() => createMutation.mutate()}
        >
          Ajouter un revenu
        </CustomButton>
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryIncome
