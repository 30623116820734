import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import {
  DiscoveryBuyer,
  DiscoveryBuyerHousingSituation,
  DiscoveryBuyerPersonalSituation,
} from '../discovery.types'

export type UpdateDiscoveryBuyerPayload = {
  firstName?: string
  lastName?: string
  age?: number
  nationality?: string
  personalSituation?: DiscoveryBuyerPersonalSituation
  housingSituation?: DiscoveryBuyerHousingSituation
  housingLocation?: string
  rent?: number
  mortgage?: boolean
  planToSell?: boolean
  host?: string
}

export const getDiscoveryBuyersForUser = async (userId: string) => {
  return (await authenticatedGetQuery(`/admin/user/discovery/${userId}/buyers`)) as Promise<
    DiscoveryBuyer[]
  >
}

export const getDiscoveryBuyer = async (userId: string, buyerId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}`,
  )) as Promise<DiscoveryBuyer>
}

export const createDiscoveryBuyer = async (userId: string) => {
  return (await authenticatedPostQuery(`/admin/user/discovery/${userId}/buyers`)) as Promise<void>
}

export const updateDiscoveryBuyer = async (
  userId: string,
  buyerId: string,
  payload: UpdateDiscoveryBuyerPayload,
) => {
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}`,
    payload,
  )) as Promise<void>
}

export const deleteDiscoveryBuyer = async (userId: string, buyerId: string) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}`,
  )) as Promise<void>
}
