import React from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import DashboardLayout from '../DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Header from '../components/Typo/Header'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { reIndexAll } from './queries/reindexAll'
import { useCustomToast } from '../../utility/hooks/toats/useCustomToasts'
import { refreshAllProcessSteps } from './queries/refreshAllProcessSteps'
import { identifyAllUsers } from './queries/identifyAllUsers'

const CreateProAccount = () => {
  const navigate = useNavigate()
  const { errorToast, successToast } = useCustomToast()

  const reindexMutation = useMutation(() => reIndexAll(), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      successToast({ description: 'Reindex launched' })
    },
  })
  const refreshMutation = useMutation(() => refreshAllProcessSteps(), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      successToast({ description: 'Refresh launched' })
    },
  })
  const identifyAllMutation = useMutation(() => identifyAllUsers(), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      successToast({ description: 'IdentifyAll launched' })
    },
  })

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <Flex direction='column' gap='48px' mt='48px'>
        <Flex direction='column' gap='24px' width={['100%', '400px']}>
          <Header>Dev tools</Header>
          <Alert status='info' maxWidth={['100%', '800px']}>
            <AlertIcon />
            With great power comes great responsibility
          </Alert>
        </Flex>
        <Flex direction='row' gap='8px'>
          <Button
            disabled={reindexMutation.isLoading}
            colorScheme='green'
            bg='HestiaForestGreen'
            onClick={() => reindexMutation.mutate()}
            w='fit-content'
          >
            Tout réindexer
          </Button>
          <Button
            disabled={identifyAllMutation.isLoading}
            colorScheme='green'
            bg='HestiaForestGreen'
            onClick={() => identifyAllMutation.mutate()}
            w='fit-content'
          >
            Re-identifier tous les users
          </Button>
          <Button
            disabled={refreshMutation.isLoading}
            colorScheme='green'
            bg='HestiaForestGreen'
            onClick={() => refreshMutation.mutate()}
            w='fit-content'
          >
            Rafraichir toutes les étapes de process
          </Button>
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default CreateProAccount
