import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'

export interface GetUserFilesInputs {
  id?: string
}

export interface UserFile {
  createdAt: string
  id: string
  key: string
  name?: string
  updatedAt: string
  previewUrl: string
  downloadUrl: string
}

export const getUserFiles = async (getUserFilesData: GetUserFilesInputs) => {
  return await authenticatedGetQuery(`/admin/users/${getUserFilesData.id}/files`).then(
    (data) => data.files.filesWithUrl as UserFile[],
  )
}
