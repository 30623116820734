import { deleteAuthToken } from '../../auth/AuthProvider'

export const handleQueryError = (error: any) => {
  if (error.response.status === 401) {
    // Handle deauth
    deleteAuthToken()
  } else if (error.response.data.message) {
    throw new Error(error.response.data.message)
  } else {
    throw new Error(error.message)
  }
}
