import React, { FC } from 'react'
import { CategoryGroup } from '../../../utility/algoan/types/CreditInsights.interface'
import { TransactionCategoryType } from '../../../utility/algoan/types/Transaction.interface'
import { Badge } from '@chakra-ui/react'
import {
  TransactionBankOperationCategory,
  TransactionCreditCategory,
  TransactionExpenseCategory,
  TransactionIncomeCategory,
  TransactionRefundCategory,
  TransactionSavingCategory,
  TransactionTaxCategory,
  TransactionType,
} from '../../../utility/algoan/types/Transaction.enum'
import { ThemeTypings } from '@chakra-ui/styled-system'

const CashflowTypeBadge: FC<{
  type?: TransactionType | undefined
}> = ({ type }) => {
  const getColor = (): ThemeTypings['colorSchemes'] => {
    if (!type) {
      return 'blue'
    }
    if (
      type === 'CASH_DEPOSIT' ||
      type === 'CHECK_DEPOSIT' ||
      type === 'INCOMING_TRANSFER' ||
      type === 'INCOMING_INTERNAL_TRANSFER'
    ) {
      return 'green'
    }
    if (
      type === 'CASH_WITHDRAWAL' ||
      type === 'OUTGOING_TRANSFER' ||
      type === 'OUTGOING_INTERNAL_TRANSFER'
    ) {
      return 'red'
    }
    if (type === 'CARD') {
      return 'orange'
    }
    if (type === 'CARD_REFUND' || type === 'INCOMING_CARD') {
      return 'cyan'
    }
    if (type === 'CHECK') {
      return 'yellow'
    }
    return 'yellow'
  }
  return <Badge colorScheme={getColor()}>{type}</Badge>
}

export default CashflowTypeBadge
