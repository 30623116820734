export enum UserAnalaysisRejectionReasonEnum {
  ONGOING_CREDIT = 'ONGOING_CREDIT',
  FAMILY_SITUATION = 'FAMILY_SITUATION',
  LACK_OF_FINANCIAL_HISTORY = 'LACK_OF_FINANCIAL_HISTORY',
  MULTIPLE_ISSUES = 'MULTIPLE_ISSUES',
  UNSTABLE_PROFESSIONAL_SITUATION = 'UNSTABLE_PROFESSIONAL_SITUATION',
  UNDERVALUED_SALE_PROPERTY = 'UNDERVALUED_SALE_PROPERTY',
}

export type UserAnalaysisRejectionReason = {
  label: string
  value: UserAnalaysisRejectionReasonEnum
  emailContent: string
}

export const userAnalysisRejectionReasons: UserAnalaysisRejectionReason[] = [
  {
    label: 'Crédit en cours',
    value: UserAnalaysisRejectionReasonEnum.ONGOING_CREDIT,
    emailContent:
      "Après analyse de votre situation financière, il semble que votre crédit actuel ne sera pas intégralement soldé à la fin du leasing. Cela pourrait compromettre la réalisation de votre projet d'achat avec Hestia 🏦.",
  },
  {
    label: 'Situation familiale',
    value: UserAnalaysisRejectionReasonEnum.FAMILY_SITUATION,
    emailContent:
      "Votre situation familiale actuelle impacte votre capacité d'emprunt et le risque associé à votre dossier financier 🏠.",
  },
  {
    label: 'Moins de trois ans de bilans comptables',
    value: UserAnalaysisRejectionReasonEnum.LACK_OF_FINANCIAL_HISTORY,
    emailContent:
      "Il apparaît que vous n'aurez pas les trois ans de bilans comptables requis pour valider votre demande de financement. Cette historique est crucial pour évaluer la stabilité et la viabilité de votre activité professionnelle 📊.",
  },
  {
    label: 'Cumul de problématiques',
    value: UserAnalaysisRejectionReasonEnum.MULTIPLE_ISSUES,
    emailContent:
      "Votre dossier présente plusieurs problématiques. Cette combinaison de facteurs impacte votre capacité d'emprunt et le risque associé à votre dossier financier 🔍.",
  },
  {
    label: 'Situation professionnelle instable',
    value: UserAnalaysisRejectionReasonEnum.UNSTABLE_PROFESSIONAL_SITUATION,
    emailContent:
      "Nous avons noté que votre situation professionnelle était incertaine. Cette instabilité nous empêche de garantir une base financière solide pour votre projet d'achat avec Hestia 📉.",
  },
  {
    label: "Valeur du bien en vente inférieure au projet d'achat",
    value: UserAnalaysisRejectionReasonEnum.UNDERVALUED_SALE_PROPERTY,
    emailContent:
      "Le bien que vous proposez de vendre dans le cadre de votre projet d'achat avec Hestia est évalué à une valeur inférieure à celle nécessaire pour financer l'achat envisagé. Cela limite la portée financière de votre projet 🏘️.",
  },
]
