import { AddIcon, SearchIcon, ViewIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  hasDocumentAtom,
  hasSyncedAccountAtom,
  isBusinessAtom,
  pageAtom,
  searchAtom,
  searchPostalCodeAtom,
} from '../../atoms/usersList'
import useDebounce from '../../utility/hooks/useDebounce'
import AssetProcessModalWrapper from '../assetAds/v2/AssetProcessModalWrapper'
import CustomTable from '../components/customTable'
import DashboardLayout from '../DashboardLayout'
import QualifyAction from './actions/QualifyAction'
import { getUsersQuery, UsersResponseSingleUser } from './queries/usersQuery'

const UsersList = () => {
  const toast = useToast()
  const [page, setPage] = useAtom(pageAtom)
  const [search, setSearch] = useAtom(searchAtom)
  const [searchPostalCode, setSearchPostalCode] = useAtom(searchPostalCodeAtom)
  const [hasDocument, setHasDocument] = useAtom(hasDocumentAtom)
  const [hasSyncedAccount, setHasSyncedAccount] = useAtom(hasSyncedAccountAtom)
  const [isBusiness, setIsBusiness] = useAtom(isBusinessAtom)
  const pageSize = 10
  const navigate = useNavigate()

  const { onOpen, isOpen, onClose } = useDisclosure()

  const searchDebounced = useDebounce(search)

  const usersQuery = useQuery(
    [
      'users',
      { page, searchDebounced, hasDocument, hasSyncedAccount, searchPostalCode, isBusiness },
    ],
    () =>
      getUsersQuery({
        page,
        search: searchDebounced,
        hasDocument,
        hasSyncedAccount,
        searchPostalCode,
        isBusiness,
      }),
    {
      onError: (error: AxiosError) => {
        console.log(error)
        toast({
          title: 'Erreur inconnue:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
    },
  )

  const getAmountBadge = (amount: number) => {
    if (amount > 0) {
      return (
        <Badge fontSize='l' colorScheme='green'>
          {amount}
        </Badge>
      )
    } else {
      return (
        <Badge fontSize='l' colorScheme='orange'>
          {amount}
        </Badge>
      )
    }
  }

  const getAccountTypeBadge = (business: boolean) => {
    return business ? (
      <Badge fontSize='l' colorScheme='green'>
        BUSINESS
      </Badge>
    ) : (
      <Badge fontSize='l' colorScheme='blue'>
        PERSONAL
      </Badge>
    )
  }

  return (
    <DashboardLayout>
      <Flex direction='column' gap='16px'>
        <InputGroup>
          <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            type='tel'
            placeholder='Rechercher...'
          />
        </InputGroup>
        <Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='start'>
          <Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='end'>
            <Button colorScheme='green' rightIcon={<AddIcon />} onClick={onOpen}>
              Créer un compte B2B
            </Button>
            <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
              <QualifyAction />
            </AssetProcessModalWrapper>
          </Flex>
          {/* <Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='end'>
            <Button
              colorScheme='green'
              rightIcon={<AddIcon />}
              onClick={() => navigate(`/proaccount/create`)}
            >
              Créer un compte pro
            </Button>
          </Flex> */}

          {/*<Checkbox*/}
          {/*  id='hasDocument'*/}
          {/*  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHasDocument(e.target.checked)}*/}
          {/*  isRequired={true}*/}
          {/*  defaultChecked={hasDocument}*/}
          {/*>*/}
          {/*  <Text>Au moins un document</Text>*/}
          {/*</Checkbox>*/}
          {/*<Checkbox*/}
          {/*  id='hasSyncedAccount'*/}
          {/*  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>*/}
          {/*    setHasSyncedAccount(e.target.checked)*/}
          {/*  }*/}
          {/*  isRequired={true}*/}
          {/*  defaultChecked={hasSyncedAccount}*/}
          {/*>*/}
          {/*  <Text>Au moins un compte connecté</Text>*/}
          {/*</Checkbox>*/}
          <Checkbox
            id='isBusiness'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsBusiness(e.target.checked)}
            defaultChecked={isBusiness}
          >
            <Text>Comptes business uniquement</Text>
          </Checkbox>
          <InputGroup w='fit-content'>
            <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
            <Input
              onChange={(e) => setSearchPostalCode(e.target.value)}
              value={searchPostalCode}
              type='tel'
              placeholder='Code postal...'
            />
          </InputGroup>
        </Flex>
      </Flex>
      <TableContainer mt='16px'>
        <CustomTable
          headers={['Email', 'Nom', 'Type', 'Code postal', 'Biens proposés']}
          widths={['fit-content', 'fit-content', 'fit-content', '30px', 'fit-content']}
          total={usersQuery.data?.total}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          managedPagination={false}
          content={
            usersQuery.data?.users
              ? usersQuery.data?.users.map((user: UsersResponseSingleUser) => {
                  return {
                    email: user.email,
                    name: `${user.firstName} ${user.lastName}`,
                    type: getAccountTypeBadge(user.business),
                    postalCodeWanted: `${user.userInformations?.inseePostalCode?.postalCode} (${user.userInformations?.inseePostalCode?.department})`,
                    accountsCount: getAmountBadge(user.assetAdsCount),
                    // documentsCount: getAmountBadge(user.documentsCount),

                    details: (
                      <div onClick={() => navigate(`/users/${user.id}`)}>
                        <IconButton aria-label='Voir' icon={<ViewIcon />} />
                      </div>
                    ),
                  }
                })
              : []
          }
        />
        {/*<Table variant='simple'>*/}
        {/*  <Thead>*/}
        {/*    <Tr>*/}
        {/*      <Th>Email</Th>*/}
        {/*      <Th>Prénom</Th>*/}
        {/*      <Th>Nom</Th>*/}
        {/*    </Tr>*/}
        {/*  </Thead>*/}
        {/*  <Tbody>*/}
        {/*    {usersQuery.data?.users.map((user: UsersResponseSingleUser) => (*/}
        {/*      <Tr cursor='pointer' onClick={() => navigate(`/users/${user.id}`)}>*/}
        {/*        <Td>{user.email}</Td>*/}
        {/*        <Td>{user.firstName}</Td>*/}
        {/*        <Td>{user.lastName}</Td>*/}
        {/*      </Tr>*/}
        {/*    ))}*/}
        {/*  </Tbody>*/}
        {/*</Table>*/}
        {/*<Box>*/}
        {/*  <Flex alignItems='center' justifyContent='space-between'>*/}
        {/*    <Text as='span'>{usersQuery.data?.total} results</Text>*/}
        {/*    <Flex gap={3}>*/}
        {/*      <Link color='HestiaForestGreen' onClick={handlePrev}>*/}
        {/*        {'< PREV'}*/}
        {/*      </Link>*/}
        {/*      <Text as='span'>*/}
        {/*        {page}/{Math.round(usersQuery.data?.total / pageSize)}*/}
        {/*      </Text>*/}
        {/*      <Link color='HestiaForestGreen' onClick={handleNext}>*/}
        {/*        {'NEXT >'}*/}
        {/*      </Link>*/}
        {/*    </Flex>*/}
        {/*  </Flex>*/}
        {/*</Box>*/}
      </TableContainer>

      {/*<ReactPaginate*/}
      {/*  breakLabel='...'*/}
      {/*  nextLabel='next >'*/}
      {/*  onPageChange={(event) => setPage(event.selected)}*/}
      {/*  pageRangeDisplayed={0}*/}
      {/*  pageCount={50}*/}
      {/*  previousLabel='< previous'*/}
      {/*/>*/}
    </DashboardLayout>
  )
}

export default UsersList
