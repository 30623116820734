import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputProps,
  SelectProps,
} from '@chakra-ui/react'
import React from 'react'
import { FormikValues, useFormikContext } from 'formik'
import InputWrapper, { InputWrapperProps } from './InputWrapper'
import { GroupBase, OptionBase, OptionsOrGroups, Select, Size } from 'chakra-react-select'

interface SelectOption extends OptionBase {
  label: string
  value: string | number
}

type Props = {
  field: string
  label: string
  placeHolder?: string
  isRequired?: boolean
  options: SelectOption[]
  selectSize?: Size
}

const MultiSelectInput = (props: InputWrapperProps & Props) => {
  const formik = useFormikContext<FormikValues>()

  const selectedOption = props.options?.filter((o) => formik.values[props.field].includes(o.value))

  console.log(formik.values[props.field], selectedOption)

  return (
    <InputWrapper field={props.field} label={props.label} isRequired={props.isRequired}>
      <FormControl>
        <Select
          id={props.field}
          value={selectedOption !== undefined ? selectedOption : null}
          onChange={(values) => {
            console.log(values)
            formik.setFieldValue(
              props.field,
              values !== undefined ? values.map((v) => v.value) : undefined,
            )
          }}
          isMulti={true}
          onBlur={formik.handleBlur}
          options={props.options}
          size={props.selectSize}
        />

        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </InputWrapper>
  )
}

export default MultiSelectInput
