import _ from 'lodash'
import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import {
  ContractTypeEnum,
  DiscoveryIncome,
  DiscoveryIncomeType,
  ProfessionalStatusEnum,
} from '../discovery.types'

export type UpdateDiscoveryBuyerIncomePayload = {
  id: string
  buyerId: string
  incomeType: DiscoveryIncomeType | null
  professionalStatus: ProfessionalStatusEnum | null
  contractType: ContractTypeEnum | null
  name: string | null
  employerName: string | null
  startDate: Date | null
  trialPeriod: boolean | null
  annualNetIncome: number | null
  annualNetBonus: number | null
  endDate: Date | null
  businessType: string | null
}

export const getDiscoveryBuyerIncomes = async (userId: string, buyerId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/incomes`,
  )) as Promise<DiscoveryIncome[]>
}

export const createDiscoveryBuyerIncome = async (userId: string, buyerId: string) => {
  return (await authenticatedPostQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/incomes`,
  )) as Promise<void>
}

export const updateDiscoveryBuyerIncome = async (
  userId: string,
  buyerId: string,
  payload: UpdateDiscoveryBuyerIncomePayload,
) => {
  const finalPayload = _.omit(payload, 'id')
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/incomes/${payload.id}`,
    finalPayload,
  )) as Promise<void>
}

export const deleteDiscoveryBuyerIncome = async (
  userId: string,
  buyerId: string,
  incomeId: string,
) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/incomes/${incomeId}`,
  )) as Promise<void>
}
