import dayjs from 'dayjs'
import { FormikValues } from 'formik'
import React from 'react'
import {
  ContractTypeEnum,
  contractTypes,
  DiscoveryBuyerHousingSituation,
  discoveryBuyerHousingSituations,
  DiscoveryIncomeType,
  discoveryIncomeTypes,
  ExpenseTypeEnum,
  expenseTypes,
  financialSavingsTypes,
  getLabel,
  loanPurposes,
  otherAssetTypes,
  PatrimonyTypeEnum,
  patrimonyTypes,
  professionalStatuses,
  realEstateLoanTypes,
  realEstateTypes,
} from '../../dashboard/users/user/discovery/discovery.types'
import DiscoveryDisplayValue from '../../dashboard/users/user/discovery/DiscoveryDisplayValue'
import { DiscoveryLineItemField } from '../../dashboard/users/user/discovery/DiscoveryLineItem'
import { getPrettyNumber } from '../money'

export const getHousingSituationItems = (values: FormikValues): React.ReactNode[] => {
  console.log('get items :)', values)
  const items: React.ReactNode[] = [
    <DiscoveryDisplayValue
      key='housingSituation'
      type='regular'
      value={values.housingSituation}
      label={getLabel(discoveryBuyerHousingSituations, values.housingSituation)}
    />,
    <DiscoveryDisplayValue
      key='location'
      type='regular'
      value={values.housingLocation}
      label={values.housingLocation}
    />,
  ]

  if (values.housingSituation) {
    if (values.housingSituation === DiscoveryBuyerHousingSituation.TENANT) {
      items.push(
        <DiscoveryDisplayValue
          key='2'
          type='badge'
          value={values.rent}
          label={`${getPrettyNumber(values.rent, 0)} / mois`}
        />,
      )
    } else if (values.housingSituation === DiscoveryBuyerHousingSituation.OWNER) {
      items.push(
        values.mortgage ? (
          <DiscoveryDisplayValue
            key='2'
            type='badge'
            value={values.mortgage}
            label={`Avec prêt`}
            customBadgeProps={{ variant: 'red' }}
          />
        ) : (
          <DiscoveryDisplayValue
            key='2'
            type='badge'
            value={values.mortgage}
            label={`Sans prêt`}
            customBadgeProps={{ variant: 'green' }}
          />
        ),
      )
      items.push(
        values.planToSell && (
          <DiscoveryDisplayValue
            key='2'
            type='badge'
            value={values.planToSell}
            label={`Vente prévue`}
            customBadgeProps={{ variant: 'green' }}
          />
        ),
      )
    } else if (values.housingSituation === DiscoveryBuyerHousingSituation.HOSTED) {
      items.push(
        <DiscoveryDisplayValue key='1' type='regular' value={values.host} label={values.host} />,
      )
    }
  }

  return items
}
export const getHousingSituationsFields = (values: FormikValues): DiscoveryLineItemField[] => {
  const fields: DiscoveryLineItemField[] = [
    {
      fieldName: 'housingSituation',
      type: 'select',
      label: '',
      options: discoveryBuyerHousingSituations,
    },
    {
      fieldName: 'housingLocation',
      type: 'text',
      label: 'Ville',
    },
  ]

  if (values.housingSituation) {
    if (values.housingSituation === DiscoveryBuyerHousingSituation.TENANT) {
      fields.push({
        fieldName: 'rent',
        type: 'currency',
        label: 'Montant du loyer',
      })
    }
    if (values.housingSituation === DiscoveryBuyerHousingSituation.OWNER) {
      fields.push({
        fieldName: 'mortgage',
        type: 'boolean',
        label: 'Avec un prêt en cours ?',
      })
      fields.push({
        fieldName: 'planToSell',
        type: 'boolean',
        label: 'A prévu de le vendre pour racheter ?',
      })
    }
    if (values.housingSituation === DiscoveryBuyerHousingSituation.HOSTED) {
      fields.push({
        fieldName: 'host',
        type: 'text',
        label: "Qui est l'hébergeur?",
      })
    }
  }

  console.log(fields)

  return fields
}

export const housingSituationsInitialValues = {
  housingSituation: undefined,
  housingLocation: undefined,
  rent: undefined,
  mortgage: undefined,
  planToSell: undefined,
  host: undefined,
}

export const getIncomeItems = (values: FormikValues): React.ReactNode[] => {
  const items: React.ReactNode[] = [
    <DiscoveryDisplayValue
      key='annualNetIncome'
      type='currency'
      value={values.annualNetIncome + values.annualNetBonus}
    />,
  ]

  if (
    values.incomeType === DiscoveryIncomeType.JOB ||
    values.incomeType === DiscoveryIncomeType.INDEPENDANT
  ) {
    items.push(
      <DiscoveryDisplayValue
        key='startDate'
        type='regular'
        value={values.startDate}
        label={dayjs(values.startDate).fromNow()}
      />,
    )
  }

  if (values.incomeType === DiscoveryIncomeType.JOB) {
    items.push(
      // <DiscoveryDisplayValue
      //   key='employerName'
      //   type='regular'
      //   value={values.employerName}
      //   label={values.employerName}
      // />,
      <DiscoveryDisplayValue key='name' type='regular' value={values.name} label={values.name} />,
    )

    if (values.trialPeriod === true) {
      items.push(
        <DiscoveryDisplayValue
          key='trialPeriod'
          type='badge'
          value={values.trialPeriod}
          label={"Période d'éssai"}
          customBadgeProps={{ variant: 'red' }}
        />,
      )
    }
  } else if (values.incomeType !== DiscoveryIncomeType.JOB) {
    items.push(
      <DiscoveryDisplayValue
        key='details'
        type='regular'
        value={values.details}
        label={values.details}
      />,
    )
  }

  return items
}
export const getIncomeFields = (values: FormikValues): DiscoveryLineItemField[] => {
  const fields: DiscoveryLineItemField[] = [
    {
      fieldName: 'id',
      type: 'hidden',
      label: '',
    },
    {
      fieldName: 'annualNetIncome',
      type: 'currency',
      label: 'Revenu net annuel',
    },

    {
      fieldName: 'incomeType',
      type: 'select',
      label: 'Type de revenu',
      options: discoveryIncomeTypes,
    },
  ]

  if (values.incomeType === DiscoveryIncomeType.JOB) {
    fields.push({
      fieldName: 'name',
      type: 'text',
      label: 'Fonction',
    })
    fields.push({
      fieldName: 'professionalStatus',
      type: 'select',
      label: 'Secteur',
      options: professionalStatuses,
    })
    fields.push({
      fieldName: 'contractType',
      type: 'select',
      label: 'Type de contrat',
      options: contractTypes,
    })
    fields.push({
      fieldName: 'startDate',
      type: 'date',
      label: 'Date de début',
    })
    if (values.contractType !== ContractTypeEnum.CDD) {
      fields.push({
        fieldName: 'endDate',
        type: 'date',
        label: 'Date de fin',
      })
    }
    fields.push({
      fieldName: 'employerName',
      type: 'text',
      label: 'Employeur',
    })
    fields.push({
      fieldName: 'trialPeriod',
      type: 'boolean',
      label: "Période d'éssai",
    })
    fields.push({
      fieldName: 'annualNetBonus',
      type: 'currency',
      label: 'Bonus net annuel',
    })
  } else {
    if (values.incomeType === DiscoveryIncomeType.INDEPENDANT) {
      fields.push({
        fieldName: 'startDate',
        type: 'date',
        label: 'Date de début',
      })
      fields.push({
        fieldName: 'businessType',
        type: 'text',
        label: 'Type de structure',
      })
    }
    fields.push({
      fieldName: 'details',
      type: 'text',
      label: 'Détails',
    })
  }

  return fields
}

export const incomeInitialValues = {
  id: undefined,
  name: undefined,
  annualNetIncome: undefined,
  incomeType: undefined,
  professionalStatus: undefined,
  contractType: undefined,
  employerName: undefined,
  trialPeriod: undefined,
  annualNetBonus: undefined,
  businessType: undefined,
  details: undefined,

  startDate: undefined,
  endDate: undefined,
}

export const getIncomeTitle = (values: FormikValues) => {
  if (values.incomeType === DiscoveryIncomeType.JOB) {
    return `${values.contractType} chez ${values.employerName}`
  } else {
    return `${discoveryIncomeTypes.find((o) => o.value === values.incomeType)?.label}`
  }
}

//
//
//

export const getChildItems = (values: FormikValues): React.ReactNode[] => {
  const items: React.ReactNode[] = [
    <DiscoveryDisplayValue
      key='age'
      type='regular'
      value={values.age}
      label={`${values.age} ans`}
    />,
  ]

  return items
}
export const getChildFields = (values: FormikValues): DiscoveryLineItemField[] => {
  const fields: DiscoveryLineItemField[] = [
    {
      fieldName: 'id',
      type: 'hidden',
      label: '',
    },
    {
      fieldName: 'age',
      type: 'number',
      label: 'Âge',
    },
  ]

  return fields
}

export const childInitialValues = {
  id: undefined,
  name: undefined,
  age: undefined,
}

//
//
//

export const getExpenseItems = (values: FormikValues): React.ReactNode[] => {
  const items: React.ReactNode[] = [
    <DiscoveryDisplayValue
      key='monthlypayment'
      type='badge'
      value={values.monthlyPayment}
      label={`${getPrettyNumber(values.monthlyPayment)} / mois`}
    />,
    <DiscoveryDisplayValue
      key='endDate'
      type='regular'
      value={values.endDate}
      label={`Fin le ${dayjs(values.endDate).format('DD/MM/YYYY')}`}
    />,
    <DiscoveryDisplayValue
      key='crd'
      type='badge'
      value={values.crd}
      label={`${getPrettyNumber(values.crd)} CRD`}
      customBadgeProps={{ variant: 'red' }}
    />,
  ]

  values.details &&
    items.push(<DiscoveryDisplayValue key='details' type='regular' value={values.details} />)

  return items
}
export const getExpenseFields = (values: FormikValues): DiscoveryLineItemField[] => {
  const fields: DiscoveryLineItemField[] = [
    {
      fieldName: 'id',
      type: 'hidden',
      label: '',
    },
    {
      fieldName: 'monthlyPayment',
      type: 'currency',
      label: 'Mensualité',
    },
    {
      fieldName: 'crd',
      type: 'currency',
      label: 'Crédit restant dû',
    },
    {
      fieldName: 'expenseType',
      type: 'select',
      label: 'Type de charge',
      options: expenseTypes,
    },
    {
      fieldName: 'endDate',
      type: 'date',
      label: 'Date de fin',
    },
  ]

  if (values.expenseType === ExpenseTypeEnum.REAL_ESTATE_LOAN) {
    fields.push({
      fieldName: 'realEstateLoanType',
      type: 'select',
      label: 'Type de prêt immo',
      options: realEstateLoanTypes,
    })
  }
  if (values.expenseType === ExpenseTypeEnum.LOAN) {
    fields.push({
      fieldName: 'loanPurpose',
      type: 'select',
      label: 'Type de prêt',
      options: loanPurposes,
    })
  }

  fields.push({
    fieldName: 'details',
    type: 'text',
    label: 'Détails',
  })

  return fields
}

export const expenseInitialValues = {
  id: undefined,
  monthlyPayment: undefined,
  crd: undefined,
  expenseType: undefined,
  endDate: undefined,
  realEstateLoanType: undefined,
  loanPurpose: undefined,
  details: undefined,
}

export const getExpenseTitle = (values: FormikValues) => {
  if (values.expenseType) {
    return `${expenseTypes.find((o) => o.value === values.expenseType)?.label}`
  } else {
    return `Pas complété`
  }
}

//
// Patrimony
//

export const getPatrimonyItems = (values: FormikValues): React.ReactNode[] => {
  const items: React.ReactNode[] = [
    <DiscoveryDisplayValue key='value' type='currency' value={values.value} />,
  ]

  if (values.patrimonyType === PatrimonyTypeEnum.FINANCIAL_SAVINGS) {
    items.push(
      <DiscoveryDisplayValue
        key='financialSavingsType'
        type='regular'
        value={values.financialSavingsType}
        label={financialSavingsTypes.find((o) => o.value === values.financialSavingsType)?.label}
      />,
    )
  }

  if (values.patrimonyType === PatrimonyTypeEnum.OTHER_ASSETS) {
    items.push(
      <DiscoveryDisplayValue
        key='otherAssetType'
        type='regular'
        value={values.otherAssetType}
        label={otherAssetTypes.find((o) => o.value === values.otherAssetType)?.label}
      />,
    )
  }
  if (values.realEstateType === PatrimonyTypeEnum.REAL_ESTATE) {
    items.push(
      <DiscoveryDisplayValue
        key='realEstateType'
        type='regular'
        value={values.realEstateType}
        label={realEstateTypes.find((o) => o.value === values.realEstateType)?.label}
      />,
    )
  }

  values.locationAbroad &&
    items.push(
      <DiscoveryDisplayValue
        key='locationAbroad'
        type='badge'
        value={"A l'étranger"}
        label={"A l'étranger"}
        customBadgeProps={{ variant: 'red' }}
      />,
    )

  values.details &&
    items.push(
      <DiscoveryDisplayValue
        key='details'
        type='regular'
        value={values.details}
        label={values.details}
      />,
    )

  return items
}

export const getPatrimonyFields = (values: FormikValues): DiscoveryLineItemField[] => {
  const fields: DiscoveryLineItemField[] = [
    {
      fieldName: 'id',
      type: 'hidden',
      label: '',
    },
    {
      fieldName: 'patrimonyType',
      type: 'select',
      label: 'Type de patrimoine',
      options: patrimonyTypes,
    },
    {
      fieldName: 'value',
      type: 'currency',
      label: 'Valeur',
    },
  ]

  if (values.patrimonyType === PatrimonyTypeEnum.FINANCIAL_SAVINGS) {
    fields.push({
      fieldName: 'financialSavingsType',
      type: 'select',
      label: 'Type de patrimoine financier',
      options: financialSavingsTypes,
    })
  }
  if (values.patrimonyType === PatrimonyTypeEnum.OTHER_ASSETS) {
    fields.push({
      fieldName: 'otherAssetType',
      type: 'select',
      label: 'Type de patrimoine autre',
      options: otherAssetTypes,
    })
  }
  if (values.patrimonyType === PatrimonyTypeEnum.REAL_ESTATE) {
    fields.push({
      fieldName: 'realEstateType',
      type: 'select',
      label: 'Type de bien',
      options: realEstateTypes,
    })
    fields.push({
      fieldName: 'housingLocation',
      type: 'text',
      label: 'Ville',
    })
    fields.push({
      fieldName: 'mortgage',
      type: 'boolean',
      label: 'Prêt actif?',
    })
    fields.push({
      fieldName: 'planToSell',
      type: 'boolean',
      label: 'Vente prévue?',
    })
  }

  fields.push({
    fieldName: 'locationAbroad',
    type: 'boolean',
    label: "A l'étranger",
  })
  fields.push({
    fieldName: 'details',
    type: 'text',
    label: 'Détails',
  })

  return fields
}

export const patrimonyInitialValues = {
  id: undefined,
  patrimonyType: undefined,
  value: undefined,
  financialSavingsType: undefined,
  otherAssetType: undefined,
  realEstateType: undefined,
  housingLocation: undefined,
  mortgage: undefined,
  planToSell: undefined,
  locationAbroad: undefined,
  details: undefined,
}

export const getPatrimonyTitle = (values: FormikValues) => {
  if (values.patrimonyType) {
    return `${patrimonyTypes.find((o) => o.value === values.patrimonyType)?.label}`
  } else {
    return `Pas complété`
  }
}
