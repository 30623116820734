import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import { Bank, Files, ListChecks, NotionLogo, Pen, Phone, UserList } from '@phosphor-icons/react'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonCard from '../../../components/ButtonCard'
import { userLostReasons } from '../../../interfaces/prisma'
import { userAnalysisRejectionReasons } from '../../../shared/backShared/userAnalysisRejectionTypes'
import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import ConfirmButton from '../../../shared/components/ConfirmButton'
import Loading from '../../../shared/components/Loading'
import { useKonamiCode } from '../../../utility/hooks/useKonamiCode'
import { ROUTES } from '../../../utility/routes/routeConstants'
import AssetProcessModalWrapper from '../../assetAds/v2/AssetProcessModalWrapper'
import CustomButton from '../../components/CustomButton'
import DashboardLayout from '../../DashboardLayout'
import ChangeOwner from '../actions/ChangeOwnerAction'
import { deleteRGPD } from '../queries/deleteRGPD'
import { resetUserHubspotDeal } from '../queries/resetUserHubspotDeal'
import { getUserQuery } from '../queries/userQuery'
import UserHeader from './UserHeader'

type Props = {
  children: React.ReactNode
  noPadding?: boolean
  discoveryCall?: boolean
}

const User = (props: Props) => {
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))
  const navigate = useNavigate()

  const {
    isOpen: isChangeOwnerActionOpen,
    onOpen: onChangeOwnerActionOpen,
    onClose: onChangeOwnerActionClose,
  } = useDisclosure()

  const konami = useKonamiCode()

  const deleteRGPDMutation = useMutation((id: string) => deleteRGPD(id))
  const resetUserHubspotDealMutation = useMutation((id: string) => resetUserHubspotDeal(id))

  if (!userQuery.data?.user) {
    return <Loading />
  }

  return (
    <DashboardLayout noPadding={props.noPadding} discoveryCall={props.discoveryCall}>
      <Flex direction='column' p={props.noPadding ? 12 : 0} gap='24px' w='100%'>
        <Flex direction='column' gap='24px'>
          <UserHeader />
          {userQuery.data?.user.userInformations?.processStage ===
            UserProcessStagesEnum.CLOSED_LOST && (
            <Alert
              status='error'
              bg='HestiaLightRed'
              border='1px solid'
              borderColor='HestiaRed'
              borderRadius='8px'
            >
              <AlertIcon />
              <Box>
                <AlertTitle>User en LOST</AlertTitle>
                <AlertDescription>
                  <b>La raison :</b>{' '}
                  {userQuery.data?.user.userInformations?.lostReason &&
                    userLostReasons.find(
                      (e) => e.value === userQuery.data?.user.userInformations?.lostReason,
                    )?.label}
                  {userQuery.data?.user.userInformations?.analysisRejectionReason && (
                    <>
                      <br />
                      <b>Refus de la team crédit :</b>{' '}
                      {
                        userAnalysisRejectionReasons.find(
                          (e) =>
                            e.value ===
                            userQuery.data?.user.userInformations?.analysisRejectionReason,
                        )?.label
                      }
                    </>
                  )}
                  {userQuery.data?.user.userInformations?.analysisRejectionCustomMessage && (
                    <>
                      <br />
                      <b>Message de la team crédit :</b>{' '}
                      {userQuery.data?.user.userInformations?.analysisRejectionCustomMessage}
                    </>
                  )}
                </AlertDescription>
              </Box>
            </Alert>
          )}
          <Flex justifyContent='space-between' w='100%' mb={4}>
            <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
              Retour
            </Button>
            <Flex gap='13px'>
              {konami && (
                <>
                  <ConfirmButton
                    bodyText='Cette action est définitive!'
                    buttonText={'Supprimer les données'}
                    headerText='Supprimer les données'
                    rightIcon={<DeleteIcon />}
                    colorScheme='red'
                    onSuccessAction={() => {
                      deleteRGPDMutation.mutate(id || '')
                    }}
                  />
                  <ConfirmButton
                    bodyText='Cette action est définitive!'
                    buttonText={'Reset le hubspot deal id'}
                    headerText='Reset le hubspot deal id'
                    rightIcon={<DeleteIcon />}
                    colorScheme='red'
                    onSuccessAction={() => {
                      resetUserHubspotDealMutation.mutate(id || '')
                    }}
                  />
                  <AssetProcessModalWrapper
                    isOpen={isChangeOwnerActionOpen}
                    onClose={onChangeOwnerActionClose}
                  >
                    <ChangeOwner />
                  </AssetProcessModalWrapper>
                  <Button
                    onClick={onChangeOwnerActionOpen}
                    variant='outline'
                    colorScheme='green'
                    size='sm'
                    w='fit-content'
                  >
                    Changer l'owner
                  </Button>
                </>
                // <Button
                //   colorScheme='red'
                //   rightIcon={<DeleteIcon />}
                //   onClick={() => navigate(`/users/${userQuery.data?.user.id}/createProject`)}
                // >

                // </Button>
              )}

              <CustomButton
                leftIcon={<UserList size={24} />}
                variant='outline'
                w='fit-content'
                disabled={!userQuery.data?.user.userInformations?.hubspotId}
                color='orange.700'
                bg='neutral.white'
                colorScheme='orange'
                border='1px solid'
                borderColor='orange.700'
                _hover={{
                  bg: 'orange.50',
                }}
                onClick={() =>
                  window.open(
                    `https://app-eu1.hubspot.com/contacts/26051163/record/0-1/${userQuery.data?.user.userInformations?.hubspotId}`,
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
              >
                Voir sur hubspot
              </CustomButton>
              <CustomButton
                leftIcon={<NotionLogo size={24} />}
                variant='outline'
                w='fit-content'
                color='green.700'
                bg='neutral.white'
                colorScheme='green'
                border='1px solid'
                borderColor='green.700'
                _hover={{
                  bg: 'green.50',
                }}
                disabled={!userQuery.data?.user.userInformations?.notionPageId}
                onClick={() =>
                  window.open(
                    `https://notion.so/${userQuery.data?.user.userInformations?.notionPageId?.replace(
                      /-/g,
                      '',
                    )}`,
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
              >
                Voir sur Notion
              </CustomButton>
            </Flex>
          </Flex>
        </Flex>

        <SimpleGrid columns={5} gap='24px' mb='24px'>
          <ButtonCard
            icon={<ListChecks size={32} />}
            text='Summary'
            route={`/${ROUTES.USERS_BASE}/${id}`}
          />
          <ButtonCard
            icon={<Pen size={32} />}
            text='Informations'
            route={`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_INFORMATIONS}`}
          />
          <ButtonCard
            icon={<Bank size={32} />}
            text='Budget'
            route={`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_BUDGET}`}
          />
          <ButtonCard
            icon={<Files size={32} />}
            text='Files'
            route={`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_FILES}`}
          />
          <ButtonCard
            icon={<Phone size={32} />}
            text='Discovery call'
            route={`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_DISCOVERY}`}
          />
        </SimpleGrid>
      </Flex>

      {userQuery.data?.user.userInformations && (
        <Box position='relative'>
          {props.children}
          {/* <Tabs width='100%'>
            <TabList>
              <Tab>Summary</Tab>
              <Tab>Informations</Tab>
              <Tab>Income</Tab>
              <Tab>Employment</Tab>
              <Tab>Dossier</Tab>
              <Tab>Transactions</Tab>
              <Tab>Fichiers</Tab>
              <Tab>Découverte</Tab>
      
            </TabList>

            <TabPanels>
              <TabPanel>
                <UserSummary user={user} />
              </TabPanel>
              <TabPanel>
                <UserInformations user={user} />
              </TabPanel>
              <TabPanel>
                <UserIncome user={user} />
              </TabPanel>
              <TabPanel>
                <UserEmployment user={user} />
              </TabPanel>
              <TabPanel>
                <Folde user={user} />
              </TabPanel>
              <TabPanel>
                <UserOpenBanking user={user} />
              </TabPanel>
              <TabPanel>
                <Files user={user} />
              </TabPanel>
              <TabPanel>
                <DiscoveryCall />
              </TabPanel>
   
            </TabPanels>
          </Tabs> */}
        </Box>
      )}
    </DashboardLayout>
  )
}

export default User
