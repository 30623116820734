import React, { FC } from 'react'
import { Center, useToast } from '@chakra-ui/react'
import { googleClientId } from '../utility/config'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { loginQuery, LoginQueryInputs } from './queries/signupQuery'
import { useAuth } from './AuthProvider'
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'

const Login: FC = () => {
  const toast = useToast()
  const authContext = useAuth()

  const loginMutation = useMutation(
    (loginQueryInputs: LoginQueryInputs) => loginQuery(loginQueryInputs),
    {
      onError: (error: AxiosError, variables, context) => {
        console.log(error)
        toast({
          title: 'Erreur inconnue:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        console.log(data)
        if (data.tokenData?.token) {
          authContext?.login(data.tokenData.token)
        }
      },
    },
  )

  const responseGoogle = (response: any) => {
    console.log(response)
  }
  const onSuccess = (response: CredentialResponse) => {
    if ('credential' in response && response.credential) {
      loginMutation.mutate({ googleToken: response.credential })
    }
  }

  return (
    <Center h='100vh' bg='HestiaForestGreen'>
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleLogin
          auto_select
          onSuccess={onSuccess}
          onError={() => {
            console.log('Login Failed')
          }}
          useOneTap
        />
      </GoogleOAuthProvider>
    </Center>
  )
}

export default Login
