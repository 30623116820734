import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface AddPersonToAgencyInputs {
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  phone?: string | undefined
  agencyId?: string | undefined
  partnerId?: string | undefined
  hubspotId?: string | undefined
}

export const addPersonToAgencyQuery = async (data: AddPersonToAgencyInputs) => {
  return await authenticatedPostQuery(
    `/admin/partners/${data.partnerId}/agency/${data.agencyId}/person`,
    {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      hubspotId: data.hubspotId,
    },
  ).then((data) => data.data)
}
