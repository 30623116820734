import { Divider, Flex } from '@chakra-ui/react'
import { Plus } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomCard from '../../../../components/CustomCard'
import {
  childInitialValues,
  getChildFields,
  getChildItems,
} from '../../../../utility/discovery/discoveryFields.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import { DiscoveryBuyer } from './discovery.types'
import DiscoveryLineItem from './DiscoveryLineItem'
import {
  createDiscoveryBuyerChild,
  deleteDiscoveryBuyerChild,
  getDiscoveryBuyerChildren,
  updateDiscoveryBuyerChild,
  UpdateDiscoveryBuyerChildPayload,
} from './queries/DicsoveryBuyerChildQueries'

type Props = {
  buyer?: DiscoveryBuyer | undefined
}

const DiscoveryChildren = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const currentData = useQuery(
    ['discoveryBuyerChildren', { id: userId, buyerId: props.buyer?.id }],
    () => getDiscoveryBuyerChildren(userId || '', props.buyer?.id || ''),
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryBuyerChildPayload) =>
      updateDiscoveryBuyerChild(userId || '', props.buyer?.id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerChildren'])
        successToast({ description: 'Enfant modifié' })
      },
    },
  )

  const deleteMutation = useMutation(
    ({ id }: { id: string }) => deleteDiscoveryBuyerChild(userId || '', props.buyer?.id || '', id),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerChildren'])
        successToast({ description: 'Enfant supprimé' })
      },
    },
  )

  const createMutation = useMutation(
    () => createDiscoveryBuyerChild(userId || '', props.buyer?.id || ''),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerChildren'])
        successToast({ description: 'Enfant ajouté' })
      },
    },
  )

  console.log(currentData.data)

  return (
    <CustomCard light title={`Enfants de ${props.buyer?.firstName}`}>
      <Flex flexDirection='column' gap='16px' w='100%'>
        {currentData.data?.map((child, i) => (
          <>
            <DiscoveryLineItem
              initialValues={childInitialValues}
              getFieldsFunction={getChildFields}
              title={`Enfant ${i + 1}`}
              getItemsFunction={getChildItems}
              updateMutation={updateMutation}
              currentdata={child}
              canDelete
              deleteMutation={deleteMutation}
            />
            <Divider />
          </>
        ))}

        <CustomButton
          variant='outline'
          leftIconComponent={Plus}
          onClick={() => createMutation.mutate()}
        >
          Ajouter un enfant
        </CustomButton>
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryChildren
