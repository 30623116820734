import { Divider, Flex } from '@chakra-ui/react'
import { Plus } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomCard from '../../../../components/CustomCard'
import {
  getPatrimonyFields,
  getPatrimonyItems,
  getPatrimonyTitle,
  patrimonyInitialValues,
} from '../../../../utility/discovery/discoveryFields.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import { DiscoveryBuyer } from './discovery.types'
import DiscoveryLineItem from './DiscoveryLineItem'
import {
  createDiscoveryBuyerPatrimony,
  deleteDiscoveryBuyerPatrimony,
  getDiscoveryBuyerPatrimonies,
  updateDiscoveryBuyerPatrimony,
  UpdateDiscoveryBuyerPatrimonyPayload,
} from './queries/DiscoveryBuyerPatrimonyQueries'

type Props = {
  buyer?: DiscoveryBuyer | undefined
}

const DiscoveryPatrimony = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const currentData = useQuery(
    ['discoveryBuyerPatrimonies', { id: userId, buyerId: props.buyer?.id }],
    () => getDiscoveryBuyerPatrimonies(userId || '', props.buyer?.id || ''),
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryBuyerPatrimonyPayload) =>
      updateDiscoveryBuyerPatrimony(userId || '', props.buyer?.id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimony'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimonies'])
        successToast({ description: 'Patrimoine modifié' })
      },
    },
  )

  const deleteMutation = useMutation(
    ({ id }: { id: string }) =>
      deleteDiscoveryBuyerPatrimony(userId || '', props.buyer?.id || '', id),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimony'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimonies'])
        successToast({ description: 'Patrimoine supprimé' })
      },
    },
  )

  const createMutation = useMutation(
    () => createDiscoveryBuyerPatrimony(userId || '', props.buyer?.id || ''),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimony'])
        queryClient.invalidateQueries(['discoveryBuyerPatrimonies'])
        successToast({ description: 'Patrimoine ajouté' })
      },
    },
  )

  console.log(currentData.data)

  return (
    <CustomCard light title={`${props.buyer?.firstName} ${props.buyer?.lastName}`}>
      <Flex flexDirection='column' gap='16px' w='100%'>
        {currentData.data?.map((patrimony) => (
          <>
            <DiscoveryLineItem
              initialValues={patrimonyInitialValues}
              getFieldsFunction={getPatrimonyFields}
              title={getPatrimonyTitle(patrimony) || 'Patrimoine'}
              getItemsFunction={getPatrimonyItems}
              updateMutation={updateMutation}
              currentdata={patrimony}
              canDelete
              deleteMutation={deleteMutation}
            />
            <Divider />
          </>
        ))}

        <CustomButton
          variant='outline'
          leftIconComponent={Plus}
          onClick={() => createMutation.mutate()}
        >
          Ajouter un patrimoine
        </CustomButton>
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryPatrimony
