import { Flex, Badge } from '@chakra-ui/react'
import { info } from 'console'
import { useAtom } from 'jotai'
import React from 'react'
import { BiUser } from 'react-icons/bi'
import { Highlight } from 'react-instantsearch-hooks-web'
import { useNavigate } from 'react-router-dom'
import { isCommandPaletteOpenAtom } from '../../atoms/searchAtoms'
import { getPrettyNumber } from '../../utility/money'
import { ROUTES } from '../../utility/routes/routeConstants'
import Bold from '../components/Typo/Bold'
import Regular from '../components/Typo/Regular'
import HelperSmall from '../components/Typo/Regular'
import { InseePostalCode } from '../users/queries/userQuery'
import User from '../users/user/User'
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types'

export type UserAlgolia = {
  objectID: string
  createdAt: string
  email: string
  firstName: string
  lastName: string
  business: boolean
  departmentWanted: string
  postalCodeWanted: string
  regionWanted: string
  hestiaBudget: number
  userScoring: HubspotScoringEnum
}

export type AlgoliaCommonProps = {
  __position: number
  __queryID?: string | undefined
}

export enum HubspotScoringEnum {
  'VERY_LOW' = 'very_low',
  'LOW' = 'low',
  'MEDIUM' = 'medium',
  'HIGH' = 'high',
}

export enum HubspotScoringFormattedEnum {
  'very_low' = 'P3 🔵',
  'low' = 'P2 🟡',
  'medium' = 'P1 🟠',
  'high' = 'P0 🔴',
}

interface IHubspotScoringFormattedEnum {
  [key: string]: HubspotScoringFormattedEnum
}

// Use the defined interface.
export const hubspotScoringFormattedEnum: IHubspotScoringFormattedEnum = HubspotScoringFormattedEnum

type Props = { hit: AlgoliaHit & Partial<UserAlgolia> }

const UserHit = (props: Props) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useAtom(isCommandPaletteOpenAtom)

  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      _hover={{ bg: 'HestiaLightGrey' }}
      cursor='pointer'
      padding='8px'
      gap='8px'
      onClick={() => {
        navigate(`/${ROUTES.USERS_BASE}/${props.hit.objectID}`)
        setIsOpen(false)
      }}
    >
      <Flex justifyContent='center' verticalAlign='middle' alignItems='center'>
        <BiUser size='24px' color='gray' />
      </Flex>
      <Flex direction='column' w='100%' minW='0'>
        <Bold overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='100%'>
          <Highlight hit={props.hit} attribute='firstName'>
            {props.hit.firstName}
          </Highlight>{' '}
          <Highlight hit={props.hit} attribute='lastName'>
            {props.hit.lastName}
          </Highlight>
        </Bold>
        <HelperSmall overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='100%'>
          <Highlight hit={props.hit} attribute='email'>
            {props.hit.email}{' '}
          </Highlight>
        </HelperSmall>
      </Flex>
      <Flex w='100%' minW='0' justifyContent='flex-end'>
        <Flex w='100%' direction='column' justifyContent='flex-end'>
          <Flex w='100%' minW='0' justifyContent='flex-end' gap='8px'>
            <Badge h='fit-content'>
              <Highlight hit={props.hit} attribute='departmentWanted'>
                {props.hit.departmentWanted}
              </Highlight>
            </Badge>
            {props.hit.business && <Badge h='fit-content'>Business</Badge>}
            {props.hit.userScoring && (
              <Badge h='fit-content'>{HubspotScoringFormattedEnum[props.hit.userScoring]}</Badge>
            )}
          </Flex>
          <Flex w='100%' minW='0' justifyContent='flex-end'>
            <Regular>
              Budget : <b>{getPrettyNumber(props?.hit?.hestiaBudget, 0, true)}</b>
            </Regular>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserHit
