import React, { FC, useState } from 'react'
import {
  Button,
  Code,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { impersonateQuery } from '../queries/impersonateQuery'
import { AxiosError } from 'axios'
import Header from '../../components/Typo/Header'
import Regular from '../../components/Typo/Regular'
import RegularLink from '../../components/Typo/RegularLink'
import CopyButton from '../../components/Code/CopyButton'
import { GetLinksOutput, getLinksQuery } from '../queries/getLinksQuery'
import Bold from '../../components/Typo/Bold'

const GetLinksCTA: FC<{
  userId: string
}> = ({ userId }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [links, setLinks] = useState<undefined | GetLinksOutput>(undefined)

  const getLinks = useMutation((id: string) => getLinksQuery(id), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data: GetLinksOutput) => {
      setLinks(data)
    },
  })

  return (
    <>
      <span onClick={() => onOpen()}>Générer les liens</span>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent padding='24px' paddingBottom='0px' gap='24px' width='fit-content'>
          {links ? (
            <>
              <Flex direction='column' gap='12px'>
                <Bold>Lien d'invitation business</Bold>
                <CopyButton code={links?.inviteUrl || ''} />
                <RegularLink onClick={() => window.open(links?.inviteUrl, '_blank')}>
                  {links?.inviteUrl}
                </RegularLink>
              </Flex>
              <Flex direction='column' gap='12px'>
                <Bold>Lien pour réinitialiser le mot de passe</Bold>
                <CopyButton code={links?.passwordResetUrl || ''} />
                <RegularLink onClick={() => window.open(links?.passwordResetUrl, '_blank')}>
                  {links?.passwordResetUrl}
                </RegularLink>
              </Flex>
            </>
          ) : (
            <>
              <Header>Générer les liens</Header>
              <Regular>Êtes vous sûr de vouloir générer les liens pour ce user ?</Regular>
              <Button colorScheme='green' variant='outline' onClick={() => getLinks.mutate(userId)}>
                Générer les liens
              </Button>
            </>
          )}

          <ModalFooter>
            <Flex justifyContent='flex-end' gap='16px'>
              <Button color='HestiaGrey' onClick={onClose}>
                Sortir
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default GetLinksCTA
