import { Box } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import CustomButton, { CustomButtonProps } from '../../dashboard/components/CustomButton'

interface ConfirmButtonProps {
  onSuccessAction: () => void
  headerText: string
  bodyText: string | ReactNode
  buttonText: string
  isDanger?: boolean
  disabled?: boolean
  isActionDisabled?: boolean
}

const ConfirmButton = ({
  onSuccessAction,
  buttonText,
  headerText,
  bodyText,
  isDanger,
  disabled,
  isActionDisabled,
  ...props
}: ConfirmButtonProps & CustomButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmit = () => {
    onSuccessAction()
    onClose()
  }

  return (
    <>
      <CustomButton
        onClick={disabled ? onSuccessAction : onOpen}
        disabled={isActionDisabled}
        colorScheme={isDanger ? 'red' : 'green'}
        {...props}
      >
        {buttonText}
      </CustomButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headerText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{bodyText}</Box>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' onClick={onClose} mr={3}>
              Annuler
            </Button>
            <Button
              colorScheme={isDanger ? 'red' : 'green'}
              onClick={onSubmit}
              disabled={isActionDisabled}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default ConfirmButton
