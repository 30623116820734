import React, { ReactNode } from 'react'
import {
  Alert,
  AlertDescription,
  Box,
  Flex,
  Tooltip,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { cx } from '@chakra-ui/utils'

interface BulletPointProps {
  text: string
  icon: ReactNode
  disabled?: boolean
  current?: boolean
}

const MenuItem = (props: BulletPointProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const toast = useToast()

  const getTextColor = () => {
    if (props.current) {
      return 'white'
    } else {
      return 'white'
    }
  }

  const handleClick = () => {
    if (props.disabled) {
      toast({
        title: 'Inscription en attente',
        description: '',
        status: 'info',
        position: 'bottom',
        render: () => (
          <Alert
            color='white'
            alignItems='center'
            borderRadius='md'
            boxShadow='lg'
            p={3}
            bg='#00755A'
            verticalAlign='middle'
            gap='8px'
          >
            <AiOutlineInfoCircle className={cx('chakra-alert__icon')} />
            <AlertDescription>
              Cet onglet sera disponible une fois l'inscription terminée
            </AlertDescription>
          </Alert>
        ),
      })
    }
  }

  const content = (
    <Flex
      align='center'
      padding='8px'
      borderRadius='8px'
      bg={props.current ? 'rgba(255, 255, 255, 0.15)' : 'initial'}
      w='100%'
      cursor='pointer'
      onClick={handleClick}
    >
      <Flex align='center' alignItems='center' flexDirection='row' w='20px' h='20px' color='white'>
        {props.icon}
        {/*{!props.current && !props.disabled ? <CheckIcon boxSize='12px' /> : props.number}*/}
      </Flex>
      <Box
        marginLeft={['8px', '16px']}
        color={getTextColor()}
        fontWeight={600}
        fontSize='16px'
        lineHeight='21px'
      >
        {props.text}
      </Box>
    </Flex>
  )

  return props.disabled && !isMobile ? (
    <Tooltip hasArrow label="Disponible une fois l'inscription terminée" placement='right'>
      {content}
    </Tooltip>
  ) : (
    content
  )
}

export default MenuItem
