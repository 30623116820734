import { atom } from 'jotai'

export enum DiscoveryTab {
  PROJECT = 'PROJECT',
  PEOPLE = 'PEOPLE',
  INCOME = 'INCOME',
  EXPENSES = 'EXPENSES',
  PATRIMONY = 'PATRIMONY',
}

export type DiscoveryTabData = {
  visibleHeight: number
  ref: React.RefObject<HTMLElement>
}

export type DiscoveryTabs =
  | {
      [key in DiscoveryTab]?: DiscoveryTabData
    }
  | undefined

export const isOnDiscoveryPageAtom = atom(false)
export const isDiscoveryHeaderStickyAtom = atom(false)
export const headerHeightAtom = atom(0)
export const isFloatingBudgetStickyAtom = atom(false)

export const discoveryTabsAtom = atom<DiscoveryTabs>(undefined)

export const currentEditFieldAtom = atom<string | undefined>(undefined)
