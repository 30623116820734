import { AddIcon } from '@chakra-ui/icons'
import { Button, Divider, Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import algoliasearch from 'algoliasearch'
import 'instantsearch.css/themes/satellite.css'
import React from 'react'
import {
  Configure,
  InstantSearch,
  Pagination,
  RangeInput,
  RefinementList,
} from 'react-instantsearch-hooks-web'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { transformProcessStage } from '../../utility/algoan/searchTransformations'
import { USERS_INDEX_NAME } from '../../utility/algolia'
import AssetProcessModalWrapper from '../assetAds/v2/AssetProcessModalWrapper'
import CustomHits from '../commandPalette/CustomHits'
import { getAlgoliaKeys } from '../commandPalette/queries/getAlgoliaKeys'
import AlgoliaCommonSearchBox from '../components/Algolia/AlgoliaCommonSearchBox'
import AlgoliaStats from '../components/Algolia/AlgoliaStats'
import UserHitComplete from '../components/Algolia/UserHitComplete'
import Bold from '../components/Typo/Bold'
import DashboardLayout from '../DashboardLayout'
import CreateUserAction from './actions/CreateUserAction'

const UsersListV2 = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  const algoliaKeys = useQuery(['getAlgoliaKeys'], () => getAlgoliaKeys(), {
    refetchInterval: 15000,
  })
  const navigate = useNavigate()

  if (!algoliaKeys.data?.secret) {
    return <Spinner />
  }

  const algoliaClient = algoliasearch(algoliaKeys.data?.appName, algoliaKeys.data?.secret)

  return (
    <DashboardLayout noPadding>
      <InstantSearch indexName={USERS_INDEX_NAME} searchClient={algoliaClient} routing={true}>
        {/* Widgets */}
        <Configure analytics={false} hitsPerPage={20} />
        <Flex direction='column' gap='0'>
          <Flex
            alignItems='flex-start'
            direction='row'
            justifyContent='flex-start'
            position='sticky'
            top='0'
            bg='white'
            zIndex='99'
            w='100%'
            pl='12'
            pr='12'
            pt='12'
            pb='8'
            boxShadow='0px 3px 16px rgba(0, 0, 0, 0.08)'
          >
            <AlgoliaCommonSearchBox />
          </Flex>

          <Flex gap='16px' padding='12' paddingTop='4'>
            <Flex
              direction='column'
              // gap='16px'
              // bg='white'
              // h='fit-content'
              // position='sticky'
              gap='16px'
            >
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Actions</Bold>
                <Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='end'>
                  <Button
                    colorScheme='green'
                    leftIcon={<AddIcon />}
                    size='sm'
                    onClick={onOpen}
                    w='100%'
                  >
                    Créer un compte
                  </Button>
                  <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
                    <CreateUserAction />
                  </AssetProcessModalWrapper>
                </Flex>
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Partenaire</Bold>
                <RefinementList searchable={true} attribute='partner.name' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Agence</Bold>
                <RefinementList searchable={true} attribute='agency.name' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Contact partenaire</Bold>
                <RefinementList searchable={true} attribute='personSource.email' />
                <Divider />
              </Flex>

              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Etape</Bold>
                <RefinementList attribute='processStage' transformItems={transformProcessStage} />
                <Divider />
              </Flex>

              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Budget</Bold>
                <RangeInput attribute='hestiaBudget' precision={0} prefix='€' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Département</Bold>
                <RefinementList searchable={true} attribute='departmentWanted' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Code postal</Bold>
                <RefinementList searchable={true} attribute='postalCodeWanted' />
                <Divider />
              </Flex>
            </Flex>
            <Flex direction='column' w='100%'>
              {/* <InfiniteHits hitComponent={AssetHit} /> */}

              <CustomHits HitComponent={UserHitComplete} />
              <Flex gap='8px' alignItems='center'>
                <Pagination />
                <AlgoliaStats />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </InstantSearch>
    </DashboardLayout>
  )
}

export default UsersListV2
