import {
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Input,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh'
import TextInput from '../../../shared/components/inputs/InlineLayout/TextInput'
import { getAssetAdById } from '../queries/getAssetAdById'
import { UpdateAssetAdInputs, updateAssetAd } from '../queries/updateAssetAd'
import HelperSmall from '../../components/Typo/HelperSmall'
import { isNotionUrl } from '../../../utility/urls'

type Props = {}

const UpdateNotionUrl = (props: Props) => {
  const toast = useToast()
  const initialValues = {
    notionUrl: undefined,
  }
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      toast({
        title: 'Succès!:',
        description: 'All good',
        status: 'success',
        position: 'top-right',
      })
    },
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        console.log(values)
        updateMutation.mutate({ id: id!, notionUrl: values.notionUrl })
      }}
    >
      {(formik) => (
        <>
          <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
          <Flex gap='8px' justifyContent='flex-start' alignItems='flex-end'>
            <TextInput size='sm' field={'notionUrl'} label={'URL Notion'} />
            <Button
              size='sm'
              onClick={() => formik.handleSubmit()}
              colorScheme='green'
              variant='outline'
              disabled={!isNotionUrl(formik.values.notionUrl || '')}
            >
              Sauvegarder
            </Button>
          </Flex>
          <Button
            w='fit-content'
            size='md'
            onClick={() =>
              window.open(assetAd.data?.notionUrl || '', '_blank', 'noopener,noreferrer')
            }
            colorScheme='green'
          >
            Voir le Notion
          </Button>
        </>
      )}
    </Formik>
  )
}

export default UpdateNotionUrl
