import { FormControl, FormHelperText } from '@chakra-ui/react'
import { OptionBase, Select, Size } from 'chakra-react-select'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

export interface SelectOption extends OptionBase {
  label: string
  value: string | number
}

type Props = {
  field: string
  label: string
  placeHolder?: string
  isRequired?: boolean
  options: SelectOption[]
  selectSize?: Size
  autoFocus?: boolean
}

const SelectInput = (props: InputWrapperProps & Props) => {
  const formik = useFormikContext<FormikValues>()

  const selectedOption = props.options?.find((o) => o.value === formik.values[props.field])

  console.log(selectedOption)

  return (
    <InputWrapper field={props.field} label={props.label} isRequired={props.isRequired}>
      <FormControl>
        <Select
          id={props.field}
          value={selectedOption !== undefined ? selectedOption : null}
          onChange={(singleValue) => {
            formik.setFieldValue(
              props.field,
              singleValue?.value !== undefined ? singleValue?.value : undefined,
            )
          }}
          onBlur={formik.handleBlur}
          options={props.options}
          size={props.selectSize}
          autoFocus={props.autoFocus}
        />

        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </InputWrapper>
  )
}

export default SelectInput
