import dayjs from 'dayjs'

/**
 * Calcule la durée du prêt basée sur la date de naissance.
 *
 * @param dob Date de naissance de l'utilisateur (Date ou chaîne de caractères).
 * @returns Durée du prêt en années.
 */
export function calculateLoanDuration(dob: Date | string): number {
  const dobDayjs = dayjs(dob)
  const currentAge = dayjs().diff(dobDayjs, 'year')

  // Calculer l'âge à la fin du prêt de 25 ans
  const ageAtEndOf25YearsLoan = currentAge + 25

  // Retourner la durée du prêt
  return ageAtEndOf25YearsLoan <= 75 ? 25 : 20
}
