import { Box, SystemStyleObject } from '@chakra-ui/react'
import { AsyncSelect } from 'chakra-react-select'
import React, { FC, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import Regular from '../../../dashboard/components/Typo/Regular'
import { searchPostalCode } from '../queries/searchPostalCode'

export interface InseePostalCode {
  id: string
  postalCode: string
  latitude: number
  longitude: number
  region: string
  department: string
}

const PostalCodeInput: FC<{
  postalCode: { label: string; value: string } | undefined
  setPostalCode: (postalCode: { label: string; value: string } | undefined) => void
}> = ({ postalCode, setPostalCode }) => {
  useEffect(() => {
    console.log(postalCode)
  }, [postalCode])

  const searchPostalCodeMutation = useMutation((search: string) => searchPostalCode(search))

  const debouncedFetchSuggestions = useDebouncedCallback((inputValue, callback) => {
    searchPostalCodeMutation.mutateAsync(inputValue).then((postalCodeResults) =>
      callback(
        (postalCodeResults || []).map((postalCodeResult) => ({
          label: `${postalCodeResult.postalCode} (${postalCodeResult.department})`,
          value: postalCodeResult.id,
        })),
      ),
    )
  }, 200)

  const messagesSx: SystemStyleObject = {
    textAlign: 'center',
  }

  const customComponents = {
    NoOptionsMessage: ({}) => (
      <Box sx={messagesSx}>
        <Regular>Pas de résultats</Regular>
      </Box>
    ),
    LoadingMessage: ({}) => (
      <Box sx={messagesSx}>
        <Regular>Chargement...</Regular>
      </Box>
    ),
  }

  return (
    <Box width={'100%'}>
      <AsyncSelect
        size='lg'
        value={postalCode}
        onChange={(currentValue) => {
          currentValue ? setPostalCode(currentValue) : setPostalCode(undefined)
        }}
        className='select-container'
        name='address'
        placeholder='Code postal'
        loadOptions={debouncedFetchSuggestions}
        components={customComponents}
      />
    </Box>
  )
}

export default PostalCodeInput
