import { Checkbox, FormControl, FormHelperText } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

type Props = {
  field: string
  label?: string
  checkboxLabel?: string
  placeHolder?: string
  isRequired?: boolean
  autoFocus?: boolean
}

const BooleanInput = (props: InputWrapperProps & Props) => {
  const formik = useFormikContext<FormikValues>()

  return (
    <InputWrapper field={props.field} label={props.label} isRequired={props.isRequired}>
      <FormControl>
        <Checkbox
          placeholder={props.placeHolder}
          id={props.field}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[props.field]}
          isChecked={formik.values[props.field]}
          autoFocus={props.autoFocus}
        >
          {props.checkboxLabel}
        </Checkbox>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </InputWrapper>
  )
}

export default BooleanInput
