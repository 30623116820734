import _ from 'lodash'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'

export type UpdateDiscoveryBankPayload = {
  id: string
  name: string
}

export const updateDiscoveryBankVisited = async (
  userId: string,
  payload: UpdateDiscoveryBankPayload,
) => {
  const finalPayload = _.omit(payload, 'id')
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/banks/${payload.id}`,
    finalPayload,
  )) as Promise<void>
}
