import React, { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import { IncomesV2, Loans } from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import AlgoanIncomeDetails from './IncomeDetails'

const AlgoanIncomes = ({ incomes }: { incomes: IncomesV2 | undefined }) => {
  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      padding='24px'
      direction='column'
      gap='8px'
      w='100%'
    >
      <Accordion allowToggle w='100%'>
        <AccordionItem w='100%' border='0'>
          <AccordionButton textAlign='left' p='0' w='100%'>
            <Flex direction='column' gap='8px' w='100%'>
              <Header fontSize='24px'>
                Incomes{' '}
                <Box as='span' fontSize='16px'>
                  (
                  <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                    {getPrettyNumber(incomes?.monthlyAmount)}
                  </Box>{' '}
                  / month)
                </Box>
              </Header>

              <Regular>
                <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                  {(incomes?.indicators.allowancesRatio || 0) * 100}%
                </Box>{' '}
                <Box as='span' fontSize='12px'>
                  d'allocations
                </Box>
                {' / '}
                <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                  {getPrettyNumber(incomes?.totalAmount)}
                </Box>{' '}
                <Box as='span' fontSize='12px'>
                  total
                </Box>
              </Regular>
            </Flex>

            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex gap='8px' flex='1 1 0px' flexWrap='wrap'>
              {incomes?.categories.map((category) => (
                <AlgoanIncomeDetails categoryGroup={category} />
              ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default AlgoanIncomes
