import SHA256 from 'crypto-js/sha256'
import _ from 'lodash'
import { DiscoveryTab, DiscoveryTabData, DiscoveryTabs } from '../atoms/discoveryAtom'

export const getFileNameFromKey = (name?: string) => {
  return name
    ? name.replace(/^[\da-f]{8}-([\da-f]{4})-([\da-f]{4})-([\da-f]{4})-([\da-f]{12})-/, '')
    : ''
}

export const isPDFOrPicture = (url?: string) => {
  return url ? /\.(pdf|jpg|jpeg|png|gif)$/i.test(url) : false
}

export type SimpleFileType = 'pdf' | 'image' | 'other'

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  return emailRegex.test(email)
}

export function hashObject(obj: object): string {
  const jsonString = JSON.stringify(obj, Object.keys(obj).sort())

  // Create a hash using SHA-256
  const hash = SHA256(jsonString)

  // Return the hash in hex format (this will be the unique ID)
  return hash.toString()
}

export const getMaxPercentageVisibleTab = (
  discoveryTabs: DiscoveryTabs,
): DiscoveryTab | undefined => {
  if (!discoveryTabs) {
    return undefined
  }

  // Flatten the object to an array of entries [tab, TabData]
  const entries = Object.entries(discoveryTabs) as [
    keyof typeof DiscoveryTab,
    DiscoveryTabData | undefined,
  ][]

  // Use lodash to find the entry with the maximum percentageVisible
  const maxEntry = _.maxBy(entries, ([, value]) => value?.visibleHeight || 0)

  // Return the tab with the highest percentageVisible
  return maxEntry ? DiscoveryTab[maxEntry[0]] : undefined
}

export function isStringAter<T extends Record<string, number | string>>(
  firstStage: keyof T | string | undefined,
  secondStage: keyof T | string | undefined,
  stagesEnum: T,
): boolean {
  if (!firstStage || !secondStage) {
    return false
  }

  const stages = Object.keys(stagesEnum) as Array<keyof T>
  const firstIndex = stages.indexOf(firstStage)
  const secondIndex = stages.indexOf(secondStage)

  if (firstIndex === -1 || secondIndex === -1) {
    return false
  }

  return firstIndex > secondIndex
}

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function countWords(text: string) {
  // Trim the text to remove leading/trailing spaces
  text = text.trim()

  // Split the text by spaces, newlines, and other whitespace characters
  const words = text.split(/\s+/)

  // Filter out any empty strings in case there are multiple spaces in a row
  const filteredWords = words.filter((word) => word.length > 0)

  // Return the word count
  return filteredWords.length
}

export const scrollToElement = (element: HTMLElement, offset: number): void => {
  console.log(`scrolling to element ${element}, offset ${offset}`)
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

export const getFileExtension = (fileName?: string): string => {
  if (!fileName) return ''

  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
    return ''
  }

  return fileName.substring(lastDotIndex + 1)
}

export const getFileType = (url?: string): SimpleFileType => {
  if (!url) return 'other'
  if (/\.(pdf)$/i.test(url)) {
    return 'pdf'
  } else if (/\.(jpg|jpeg|png|gif)$/i.test(url)) {
    return 'image'
  }
  return 'other'
}

export const forceDownload = (fileName: string, url: string) => {
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = fileName
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}
