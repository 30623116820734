import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface AdminsResponseSingle {
  createdAt: string
  id: string
  email: string
  teams: TeamsEnum[]
}

const TeamsEnum = {
  SUPPLY: 'SUPPLY',
  DEMAND: 'DEMAND',
  CREDIT: 'CREDIT',
  FUND: 'FUND',
  MARKETING: 'MARKETING',
  DEV: 'DEV',
  PARTNERSHIPS: 'PARTNERSHIPS',
  EXEC: 'EXEC',
  ALL: 'ALL',
}

export type TeamsEnum = typeof TeamsEnum[keyof typeof TeamsEnum]

export interface AdminsResponse {
  admins: AdminsResponseSingle[]
}

export const getAdmins = async (): Promise<AdminsResponse> => {
  return await authenticatedGetQuery('/admin/admins')
}
