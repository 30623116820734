import { Button, Flex, ModalFooter, SimpleGrid, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import ConfirmButton from '../../../shared/components/ConfirmButton'
import PhoneNumberInputInline from '../../../shared/components/inputs/InlineLayout/PhoneNumberInlineInput'
import TextInput from '../../../shared/components/inputs/InlineLayout/TextInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import { AddPersonToAgencyInputs, addPersonToAgencyQuery } from '../queries/addPersonToAgencyQuery'
import { validateAddPerson } from './validateAddPerson'

type Props = {
  agencyId: string
}

export type AddPersonToAgencyValues = {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phone: string | undefined
  hubspotId: string | undefined
}

const AddPersonToAgencyAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: AddPersonToAgencyValues = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    hubspotId: undefined,
  }

  const updateMutation = useMutation(
    (inputs: AddPersonToAgencyInputs) => addPersonToAgencyQuery(inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['partner', { id }])
        successToast({ description: 'All good' })
      },
    },
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validateAddPerson}
        onSubmit={async (values, formik) => {
          console.log(values)
          updateMutation.mutate(
            {
              ...values,
              partnerId: id,
              agencyId: props.agencyId,
            },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Ajouter une personne à l’agence'
                // subtitle='Un email sera envoyé pour créer son mot de passe.'
              />
              <Flex gap='24px' direction='column'>
                <SimpleGrid columns={2} gap='16px'>
                  <TextInput label='Prénom' placeholder='Antoine' field={'firstName'} />
                  <TextInput label='Nom' placeholder='Dupont' field={'lastName'} />
                </SimpleGrid>
                <SimpleGrid columns={2} gap='16px'>
                  <TextInput
                    label='Email'
                    placeholder='antoine.d@hestia.fr'
                    field={'email'}
                    required
                  />
                  <PhoneNumberInputInline
                    placeholder='0687054447'
                    field='phone'
                    isRequired
                    label='Numéro de téléphone'
                  />
                </SimpleGrid>
                <TextInput label='Hubspot ID' placeholder='10574815' field={'hubspotId'} />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <ConfirmButton
                  bodyText="Comme il n'y a pas d'ID Hubspot, cela va créer un nouveau contact. Êtes-vous sûr qu'il n'existe pas déjà ?"
                  buttonText={'Créer le contact'}
                  headerText="Le contact n'existe pas dans Hubspot ?"
                  disabled={!!formik.values.hubspotId && formik.values.hubspotId !== ''}
                  isActionDisabled={!formik.isValid}
                  onSuccessAction={() => {
                    formik.submitForm()
                  }}
                />
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default AddPersonToAgencyAction
