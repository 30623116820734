import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface GetLeasingProjectsQueryInputs {
  page: number
  search: string | undefined
}

export interface LeasingProjectsResponseSingle {
  id: string
  createdAt: string
  rentAmount: number
  cashbackOnRent: number
  user: { email: 'pauldgse@heyhestia.com'; firstName: 'Paul'; lastName: 'Lefebvre' }
  fundsGroup: { name: string }
}

export interface LeasingProjectsResponse {
  leasingProjects: LeasingProjectsResponseSingle[]
}

export const getLeasingProjectsQuery = async <leasingProjectsResponse>(
  getLeasingProjectsQueryInputs: GetLeasingProjectsQueryInputs,
) => {
  return await authenticatedGetQuery('/admin/leasingProjects', {
    page: getLeasingProjectsQueryInputs.page,
    search: getLeasingProjectsQueryInputs.search,
  })
}
