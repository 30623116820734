import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'

export const useCustomToast = () => {
  const toast = useToast()

  const errorToast = (error: AxiosError) => {
    toast({
      title: 'Erreur:',
      description: error.message,
      status: 'error',
      position: 'top-right',
      isClosable: true,
    })
  }

  const successToast = ({ description }: { description: string }) => {
    toast({
      title: 'Succès!:',
      description: description,
      status: 'success',
      position: 'bottom',
      isClosable: true,
      duration: 2000,
    })
  }

  return { errorToast, successToast }
}
