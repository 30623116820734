import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import { Loans } from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import { useAtom } from 'jotai'
import { isTransactionsModalOpenedAtom, transactionModalContentAtom } from '../../../atoms/algoan'

const AlgoanLoans = ({ loans }: { loans: Loans | undefined }) => {
  const [isOpen, setIsOpen] = useAtom(isTransactionsModalOpenedAtom)
  const [transactionsModalContent, setTransactionsModalContent] = useAtom(
    transactionModalContentAtom,
  )

  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      padding='24px'
      direction='column'
      gap='8px'
      w='100%'
      cursor='pointer'
      onClick={() => {
        setTransactionsModalContent({
          title: 'Loans',
          transactions: [
            ...(loans?.repayments.details.flatMap((cf) => cf.transactions) || []),
            ...(loans?.drawdowns.details.flatMap((cf) => cf.transactions) || []),
          ],
        })
        setIsOpen(true)
      }}
    >
      <Header fontSize='24px'>Loans </Header>

      <Regular>
        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
          {loans?.drawdowns.count}
        </Box>{' '}
        <Box as='span' fontSize='12px'>
          drawdowns
        </Box>
        {' / '}
        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
          {loans?.repayments.count}
        </Box>{' '}
        <Box as='span' fontSize='12px'>
          repayments
        </Box>
      </Regular>
      {/*{(loans?.drawdowns.details || loans?.repayments.details) && (*/}
      {/*  <CashflowsTable cashflows={[...loans?.drawdowns.details, ...loans?.repayments.details]} />*/}
      {/*)}*/}
    </Flex>
  )
}

export default AlgoanLoans
