import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
} from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

type Props = ModalProps & { maxW?: string }

const AssetProcessModalWrapper = (props: PropsWithChildren<Props>) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent maxW={props.maxW}>{props.children}</ModalContent>
    </Modal>
  )
}

export default AssetProcessModalWrapper
