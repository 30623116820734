import React, { FC, ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react'

const Subtitle: FC<
  TextProps & {
    children?: ReactNode
  }
> = ({ children, ...props }) => {
  return (
    <Text
      color='#6F6F6F'
      maxWidth={['100%', '600px']}
      fontSize='20px'
      fontWeight='400'
      fontFamily='HKGroteskMedium'
      lineHeight='26px'
      {...props}
    >
      {children}
    </Text>
  )
}

export default Subtitle
