import React, { FC, ReactNode } from 'react'
import { Heading } from '@chakra-ui/react'
import * as _chakra_ui_system from '@chakra-ui/system'
import { HeadingProps } from '@chakra-ui/layout/dist/heading'

const Header: FC<
  HeadingProps & {
    children?: ReactNode
    color?: string
    fontSize?: string
  }
> = ({ children, color = '#333333', fontSize = '24px', ...props }) => {
  return (
    <Heading color={color} fontSize={fontSize} {...props}>
      {children}
    </Heading>
  )
}

export default Header
