import axios from 'axios'
import { apiUrl } from '../config'
import { getAuthToken } from '../../auth/AuthProvider'
import { handleQueryError } from './handleQueryError'

export const authenticatedDeleteQuery = async (path: string, params?: any) => {
  return await axios
    .delete(apiUrl + path, {
      params,
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    })
    .then(function (response) {
      console.log(response)
      return response.data
    })
    .catch(function (error) {
      handleQueryError(error)
    })
}
