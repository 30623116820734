import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { usePreviousValue } from '../../utility/hooks/usePreviousValue'
import { getAssetAdById } from './queries/getAssetAdById'
import RegularLink from '../components/Typo/RegularLink'
import { AssetAdStatus } from '../../interfaces/prisma'
import Regular from '../components/Typo/Regular'
import { updateAssetAd, UpdateAssetAdInputs } from './queries/updateAssetAd'
import UserFinancialSummary from '../components/UserFinancialSummary'
import GoogleAddressInput, { GoogleAddressOutput } from '../components/inputs/GoogleAddressInput'
import CopyButton from '../components/Code/CopyButton'
import ConfirmButton from '../../shared/components/ConfirmButton'
import FileUploadResult from '../components/inputs/FileUploadResult'

const AssetAdDetail = () => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()
  const [address, setAddress] = useState('')
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [place, setPlace] = useState<GoogleAddressOutput | undefined>()
  const queryClient = useQueryClient()
  const warningRejectionsWords = ['Bonjour', 'Hello', 'Merci', 'Cordialement']

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      toast({
        title: 'Succès!:',
        description: 'All good',
        status: 'success',
        position: 'top-right',
      })
    },
  })

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  useEffect(() => {
    formik.setFieldValue('address', place)
  }, [place])

  const prevAssetAd = usePreviousValue(assetAd.data) as typeof assetAd.data

  const formik = useFormik({
    initialValues: {
      hestiaStatus: undefined,
      userStatus: undefined,
      address: undefined,
      rejectionMessage: '',
    },
    onSubmit: (values) => {
      updateMutation.mutate({
        id: id!,
        placeId: place?.value.place_id,
        hestiaStatus: values.hestiaStatus,
        userStatus: values.userStatus,
      })
    },
  })

  useEffect(() => {
    if (assetAd.data !== prevAssetAd) {
      formik.setFieldValue('hestiaStatus', assetAd.data?.hestiaStatus)
      formik.setFieldValue('userStatus', assetAd.data?.userStatus)
      formik.setFieldValue('rejectionMessage', assetAd.data?.rejectionMessage)
    }
  }, [assetAd.data])

  useEffect(() => {
    console.log(prevAssetAd?.hestiaStatus, assetAd.data?.hestiaStatus)
    if (
      assetAd.data?.hestiaStatus === AssetAdStatus.PENDING &&
      formik.values.hestiaStatus === AssetAdStatus.REJECTED
    ) {
      setIsSendingEmail(true)
    } else {
      setIsSendingEmail(false)
    }
  }, [formik.values.hestiaStatus])

  useEffect(() => {
    console.log(address)
  }, [address])

  return (
    <Flex>
      <Flex w='50%' direction='column' gap='64px' padding='12px'>
        <Flex direction='column' gap='16px'>
          <form onSubmit={formik.handleSubmit} style={{ height: '100%', width: '100%' }}>
            <Flex direction='column' gap='16px'>
              <Regular>
                <b>Nom:</b> {assetAd.data?.name}
              </Regular>
              <Regular>
                <Flex gap='8px'>
                  <b>Lien:</b>
                  <RegularLink
                    onClick={() =>
                      window.open(assetAd.data?.url || '', '_blank', 'noopener,noreferrer')
                    }
                  >
                    {assetAd.data?.url}
                  </RegularLink>
                </Flex>
              </Regular>
              <Flex direction='column' gap='16px' maxW='75%'>
                <FormControl isRequired>
                  <FormLabel>Hestia status</FormLabel>
                  <Select
                    placeholder='Hestia Status'
                    id='accountState'
                    onChange={(e) => {
                      formik.setFieldValue('hestiaStatus', e.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.hestiaStatus}
                  >
                    {Object.values(AssetAdStatus).map((assetAdStatus: string) => (
                      <option value={assetAdStatus}>{assetAdStatus}</option>
                    ))}
                  </Select>
                </FormControl>
                {formik.values.hestiaStatus === AssetAdStatus.REJECTED && (
                  <FormControl isRequired>
                    <FormLabel>Raison du refus (Envoyée et affichée au user)</FormLabel>
                    <Textarea
                      placeholder='Raison du refus'
                      onBlur={formik.handleBlur}
                      value={formik.values.rejectionMessage}
                      onChange={formik.handleChange}
                      id='rejectionMessage'
                    />
                    {warningRejectionsWords.some(
                      (substring) =>
                        formik.values.rejectionMessage &&
                        formik.values.rejectionMessage
                          .toLowerCase()
                          .includes(substring.toLowerCase()),
                    ) && (
                      <FormHelperText color='red'>
                        La raison sera accompagné des formules de politesses. Pas besoin de répéter
                        les "Bonjour" et "Cordialement" ici :)
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                <FormControl isRequired>
                  <FormLabel>User status</FormLabel>
                  <Select
                    placeholder='User Status'
                    id='accountState'
                    onChange={(e) => {
                      formik.setFieldValue('userStatus', e.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.userStatus}
                  >
                    {Object.values(AssetAdStatus).map((assetAdStatus: string) => (
                      <option value={assetAdStatus}>{assetAdStatus}</option>
                    ))}
                  </Select>
                </FormControl>
                <Flex direction='column' gap='16px'>
                  <FormLabel>Fichiers associés</FormLabel>
                  <Flex gap='0px' direction='column' maxW='800px' w='100%'>
                    {assetAd.data?.userFiles.map((f) => (
                      <FileUploadResult name={f.key.slice(37)} id={f.id} />
                    ))}
                  </Flex>
                </Flex>
                <Flex direction='column' gap='16px'>
                  <FormLabel>Notes du user</FormLabel>
                  <Flex gap='0px' direction='column' maxW='800px' w='100%'>
                    <Textarea value={assetAd.data?.notes || ''} />
                  </Flex>
                </Flex>

                <FormControl>
                  <FormLabel htmlFor='cashbackOnRent'>Adresse</FormLabel>
                  {assetAd.data && (
                    <>
                      <CopyButton
                        code={assetAd.data?.address?.formattedAddress || ''}
                        label="Copier l'adresse"
                      />
                      <GoogleAddressInput
                        defaultValue={{
                          placeId: assetAd.data?.address?.placeId,
                          formattedAddress: assetAd.data?.address?.formattedAddress || '',
                        }}
                        place={formik.values.address}
                        setPlace={(place: GoogleAddressOutput | undefined) => {
                          setPlace(place)
                        }}
                      />
                    </>
                  )}
                </FormControl>
                <Divider />
                {/*<ConfirmButton*/}
                {/*  bodyText='Cette action va envoyer un mail à un utilisateur comme il est passé de PENDING vers un autre état'*/}
                {/*  buttonText={isSendingEmail ? 'Enregistrer et envoyer un email' : 'Enregistrer'}*/}
                {/*  headerText='Cette action va envoyer un email'*/}
                {/*  onSuccessAction={formik.submitForm}*/}
                {/*  disabled={!isSendingEmail}*/}
                {/*/>*/}
                <Button w='fit-content' colorScheme='green' type='submit'>
                  Enregistrer les modification
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
      <Flex w='50%' height='fit-content' direction='column' gap='24px'>
        {assetAd.data?.user && <UserFinancialSummary user={assetAd.data?.user} />}
      </Flex>
    </Flex>
  )
}

export default AssetAdDetail
