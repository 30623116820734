import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface ImpersonateResponse {
  token: string
}

export const impersonateQuery = async (id: string) => {
  return await authenticatedGetQuery(`/admin/users/${id}/impersonate`)
}
// await (await fetch("http://localhost:3004/users")).json();
