import { atomWithHash, atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { CashFlowV2 } from '../utility/algoan/types/CreditInsights.interface'
import { Transaction } from '../utility/algoan/types/Transaction.interface'

export type TransactionModalContent = {
  title: string
  transactions: Transaction[]
}

export const cashFlowsColumnsVisibilityAtom = atomWithStorage('cfColumnsVisibles', {})
export const cashFlowsSelectedAtom = atom<CashFlowV2[]>([])

export const isTransactionsModalOpenedAtom = atom<boolean>(false)
export const transactionModalContentAtom = atom<TransactionModalContent>({
  title: '',
  transactions: [],
})
