import { UserLostReasonEnum } from '../../../interfaces/prisma'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface SetUserAsLostPayload {
  lostReason: UserLostReasonEnum | undefined
}

export const setUserAsLostQuery = async (id: string, payload: SetUserAsLostPayload) => {
  return await authenticatedPostQuery(`/admin/users/${id}/action/lost`, payload)
}
