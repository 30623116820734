import axios from 'axios'
import { apiUrl } from '../../../utility/config'

export type SearchPostalCodeResult = {
  department: string
  region: string
  latitude: number
  longitude: number
  id: string
  postalCode: string
}

export const searchPostalCode = async (search: string): Promise<SearchPostalCodeResult[]> => {
  return await axios
    .get(`${apiUrl}/postalCode/search`, {
      params: { search },
    })
    .then(function (response) {
      return response.data as SearchPostalCodeResult[]
    })
    .catch(function (error) {
      throw error
    })
}
