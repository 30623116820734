import { Button, Card, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../utility/routes/routeConstants'
import { isNotionUrl } from '../../../utility/urls'
import Bold from '../../components/Typo/Bold'
import { getAssetAdById } from '../queries/getAssetAdById'
import AnalysisAction from './actions/AnalysisAction'
import ChangeStepAction from './actions/ChangeStepAction'
import SetAsLostAction from './actions/SetAsLostAction'
import AssetProcessModalWrapper from './AssetProcessModalWrapper'

type Props = {}

const AssetAdQuickActions = (props: Props) => {
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const navigate = useNavigate()
  const {
    isOpen: isChangeStepActionOpen,
    onOpen: onChangeStepActionOpen,
    onClose: onChangeStepActionClose,
  } = useDisclosure()
  const {
    isOpen: isSetAsLostOpen,
    onOpen: onSetAsLostOpen,
    onClose: onSetAsLostClose,
  } = useDisclosure()

  return (
    <Card
      border={assetAd.data?.isLost ? '1px solid' : 'initial'}
      borderColor={assetAd.data?.isLost ? 'HestiaRed' : 'initial'}
    >
      <AssetProcessModalWrapper isOpen={isChangeStepActionOpen} onClose={onChangeStepActionClose}>
        <ChangeStepAction />
      </AssetProcessModalWrapper>
      <AssetProcessModalWrapper isOpen={isSetAsLostOpen} onClose={onSetAsLostClose}>
        <SetAsLostAction />
      </AssetProcessModalWrapper>

      <Flex direction='column' gap='8px' padding='16px'>
        <Bold>Voir</Bold>
        <Flex direction='row' gap='8px' flexFlow='wrap'>
          <Button
            variant='outline'
            colorScheme='blue'
            size='sm'
            w='fit-content'
            onClick={() => navigate(`/${ROUTES.ASSETADS_BASE}/${id}/${ROUTES.ASSETADS_ANALYSIS}`)}
          >
            Espace analyse
          </Button>
          <Button
            onClick={() =>
              window.open(assetAd.data?.notionUrl || '', '_blank', 'noopener,noreferrer')
            }
            disabled={!isNotionUrl(assetAd.data?.notionUrl || '')}
            variant='outline'
            colorScheme='blue'
            size='sm'
            w='fit-content'
          >
            Page notion liée
          </Button>
          <Button
            variant='outline'
            colorScheme='blue'
            size='sm'
            w='fit-content'
            disabled={!assetAd.data?.hubspotDealId}
            onClick={() =>
              window.open(
                `https://app-eu1.hubspot.com/contacts/26051163/record/0-3/${assetAd.data?.hubspotDealId}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Voir sur hubspot
          </Button>
          <Button
            variant='outline'
            colorScheme='gray'
            size='sm'
            w='fit-content'
            onClick={() => navigate(`/${ROUTES.ASSETADS_BASE}/${id}/old`)}
          >
            Voir l'ancien BO
          </Button>
        </Flex>
        <Bold>Actions</Bold>
        <Flex direction='row' gap='8px' flexFlow='wrap'>
          <Button
            variant='outline'
            colorScheme='blue'
            size='sm'
            w='fit-content'
            onClick={onChangeStepActionOpen}
          >
            Changer l'étape manuellement
          </Button>

          {assetAd.data?.isLost ? (
            <Button
              onClick={onSetAsLostOpen}
              variant='outline'
              colorScheme='green'
              size='sm'
              w='fit-content'
            >
              Modifier le bien en non "Lost"
            </Button>
          ) : (
            <Button
              onClick={onSetAsLostOpen}
              variant='outline'
              colorScheme='red'
              size='sm'
              w='fit-content'
            >
              Modifier le bien en "Lost"
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

export default AssetAdQuickActions
