import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { calculateLoanDuration } from '../../../shared/backShared/sharedUtils'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { usePreviousValue } from '../../../utility/hooks/usePreviousValue'
import { parseNumber } from '../../../utility/parseNumber'
import UserBudgetDetails from '../../components/UserBudgetDetails'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { getUserQuery } from '../queries/userQuery'

const UserIncome: FC<{}> = ({}) => {
  const { id } = useParams()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const { errorToast, successToast } = useCustomToast()
  const prevUser = usePreviousValue(userQuery.data?.user)

  const updateMutation = useMutation(
    (updateUserInputs: UpdateUserQueryInputs) => updateUserQuery(updateUserInputs),
    {
      onError: (error: AxiosError, variables, context) => errorToast(error),
      onSuccess: (data) =>
        successToast({ description: 'Vos modifications ont bien été enregistrées.' }),
    },
  )

  const formik = useFormik({
    initialValues: {
      monthlyIncome: userQuery.data?.user.userInformations?.monthlyIncome,
      monthlyIncomeCobuyer: userQuery.data?.user.userInformations?.monthlyIncomeCobuyer,
      monthlyDebt: userQuery.data?.user.userInformations?.monthlyDebt,
      expectedGains: userQuery.data?.user.userInformations?.expectedGains,
      deposit: userQuery.data?.user.userInformations?.deposit,
      loanDuration: userQuery.data?.user.userInformations?.loanDuration,
      durationInMonths: userQuery.data?.user.userInformations?.durationInMonths,
      debtToIncomeRatio: userQuery.data?.user.userInformations?.debtToIncomeRatio,
    },
    onSubmit: (values) => {
      console.log(values)
      updateMutation.mutate({
        id,
        monthlyIncome: values.monthlyIncome,
        monthlyIncomeCobuyer: values.monthlyIncomeCobuyer,
        monthlyDebt: values.monthlyDebt,
        expectedGains: values.expectedGains,
        deposit: values.deposit,
        loanDuration: values.loanDuration,
        durationInMonths: values.durationInMonths,
        debtToIncomeRatio: values.debtToIncomeRatio,
      })
    },
  })

  useEffect(() => {
    if (userQuery.data?.user !== prevUser) {
      formik.setFieldValue('monthlyIncome', userQuery.data?.user.userInformations?.monthlyIncome)
      formik.setFieldValue(
        'monthlyIncomeCobuyer',
        userQuery.data?.user.userInformations?.monthlyIncomeCobuyer,
      )
      formik.setFieldValue('monthlyDebt', userQuery.data?.user.userInformations?.monthlyDebt)
      formik.setFieldValue('expectedGains', userQuery.data?.user.userInformations?.expectedGains)
      formik.setFieldValue('deposit', userQuery.data?.user.userInformations?.deposit)
      formik.setFieldValue('loanDuration', userQuery.data?.user.userInformations?.loanDuration)
      formik.setFieldValue(
        'durationInMonths',
        userQuery.data?.user.userInformations?.durationInMonths,
      )
      formik.setFieldValue(
        'debtToIncomeRatio',
        userQuery.data?.user.userInformations?.debtToIncomeRatio,
      )
    }
  }, [userQuery.data?.user])

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: '100%', width: '100%' }}>
      <Flex gap='24px' flexDirection='column'>
        <SimpleGrid minChildWidth='240px' spacing='40px'>
          <FormControl isRequired w='100%'>
            <FormLabel htmlFor='firstName'>Monthly income</FormLabel>
            <Input
              placeholder=''
              size='md'
              type='string'
              id='monthlyIncome'
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('monthlyIncome', parseNumber(e.target.value))}
              value={
                formik.values.monthlyIncome && !isNaN(formik.values.monthlyIncome)
                  ? formik.values.monthlyIncome.toString()
                  : 0
              }
            />
          </FormControl>
          <FormControl isRequired w='100%'>
            <FormLabel htmlFor='firstName'>Cobuyer monthly income</FormLabel>
            <Input
              placeholder=''
              size='md'
              type='string'
              id='monthlyIncomeCobuyer'
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.setFieldValue('monthlyIncomeCobuyer', parseNumber(e.target.value))
              }
              value={
                formik.values.monthlyIncomeCobuyer && !isNaN(formik.values.monthlyIncomeCobuyer)
                  ? formik.values.monthlyIncomeCobuyer.toString()
                  : 0
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor='firstName'>Monthly Debt</FormLabel>
            <Input
              placeholder=''
              size='md'
              type='string'
              id='monthlyDebt'
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('monthlyDebt', parseNumber(e.target.value))}
              value={
                formik.values.monthlyDebt && !isNaN(formik.values.monthlyDebt)
                  ? formik.values.monthlyDebt.toString()
                  : 0
              }
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor='firstName'>Deposit</FormLabel>
            <Input
              placeholder=''
              size='md'
              type='string'
              id='deposit'
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('deposit', parseNumber(e.target.value))}
              value={
                formik.values.deposit && !isNaN(formik.values.deposit)
                  ? formik.values.deposit.toString()
                  : 0
              }
            />
          </FormControl>

          <FormControl isRequired h='100%'>
            <FormLabel htmlFor='firstName'>Gains futurs estimés</FormLabel>
            <Input
              placeholder=''
              size='md'
              type='string'
              id='expectedGains'
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('expectedGains', parseNumber(e.target.value))}
              value={
                formik.values.expectedGains && !isNaN(formik.values.expectedGains)
                  ? formik.values.expectedGains.toString()
                  : 0
              }
            />
          </FormControl>
          <FormControl isRequired h='100%'>
            <FormLabel htmlFor='firstName'>Pourcentage d'épargne</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents='none' children='%' />
              <Input
                placeholder=''
                size='md'
                type='string'
                id='debtToIncomeRatio'
                onBlur={formik.handleBlur}
                onChange={(e) =>
                  formik.setFieldValue('debtToIncomeRatio', parseNumber(e.target.value))
                }
                value={
                  formik.values.debtToIncomeRatio && !isNaN(formik.values.debtToIncomeRatio)
                    ? formik.values.debtToIncomeRatio.toString()
                    : 0
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Durée du leasing</FormLabel>

            <Select
              value={formik.values.durationInMonths}
              onChange={(e) => {
                formik.setFieldValue('durationInMonths', parseFloat(e.target.value))
              }}
              w='100%'
              size='md'
            >
              <option value={12}>12 mois</option>
              <option value={15}>15 mois</option>
              <option value={18}>18 mois</option>
              <option value={24}>24 mois</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Durée du prêt</FormLabel>

            <Select
              value={formik.values.loanDuration}
              onChange={(e) => {
                formik.setFieldValue('loanDuration', parseFloat(e.target.value))
              }}
              w='100%'
              size='md'
            >
              <option value={0}>Pas de prêt</option>
              <option value={5}>5 ans</option>
              <option value={10}>10 ans</option>
              <option value={15}>15 ans</option>
              <option value={20}>20 ans</option>
              <option value={25}>25 ans</option>
            </Select>
            {userQuery.data?.user.userInformations?.dob &&
              calculateLoanDuration(userQuery.data?.user.userInformations?.dob) !==
                formik.values.loanDuration && (
                <FormHelperText color='red'>
                  La durée recommandée pour l'âge du client est de{' '}
                  {calculateLoanDuration(userQuery.data?.user.userInformations?.dob)} ans.
                </FormHelperText>
              )}
          </FormControl>
          <Flex alignItems='end'>
            <Button
              disabled={false}
              colorScheme='green'
              bg='HestiaForestGreen'
              type='submit'
              size='lg'
            >
              Enregistrer
            </Button>
          </Flex>
        </SimpleGrid>
        <Flex>
          {userQuery.data?.user && (
            <UserBudgetDetails
              user={{
                ...userQuery.data?.user,
                userInformations: userQuery.data?.user.userInformations && {
                  ...userQuery.data?.user.userInformations,
                  monthlyIncome: formik.values.monthlyIncome || 0,
                  monthlyIncomeCobuyer: formik.values.monthlyIncomeCobuyer || 0,
                  monthlyDebt: formik.values.monthlyDebt || 0,
                  deposit: formik.values.deposit || 0,
                  expectedGains: formik.values.expectedGains || 0,
                  loanDuration: formik.values.loanDuration || 0,
                  durationInMonths: formik.values.durationInMonths || 0,
                  debtToIncomeRatio: formik.values.debtToIncomeRatio || 0,
                },
              }}
            />
          )}
        </Flex>
      </Flex>
    </form>
  )
}

export default UserIncome
