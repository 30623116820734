import { Badge, Button, Flex, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  assetProcessStages,
  AssetProcessStagesEnum,
} from '../../../shared/backShared/assetProcessStages'
import { getStepNumber, TeamsEnum } from '../../../shared/backShared/assetProcessSteps'
import { getAssetAdById } from '../queries/getAssetAdById'
import StepTodo from '../Steps/StepTodo'
import AnalysisAction from './actions/AnalysisAction'
import AssetProcessModalWrapper from './AssetProcessModalWrapper'
require('dayjs/locale/fr')
dayjs.extend(relativeTime)
dayjs.locale('fr')

type Props = {
  currentStep: AssetProcessStagesEnum
  step: AssetProcessStagesEnum
}

const AssetProcessStepTodo = (props: Props) => {
  const current = props.currentStep == props.step
  console.log(
    'AssetProcessStepTodo -> current',
    current,
    props.currentStep,
    props.step,
    getStepNumber(props.currentStep),
    getStepNumber(props.step),
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const getTeamBadge = (team: TeamsEnum) => {
    return team === TeamsEnum.SUPPLY ? (
      <Badge colorScheme='yellow'>Analyst</Badge>
    ) : (
      <Badge colorScheme='blue'>Sales</Badge>
    )
  }

  const getCurrentAction = (step: AssetProcessStagesEnum) => {
    switch (step) {
      case AssetProcessStagesEnum.TO_PROCESS:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
            <AnalysisAction />
          </AssetProcessModalWrapper>
        )
      default:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            Pas encore développé !
          </AssetProcessModalWrapper>
        )
    }
  }

  return (
    <>
      {getCurrentAction(props.step)}

      <StepTodo
        text={assetProcessStages[props.step].label}
        done={getStepNumber(props.currentStep) > getStepNumber(props.step)}
        current={current}
        // subtitle={
        //   currentProcessStepData && (
        //     <Flex>
        //       <Regular color='HestiaGrey' fontSize='16px'>
        //         <Tooltip>
        //           <Flex gap='8px' alignItems='center'>
        //             <BiCalendar />
        //             <Text as='span'>
        //               {current
        //                 ? `Commencé ${dayjs(currentProcessStepData.startedAt)
        //                     .locale('fr')
        //                     .fromNow()}`
        //                 : `Terminé le ${dayjs(currentProcessStepData.finishedAt).format(
        //                     'DD/MM/YYYY (HH:MM)',
        //                   )}`}
        //             </Text>
        //           </Flex>
        //         </Tooltip>
        //       </Regular>
        //     </Flex>
        //   )
        // }
        rightPart={
          <Flex h='100%' alignItems='center' gap='16px'>
            {getTeamBadge(assetProcessStages[props.step].team)}
            <Button
              size='sm'
              color={current ? 'white' : 'HestiaGrey'}
              // bg={current ? 'HestiaPintGreen' : 'initial'}
              colorScheme={current ? 'green' : 'gray'}
              onClick={onOpen}
            >
              Voir plus
            </Button>
          </Flex>
        }
      />
    </>
  )
}

export default AssetProcessStepTodo
