import React, { FC, ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import * as CSS from 'csstype'

const Bold: FC<
  TextProps & {
    children?: ReactNode
    size?: 'large' | 'regular' | 'small' | 'tiny'
  }
> = ({ children, ...props }) => {
  const getTextProps = (): TextProps => {
    let textProps: TextProps = {
      textTransform: 'none',
    }

    const size = props.size || 'regular'

    if (size === 'large') {
      textProps = {
        ...textProps,
        fontSize: '20px',
        letterSpacing: '0.15px',
      }
    } else if (size === 'regular') {
      textProps = {
        ...textProps,
        fontSize: '15px',
        letterSpacing: '0px',
      }
    } else if (size === 'small') {
      textProps = {
        ...textProps,
        fontSize: '13px',
        letterSpacing: '0px',
      }
    } else if (size === 'tiny') {
      textProps = {
        ...textProps,
        fontSize: '12px',
        letterSpacing: '0px',
      }
    }

    textProps = {
      ...textProps,
      fontWeight: 600,
      fontFamily: 'Inter',
      lineHeight: 'normal',
      color: 'neutral.primary',
    }

    return textProps
  }

  return (
    <Text {...getTextProps()} {...props}>
      {children}
    </Text>
  )
}

export default Bold
