import { Alert, AlertDescription, AlertIcon, Button, Divider, Flex } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import Loading from '../../shared/components/Loading'
import { useHestiaBudgetAdmin } from '../../utility/hooks/useHestiaBudgetAdmin'
import { getPrettyNumber } from '../../utility/money'
import { getMortgageSimulatorResultsV3 } from '../commonQueries/getMortgageSimulatorResultsV3'
import { UserResponseSingleUser } from '../users/queries/userQuery'
import Bold from './Typo/Bold'
import Header from './Typo/Header'
import Regular from './Typo/Regular'

const UserFinancialSummary: FC<{
  user: UserResponseSingleUser
}> = ({ user }) => {
  if (!user.userInformations) {
    return <></>
  }
  const [debouncedInputs, debouncedInputsControlFunctions] = useDebounce(user.userInformations, 600)
  const navigate = useNavigate()
  const oldSimulatorResults = useQuery(
    ['getMortgageSimulatorresults', user.id, debouncedInputs],
    () =>
      getMortgageSimulatorResultsV3({
        monthlyIncome:
          (debouncedInputs?.monthlyIncome || 0) + (debouncedInputs?.monthlyIncomeCobuyer || 0),
        monthlyDebt: debouncedInputs?.monthlyDebt || 0,
        savingsAvailable: (debouncedInputs?.deposit || 0) + (debouncedInputs?.expectedGains || 0),
      }),
  )
  const budget = useHestiaBudgetAdmin({ user })

  const hestiaFees = (budget.simulatorData?.budgetNow || 0) * 0.03
  const savings = (debouncedInputs?.deposit || 0) + (debouncedInputs?.expectedGains || 0)
  const availableDepositAfterFees = savings - hestiaFees

  return (
    <Flex height='fit-content' direction='column' gap='24px'>
      <Flex
        gap='8px'
        direction='column'
        border='1px solid #EFEFEF'
        borderRadius='8px'
        padding='24px'
        w='100%'
      >
        {debouncedInputsControlFunctions.isPending() ? (
          <Loading />
        ) : (
          <>
            <Header fontSize='48px' color='HestiaPintGreen'>
              {getPrettyNumber(budget.simulatorData?.budgetNow, 0, true)}
            </Header>
            {availableDepositAfterFees < 0 && (
              <Alert status='error' borderRadius='8px'>
                <AlertIcon />
                <Flex direction='column'>
                  {/* <AlertTitle> Apport insuffisant pour payer les frais</AlertTitle> */}
                  <AlertDescription>
                    Avec l'apport déclaré de <b>{getPrettyNumber(savings, 0)}</b>, il manque encore{' '}
                    <b>{getPrettyNumber(Math.abs(availableDepositAfterFees), 0)} </b> pour payer les
                    frais qui s'élèvent à <b>{getPrettyNumber(Math.abs(hestiaFees), 0)}</b>
                  </AlertDescription>
                </Flex>
              </Alert>
            )}
          </>
        )}
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>
            Taux d'intérêt (Updaté le{' '}
            {dayjs(budget.simulatorData?.interestRateDate).format('DD MMMM YYYY')})
          </Regular>
          <Bold>{((budget.simulatorData?.interestRate || 0) * 100).toFixed(2)}%</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Region utilisée pour le taux</Regular>
          <Bold>{budget.simulatorData?.region}</Bold>
        </Flex>
        <Button
          variant='outline'
          w='fit-content'
          colorScheme='green'
          onClick={() => navigate(`/users/${user.id}`)}
        >
          Voir le user
        </Button>
      </Flex>
      <Flex
        border='1px solid #EFEFEF'
        borderRadius='8px'
        padding='24px'
        direction='column'
        gap='12px'
      >
        <Bold>Resumé</Bold>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Revenu 1er acheteur</Regular>
          <Bold>{getPrettyNumber(user.userInformations.monthlyIncome)}</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Revenu 2ème acheteur</Regular>
          <Bold>{getPrettyNumber(user.userInformations.monthlyIncomeCobuyer)}</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Remboursement de crédits</Regular>
          <Bold>{getPrettyNumber(user.userInformations.monthlyDebt)}</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Apport disponible</Regular>
          <Bold>{getPrettyNumber(user.userInformations.deposit)}</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Futurs gains estimés</Regular>
          <Bold>{getPrettyNumber(user.userInformations.expectedGains)}</Bold>
        </Flex>
        <Divider />
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Budget Hestia (frais d'agences inclus)</Regular>
          <Bold>{getPrettyNumber(budget.simulatorData?.budgetNow, 0, true)}</Bold>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Prix de rachat</Regular>
          <Regular>
            {getPrettyNumber((budget.simulatorData?.budgetNow || 0) * 1.1, 0, true)}
          </Regular>
        </Flex>
        <Flex justifyContent='space-between' gap='24px'>
          <Regular>Ancien budget</Regular>
          <Regular>
            {getPrettyNumber(
              (oldSimulatorResults.data?.userResult.actualBudget || 0) * 1.1,
              0,
              true,
            )}
          </Regular>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserFinancialSummary
