import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import logoBrand from '../../assets/hestia-logo-brand.svg'
import logo from '../../assets/hestia-logo.svg'

interface LogoProps {
  brand?: boolean
}

const Logo = (props: LogoProps) => {
  return (
    <Box>
      <Text fontSize='lg' fontWeight='bold'>
        <Image height='26px' src={props.brand ? logoBrand : logo} alt='Logo' />
      </Text>
    </Box>
  )
}

export default Logo
