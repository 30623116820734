import { FormikErrors } from 'formik'
import { AddAgencyToPartnerValues } from './AddAgencyToPartnerAction'

export const validateAddAgency = (values: AddAgencyToPartnerValues) => {
  const errors: FormikErrors<AddAgencyToPartnerValues> = {}

  if (!values.name) {
    errors.name = 'Ce champ est obligatoire'
  }

  if (values.hubspotId && !/^\d+$/.test(values.hubspotId)) {
    errors.hubspotId = "L'ID HubSpot doit être un nombre valide"
  } else if (values.hubspotId && values.hubspotId.length <= 5) {
    errors.hubspotId = "L'ID HubSpot doit contenir plus de 5 caractères"
  }

  return errors
}
