import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'

export interface SaveFileKeyInputs {
  key: string
  userId: string
  assetAdId?: string
}

export const saveFileKey = async (data: SaveFileKeyInputs) => {
  return await authenticatedPostQuery(`/admin/files/savekey`, data).then((response) => response)
}
