import { Flex, Text } from '@chakra-ui/react'
import { Building, BuildingApartment, Phone } from '@phosphor-icons/react'
import React from 'react'
import { BiMessageDetail, BiUser } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Loading from '../../../shared/components/Loading'
import Header from '../../components/Typo/Header'
import Regular from '../../components/Typo/Regular'
import { getUserQuery } from '../queries/userQuery'

type Props = {}

const UserHeader = (props: Props) => {
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))
  const navigate = useNavigate()
  const location = useLocation()

  if (!userQuery.data?.user.userInformations) {
    return <Loading />
  }

  // const budget = assetAd?.data?.user.userInformations
  //   ? useHestiaBudgetAdmin({ user: assetAd?.data?.user })
  //   : undefined

  return (
    <Flex direction='column' gap='8px'>
      {/* {location.pathname !== `/${ROUTES.ASSETADS_BASE}/${id}` && (
        <Button
          w='fit-content'
          size='sm'
          variant='outline'
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/${ROUTES.ASSETADS_BASE}/${id}`)}
        >
          {'Retour'}
        </Button>
      )} */}

      <Flex direction='row' gap='8px' alignItems='center'>
        {/* <CircularProgress
          value={completionPercent}
          size='72px'
          color='HestiaPintGreen'
          thickness='6px'
        >
          <CircularProgressLabel>
            <LargeBold fontSize='20px'>{completionPercent.toFixed(0)}%</LargeBold>
          </CircularProgressLabel>
        </CircularProgress> */}
        <Flex gap='8px' direction='column'>
          <Header>
            {userQuery.data?.user.firstName} {userQuery.data?.user.lastName}
          </Header>
          <Flex gap='24px' alignItems='center'>
            {/* <Regular
              color='HestiaGrey'
              fontSize='16px'
              cursor='pointer'
         
            >
              <Flex gap='8px' alignItems='center'>
                <BiLinkAlt />
                <Text as='span'>
                  Process:{' '}
                  <b>
                    {userProcessStages[userQuery.data.user.userInformations.processStage].label}
                  </b>{' '}
                  <TeamBadge
                    team={userProcessStages[userQuery.data.user.userInformations.processStage].team}
                  />
                </Text>
              </Flex>
            </Regular> */}
          </Flex>
          <Flex
            gap='24px'
            cursor='pointer'
            // onClick={() => navigate(`/${ROUTES.USERS_BASE}/${assetAd.data?.user.id}`)}
          >
            <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiUser />
                <Text as='span'>
                  Propriétaire:{' '}
                  <b>{userQuery.data?.user.userInformations.owner?.email || 'inconnu'}</b>
                </Text>
              </Flex>
            </Regular>
            <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiMessageDetail />
                <Text as='span'>{userQuery.data?.user.email}</Text>
              </Flex>
            </Regular>
            <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiUser />
                <Text as='span'>
                  File d'assignation:{' '}
                  <b>{userQuery.data?.user.userInformations.roundRobinQueue || 'inconnu'}</b>
                </Text>
              </Flex>
            </Regular>
            {/* <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiCoinStack />
                <Text as='span'>{getPrettyNumber(budget?.simulatorData?.budgetNow, 0, true)}</Text>
              </Flex>
            </Regular> */}
          </Flex>
          {userQuery.data.user.partnerUserInformations?.partnerSource && (
            <Flex
              gap='24px'
              cursor='pointer'
              // onClick={() => navigate(`/${ROUTES.USERS_BASE}/${assetAd.data?.user.id}`)}
            >
              <Regular color='HestiaGrey' fontSize='16px'>
                <Flex gap='8px' alignItems='center'>
                  <BuildingApartment />
                  <Text as='span'>
                    Partenaire:{' '}
                    <b>
                      {userQuery.data?.user.partnerUserInformations.partnerSource.name || 'inconnu'}
                    </b>
                  </Text>
                </Flex>
              </Regular>
              <Regular color='HestiaGrey' fontSize='16px'>
                <Flex gap='8px' alignItems='center'>
                  <Building />
                  <Text as='span'>
                    Agence:{' '}
                    <b>
                      {userQuery.data?.user.partnerUserInformations?.agencySource?.name ||
                        'inconnu'}
                    </b>
                  </Text>
                </Flex>
              </Regular>
              <Regular color='HestiaGrey' fontSize='16px'>
                <Flex gap='8px' alignItems='center'>
                  <Phone />
                  <Text as='span'>
                    Contact pro:{' '}
                    <b>
                      {userQuery.data?.user.partnerUserInformations?.personSource?.firstName}{' '}
                      {userQuery.data?.user.partnerUserInformations?.personSource?.lastName} (
                      {userQuery.data?.user.partnerUserInformations?.personSource?.email} -{' '}
                      {userQuery.data?.user.partnerUserInformations?.personSource?.phone})
                    </b>
                  </Text>
                </Flex>
              </Regular>

              {/* <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiCoinStack />
                <Text as='span'>{getPrettyNumber(budget?.simulatorData?.budgetNow, 0, true)}</Text>
              </Flex>
            </Regular> */}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserHeader
