import { Divider, Flex, useDisclosure } from '@chakra-ui/react'
import { Bank } from '@phosphor-icons/react'
import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import {
  DiscoveryTab,
  discoveryTabsAtom,
  headerHeightAtom,
  isDiscoveryHeaderStickyAtom,
} from '../atoms/discoveryAtom'
import AssetProcessModalWrapper from '../dashboard/assetAds/v2/AssetProcessModalWrapper'
import CustomButton from '../dashboard/components/CustomButton'
import Header from '../dashboard/components/Typo/Header'
import { getUserQuery } from '../dashboard/users/queries/userQuery'
import FinalizeDiscoveryModal from '../dashboard/users/user/discovery/FinalizeDiscoveryModal'
import { UserProcessStagesEnum } from '../shared/backShared/userProcessStages'
import CustomBadge from '../shared/components/CustomBadge'
import { useHandleOpenValidation } from '../utility/hooks/useHandleOpenValidation'
import { getMaxPercentageVisibleTab, isStringAter } from '../utility/other'
import DiscoveryHeaderTab from './DiscoveryHeaderTab'
type Props = {
  isSticky?: boolean
  calculatedHeight?: number
}

const DiscoveryHeader = (props: Props) => {
  const [isHeaderSticky, setIsHeaderSticky] = useAtom(isDiscoveryHeaderStickyAtom)
  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom)
  const [discoveryTabs, setDiscoveryTabs] = useAtom(discoveryTabsAtom)
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { id: userId } = useParams()

  useHandleOpenValidation(onOpen)

  const userQuery = useQuery(['user', { id: userId }], () => getUserQuery({ id: userId }))

  useEffect(() => {
    setIsHeaderSticky(!!props.isSticky)
  }, [props.isSticky])
  useEffect(() => {
    props.calculatedHeight && setHeaderHeight(props.calculatedHeight)
  }, [props.calculatedHeight])

  // get the one with the most visible height
  const currentTab = getMaxPercentageVisibleTab(discoveryTabs)

  return (
    <Flex direction='column' w='100%'>
      <Flex
        bg='white'
        gap='24px'
        zIndex='999'
        direction='column'
        paddingTop='24px'
        w='100%'
        boxShadow={props.isSticky ? '0px 10px 36px 0px rgba(5, 84, 72, 0.16)' : 'none'}
        transition='all 0.5s ease-in-out'
      >
        <Flex gap='24px' direction='column' w='100%' padding='0px 24px'>
          <Flex w='100%'>
            <Flex gap='8px' direction='row' w='100%' alignItems='center'>
              <Header>Test</Header>
              {isStringAter(
                userQuery?.data?.user?.userInformations?.processStage,
                UserProcessStagesEnum.APPOINTMENT_BOOKED,
                UserProcessStagesEnum,
              ) && <CustomBadge variant='blue'>Call découverte déjà validé!</CustomBadge>}
            </Flex>
            <CustomButton onClick={onOpen}> Valider la découverte </CustomButton>
            <AssetProcessModalWrapper size='3xl' isOpen={isOpen} onClose={onClose}>
              <FinalizeDiscoveryModal />
            </AssetProcessModalWrapper>
          </Flex>
          <Flex
            gap='8'
            transition='all 0.5s ease-in-out'
            // display={props.isSticky ? 'flex' : 'none'}
            opacity={props.isSticky ? '1' : '0'}
            h={props.isSticky ? '100%' : '0px'}
            overflow='hidden'
          >
            <DiscoveryHeaderTab tab={DiscoveryTab.PROJECT} title='Projet' icon={<Bank />} />
            <DiscoveryHeaderTab tab={DiscoveryTab.PEOPLE} title='People' icon={<Bank />} />
            <DiscoveryHeaderTab tab={DiscoveryTab.INCOME} title='Revenus' icon={<Bank />} />
            <DiscoveryHeaderTab tab={DiscoveryTab.EXPENSES} title='Charges' icon={<Bank />} />
            <DiscoveryHeaderTab tab={DiscoveryTab.PATRIMONY} title='Patrimoine' icon={<Bank />} />
          </Flex>
        </Flex>

        <Divider />
      </Flex>
    </Flex>
  )
}

export default DiscoveryHeader
