export enum RejectionReasonEnum {
  GEOGRAPHIC_LOCATION = 'GEOGRAPHIC_LOCATION',
  POPULATION = 'POPULATION',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  PROPERTY_CONDITION = 'PROPERTY_CONDITION',
  PROPERTY_VALUE_MIN = 'PROPERTY_VALUE_MIN',
  OVERPRICED = 'OVERPRICED',
  INCONSISTENT_VALUE = 'INCONSISTENT_VALUE',
  INACTIVE_AD = 'INACTIVE_AD',
  OWNER_LEASE_REJECTION = 'OWNER_LEASE_REJECTION',
  DUPLICATE = 'DUPLICATE',
}

export type RejectionReason = { label: string; value: RejectionReasonEnum; emailContent: string }

export const rejectionReasons: RejectionReason[] = [
  {
    label: 'Propriétaire a refusé le leasing',
    value: RejectionReasonEnum.OWNER_LEASE_REJECTION,
    emailContent:
      "Le propriétaire du bien que vous avez proposé n'a malheureusement pas retenu notre proposition de leasing 😕",
  },
  {
    label: 'Situation géographique',
    value: RejectionReasonEnum.GEOGRAPHIC_LOCATION,
    emailContent:
      "Nous avons étudié avec attention le logement que vous nous avez proposé. Malheureusement, sa situation géographique et l'attractivité du secteur ne correspondent pas à nos critères 🏠. \n Mais l'aventure n'est pas finie, c'est l'occasion de repartir en quête du logement qui vous conviendra vraiment 😉 ! Notre équipe est là pour vous aider à trouver un nouveau chez-vous. N'hésitez pas à nous proposer d'autres biens, nous serons ravis de les analyser pour vous.",
  },
  {
    label: 'Moins de 40k habitants',
    value: RejectionReasonEnum.POPULATION,
    emailContent:
      "Nous avons étudié avec attention le logement que vous nous avez proposé. Malheureusement, il ne correspond pas à nos critères en matière de taille de la commune ou de la ville. \n Chez Hestia, nous privilégions les biens situés à moins de 10 km d'une ville de plus de 40 000 habitants, pour vous offrir un cadre de vie dynamique et attractif 🌆.",
  },
  {
    label: 'Type de bien non conforme',
    value: RejectionReasonEnum.PROPERTY_TYPE,
    emailContent:
      "Nous avons pris le temps d'analyser le logement que vous nous avez présenté 🕵️. \n\nCependant, nous ne nous engageons pas dans l'achat de résidences seniors, de logements appartenant à des bailleurs sociaux, ou d'autres biens similaires qui ne correspondent pas à notre modèle de leasing immobilier 😕.",
  },
  {
    label: 'État du bien',
    value: RejectionReasonEnum.PROPERTY_CONDITION,
    emailContent:
      'Nous avons étudié avec soin le logement que vous nous avez proposé. Cependant, il ne cochait pas toutes les cases du logement idéal 😕. \n\nEn effet, ce logement nécessite trop de travaux ou de rénovation, et/ou présente un DPE de catégorie F ou G.',
  },
  {
    label: 'Valeur du bien < 200k€',
    value: RejectionReasonEnum.PROPERTY_VALUE_MIN,
    emailContent:
      "Nous avons étudié le logement que vous nous avez récemment soumis. Cependant, la valeur du bien est en dessous de notre seuil minimum de 200k€ 😕. \n\nContinuons à avancer ! Notre équipe est là pour vous accompagner dans la recherche d'un logement qui répondra à vos attentes et à nos critères 🌟.",
  },
  {
    label: 'Valeur vénale surévaluée',
    value: RejectionReasonEnum.OVERPRICED,
    emailContent:
      "Nous avons soigneusement étudié le logement que vous nous avez présenté. Toutefois, il semble que la valeur vénale du bien soit surévaluée par rapport au prix du marché 🕵️. \n\nChez Hestia, nous nous engageons à proposer des solutions justes et compétitives, c'est pourquoi nous nous devons de respecter les prix du marché pour garantir une expérience équilibrée à nos clients 🎯.",
  },
  {
    label: 'Valeur non cohérente',
    value: RejectionReasonEnum.INCONSISTENT_VALUE,
    emailContent:
      "Nous avons attentivement étudié le logement que vous nous avez soumis. Cependant, après analyse, il semble que la valeur du bien ne soit pas cohérente avec les paramètres de votre projet, notamment en termes de budget et d'apport 💼.",
  },
  {
    label: 'Annonce désactivée',
    value: RejectionReasonEnum.INACTIVE_AD,
    emailContent:
      "Merci de nous avoir soumis votre logement pour notre analyse. Toutefois, nous avons rencontré un petit souci : le lien que vous avez partagé redirige vers une annonce désactivée ou indisponible 😕. \n\n Pas de panique, cela peut arriver à tout le monde 😉. Afin de continuer notre évaluation et vous accompagner dans votre projet, nous avons besoin d'un lien valide vers l'annonce du logement que vous souhaitez habiter 🏠.",
  },
  {
    label: 'Doublon',
    value: RejectionReasonEnum.DUPLICATE,
    emailContent:
      "Nous avons bien reçu votre nouvelle proposition de logement à analyser. Cependant, il semblerait que nous ayons déjà étudié cette annonce précédemment 🕵️‍♂️. \n\nSi vous pensez qu'il s'agit d'une erreur de notre part, n'hésitez pas à nous le signaler, et nous vérifierons à nouveau avec plaisir. Sinon, continuez à nous envoyer de nouvelles propositions de logements qui correspondent à vos besoins et à votre budget 🏘️.",
  },
]
