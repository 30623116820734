export const ROUTES = {
  USERS_BASE: 'users',
  USERS_ANALYSIS: 'analysis',

  USERS_SUMMARY: 'summary',
  USERS_INFORMATIONS: 'informations',
  USERS_FILES: 'files',
  USERS_DISCOVERY: 'discovery',
  USERS_BUDGET: 'budget',

  ASSETADS_BASE: 'assetads',
  ASSETADS_ANALYSIS: 'analysis',
  TOOLS: 'tools',
  ADMINS: 'admins',
  PARTNERS_BASE: 'partners',
  CREATE_PARTNER: 'partner/create',
}
