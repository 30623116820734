import dayjs from 'dayjs'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import DatePickerChakra from './DatePickerChakra'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

type Props = {
  field: string
  label?: string
  placeHolder?: string
  isRequired?: boolean
  autoFocus?: boolean
}

const DatePickerInput = (props: InputWrapperProps & Props) => {
  const formik = useFormikContext<FormikValues>()
  console.log(props.field, formik.values[props.field], formik.values)
  return (
    <InputWrapper field={props.field} label={props.label} isRequired={props.isRequired}>
      <DatePickerChakra
        onChange={(value: Date) => {
          formik.setFieldValue(props.field, value)
        }}
        onBlur={formik.handleBlur}
        selected={formik.values[props.field]}
        id={props.field}
        // minDate={dayjs().subtract(1, 'month').toDate()}
        placeholderText={dayjs().add(7, 'day').format('DD/MM/YYYY')}
        autoFocus={props.autoFocus}
      />
    </InputWrapper>
  )
}

export default DatePickerInput
