import { authenticatedPostQuery } from '../../../../utility/queries/authenticatedPostQuery'

export interface CreateAnalysisInputs {
  id: string
}

export const createAnalysisQuery = async (updateAnalysisQuery: CreateAnalysisInputs) => {
  return await authenticatedPostQuery(`/admin/users/${updateAnalysisQuery.id}/analyses/`)
}
// await (await fetch("http://localhost:3004/users")).json();
