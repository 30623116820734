import { RefinementListProps } from 'react-instantsearch-hooks-web'
import { hubspotScoringFormattedEnum } from '../../dashboard/commandPalette/UserHit'
import { userProcessStages, UserProcessStagesEnum } from '../../shared/backShared/userProcessStages'

export const transformUsersPriority: RefinementListProps['transformItems'] = (items) => {
  return items.map((item) => {
    let label = '?'
    if (item.label in hubspotScoringFormattedEnum) {
      label = hubspotScoringFormattedEnum[item.label]
    }

    return {
      ...item,
      label: label,
    }
  })
}

export const transformProcessStage: RefinementListProps['transformItems'] = (items) => {
  return items.map((item) => {
    let label = '?'
    if (item.label in UserProcessStagesEnum) {
      const itemLabel = item.label as UserProcessStagesEnum
      label = userProcessStages[itemLabel].label
    }

    return {
      ...item,
      label: label,
    }
  })
}
