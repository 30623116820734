import { Button, Card, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { getUserQuery } from '../queries/userQuery'

type Props = {}

const WaitingForDocs = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  return (
    <>
      <ModalCustomCloseButton />
      <Flex padding='32px' gap='16px' direction='column'>
        <ModalCustomHeader title='En attente de documents' />
        <Card border='1px solid' borderColor='HestiaLightBlue' p='8px'>
          <Flex direction='column' gap='8px'>
            <Bold>Documents manquants pour la team crédit :</Bold>
            <Regular whiteSpace='pre-wrap'>
              {userQuery.data?.user.userInformations?.analysisNeedDocsCustomMessage}
            </Regular>
          </Flex>
        </Card>
      </Flex>

      <ModalFooter>
        <Flex justifyContent='flex-end' gap='16px'>
          <Button color='HestiaGrey' onClick={onClose}>
            Retour
          </Button>
        </Flex>
      </ModalFooter>
    </>
  )
}

export default WaitingForDocs
