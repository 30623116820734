import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react'
import { useLocalStorage } from '../utility/hooks/useLocalStorage'

interface AuthContextInterface {
  admin: string
  login: (token: string) => Promise<void>
}

const AuthContext = createContext<AuthContextInterface | null>(null)

export const AuthProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [admin, setAdmin] = useLocalStorage('admin', undefined)
  const authToken = getAuthToken()

  // call this function when you want to authenticate the user
  const login = async (token: string) => {
    setAdmin(token)
    localStorage.setItem('hestia:admin-jwt', token)
  }
  //
  // // call this function to sign out logged in user
  // const logout = () => {
  //     setAdmin(null);
  //     navigate("/", { replace: true });
  // };
  useEffect(() => {
    if (!authToken) {
      setAdmin(undefined)
    }
  }, [authToken])

  const value = useMemo(
    () => ({
      admin,
      login,
      // logout
    }),
    [admin],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const getAuthToken = () => {
  return localStorage.getItem('hestia:admin-jwt')
}

export const deleteAuthToken = () => {
  return localStorage.removeItem('hestia:admin-jwt')
}
