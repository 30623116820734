export const isNotionUrl = (url: URL | string): boolean => {
  if (typeof url === 'string') {
    try {
      url = new URL(url)
    } catch (error) {
      return false
    }
  }

  return url.host === 'www.notion.so' || url.host === 'notion.so'
}
