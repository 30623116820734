import { Box, Flex, useToast } from '@chakra-ui/react'
import { Formik } from 'formik'
import { useAtom } from 'jotai'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Sticky, StickyContainer } from 'react-sticky'
import { HESTIA_COLORS } from '../../../App'
import {
  headerHeightAtom,
  isDiscoveryHeaderStickyAtom,
  isFloatingBudgetStickyAtom,
  isOnDiscoveryPageAtom,
} from '../../../atoms/discoveryAtom'
import DiscoveryHeader from '../../../components/DiscoveryHeader'
import {
  ContractTypesEnum,
  DetailedContractTypesEnum,
  EmploymentSectorEnum,
  ProfessionalSituationEnum,
  PublicContractEnum,
} from '../../../interfaces/prisma'
import StickyNotepad from '../../../shared/components/inputs/InlineLayout/StickyNotepad'
import FloatingBudget from '../../FloatingBudget'
import DiscoveryContext from './discovery/DiscoveryContext'
import DiscoveryExpenses from './discovery/DiscoveryExpenses'
import DiscoveryIncomes from './discovery/DiscoveryIncomes'
import DiscoveryPatrimonies from './discovery/DiscoveryPatrimonies'
import DiscoveryPeople from './discovery/DiscoveryPeople'

export type DiscoveryCallValues = {
  project: string | undefined
  whatIsBlocking: string | undefined
  whyBuying: string | undefined
  other: string | undefined
  assetFound: boolean | undefined
  assetAgreement: boolean | undefined
  assetAgreementDate: Date | undefined
  employmentSector: EmploymentSectorEnum | undefined
  contractType: ContractTypesEnum | undefined
  professionalSituation: ProfessionalSituationEnum | undefined
  detailedContractType: DetailedContractTypesEnum | undefined
  publicContract: PublicContractEnum | undefined
}

const DiscoveryCall = () => {
  const toast = useToast()
  const { id } = useParams()

  const [isDiscovery, setIsDiscovery] = useAtom(isOnDiscoveryPageAtom)
  const [isHeaderSticky, setIsHeaderSticky] = useAtom(isDiscoveryHeaderStickyAtom)
  const [isFloatingBudgetSticky, setIsFloatingBudgetSticky] = useAtom(isFloatingBudgetStickyAtom)
  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom)

  // On unmount, setIsHeaderSticky to false
  React.useEffect(() => {
    setIsDiscovery(true)
    return () => {
      setIsDiscovery(false)
    }
  }, [])

  const initialValues: DiscoveryCallValues = {
    project: undefined,
    whatIsBlocking: undefined,
    whyBuying: undefined,
    other: undefined,
    assetFound: undefined,
    assetAgreement: undefined,
    assetAgreementDate: undefined,
    employmentSector: undefined,
    contractType: undefined,
    professionalSituation: undefined,
    detailedContractType: undefined,
    publicContract: undefined,
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validate={validateAnalysisCompletedAction}
        onSubmit={async (values, formik) => {
          console.log(values)
          // const payload = {
          //   ..._.omit(values, ['customMessage']),
          // }
          // updateMutation.mutate(payload, {
          //   onSuccess: (data) => {
          //     formik.resetForm()
          //     onClose()
          //   },
          // })
        }}
      >
        {(formik) => (
          <Box w='100%' bg={HESTIA_COLORS.neutral.background}>
            <StickyNotepad />
            <StickyContainer>
              <Sticky style={{ 'z-index': 'white', width: '100%' }}>
                {({ style, isSticky, calculatedHeight }) => (
                  <Box
                    style={{ ...style, zIndex: 999, width: '100%' }}
                    bg='white'
                    marginLeft={isFloatingBudgetSticky ? '-240px' : '0'}
                  >
                    <DiscoveryHeader isSticky={isSticky} calculatedHeight={calculatedHeight} />
                  </Box>
                )}
              </Sticky>
              <StickyContainer>
                <Flex flexDirection='row' w='100%' padding={12} gap={6}>
                  <Sticky style={{ 'z-index': 'white', width: '100%' }}>
                    {({ style, isSticky }) => (
                      <Flex
                        style={{
                          ...style,
                          width: '240px',
                          zIndex: 10,
                          top: `${headerHeight + 24}px`,
                        }}
                        transition='opacity .3s ease-in-out'
                        // marginLeft={isSticky ? '0' : '-240px'}
                        marginLeft={'-240px'}
                        opacity={isSticky ? 1 : 0}
                      >
                        <FloatingBudget isSticky={isSticky} />
                      </Flex>
                    )}
                  </Sticky>
                  <Flex
                    direction='column'
                    gap='24px'
                    transition={isHeaderSticky ? 'margin .3s ease-in-out' : '0'}
                    // marginLeft={isFloatingBudgetSticky ? '240px' : '0'}
                    marginLeft={'0'}
                    w='100%'
                  >
                    <DiscoveryContext />
                    <DiscoveryPeople />
                    <DiscoveryIncomes />
                    <DiscoveryExpenses />
                    <DiscoveryPatrimonies />
                  </Flex>
                </Flex>
              </StickyContainer>
            </StickyContainer>
          </Box>
        )}
      </Formik>
    </>
  )
}

export default DiscoveryCall
