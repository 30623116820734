import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface GetLinksOutput {
  inviteUrl: string
  passwordResetUrl: string
}

export const getLinksQuery = async (id: string) => {
  return (await authenticatedGetQuery(`/admin/users/${id}/getlinks`)) as Promise<GetLinksOutput>
}
// await (await fetch("http://localhost:3004/users")).json();
