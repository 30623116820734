import { Dispatch, SetStateAction, useEffect } from 'react'

export function useHandleOpenValidation(setIsOpen: Dispatch<SetStateAction<boolean>>) {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (navigator?.platform?.toLowerCase().includes('mac') ? e.metaKey : e.ctrlKey) &&
        e.key === 'Enter'
      ) {
        e.preventDefault()
        e.stopPropagation()

        setIsOpen((currentValue: boolean) => {
          return !currentValue
        })
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}
