import React, { FC, useState } from 'react'
import { Flex, Spinner, useMediaQuery } from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi'
import { useFormikContext } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { getFileUrl } from './queries/files/getFileUrl'
import Bold from '../Typo/Bold'

const FileUploadResult: FC<{
  name?: string
  id?: string
}> = ({ name, id }) => {
  const queryClient = useQueryClient()

  const fileUrl = id ? useQuery(['file', id], () => getFileUrl({ id })) : null

  return (
    <Flex
      display='none'
      w='100%'
      direction='column'
      gap='0px'
      p='8px 12px'
      bg='white'
      _notFirst={{
        borderTop: 'none',
      }}
      borderRadius='0px'
      _first={{
        borderRadius: '8px 8px 0px 0px',
      }}
      _last={{
        borderRadius: '0px 0px 8px 8px',
      }}
      // transition='background-color 0.2s ease'
      justifyContent='space-between'
      alignItems='center'
      border='1px solid'
      borderColor='HestiaPintGreen'
      cursor='pointer'
      onClick={() =>
        fileUrl?.data ? window.open(fileUrl?.data?.fileUrl, '_blank', 'noopener,noreferrer') : {}
      }
    >
      <Flex w='100%' justifyContent='space-between' alignItems='center' gap='16px'>
        <Flex
          alignItems='center'
          gap='8px'
          overflow='hidden'
          whiteSpace='nowrap'
          w='100%'
          flexGrow={1}
        >
          <Bold
            color='HestiaPintGreen'
            fontSize='14px'
            textOverflow='ellipsis'
            overflow='hidden'
            whiteSpace='nowrap'
          >
            {name}
          </Bold>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FileUploadResult
