import { Alert, Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserLostReasonEnum, userLostReasons } from '../../../interfaces/prisma'
import {
  userProcessStages,
  UserProcessStagesEnum,
} from '../../../shared/backShared/userProcessStages'
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh'
import BooleanInput from '../../../shared/components/inputs/InlineLayout/BooleanInput'
import SelectInput from '../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { useHestiaBudgetAdmin } from '../../../utility/hooks/useHestiaBudgetAdmin'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import Regular from '../../components/Typo/Regular'
import { UserQualifyPayload, userQualifyQuery } from '../queries/userQualifyQuery'
import { getUserQuery } from '../queries/userQuery'
import { validateQualifyAction } from './validateQualifyAction'
type Props = {}

export type QualifyActionValues = {
  newStage: UserProcessStagesEnum | undefined
  lostReason: UserLostReasonEnum | undefined
  shouldResendToPartner: boolean | undefined
}

const QualifyAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))
  const budget = userQuery.data?.user && useHestiaBudgetAdmin({ user: userQuery.data?.user })

  const initialValues: QualifyActionValues = {
    newStage: undefined,
    lostReason: undefined,
    shouldResendToPartner: undefined,
  }

  const updateMutation = useMutation(
    (inputs: UserQualifyPayload) => userQualifyQuery(id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['user', { id }])
        successToast({ description: 'All good' })
      },
    },
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(value) => validateQualifyAction(value, budget?.simulatorData)}
        onSubmit={async (values, formik) => {
          console.log(values)
          const payload = {
            ..._.omit(values, ['customMessage']),
          }
          updateMutation.mutate(payload, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={userQuery.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Qualifier le client'
                subtitle='Un email sera envoyé pour créer son mot de passe (sauf en cas de LOST).'
              />
              <Flex gap='24px' direction='column'>
                <Flex gap='24px' direction='column'>
                  <SelectInput
                    label='Nouvelle étape'
                    field='newStage'
                    isRequired
                    options={Object.entries(UserProcessStagesEnum).map(([key, value]) => ({
                      label: userProcessStages[value].label,
                      value: value,
                    }))}
                  />
                </Flex>
                {formik.values.newStage === UserProcessStagesEnum.CLOSED_LOST && (
                  <SelectInput
                    field={'lostReason'}
                    label={'Raison du LOST'}
                    options={userLostReasons}
                  />
                )}
                {formik.values.newStage === UserProcessStagesEnum.CLOSED_LOST && (
                  <BooleanInput
                    field={'shouldResendToPartner'}
                    // label={'Intéréssant de le renvoyer au partenaire?'}
                    checkboxLabel='Intéréssant de le renvoyer au partenaire?'
                  />
                )}
                {formik.values.newStage !== UserProcessStagesEnum.CLOSED_LOST &&
                  budget?.simulatorData?.isEligible === true && (
                    <Alert borderRadius='8px'>
                      <Regular>
                        Un email sera envoyé à <b>{userQuery.data?.user?.email}</b> pour créer son
                        mot de passe.
                      </Regular>
                    </Alert>
                  )}
                {formik.values.newStage !== UserProcessStagesEnum.CLOSED_LOST &&
                  budget?.simulatorData?.isEligible === false && (
                    <Alert borderRadius='8px' colorScheme='red'>
                      <Regular>
                        Ce client n'est pas éligible à Hestia. Il faut le modifier pour pouvoir le
                        qualifier.
                      </Regular>
                    </Alert>
                  )}
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default QualifyAction
