import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface Asset {
  id: string
  createdAt: Date
  updatedAt: Date
  address: string
  city: string
  postalCode: string
  acquisitionCost: number
}

export interface FundsGroup {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
}

export interface VirtualIban {
  id: string
  createdAt: Date
  updatedAt: Date
  leasingProjectId: string
  IBAN: string
  BIC: string
  externalId: string
  status: string
  userId: string
}

export interface User {
  id: string
  createdAt: Date
  updatedAt: Date
  email: string
  firstName: string
  lastName: string
  phone: string
  country: string
  verified: boolean
}

export interface LeasingProjectDetailed {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  fundsGroupId: string
  assetId: string
  rentAmount: number
  cashbackOnRent: number
  futurePrice: number
  expectedDayForPayments: number
  asset: Asset
  fundsGroup: FundsGroup
  VirtualIban: VirtualIban[]
  user: User
}

export const getLeasingProjectQuery = async (id: string) => {
  return (await authenticatedGetQuery(`/admin/leasingProjects/${id}`)) as LeasingProjectDetailed
}
