import { TeamsEnum } from './assetProcessSteps'

export enum UserProcessStagesEnum {
  'TO_QUALIFY' = 'TO_QUALIFY',
  'SIGNED_UP' = 'SIGNED_UP',
  'APPOINTMENT_BOOKED' = 'APPOINTMENT_BOOKED',
  'WAITING_FOR_DOCUMENTS' = 'WAITING_FOR_DOCUMENTS',
  'DOCUMENTS_SUBMITTED' = 'DOCUMENTS_SUBMITTED',
  'ANALYSIS_COMPLETED' = 'ANALYSIS_COMPLETED',
  'PRE_PROPOSAL_SENT' = 'PRE_PROPOSAL_SENT',
  'PRE_PROPOSAL_ACCEPTED' = 'PRE_PROPOSAL_ACCEPTED',

  'PROPERTY_SUGGESTED' = 'PROPERTY_SUGGESTED',
  'PROPERTY_ACCEPTED' = 'PROPERTY_ACCEPTED',
  'NEGOCIATION_COMPLETED' = 'NEGOCIATION_COMPLETED',
  'PROPOSAL_SENT' = 'PROPOSAL_SENT',
  'PROPOSAL_ACCEPTED' = 'PROPOSAL_ACCEPTED',
  'FEES_PAID' = 'FEES_PAID',

  'INVESTOR_VALIDATED' = 'INVESTOR_VALIDATED',
  'CLOSED_LOST' = 'CLOSED_LOST',
  'CLOSED_WON' = 'CLOSED_WON',
}

export interface UserProcessStages {
  averageHoursNeeded: number
  label: string
  team: TeamsEnum
}

export const userProcessStages: Record<UserProcessStagesEnum, UserProcessStages> = {
  [UserProcessStagesEnum.TO_QUALIFY]: {
    averageHoursNeeded: 5,
    label: 'A qualifier',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.SIGNED_UP]: {
    averageHoursNeeded: 5,
    label: 'Inscrit',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.APPOINTMENT_BOOKED]: {
    averageHoursNeeded: 5,
    label: 'Rendez-vous pris',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.WAITING_FOR_DOCUMENTS]: {
    averageHoursNeeded: 5,
    label: 'Attente Docs',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.DOCUMENTS_SUBMITTED]: {
    averageHoursNeeded: 5,
    label: 'Docs Envoyés',
    team: TeamsEnum.CREDIT,
  },
  [UserProcessStagesEnum.ANALYSIS_COMPLETED]: {
    averageHoursNeeded: 5,
    label: 'Analyse effectuée',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.PRE_PROPOSAL_SENT]: {
    averageHoursNeeded: 5,
    label: 'Pré-Proposition Envoyée',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.PRE_PROPOSAL_ACCEPTED]: {
    averageHoursNeeded: 5,
    label: 'Pré-Proposition Acceptée',
    team: TeamsEnum.SUPPLY,
  },

  [UserProcessStagesEnum.PROPERTY_SUGGESTED]: {
    averageHoursNeeded: 5,
    label: 'Bien proposé',
    team: TeamsEnum.SUPPLY,
  },
  [UserProcessStagesEnum.PROPERTY_ACCEPTED]: {
    averageHoursNeeded: 5,
    label: 'Bien accepté',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.NEGOCIATION_COMPLETED]: {
    averageHoursNeeded: 5,
    label: 'Accord Vendeur',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.PROPOSAL_SENT]: {
    averageHoursNeeded: 5,
    label: 'Proposition Envoyée',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.PROPOSAL_ACCEPTED]: {
    averageHoursNeeded: 5,
    label: 'Proposition Acceptée',
    team: TeamsEnum.FUND,
  },

  [UserProcessStagesEnum.FEES_PAID]: {
    averageHoursNeeded: 5,
    label: 'Frais Versés',
    team: TeamsEnum.SUPPLY,
  },
  [UserProcessStagesEnum.INVESTOR_VALIDATED]: {
    averageHoursNeeded: 5,
    label: 'Investisseur validé',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.CLOSED_LOST]: {
    averageHoursNeeded: 5,
    label: 'Fermé perdu',
    team: TeamsEnum.DEMAND,
  },
  [UserProcessStagesEnum.CLOSED_WON]: {
    averageHoursNeeded: 5,
    label: 'Fermé gagné',
    team: TeamsEnum.DEMAND,
  },
}

// Categories

export enum UserProcessStagesCategoryEnum {
  'VALIDATE_BUDGET' = 'VALIDATE_BUDGET',
  'VALIDATE_ASSET' = 'VALIDATE_ASSET',
  'CONCLUSION' = 'CONCLUSION',
}

export interface UserProcessStagesCategory {
  label: string
  steps: UserProcessStagesEnum[]
}

export const userProcessStagesCategories: Record<
  UserProcessStagesCategoryEnum,
  UserProcessStagesCategory
> = {
  [UserProcessStagesCategoryEnum.VALIDATE_BUDGET]: {
    label: 'Validation du budget',
    steps: [
      UserProcessStagesEnum.SIGNED_UP,
      UserProcessStagesEnum.APPOINTMENT_BOOKED,
      UserProcessStagesEnum.WAITING_FOR_DOCUMENTS,
      UserProcessStagesEnum.DOCUMENTS_SUBMITTED,
      UserProcessStagesEnum.ANALYSIS_COMPLETED,
      UserProcessStagesEnum.PRE_PROPOSAL_SENT,
      UserProcessStagesEnum.PRE_PROPOSAL_ACCEPTED,
    ],
  },
  [UserProcessStagesCategoryEnum.VALIDATE_ASSET]: {
    label: 'Validation du bien',
    steps: [
      UserProcessStagesEnum.PROPERTY_SUGGESTED,
      UserProcessStagesEnum.PROPERTY_ACCEPTED,
      UserProcessStagesEnum.NEGOCIATION_COMPLETED,
      UserProcessStagesEnum.PROPOSAL_SENT,
      UserProcessStagesEnum.PROPOSAL_ACCEPTED,
    ],
  },
  [UserProcessStagesCategoryEnum.CONCLUSION]: {
    label: 'Finalisation',
    steps: [
      UserProcessStagesEnum.FEES_PAID,
      UserProcessStagesEnum.INVESTOR_VALIDATED,
      UserProcessStagesEnum.CLOSED_WON,
    ],
  },
}

export const getStepNumber = (step: UserProcessStagesEnum) => {
  return Object.keys(userProcessStages).findIndex((o) => o === step) + 1
}
