import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import {
  CashFlowV2,
  IncomesV2,
  Loans,
} from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import AlgoanIncomeDetails from './IncomeDetails'
import { Transaction } from '../../../utility/algoan/types/Transaction.interface'
import { useAtom } from 'jotai'
import {
  cashFlowsColumnsVisibilityAtom,
  isTransactionsModalOpenedAtom,
  transactionModalContentAtom,
} from '../../../atoms/algoan'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'

const AlgoanTransactionsModals = () => {
  const [isOpen, setIsOpen] = useAtom(isTransactionsModalOpenedAtom)
  const [transactionsModalContent, setTransactionsModalContent] = useAtom(
    transactionModalContentAtom,
  )

  const columnHelper = createColumnHelper<Transaction>()
  const COLUMNS = [
    columnHelper.accessor('dates.debitedAt', {
      cell: (info) => dayjs(info.getValue()).format('DD/MM/YYYY'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('amount', {
      cell: (info) => getPrettyNumber(info.getValue()),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('description', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data: transactionsModalContent.transactions,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='2xl'>
        <ModalOverlay />
        <ModalContent w='100%'>
          <ModalHeader>Transactions ({transactionsModalContent.title})</ModalHeader>
          <ModalCloseButton />
          <ModalBody w='100%'>
            <TableContainer w='100%'>
              <Table size='sm' variant='striped' w='100%'>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <Th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <Flex
                                onClick={header.column.getToggleSortingHandler()}
                                cursor='pointer'
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted() as string] ?? null}
                              </Flex>
                            )}
                          </Th>
                        )
                      })}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table
                    .getRowModel()
                    .rows.slice(0, 10)
                    .map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Td>
                            )
                          })}
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AlgoanTransactionsModals
