import React, { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'
import { MdLocationOn } from 'react-icons/md'

const MapMarker: FC<{
  text: string
  lat: number
  lng: number
}> = ({ text, lat, lng }) => (
  <Flex
    w='40px'
    h='40px'
    transform='translate(-50%, -100%)'
    color='HestiaPintGreen'
    position='absolute'
  >
    <MdLocationOn size='40px' />
  </Flex>
)

export type Location = {
  lat: number
  lng: number
}

const Map: FC<{
  location: Location
}> = ({ location }) => {
  console.log(location)
  return (
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact defaultCenter={location} defaultZoom={15}>
        <MapMarker lat={location.lat} lng={location.lng} text='Mon T3' />
      </GoogleMapReact>
    </div>
  )
}

export default Map
