import { Input, InputProps } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import { PhoneNumberInput } from '../PhoneNumberInput/PhoneNumberInput'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

type Props = {
  field: string
  label: string
  placeHolder?: string
  isRequired?: boolean
}

const PhoneNumberInputInline = (props: InputWrapperProps & Props & InputProps) => {
  const formik = useFormikContext<FormikValues>()
  return (
    <InputWrapper field={props.field} label={props.label} isRequired={props.isRequired}>
      <Input
        w='100%'
        name={props.field}
        value={formik.values[props.field] || ''}
        onChange={props.onChange || formik.handleChange}
        onBlur={() => {
          formik.handleBlur
        }}
        handleOnChange={(value: string) => {
          formik.setFieldValue(props.field, value)

          formik.setFieldTouched(props.field)
        }}
        as={PhoneNumberInput}
      />
    </InputWrapper>
  )
}

export default PhoneNumberInputInline
