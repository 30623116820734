import React, { FC } from 'react'
import { AccordionButton, CircularProgress, CircularProgressLabel, Flex } from '@chakra-ui/react'
import Subtitle from '../../components/Typo/Subtitle'
import LargeBold from '../../components/Typo/LargeBold'

const StepButton: FC<{
  text: string
  isExpanded: boolean
  currentStep: number
  totalSteps: number
  current?: boolean
}> = ({ text, current, isExpanded, currentStep, totalSteps }) => {
  let transformedStep = currentStep
  if (currentStep < 0) {
    transformedStep = 0
  }
  if (currentStep > totalSteps) {
    transformedStep = totalSteps
  }

  return (
    <AccordionButton
      w='100%'
      _focus={{ boxShadow: 'none' }}
      border='1px solid #EFEFEF'
      borderRadius='8px 8px 0px 0px'
      padding={0}
    >
      <Flex
        w='100%'
        gap='0x'
        padding='16px 24px'
        justifyContent='space-between'
        alignItems='center'
      >
        <Subtitle
          color={current ? 'HestiaBlack' : 'HestiaGrey'}
          fontWeight={current ? '600' : '400'}
          fontSize='24px'
        >
          {text}
        </Subtitle>
        <CircularProgress
          value={(transformedStep / totalSteps) * 100}
          size='72px'
          color='HestiaPintGreen'
          thickness='4px'
        >
          <CircularProgressLabel>
            <LargeBold color={current ? 'initial' : 'HestiaMediumGrey'}>
              {transformedStep}/{totalSteps}
            </LargeBold>
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </AccordionButton>
  )
}

export default StepButton
