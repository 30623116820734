import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Card,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { HouseLine, MagnifyingGlass } from '@phosphor-icons/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import { ASSSETS_INDEX_NAME } from '../../../utility/algolia'
import { ROUTES } from '../../../utility/routes/routeConstants'
import AssetProcessModalWrapper from '../../assetAds/v2/AssetProcessModalWrapper'
import Bold from '../../components/Typo/Bold'
import ChangeUserStep from '../actions/ChangeUserStepAction'
import SetUserAsLost from '../actions/SetUserAsLostAction'
import { getUserQuery } from '../queries/userQuery'
import GetLinksCTA from './GetLinksCTA'
import ImpersonateCTA from './ImpersonateCTA'

type Props = {}

const UserQuickActions = (props: Props) => {
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))
  const navigate = useNavigate()
  const {
    isOpen: isChangeStepActionOpen,
    onOpen: onChangeStepActionOpen,
    onClose: onChangeStepActionClose,
  } = useDisclosure()
  const {
    isOpen: isSetAsLostOpen,
    onOpen: onSetAsLostOpen,
    onClose: onSetAsLostClose,
  } = useDisclosure()

  return (
    <Card
      border={
        userQuery.data?.user.userInformations?.processStage === UserProcessStagesEnum.CLOSED_LOST
          ? '1px solid'
          : 'initial'
      }
      borderColor={
        userQuery.data?.user.userInformations?.processStage === UserProcessStagesEnum.CLOSED_LOST
          ? 'HestiaRed'
          : 'initial'
      }
    >
      <AssetProcessModalWrapper isOpen={isChangeStepActionOpen} onClose={onChangeStepActionClose}>
        <ChangeUserStep />
      </AssetProcessModalWrapper>
      <AssetProcessModalWrapper isOpen={isSetAsLostOpen} onClose={onSetAsLostClose}>
        <SetUserAsLost />
      </AssetProcessModalWrapper>

      <Flex direction='column' gap='8px' padding='16px'>
        <Bold>Actions</Bold>
        <Flex direction='row' gap='8px' flexFlow='wrap'>
          <Button
            variant='outline'
            colorScheme='blue'
            size='sm'
            w='fit-content'
            onClick={onChangeStepActionOpen}
            disabled={
              userQuery.data?.user.userInformations?.processStage ===
              UserProcessStagesEnum.TO_QUALIFY
            }
          >
            Changer l'étape manuellement
          </Button>

          <Button
            onClick={onSetAsLostOpen}
            variant='outline'
            colorScheme='red'
            size='sm'
            w='fit-content'
            disabled={
              userQuery.data?.user.userInformations?.processStage ===
              UserProcessStagesEnum.TO_QUALIFY
            }
          >
            Modifier le user en "Lost"
          </Button>

          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size='sm'>
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem>
                <GetLinksCTA userId={userQuery.data?.user.id || ''} />
              </MenuItem>
              <MenuItem>
                <ImpersonateCTA userId={userQuery.data?.user.id || ''} />
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size='sm'>
              Voir plus
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() =>
                  navigate(
                    `/assetads?&${ASSSETS_INDEX_NAME}%5Btoggle%5D%5BisLost%5D=true&${ASSSETS_INDEX_NAME}%5BrefinementList%5D%5BuserEmail%5D%5B0%5D=${encodeURIComponent(
                      userQuery.data?.user.email || '',
                    )}`,
                  )
                }
              >
                <Flex gap='8px' alignItems='center'>
                  <HouseLine size={24} /> Biens proposés
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => navigate(`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_ANALYSIS}`)}
              >
                <Flex gap='8px' alignItems='center'>
                  <MagnifyingGlass size={24} /> Analyses Algoan
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Card>
  )
}

export default UserQuickActions
