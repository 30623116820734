import { Flex, SimpleGrid } from '@chakra-ui/react'
import { Bank } from '@phosphor-icons/react'
import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DiscoveryTab } from '../../../../atoms/discoveryAtom'
import CustomCard from '../../../../components/CustomCard'
import useUpdateCurrentTab from '../../../../utility/hooks/discovery/useUpdateCurrentTab'
import DiscoveryIncome from './DiscoveryIncome'
import { getDiscoveryBuyersForUser } from './queries/DicsoveryBuyersQueries'
import { getDiscoverySummary } from './queries/DicsoveryQueries'

type Props = {}

const DiscoveryIncomes = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useUpdateCurrentTab(ref, DiscoveryTab.INCOME)
  const { id: userId } = useParams()

  const summary = useQuery(['discoverySummary', { id: userId }], () =>
    getDiscoverySummary(userId || ''),
  )

  const buyers = useQuery(['discoveryContextBuyers', { id: userId }], () =>
    getDiscoveryBuyersForUser(userId || ''),
  )

  return (
    <CustomCard
      title='Revenus'
      icon={<Bank size={32} color='white' />}
      // subTitle={`${getPrettyNumber(summary.data?.total.incomes.totalMonthlyIncomes)} par mois`}
    >
      <Flex flexDirection='column' gap='16px' w='100%'>
        <SimpleGrid columns={2} gap='16px' w='100%' ref={ref}>
          {buyers.data && (
            <>
              {buyers.data[0] && <DiscoveryIncome buyer={buyers.data[0]} />}
              {buyers.data[1] && <DiscoveryIncome buyer={buyers.data[1]} />}
            </>
          )}
        </SimpleGrid>
        {/* <SimpleGrid columns={2} gap='16px' w='100%' ref={ref}>
          <Flex
            border='1px solid #EFEFEF'
            borderRadius='8px'
            padding='24px'
            direction='column'
            gap='12px'
          >
            <Bold>Resumé</Bold>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Revenu total</Regular>
              <Bold>
                {getPrettyNumber(summary.data?.total.incomes.totalMonthlyIncomes)} par mois
              </Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Revenu 1er acheteur</Regular>
              <Bold>
                {getPrettyNumber(summary.data?.total.incomes.totalMonthlyIncomes)} par mois
              </Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Revenu 1er acheteur</Regular>
              <Bold>
                {getPrettyNumber(summary.data?.total.incomes.totalMonthlyIncomes)} par mois
              </Bold>
            </Flex>
          </Flex>
        </SimpleGrid> */}
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryIncomes
