import { Navigate } from 'react-router-dom'
import React, { FC, ReactNode } from 'react'
import { useAuth } from '../../auth/AuthProvider'

export const AnonymousRoute: FC<{
  children: ReactNode
}> = ({ children }) => {
  const authContext = useAuth()
  if (authContext?.admin) {
    // user is not authenticated
    return <Navigate to='/' replace />
  }
  return <>{children}</>
}
