import { Box, Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React, { FC, ReactNode } from 'react'
import { HESTIA_COLORS } from '../App'
import {
  isDiscoveryHeaderStickyAtom,
  isFloatingBudgetStickyAtom,
  isOnDiscoveryPageAtom,
} from '../atoms/discoveryAtom'
import { fileDrawerAtom } from '../atoms/filesAtom'
import CommandPalette from './commandPalette/CommandPalette'
import SideBar from './Sidebar'
import FileDrawer from './users/user/files/FileDrawer'

const DashboardLayout: FC<{
  children: ReactNode
  noPadding?: boolean
  discoveryCall?: boolean
}> = (props) => {
  const [fileDrawer, setFileDrawer] = useAtom(fileDrawerAtom)
  const [isHeaderSticky, setIsHeaderSticky] = useAtom(isDiscoveryHeaderStickyAtom)
  const [isFloatingBudgetSticky, setIsFloatingBudgetSticky] = useAtom(isFloatingBudgetStickyAtom)
  const [isDiscovery, setIsDiscovery] = useAtom(isOnDiscoveryPageAtom)

  console.log()

  return (
    <Box h='100%'>
      <CommandPalette />
      <Flex direction={['column', 'row']} height='100%' minHeight='100vh'>
        <Flex bg={HESTIA_COLORS.neutral.background}>
          <Flex
            width={['100%', '240px']}
            minWidth={['0', '240px']}
            transition='all .3s ease-in-out'
            // marginLeft={fileDrawer || (isDiscovery && isFloatingBudgetSticky) ? '-240px' : '0'}
            marginLeft={fileDrawer ? '-240px' : '0'}
            opacity={isDiscovery && isFloatingBudgetSticky ? 0 : 1}
          >
            <SideBar />
          </Flex>
        </Flex>
        <Flex width='100%' overflowX='hidden'>
          <Flex direction='column' w='100%' overflowX='hidden'>
            <Box zIndex='3' p={props.noPadding ? 0 : 12}>
              {props.children}
            </Box>
          </Flex>
          <FileDrawer />
        </Flex>
      </Flex>
    </Box>
  )
}

export default DashboardLayout
