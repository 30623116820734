import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Flex,
} from '@chakra-ui/react'
import React, { PropsWithChildren, ReactNode } from 'react'
import Bold from '../dashboard/components/Typo/Bold'

type Props = PropsWithChildren<{
  header: ReactNode | string
}>

const FloatingCard = (props: Props) => {
  return (
    <Card w='100%' bg='white' padding='12px'>
      <Flex
        flexDirection='column'
        gap='12px'
        justifyContent='space-between'
        alignItems='start'
        verticalAlign='middle'
        w='100%'
      >
        <Accordion w='100%' allowToggle={true} defaultIndex={0} border='none'>
          <AccordionItem
            w='100%'
            border='none'
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
            p={0}
          >
            <Flex flexDirection='column' gap='12px' w='100%'>
              <AccordionButton
                _focus={{ boxShadow: 'none' }}
                _hover={{ background: 'initial' }}
                border='none'
                p={0}
              >
                <Flex w='100%' justifyContent='space-between' alignItems='center'>
                  <Bold>{props.header}</Bold>
                  <AccordionIcon />
                  {/* <CaretDown size={24} /> */}
                </Flex>
              </AccordionButton>

              <AccordionPanel border='none' p={0}>
                <Flex flexDirection='column' gap='12px' w='100%'>
                  <Divider />
                  <Flex w='100%' flexDirection='column'>
                    {props.children}
                  </Flex>
                </Flex>
              </AccordionPanel>
            </Flex>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Card>
  )
}

export default FloatingCard
