import React, { useEffect } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { getLeasingProjectQuery } from './queries/getLeasingProjectById'
import Header from '../components/Typo/Header'
import Paragraph from '../components/Typo/Paragraph'
import { useFormik } from 'formik'
import { usePreviousValue } from '../../utility/hooks/usePreviousValue'
import { parseNumber } from '../../utility/parseNumber'
import { updateLeasingProject, UpdateLeasingProjectInputs } from './queries/updateLeasingProject'

const LeasingProjectsList = () => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const updateMutation = useMutation(
    (inputs: UpdateLeasingProjectInputs) => updateLeasingProject(inputs),
    {
      onError: (error: AxiosError) => {
        toast({
          title: 'Erreur:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        console.log(data)
        toast({
          title: 'Succès!:',
          description: 'All good',
          status: 'success',
          position: 'top-right',
        })
      },
    },
  )

  const leasingProject = useQuery(['leasingProject', { id }], () => getLeasingProjectQuery(id!), {
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  const prevLeasingProject = usePreviousValue(leasingProject.data)

  const formik = useFormik({
    initialValues: {
      rentAmount: 0,
      cashbackOnRent: 0,
      futurePrice: 0,
      expectedDayForPayments: 0,
    },
    onSubmit: (values) => {
      console.log(values)
      updateMutation.mutate({
        id: id!,
        ...values,
      })
    },
  })

  useEffect(() => {
    if (leasingProject.data !== prevLeasingProject) {
      formik.setFieldValue('rentAmount', leasingProject.data?.rentAmount)
      formik.setFieldValue('cashbackOnRent', leasingProject.data?.cashbackOnRent)
      formik.setFieldValue('futurePrice', leasingProject.data?.futurePrice)
      formik.setFieldValue('expectedDayForPayments', leasingProject.data?.expectedDayForPayments)
    }
  }, [leasingProject.data])

  return (
    <DashboardLayout>
      <Flex direction='column' gap='64px'>
        <Flex justifyContent='space-between' w='100%' mb={4}>
          <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
            Retour
          </Button>
        </Flex>
        <Flex direction='column' gap='16px' maxW='400px'>
          <Header>Leasing Project</Header>
          <Flex direction='row' gap='24px' alignItems='center' verticalAlign='middle'>
            <form onSubmit={formik.handleSubmit} style={{ height: '100%', width: '100%' }}>
              <FormControl isInvalid={!!formik.errors.rentAmount && formik.touched.rentAmount}>
                <FormLabel htmlFor='rentAmount'>Redevance</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color={formik.values.rentAmount ? 'black' : 'gray.300'}
                    children='€'
                  />
                  <Input
                    placeholder='800€'
                    id='rentAmount'
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue('rentAmount', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.rentAmount !== undefined &&
                      !isNaN(formik.values.rentAmount) &&
                      formik.values.rentAmount !== null
                        ? formik.values.rentAmount.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.rentAmount && formik.touched.rentAmount && (
                  <FormHelperText color='red'>{formik.errors.rentAmount}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.cashbackOnRent && formik.touched.cashbackOnRent}
              >
                <FormLabel htmlFor='cashbackOnRent'>Cashback sur le loyer</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color={formik.values.cashbackOnRent ? 'black' : 'gray.300'}
                    children='%'
                  />
                  <Input
                    placeholder='10%'
                    id='cashbackOnRent'
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue('cashbackOnRent', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.cashbackOnRent !== undefined &&
                      !isNaN(formik.values.cashbackOnRent) &&
                      formik.values.cashbackOnRent !== null
                        ? formik.values.cashbackOnRent.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.cashbackOnRent && formik.touched.cashbackOnRent && (
                  <FormHelperText color='red'>{formik.errors.cashbackOnRent}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.errors.expectedDayForPayments && formik.touched.expectedDayForPayments
                }
              >
                <FormLabel htmlFor='expectedDayForPayments'>
                  Jour attendu pour le paiement
                </FormLabel>
                <Input
                  placeholder='05 = Le 5 du mois'
                  id='expectedDayForPayments'
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue('expectedDayForPayments', parseNumber(e.target.value))
                  }
                  value={
                    formik.values.expectedDayForPayments !== undefined &&
                    !isNaN(formik.values.expectedDayForPayments) &&
                    formik.values.expectedDayForPayments !== null
                      ? formik.values.expectedDayForPayments.toString()
                      : ''
                  }
                />
                {formik.errors.expectedDayForPayments && formik.touched.expectedDayForPayments && (
                  <FormHelperText color='red'>
                    {formik.errors.expectedDayForPayments}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl isInvalid={!!formik.errors.futurePrice && formik.touched.futurePrice}>
                <FormLabel htmlFor='futurePrice'>Montant de l'option d'achat</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color={formik.values.futurePrice ? 'black' : 'gray.300'}
                    children='€'
                  />
                  <Input
                    placeholder='150 000€'
                    id='futurePrice'
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue('futurePrice', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.futurePrice !== undefined &&
                      !isNaN(formik.values.futurePrice) &&
                      formik.values.futurePrice !== null
                        ? formik.values.futurePrice.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.futurePrice && formik.touched.futurePrice && (
                  <FormHelperText color='red'>{formik.errors.futurePrice}</FormHelperText>
                )}
              </FormControl>
            </form>
          </Flex>
          <Button w='fit-content' colorScheme='green' onClick={() => navigate(-1)}>
            Enregistrer les modification
          </Button>
        </Flex>
        <Flex direction='column' gap='16px'>
          <Header>Asset</Header>
          <Flex direction='row' gap='24px' alignItems='center' verticalAlign='middle'>
            <Paragraph>
              <b>Ville:</b> {leasingProject.data?.asset.city}
            </Paragraph>
            <Paragraph>
              <b>Adresse:</b> {leasingProject.data?.asset.address}
            </Paragraph>
            <Paragraph>
              <b>Code postal:</b> {leasingProject.data?.asset.postalCode}
            </Paragraph>
            <Paragraph>
              <b>Coût d'acquisition:</b> {leasingProject.data?.asset.acquisitionCost}
            </Paragraph>
          </Flex>
          <Button
            w='fit-content'
            variant='outline'
            colorScheme='green'
            onClick={() => navigate(-1)}
          >
            Voir l'asset
          </Button>
        </Flex>
        <Flex direction='column' gap='16px'>
          <Header>Funds Group</Header>
          <Flex direction='row' gap='24px' alignItems='center' verticalAlign='middle'>
            {leasingProject.data?.fundsGroup.name}
            <Button
              variant='outline'
              w='fit-content'
              colorScheme='green'
              onClick={() => navigate(-1)}
            >
              Voir le Funds Group
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default LeasingProjectsList
