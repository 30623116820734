import { Accordion, Flex } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../auth/AuthProvider'
import { AssetProcessStagesEnum } from '../../../shared/backShared/assetProcessStages'
import { getStepNumber } from '../../../shared/backShared/assetProcessSteps'
import { getAssetAdById } from '../queries/getAssetAdById'
import StepItem from '../Steps/StepItem'
import AssetProcessStepTodo from './AssetProcessStepTodo'

type Props = {}

const AssetAdProcessList = (props: Props) => {
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const authContext = useAuth()
  const navigate = useNavigate()
  const currentStep = assetAd.data?.processStage || null

  if (!currentStep) {
    return <></>
  }

  console.log('currentStep', currentStep)

  return (
    <Flex direction='column' w='100%'>
      <Accordion w='100%' defaultIndex={0} allowMultiple allowToggle>
        <Flex direction='column' gap='24px'>
          <StepItem
            currentStep={getStepNumber(currentStep)}
            current={true}
            totalSteps={6}
            text={'Process bien'}
          >
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStagesEnum.TO_PROCESS}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStagesEnum.MARKET_VALUE_COMPLETED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStagesEnum.IN_NEGOTIATION}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStagesEnum.AGREEMENT_FOUND}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStagesEnum.CLOSED_WON}
            />
          </StepItem>
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default AssetAdProcessList
