import React, { FC } from 'react'
import { CategoryGroup } from '../../../utility/algoan/types/CreditInsights.interface'
import { TransactionCategoryType } from '../../../utility/algoan/types/Transaction.interface'
import { Badge } from '@chakra-ui/react'
import {
  TransactionBankOperationCategory,
  TransactionCreditCategory,
  TransactionExpenseCategory,
  TransactionIncomeCategory,
  TransactionRefundCategory,
  TransactionSavingCategory,
  TransactionTaxCategory,
} from '../../../utility/algoan/types/Transaction.enum'
import { ThemeTypings } from '@chakra-ui/styled-system'

const CashflowCategoryBadge: FC<{
  category?: TransactionCategoryType | undefined
}> = ({ category }) => {
  const getColor = (): ThemeTypings['colorSchemes'] => {
    if (!category) {
      return 'blue'
    }
    if (category in TransactionIncomeCategory) {
      return 'green'
    }
    if (category in TransactionExpenseCategory) {
      return 'red'
    }
    if (category in TransactionCreditCategory) {
      return 'orange'
    }
    if (category in TransactionSavingCategory) {
      return 'cyan'
    }
    if (category in TransactionBankOperationCategory) {
      return 'yellow'
    }
    if (category in TransactionRefundCategory) {
      return 'green'
    }
    if (category in TransactionTaxCategory) {
      return 'red'
    }
    return 'yellow'
  }
  return <Badge colorScheme={getColor()}>{category}</Badge>
}

export default CashflowCategoryBadge
