import { Card } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HESTIA_COLORS } from '../App'
import Bold from '../dashboard/components/Typo/Bold'

type Props = {
  icon: React.ReactNode
  text: string
  route: string
}

const ButtonCard = (props: Props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isActive = pathname === props.route
  // console.log(pathname, props.route, pathname === props.route)
  return (
    <Card
      padding='12px'
      display='flex'
      flexDirection='column'
      gap='8px'
      justifyContent='center'
      alignItems='center'
      cursor='pointer'
      border='1px solid'
      borderColor={HESTIA_COLORS.neutral.border}
      onClick={() => navigate(props.route)}
      _hover={{
        bg: '#00A881',
        color: 'white',
        transition: 'all 0.2s ease-in-out',
      }}
      bg={isActive ? '#00A881' : HESTIA_COLORS.brand.green.light}
      color={isActive ? 'white' : 'initial'}
    >
      {props.icon}
      <Bold color='inherit'>{props.text}</Bold>
    </Card>
  )
}

export default ButtonCard
