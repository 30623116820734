import React, { FC } from 'react'
import { Badge, Button, Divider, Flex } from '@chakra-ui/react'
import Header from '../../dashboard/components/Typo/Header'
import HelperMedium from '../../dashboard/components/Typo/HelperMedium'
import Bold from '../../dashboard/components/Typo/Bold'
import Regular from '../../dashboard/components/Typo/Regular'
import { getPrettyNumber } from '../../utility/money'

const OfferSummary: FC<{
  sellPrice: number
  rentPrice: number
  cashbackOnRent: number
  cta?: boolean
  depositPercent?: number
  optionYears?: number
}> = ({
  sellPrice,
  rentPrice,
  cashbackOnRent,
  optionYears = 3,
  cta = true,
  depositPercent = 2,
}) => {
  const cashbackAtTheEnd = (cashbackOnRent / 100) * rentPrice * 12 * optionYears
  return (
    <Flex alignItems='flex-start' gap='32px' direction='column' w='100%' maxW={['100%', '600px']}>
      <Flex
        alignItems='flex-start'
        gap='16px'
        direction='column'
        padding='24px'
        border='1px solid #EFEFEF'
        borderRadius='8px'
        w='100%'
      >
        <Flex alignItems='flex-start' direction='column'>
          <Header>{getPrettyNumber(sellPrice, 0, true)}</Header>
          <HelperMedium>
            Prix de vente garanti dans {optionYears.toString()} an{optionYears > 1 && 's'}
          </HelperMedium>
        </Flex>
        <Badge>FRAIS D'AGENCE INCLUS</Badge>
        {cta && (
          <Button
            type='submit'
            background='HestiaPintGreen'
            colorScheme='green'
            color='white'
            w='100%'
            onClick={() =>
              window.open(
                'https://calendly.com/adrien-hestia/30min',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Nous contacter
          </Button>
        )}
      </Flex>
      <Flex
        alignItems='flex-start'
        gap='16px'
        direction='column'
        padding='24px'
        border='1px solid #EFEFEF'
        borderRadius='8px'
        w='100%'
      >
        <Flex direction='column' gap='12px' w='100%'>
          <Bold fontSize='20px'>Apport initial</Bold>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>Montant minimum requis ({depositPercent}%)</Regular>
            <Flex w={['70%', '50%']} justifyContent='flex-end'>
              <Bold>{getPrettyNumber(sellPrice * (depositPercent / 100), 0, true)}</Bold>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex direction='column' gap='12px' w='100%'>
          <Bold fontSize='20px'>Location</Bold>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>Redevance mensuelle</Regular>
            <Flex w={['70%', '50%']} justifyContent='flex-end'>
              <Bold>{getPrettyNumber(rentPrice, 0, true)}</Bold>
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>Dont reversé sur la cagnotte Hestia</Regular>
            <Flex w={['70%', '50%']} justifyContent='flex-end'>
              <Regular>{getPrettyNumber(rentPrice * (cashbackOnRent / 100), 0, true)}</Regular>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex direction='column' gap='12px' w='100%'>
          <Bold fontSize='20px'>Achat</Bold>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>Prix de vente garanti</Regular>
            <Flex w={['70%', '50%']} justifyContent='flex-end'>
              <Bold>{getPrettyNumber(sellPrice, 0, true)}</Bold>
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>Apport initial</Regular>
            <Regular>- {getPrettyNumber(sellPrice * (depositPercent / 100), 0, true)}</Regular>
          </Flex>
          <Flex justifyContent='space-between' gap='24px'>
            <Regular>
              Cagnotte Hestia <br />({optionYears * 12} mois)
            </Regular>
            <Flex w={['70%', '50%']} justifyContent='flex-end'>
              <Regular>- {getPrettyNumber(cashbackAtTheEnd, 0, true)}</Regular>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex justifyContent='space-between' gap='48px' w='100%'>
          <Header fontSize='20px' color='HestiaPintGreen'>
            Prix final dans {optionYears.toString()} an{optionYears > 1 && 's'}
          </Header>
          <Flex w={['70%', '50%']} justifyContent='flex-end'>
            <Header fontSize='20px' color='HestiaPintGreen'>
              {getPrettyNumber(
                sellPrice - sellPrice * (depositPercent / 100) - cashbackAtTheEnd,
                0,
                true,
              )}
            </Header>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default OfferSummary
