import { AssetAdStatus } from '../interfaces/prisma'

export type PartialAssetAd = {
  acquisitionPrice?: number | null
  hestiaPrice?: number | null
  rentPrice?: number | null
  depositPercent?: number | null
  optionYears?: number | null
  hestiaStatus: AssetAdStatus
  nbOffersSent: number
}

export const isAssetAdOfferComplete = (assetAd: PartialAssetAd) => {
  return !(
    !assetAd.acquisitionPrice ||
    assetAd.acquisitionPrice === 0 ||
    !assetAd.hestiaPrice ||
    assetAd.hestiaPrice === 0 ||
    !assetAd.rentPrice ||
    assetAd.rentPrice === 0 ||
    !assetAd.depositPercent ||
    assetAd.optionYears === 0 ||
    !assetAd.optionYears
  )
}

export const getAssetAdStatus = (assetAd: PartialAssetAd) => {
  if (assetAd.hestiaStatus === AssetAdStatus.ACCEPTED) {
    if (isAssetAdOfferComplete(assetAd)) {
      return AssetAdStatus.ACCEPTED
    } else {
      return AssetAdStatus.PENDING
    }
  } else return assetAd.hestiaStatus
}
