import { Badge, Button, CircularProgress, CircularProgressLabel, Flex } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types'
import { useAtom } from 'jotai'
import React from 'react'
import { Highlight } from 'react-instantsearch-hooks-web'
import { useNavigate } from 'react-router-dom'
import { isCommandPaletteOpenAtom } from '../../../atoms/searchAtoms'
import { AssetAdStatus } from '../../../interfaces/prisma'
import {
    AssetProcessStepEnum,
    assetProcessSteps,
    getStepNumber,
    TeamsEnum
} from '../../../shared/backShared/assetProcessSteps'
import { ROUTES } from '../../../utility/routes/routeConstants'
import Bold from '../Typo/Bold'
import LargeBold from '../Typo/LargeBold'
import { default as HelperSmall, default as Regular } from '../Typo/Regular'

require('dayjs/locale/fr')
dayjs.extend(relativeTime)
dayjs.locale('fr')

export type AssetAlgolia = {
  createdAt: string
  name: string
  userEmail: string
  userFirstName: string
  userLastName: string
  hestiaStatus: AssetAdStatus
  userStatus: AssetAdStatus
}

type Props = { hit: AlgoliaHit & Partial<AssetAlgolia> }

const AssetHitComplete = (props: Props) => {
  const [isOpen, setIsOpen] = useAtom(isCommandPaletteOpenAtom)
  const navigate = useNavigate()

  const completionPercent = props.hit.processStep
    ? (getStepNumber(props.hit.processStep) / Object.keys(assetProcessSteps).length) * 100
    : 0

  const getTeamBadge = (team: TeamsEnum) => {
    return team === TeamsEnum.SUPPLY ? (
      <Badge colorScheme='yellow' alignItems='center' h='fit-content'>
        Analyst
      </Badge>
    ) : (
      <Badge colorScheme='blue' alignItems='center' h='fit-content'>
        Sales
      </Badge>
    )
  }

  return (
    <Flex
      border='1px solid'
      borderRadius='8px'
      borderColor={props.hit.isLost ? 'HestiaLightRed' : '#EFEFEF'}
      _hover={{ bg: 'HestiaLightGrey' }}
      //   borderColor='HestiaRed'
      //   bg='HestiaLightRed'
      cursor='pointer'
      padding='8px'
      gap='8px'
      onClick={() => {
        navigate(`/${ROUTES.ASSETADS_BASE}/${props.hit.objectID}`)
        setIsOpen(false)
      }}
      maxW='100%'
      justifyContent='space-between'
    >
      <Flex direction='row' gap='8px' maxW='100%' w='100%'>
        <Flex maxW='100%' justifyContent='center' verticalAlign='middle' alignItems='center'>
          {/* <BiHome size='24px' color='gray' /> */}

          <CircularProgress
            value={props.hit.isLost ? 100 : completionPercent}
            size='72px'
            color={props.hit.isLost ? 'HestiaLightRed' : 'HestiaPintGreen'}
            thickness='8px'
          >
            {!props.hit.isLost && (
              <CircularProgressLabel>
                <LargeBold fontSize='16px'>{completionPercent.toFixed(0)}%</LargeBold>
              </CircularProgressLabel>
            )}
          </CircularProgress>
        </Flex>
        <Flex
          direction='column'
          h='100%'
          minW='0'
          justifyContent='center'
          maxW='100%'
          wordBreak='break-all'
        >
          <Bold maxW='100%' w='100%'>
            {props.hit.name}
          </Bold>
          <HelperSmall overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
            <Highlight hit={props.hit} attribute='userEmail'>
              {props.hit.userEmail}
            </Highlight>
          </HelperSmall>
        </Flex>
      </Flex>

      <Flex maxW='100%' minW='fit-content' alignItems='center' justifyContent='flex-end'>
        <Flex w='100%' alignItems='center' direction='column' justifyContent='flex-end' gap='8px'>
          {props.hit.isLost ? (
            <Flex w='100%' justifyContent='center' alignItems='flex-end' direction='column'>
              <Badge colorScheme='red' fontSize='1em' alignItems='center' h='fit-content'>
                Lost
              </Badge>
              <Regular fontSize='14px'>{`Proposé le ${dayjs(props.hit.createdAt)
                .locale('fr')
                .format('DD/MM/YYYY')}`}</Regular>
            </Flex>
          ) : (
            <>
              <Flex
                w='100%'
                minW='0'
                alignItems='center'
                justifyContent='flex-end'
                gap='8px'
                direction='row'
              >
                <Button
                  color='HestiaGrey'
                  size='sm'
                  variant='outline'
                  _hover={{ background: 'initial' }}
                >
                  {assetProcessSteps[props.hit.processStep as AssetProcessStepEnum].label}
                </Button>
                {props.hit.hasBeenVisited ? (
                  <Badge colorScheme='green' alignItems='center' h='fit-content'>
                    Visité
                  </Badge>
                ) : (
                  <Badge colorScheme='blue' alignItems='center' h='fit-content'>
                    Pas visité
                  </Badge>
                )}

                {/* {getTeamBadge(
                  assetProcessSteps[props.hit.processStep as AssetProcessStepEnum].team,
                )} */}
              </Flex>
              <Flex w='100%' justifyContent='flex-end'>
                <Regular fontSize='14px'>{`Commencé ${dayjs(props.hit.processStepLastActionDate)
                  .locale('fr')
                  .fromNow()}`}</Regular>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AssetHitComplete
