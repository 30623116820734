import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface CreateUserPayload {
  partnerId?: string | undefined
  agencyId?: string | undefined
  personId?: string | undefined
  isFromPartner?: boolean | undefined
  roundRobin?: boolean | undefined
  ownerId?: string | undefined

  email?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  phone?: string | undefined

  postalCode?: { label: string; value: string } | undefined
}

export const createUserQuery = async (payload: CreateUserPayload) => {
  return (await authenticatedPostQuery(`/admin/users/create`, payload)) as Promise<{ id: string }>
}
