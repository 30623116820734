import { Flex } from '@chakra-ui/react'
import React from 'react'
import Bold from '../components/Typo/Bold'
import Header from '../components/Typo/Header'

type Props = { text: string }

const CommandDivider = (props: Props) => {
  return (
    <Flex w='100%' padding='8px 16px'>
      <Bold fontSize='14px'>{props.text}</Bold>
    </Flex>
  )
}

export default CommandDivider
