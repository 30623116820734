import { Flex, SimpleGrid } from '@chakra-ui/react'
import { Bank } from '@phosphor-icons/react'
import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DiscoveryTab } from '../../../../atoms/discoveryAtom'
import CustomCard from '../../../../components/CustomCard'
import useUpdateCurrentTab from '../../../../utility/hooks/discovery/useUpdateCurrentTab'
import DiscoveryBuyer from './DiscoveryBuyer'
import DiscoveryChildren from './DiscoveryChildren'
import { getDiscoveryBuyersForUser } from './queries/DicsoveryBuyersQueries'

type Props = {}

const DiscoveryPeople = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useUpdateCurrentTab(ref, DiscoveryTab.PEOPLE)
  const { id: userId } = useParams()

  const buyers = useQuery(['discoveryContextBuyers', { id: userId }], () =>
    getDiscoveryBuyersForUser(userId || ''),
  )

  return (
    <CustomCard title={`People`} icon={<Bank size={32} color='white' />}>
      <Flex flexDirection='column' gap='16px' w='100%'>
        <SimpleGrid columns={2} gap='16px' w='100%' ref={ref}>
          {buyers.data && (
            <>
              <DiscoveryBuyer buyerId={buyers.data[0]?.id} />
              <DiscoveryBuyer buyerId={buyers.data[1]?.id} />
            </>
          )}
        </SimpleGrid>
        <SimpleGrid columns={2} gap='16px' w='100%' ref={ref}>
          {buyers.data && (
            <>
              {buyers.data[0] && <DiscoveryChildren buyer={buyers.data[0]} />}
              {buyers.data[1] && <DiscoveryChildren buyer={buyers.data[1]} />}
            </>
          )}
        </SimpleGrid>
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryPeople
