import { Card, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  Line,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from 'recharts'
import {
  getClosestInArrayOfObject,
  getObjectWithClosestDebtToIncomeRatio,
  getPrettyNumber,
} from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { FormikValues, useFormikContext } from 'formik'
import { useState, useEffect } from 'react'

type Props = {
  hestiaFees: number
  initialSavings: number
  monthlySavings: number
  durationInMonths: number
}

interface ChartData {
  month: number
  monthlySavings: number
  initialSavings: number
}

const SavedWithHestiaChart = (props: Props) => {
  const formik = useFormikContext<FormikValues>()

  const chartData: ChartData[] = []

  let cumulativeMonthlySavings = props.monthlySavings

  for (let month = 1; month <= props.durationInMonths; month++) {
    chartData.push({
      month,
      monthlySavings: cumulativeMonthlySavings,
      initialSavings: props.initialSavings - props.hestiaFees,
    })
    cumulativeMonthlySavings += props.monthlySavings
  }

  return (
    <Flex direction='column' gap='8px' w='100%' p={0}>
      <ResponsiveContainer width='100%' height={250}>
        <BarChart
          width={600}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='month' />
          <YAxis
            type='number'
            tickFormatter={(tick) => `${getPrettyNumber(tick, 0, false)}`}
            width={100}
          />

          <Legend />

          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const totalSavings = payload.reduce(
                  (total, current) => total + (current.value as number),
                  0,
                )

                const monthlySavings =
                  (payload.find((p) => p.dataKey === 'monthlySavings')?.value as number) || 0
                const initialSavings =
                  (payload.find((p) => p.dataKey === 'initialSavings')?.value as number) || 0
                const month = payload[0].payload.month

                const monthlySavingsPercentage = (monthlySavings / totalSavings) * 100
                const initialSavingsPercentage = (initialSavings / totalSavings) * 100

                return (
                  <Card bg='white' padding='16px'>
                    <Flex gap='8px' direction='column'>
                      <Regular>
                        Mois: <b>{month}</b>
                        <br />
                        Apport dispo: <b>{`${getPrettyNumber(totalSavings)}`}</b>
                        <br />
                        Épargne mensuelle:{' '}
                        <b>
                          {`${getPrettyNumber(monthlySavings)} (${monthlySavingsPercentage.toFixed(
                            0,
                          )}%)`}
                        </b>
                        <br />
                        Apport initial:{' '}
                        <b>
                          {`${getPrettyNumber(initialSavings)} (${initialSavingsPercentage.toFixed(
                            0,
                          )}%)`}
                        </b>
                      </Regular>
                    </Flex>
                  </Card>
                )
              }
              return null
            }}
          />
          <Bar stackId='a' type='monotone' dataKey='monthlySavings' fill='#82ca9d' />
          <Bar stackId='a' type='monotone' dataKey='initialSavings' fill='#8884d8' />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  )
}

export default SavedWithHestiaChart
