import { UserAnalaysisRejectionReasonEnum } from '../../../shared/backShared/userAnalysisRejectionTypes'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { AnalysisCompletedStates } from '../actions/AnalysisCompletedAction'

export interface UserAnalysisPayload {
  analysisStatus: AnalysisCompletedStates | undefined
  userAnalysisRejectionReason: UserAnalaysisRejectionReasonEnum | undefined
  rejectionMessage: string | undefined
  needDocsMessage: string | undefined
}

export const userAnalysisQuery = async (id: string, payload: UserAnalysisPayload) => {
  return await authenticatedPostQuery(`/admin/users/${id}/action/userAnalysis`, payload)
}
