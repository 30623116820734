import React from 'react'
import { Divider, Flex, useModalContext } from '@chakra-ui/react'
import { GrClose } from 'react-icons/gr'

type Props = {}

const ModalCustomCloseButton = (props: Props) => {
  const { onClose } = useModalContext()

  return (
    <>
      <Flex minH='72px' w='100%' justifyContent='flex-end' padding='16px'>
        <Flex
          w='40px'
          h='40px'
          bg='HestiaLightGrey'
          borderRadius='51px'
          justifyContent='center'
          alignItems='center'
          cursor='pointer'
          onClick={onClose}
        >
          <GrClose size='12px' color='black' />
        </Flex>
      </Flex>
      <Divider />
    </>
  )
}

export default ModalCustomCloseButton
