import React, { FC, ReactNode, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { fromJSON, parse, stringify, toJSON } from 'flatted'

type TableContent = Record<string, string | number | ReactNode>[]

const customTable: FC<{
  total?: number | undefined
  headers: any[]
  widths?: string[]
  content: TableContent
  onPageChange?: (page: number) => void
  pageSize?: number
  managedPagination?: boolean
}> = ({
  content,
  headers,
  total,
  onPageChange,
  widths,
  pageSize = 100,
  managedPagination = true,
}) => {
  const [page, setPage] = useState(1)
  const [displayedContent, setDisplayedContent] = useState<TableContent>([])
  const navigate = useNavigate()
  const realTotal = total || content.length

  const handleNext = () => {
    if (page + 1 <= Math.ceil(realTotal / pageSize)) {
      setPage(page + 1)
    }
  }

  const handlePrev = () => {
    if (page - 1 > 0) {
      setPage(page - 1)
    }
  }

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page)
    }
  }, [page])

  useEffect(() => {
    if (managedPagination) {
      setDisplayedContent(content.slice((page - 1) * pageSize, page * pageSize))
    } else {
      setDisplayedContent(content)
    }
  }, [page, pageSize, content])

  return (
    <TableContainer width='100%'>
      <Table variant='simple' width='100%' overflowWrap='break-word'>
        <Thead width='100%'>
          <Tr>
            {headers.map((header) => {
              return <Th>{header}</Th>
            })}
          </Tr>
        </Thead>
        <Tbody width='100%'>
          {displayedContent.map((row) => {
            return (
              <Tr cursor='pointer' w='100%'>
                {Object.keys(row).map((key, index) => {
                  return (
                    <Td
                      key={index}
                      whiteSpace='normal'
                      maxWidth={widths && widths[index] ? widths[index] : 'fit-content'}
                    >
                      {row[key]}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Box width='100%'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Text as='span'>{realTotal} results</Text>
          <Flex gap={3}>
            <Link color='HestiaForestGreen' onClick={handlePrev}>
              {'< PREV'}
            </Link>
            <Text as='span'>
              {page}/{Math.ceil(realTotal / pageSize)}
            </Text>
            <Link color='HestiaForestGreen' onClick={handleNext}>
              {'NEXT >'}
            </Link>
          </Flex>
        </Flex>
      </Box>
    </TableContainer>
  )
}

export default customTable
