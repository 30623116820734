import { Divider, Flex, Input, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import algoliasearch from 'algoliasearch'
import React, { useState } from 'react'
import { Configure, Hits, Index, InstantSearch, SearchBox } from 'react-instantsearch-hooks-web'
import { useQuery } from 'react-query'
import Header from '../components/Typo/Header'
import AlgoliaSearchBox from './AlgoliaSearchBox'
import CommandDivider from './CommandDivider'
import { getAlgoliaKeys } from './queries/getAlgoliaKeys'
import UserHit from './UserHit'
import UserHits from './CustomHits'
import CustomHits from './CustomHits'
import AssetHit from './AssetHit'
import { MultipleQueriesQuery } from '@algolia/client-search'
import { useAtom } from 'jotai'
import { isSearchEmptyAtom } from '../../atoms/searchAtoms'
import AssetHitComplete from '../components/Algolia/AssetHitComplete'

type Props = {}

const OmniSearchResults = (props: Props) => {
  const algoliaKeys = useQuery(['getAlgoliaKeys'], () => getAlgoliaKeys(), {
    refetchInterval: 15000,
  })
  const [isSearchEmpty, setIsSearchEmpty] = useAtom(isSearchEmptyAtom)

  if (!algoliaKeys.data?.secret) {
    return <Spinner />
  }

  const algoliaClient = algoliasearch(algoliaKeys.data?.appName, algoliaKeys.data?.secret)

  const searchClient = {
    ...algoliaClient,
    search<TObject>(requests: readonly MultipleQueriesQuery[]) {
      if (requests.every((req: MultipleQueriesQuery) => !req.params?.query)) {
        setIsSearchEmpty(true)
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        })
      }
      setIsSearchEmpty(false)
      return algoliaClient.search<TObject>(requests)
    },
  }

  return (
    <Flex direction='column' width='100%'>
      <InstantSearch indexName='production_users' searchClient={searchClient}>
        {/* Widgets */}
        <Configure analytics={false} hitsPerPage={10} />
        <AlgoliaSearchBox />

        {!isSearchEmpty && (
          <>
            <CommandDivider text='Utilisateurs' />
            <Index indexName='production_users'>
              <CustomHits HitComponent={UserHit} />
            </Index>

            <CommandDivider text='Assets' />
            <Index indexName='production_assets'>
              <CustomHits HitComponent={AssetHitComplete} />
            </Index>
          </>
        )}
      </InstantSearch>
    </Flex>
  )
}

export default OmniSearchResults
