import { Accordion, Flex } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../auth/AuthProvider'
import {
  getStepNumber,
  userProcessStagesCategories,
  UserProcessStagesCategoryEnum,
  UserProcessStagesEnum,
} from '../../../shared/backShared/userProcessStages'
import StepItem from '../../assetAds/Steps/StepItem'
import { getUserQuery } from '../queries/userQuery'
import UserProcessStageTodo from './UserProcessStageTodo'

type Props = {}

const UserProcessStagesList = (props: Props) => {
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const authContext = useAuth()
  const navigate = useNavigate()
  const currentStep = userQuery.data?.user.userInformations?.processStage || null

  if (!currentStep) {
    return <></>
  }

  const getDefaultIndex = () => {
    {
      // if (assetAd.data?.isLost) {
      //   return []

      // }
      if (
        userProcessStagesCategories[UserProcessStagesCategoryEnum.VALIDATE_BUDGET].steps.includes(
          currentStep,
        )
      ) {
        return [0]
      } else if (
        userProcessStagesCategories[UserProcessStagesCategoryEnum.VALIDATE_ASSET].steps.includes(
          currentStep,
        )
      ) {
        return [1]
      } else if (
        userProcessStagesCategories[UserProcessStagesCategoryEnum.CONCLUSION].steps.includes(
          currentStep,
        )
      ) {
        return [2]
      } else {
        return []
      }
    }
  }

  return (
    <Flex direction='column' w='100%'>
      <Accordion w='100%' defaultIndex={getDefaultIndex()} allowMultiple allowToggle>
        <Flex direction='column' gap='24px'>
          <StepItem
            currentStep={getStepNumber(currentStep)}
            current={userProcessStagesCategories[
              UserProcessStagesCategoryEnum.VALIDATE_BUDGET
            ].steps.includes(currentStep)}
            totalSteps={8}
            text={userProcessStagesCategories[UserProcessStagesCategoryEnum.VALIDATE_BUDGET].label}
          >
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.TO_QUALIFY}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.SIGNED_UP}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.APPOINTMENT_BOOKED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.WAITING_FOR_DOCUMENTS}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.DOCUMENTS_SUBMITTED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.ANALYSIS_COMPLETED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PRE_PROPOSAL_SENT}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PRE_PROPOSAL_ACCEPTED}
            />
          </StepItem>
          <StepItem
            current={userProcessStagesCategories[
              UserProcessStagesCategoryEnum.VALIDATE_ASSET
            ].steps.includes(currentStep)}
            currentStep={getStepNumber(currentStep) - 8}
            totalSteps={5}
            text={userProcessStagesCategories[UserProcessStagesCategoryEnum.VALIDATE_ASSET].label}
          >
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PROPERTY_SUGGESTED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PROPERTY_ACCEPTED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.NEGOCIATION_COMPLETED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PROPOSAL_SENT}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.PROPOSAL_ACCEPTED}
            />
          </StepItem>
          <StepItem
            current={userProcessStagesCategories[
              UserProcessStagesCategoryEnum.CONCLUSION
            ].steps.includes(currentStep)}
            currentStep={getStepNumber(currentStep) - 8 - 5}
            totalSteps={3}
            text={userProcessStagesCategories[UserProcessStagesCategoryEnum.CONCLUSION].label}
          >
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.FEES_PAID}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.INVESTOR_VALIDATED}
            />
            <UserProcessStageTodo
              currentStep={currentStep}
              step={UserProcessStagesEnum.CLOSED_WON}
            />
          </StepItem>
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default UserProcessStagesList
