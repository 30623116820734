import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'

const Analysis = ({ creditScore }: { creditScore: CreditScore }) => {
  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      padding='24px'
      direction='column'
      gap='16px'
    >
      <Header> Credit score: </Header>
      <Flex gap='16px'>
        <CircularProgress value={creditScore.value / 10} color='green.400'></CircularProgress>
        <Header>
          <Box as='span' color='HestiaPintGreen' fontSize='38px'>
            {creditScore.value}
          </Box>
          <Box as='span' fontSize='16px'>
            /1000
          </Box>
        </Header>
      </Flex>

      <Flex gap='8px' direction='row' flex='1 1 0px'>
        <CreditScoreIndicator
          label={'Living Standard'}
          value={creditScore.indicators?.livingStandard || 0}
        />
        <CreditScoreIndicator
          label={'Budget management'}
          value={creditScore.indicators?.budgetManagement || 0}
        />
        <CreditScoreIndicator
          label={'Financial evolution'}
          value={creditScore.indicators?.financialEvolution || 0}
        />
      </Flex>
    </Flex>
  )
}

export default Analysis
