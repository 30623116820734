import React, { FC, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { getUploadUrl } from '../queries/files/getUploadUrl'
import { uploadToS3, UploadToS3Inputs } from '../queries/files/uploadToS3'
import FileUploadResult from '../FileUploadResult'
import { SaveFileKeyInputs, saveFileKey } from '../queries/files/saveFileKey'

const FolderSingleFileUploader: FC<{
  file: File
  id: string
  refetchFunction?: () => void
  callbackWhenUploaded?: () => void
  userId: string
  assetAdId?: string
}> = ({ file, id, refetchFunction, callbackWhenUploaded, assetAdId, userId }) => {
  const toast = useToast()
  const [isUploaded, setIsUploaded] = useState(false)
  const [hasFetch, setHasFetch] = useState(false)
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(true)

  const uploadUrl = useQuery(
    ['file', file.name, id],
    () => getUploadUrl({ fileName: file.name, fileType: file.type }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  )

  useEffect(() => {
    if (isUploaded) {
      refetchFunction && refetchFunction()
      callbackWhenUploaded && callbackWhenUploaded()
    }
  }, [isUploaded])

  const saveFileQuery = useMutation((data: SaveFileKeyInputs) => saveFileKey(data), {
    onSuccess: (data) => {
      setIsUploaded(true)
      setIsLoading(true)
    },
    onError: (error: AxiosError) => {
      setIsLoading(false)
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  const uploadFileQuery = useMutation((data: UploadToS3Inputs) => uploadToS3(data), {
    onSuccess: (data) => {
      saveFileQuery.mutate({ key: uploadUrl.data.key, userId, assetAdId })
    },
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  useEffect(() => {
    if (uploadUrl.data?.url && !isUploaded && !hasFetch) {
      setHasFetch(true)
      uploadFileQuery.mutate({
        file,
        url: uploadUrl.data.url,
      })
    }
  }, [uploadUrl.data?.url])

  return !isUploaded && isLoading ? <FileUploadResult /> : <></>
}

export default FolderSingleFileUploader
