import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { usePreviousValue } from '../../utility/hooks/usePreviousValue'
import { parseNumber } from '../../utility/parseNumber'
import { getAssetAdById } from './queries/getAssetAdById'
import { updateAssetAd, UpdateAssetAdInputs } from './queries/updateAssetAd'
import OfferSummary from '../../shared/components/OfferSummary'
import Regular from '../components/Typo/Regular'
import ConfirmButton from '../../shared/components/ConfirmButton'
import { getAssetAdStatus } from '../../utility/assetAds.utils'
import { AssetAdStatus } from '../../interfaces/prisma'

const Offer = () => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      assetAd.refetch()
      toast({
        title: 'Succès!:',
        description: 'All good',
        status: 'success',
        position: 'top-right',
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      acquisitionPrice: 0,
      hestiaPrice: 0,
      rentPrice: 0,
      depositPercent: 2,
      optionYears: 2,
      cashbackOnRent: 20,
      sendOfferEmail: false,
    },
    onSubmit: (values) => {
      console.log(values)
      updateMutation.mutate({
        hestiaPrice: values.hestiaPrice,
        optionYears: values.optionYears,
        rentPrice: values.rentPrice,
        cashbackOnRent: values.cashbackOnRent,
        depositPercent: values.depositPercent,
        acquisitionPrice: values.acquisitionPrice,
        id: id!,
        sendOfferEmail: values.sendOfferEmail,
      })
    },
  })

  const prevAssetAd = usePreviousValue(assetAd.data)

  useEffect(() => {
    if (assetAd.data !== prevAssetAd) {
      formik.setFieldValue('hestiaPrice', assetAd.data?.hestiaPrice || 0)
      formik.setFieldValue('rentPrice', assetAd.data?.rentPrice || 0)
      formik.setFieldValue('cashbackOnRent', assetAd.data?.cashbackOnRent || 0)
      formik.setFieldValue('depositPercent', assetAd.data?.depositPercent || 2)
      formik.setFieldValue('acquisitionPrice', assetAd.data?.acquisitionPrice || 0)
      formik.setFieldValue('optionYears', assetAd.data?.optionYears || 0)
    }
  }, [assetAd.data])

  return (
    <Flex>
      <Flex w='50%' direction='column' gap='64px' padding='12px'>
        <form onSubmit={formik.handleSubmit}>
          <Flex direction='column' gap='48px'>
            <Flex direction='column' gap='24px' width={['100%', '400px']}>
              <FormControl
                isInvalid={!!formik.errors.acquisitionPrice && formik.touched.acquisitionPrice}
              >
                <FormLabel htmlFor='acquisitionPrice'>Prix d'acquisition</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <InputLeftElement pointerEvents='none' children='€' />
                  <Input
                    id='acquisitionPrice'
                    placeholder="Prix d'acquisition"
                    onChange={(e) =>
                      formik.setFieldValue('acquisitionPrice', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.acquisitionPrice !== undefined &&
                      !isNaN(formik.values.acquisitionPrice) &&
                      formik.values.acquisitionPrice !== null &&
                      formik.values.acquisitionPrice !== 0
                        ? formik.values.acquisitionPrice.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.acquisitionPrice && formik.touched.acquisitionPrice && (
                  <FormHelperText color='red'>{formik.errors.acquisitionPrice}</FormHelperText>
                )}
              </FormControl>
              <FormControl isInvalid={!!formik.errors.hestiaPrice && formik.touched.hestiaPrice}>
                <FormLabel htmlFor='hestiaPrice'>Prix de revente</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <InputLeftElement pointerEvents='none' children='€' />
                  <Input
                    id='hestiaPrice'
                    placeholder='Prix de revente'
                    onChange={(e) =>
                      formik.setFieldValue('hestiaPrice', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.hestiaPrice !== undefined &&
                      !isNaN(formik.values.hestiaPrice) &&
                      formik.values.hestiaPrice !== null &&
                      formik.values.hestiaPrice !== 0
                        ? formik.values.hestiaPrice.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.hestiaPrice && formik.touched.hestiaPrice && (
                  <FormHelperText color='red'>{formik.errors.hestiaPrice}</FormHelperText>
                )}
              </FormControl>
              <FormControl isInvalid={!!formik.errors.rentPrice && formik.touched.rentPrice}>
                <FormLabel htmlFor='rentPrice'>Redevance mensuelle</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <InputLeftElement pointerEvents='none' children='€' />
                  <Input
                    id='rentPrice'
                    placeholder='Prix de revente'
                    onChange={(e) => formik.setFieldValue('rentPrice', parseNumber(e.target.value))}
                    value={
                      formik.values.rentPrice !== undefined &&
                      !isNaN(formik.values.rentPrice) &&
                      formik.values.rentPrice !== null &&
                      formik.values.rentPrice !== 0
                        ? formik.values.rentPrice.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.rentPrice && formik.touched.rentPrice && (
                  <FormHelperText color='red'>{formik.errors.rentPrice}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.cashbackOnRent && formik.touched.cashbackOnRent}
              >
                <FormLabel htmlFor='optionYears'>Durée de l'option (en années)</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <Input
                    id='optionYears'
                    placeholder='Prix de revente'
                    onChange={(e) =>
                      formik.setFieldValue('optionYears', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.optionYears !== undefined &&
                      !isNaN(formik.values.optionYears) &&
                      formik.values.optionYears !== null &&
                      formik.values.optionYears !== 0
                        ? formik.values.optionYears.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.optionYears && formik.touched.optionYears && (
                  <FormHelperText color='red'>{formik.errors.optionYears}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.cashbackOnRent && formik.touched.cashbackOnRent}
              >
                <FormLabel htmlFor='cashbackOnRent'>% de cagnotte Hestia</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <InputLeftElement pointerEvents='none' children='%' />
                  <Input
                    id='cashbackOnRent'
                    onChange={(e) =>
                      formik.setFieldValue('cashbackOnRent', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.cashbackOnRent !== undefined &&
                      !isNaN(formik.values.cashbackOnRent) &&
                      formik.values.cashbackOnRent !== null
                        ? formik.values.cashbackOnRent.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.cashbackOnRent && formik.touched.cashbackOnRent && (
                  <FormHelperText color='red'>{formik.errors.cashbackOnRent}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.depositPercent && formik.touched.depositPercent}
              >
                <FormLabel htmlFor='depositPercent'>% d'apport requis</FormLabel>
                <InputGroup maxWidth={['100%', '80%']}>
                  <InputLeftElement pointerEvents='none' children='%' />
                  <Input
                    id='depositPercent'
                    placeholder='3%'
                    onChange={(e) =>
                      formik.setFieldValue('depositPercent', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.depositPercent !== undefined &&
                      !isNaN(formik.values.depositPercent) &&
                      formik.values.depositPercent !== null &&
                      formik.values.depositPercent !== 0
                        ? formik.values.depositPercent.toString()
                        : ''
                    }
                  />
                </InputGroup>
                {formik.errors.depositPercent && formik.touched.depositPercent && (
                  <FormHelperText color='red'>{formik.errors.depositPercent}</FormHelperText>
                )}
              </FormControl>
            </Flex>
            <Flex gap='8px' direction='column'>
              <Flex gap='8px'>
                <Button
                  variant='outline'
                  colorScheme='green'
                  onClick={() => {
                    formik.setFieldValue('sendOfferEmail', false)
                    formik.submitForm()
                  }}
                  w='fit-content'
                >
                  Enregistrer
                </Button>
                <ConfirmButton
                  bodyText='Cette action va envoyer un mail à un utilisateur'
                  buttonText={'Enregistrer et envoyer un email'}
                  headerText='Cette action va envoyer un email'
                  onSuccessAction={() => {
                    formik.setFieldValue('sendOfferEmail', true)
                    formik.submitForm()
                  }}
                />
              </Flex>
              <Regular>Proposition envoyée {assetAd.data?.nbOffersSent} fois par email.</Regular>

              <Regular>
                La proposition est{' '}
                <Tooltip
                  hasArrow
                  label="Pour que la proposition soit visible il faut qu'elle soit acceptée et que l'offre soit complète."
                >
                  <Box
                    as='span'
                    color={
                      assetAd.data && getAssetAdStatus(assetAd.data) === AssetAdStatus.ACCEPTED
                        ? 'HestiaPintGreen'
                        : 'HestiaRed'
                    }
                    fontWeight='600'
                  >
                    {assetAd.data && getAssetAdStatus(assetAd.data) === AssetAdStatus.ACCEPTED
                      ? 'visible'
                      : 'non visible'}
                  </Box>
                </Tooltip>{' '}
                pour l'utilisateur sur la plateforme
              </Regular>
            </Flex>
          </Flex>
        </form>
      </Flex>
      <Flex w='50%' direction='column' gap='64px' padding='12px'>
        <OfferSummary
          cta={false}
          sellPrice={formik.values.hestiaPrice || 0}
          rentPrice={formik.values.rentPrice || 0}
          cashbackOnRent={formik.values.cashbackOnRent || 0}
          depositPercent={formik.values.depositPercent || 0}
          optionYears={formik.values.optionYears || 0}
        />
      </Flex>
    </Flex>
  )
}

export default Offer
