import { Button, Flex, ModalFooter, SimpleGrid, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { TeamsEnum } from '../../../shared/backShared/assetProcessSteps'
import BooleanInput from '../../../shared/components/inputs/InlineLayout/BooleanInput'
import InputWrapper from '../../../shared/components/inputs/InlineLayout/InputWrapper'
import PhoneNumberInputInline from '../../../shared/components/inputs/InlineLayout/PhoneNumberInlineInput'
import SelectInput from '../../../shared/components/inputs/InlineLayout/SelectInput'
import TextInput from '../../../shared/components/inputs/InlineLayout/TextInput'
import PostalCodeInput from '../../../shared/components/inputs/PostalCodeInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { ROUTES } from '../../../utility/routes/routeConstants'
import { AdminsResponseSingle, getAdmins } from '../../admins/queries/getAdmins'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import { Partner, PartnerAgency, PartnerPerson } from '../../partners/queries/getPartnerQuery'
import { getPartnersQuery } from '../../partners/queries/getPartnersQuery'
import { CreateUserPayload, createUserQuery } from '../queries/createUser'
import { validateCreateUserAction } from './validateCreateUserAction'

type Props = {}

export type CreateUserActionValues = {
  partnerId: string | undefined
  agencyId: string | undefined
  personId: string | undefined
  isFromPartner: boolean | undefined
  roundRobin: boolean | undefined
  ownerId: string | undefined

  email: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  phone: string | undefined
  postalCode: { label: string; value: string } | undefined
}

const CreateUserAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const partnersQuery = useQuery(['partners'], () => getPartnersQuery())
  const admins = useQuery(['admins'], () => getAdmins())
  const navigate = useNavigate()

  const initialValues: CreateUserActionValues = {
    partnerId: undefined,
    agencyId: undefined,
    personId: undefined,
    isFromPartner: undefined,
    roundRobin: true,
    ownerId: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    postalCode: undefined,
  }

  const updateMutation = useMutation((inputs: CreateUserPayload) => createUserQuery(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      successToast({ description: 'All good' })
      navigate(`/${ROUTES.USERS_BASE}/${data.id}`)
    },
  })

  const getAssignationOptions = () => {
    return (
      admins.data?.admins
        .filter((admin: AdminsResponseSingle) => admin.teams.includes(TeamsEnum.DEMAND))
        .map((admin: AdminsResponseSingle) => ({
          label: admin.email,
          value: admin.id,
        })) || []
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(value) => validateCreateUserAction(value)}
        onSubmit={async (values, formik) => {
          console.log(values)
          updateMutation.mutate(values, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Créer un compte'
                // subtitle='Un email sera envoyé pour créer son mot de passe.'
              />
              <Flex gap='24px' direction='column'>
                <BooleanInput field={'isFromPartner'} checkboxLabel="Vient d'un partenaire?" />
                {formik.values.isFromPartner && (
                  <>
                    <SimpleGrid columns={2} gap='16px'>
                      {partnersQuery.data && (
                        <SelectInput
                          field={'partnerId'}
                          label={'Partenaire'}
                          options={partnersQuery.data.partners.map((partner: Partner) => ({
                            label: partner.name,
                            value: partner.id,
                          }))}
                        />
                      )}
                      {formik.values.partnerId && (
                        <SelectInput
                          field={'agencyId'}
                          label={'Agence'}
                          options={
                            partnersQuery.data?.partners
                              .find((partner) => partner.id === formik.values.partnerId)
                              ?.agencies.map((agency: PartnerAgency) => ({
                                label: agency.name,
                                value: agency.id,
                              })) || []
                          }
                        />
                      )}
                    </SimpleGrid>
                    {formik.values.agencyId && (
                      <SelectInput
                        field={'personId'}
                        label={'Contact'}
                        options={
                          partnersQuery.data?.partners
                            .find((partner) => partner.id === formik.values.partnerId)
                            ?.agencies.find((agency) => agency.id === formik.values.agencyId)
                            ?.persons.map((person: PartnerPerson) => ({
                              label: `${person.email} - ${person.firstName} ${person.lastName}`,
                              value: person.id,
                            })) || []
                        }
                      />
                    )}
                  </>
                )}
                <TextInput label='Email' placeholder='...' field={'email'} required />
                <PhoneNumberInputInline
                  placeholder='0687054447'
                  field='phone'
                  isRequired
                  label='Numéro de téléphone'
                />
                <SimpleGrid columns={2} gap='16px'>
                  <TextInput label='Prénom' placeholder='...' field={'firstName'} required />
                  <TextInput label='Nom' placeholder='...' field={'lastName'} required />
                </SimpleGrid>

                <InputWrapper field='postalCode' label='Code postal souhaité' isRequired>
                  <PostalCodeInput
                    postalCode={formik.values.postalCode}
                    setPostalCode={(postalCode) => formik.setFieldValue('postalCode', postalCode)}
                  />
                </InputWrapper>

                <BooleanInput
                  field={'roundRobin'}
                  checkboxLabel='Affecter équitablement à un commercial (Round Robin)'
                />
                {formik.values.roundRobin === false && (
                  <SelectInput
                    label='Assignation'
                    field='ownerId'
                    isRequired
                    options={getAssignationOptions()}
                  />
                )}
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateUserAction
