import React, { useEffect, useState } from 'react'
import Searchkit, { MultiMatchQuery } from '@searchkit/sdk'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  useToast,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon, ViewIcon } from '@chakra-ui/icons'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import {
  getScrapData,
  MADataType,
  ScrapData,
  ScrapDataCastorusOnly,
  ScrapDataMAOnly,
} from '../queries/getScrapData'
import { useAtom } from 'jotai'
import { pageAtom } from '../../../atoms/usersList'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import Header from '../../components/Typo/Header'
import Bold from '../../components/Typo/Bold'
import HelperSmall from '../../components/Typo/HelperSmall'
import Regular from '../../components/Typo/Regular'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import CastorusPriceChanges from './CastorusPriceChanges'
import RegularLink from '../../components/Typo/RegularLink'

const CastorusDescription = ({
  castorusData,
}: {
  castorusData: ScrapDataCastorusOnly[] | undefined
}) => {
  // const MAScrapData: MAData[] = scrapData.data.filter(d => d.type)

  if (!castorusData || castorusData.length <= 0) {
    return (
      <Flex>
        <Badge>Pas de donnée castorus</Badge>
      </Flex>
    )
  }

  return (
    <Flex direction='column' gap='16px'>
      <Flex gap='16px'>
        <Bold>
          Castorus{' '}
          <Link
            fontSize='12px'
            color='HestiaForestGreen'
            target='_blank'
            href={castorusData[0].data.url}
          >
            Voir la page Castorus
          </Link>
        </Bold>
      </Flex>

      <Flex gap='8px'>
        {castorusData[0].data.features &&
          castorusData[0].data.features.map((feature) => <Badge>{feature}</Badge>)}
      </Flex>
      <Regular>{castorusData[0].data.description}</Regular>
      <CastorusPriceChanges castorusData={castorusData} />
    </Flex>
  )
}

export default CastorusDescription
