import currency from 'currency.js'
import { createNumberMask } from 'text-mask-addons'
import { MortgageSimulatorV3SingleResponse } from '../dashboard/commonQueries/getMortgageSimulatorResultsV3'

export const getCurrencyMask = () => {
  const defaultMaskOptions = {
    prefix: '',
    suffix: '€',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 12, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  return createNumberMask({
    ...defaultMaskOptions,
  })
}

export const getObjectWithClosestDebtToIncomeRatio = (
  results: MortgageSimulatorV3SingleResponse[],
  targetDebtToIncomeRatio: number,
) => {
  return results.reduce((prev, curr) => {
    return Math.abs((curr.hestiaDebtToIncomeRatio || 0) - targetDebtToIncomeRatio) <
      Math.abs((prev.hestiaDebtToIncomeRatio || 0) - targetDebtToIncomeRatio)
      ? curr
      : prev
  })
}

export interface AnyObject {
  [key: string]: number
}

export function getClosestInArrayOfObject<T>(arr: T[], key: keyof T, target: number): T | null {
  if (arr.length === 0) {
    return null
  }

  return arr.reduce((closest: T, current: T) => {
    const closestDiff = Math.abs(Number(closest[key]) - target)
    const currentDiff = Math.abs(Number(current[key]) - target)

    return currentDiff < closestDiff ? current : closest
  })
}

export const getNumberFromUnscaledValue = (unscaledValue: number, scale: number) => {
  return unscaledValue * Math.pow(10, -scale)
}

export const getPrettyNumber = (
  number: number | undefined,
  precision = 0,
  onlyPositive = false,
) => {
  if (!number) {
    return '0 €'
  }
  if (onlyPositive && number < 0) {
    return '0 €'
  }
  return currency(number, {
    separator: ' ',
    symbol: '€',
    decimal: ',',
    pattern: `#!`,
    negativePattern: `-#!`,
    precision,
  }).format()
}

export function formatIncomeToK(income: number): string {
  return `${(income / 1000).toFixed(0)}k`
}
