import { FormikErrors } from 'formik'
import { isValidNumber, parse } from 'libphonenumber-js'
import { isValidEmail } from '../../../utility/other'
import { AddPersonToAgencyValues } from './AddPersonToAgencyAction'

export const validateAddPerson = (values: AddPersonToAgencyValues) => {
  const errors: FormikErrors<AddPersonToAgencyValues> = {}

  if (!values.firstName) {
    errors.firstName = 'Ce champ est obligatoire'
  }
  if (!values.lastName) {
    errors.lastName = 'Ce champ est obligatoire'
  }

  if (!values.email) {
    errors.email = 'Ce champ est requis'
  } else if (!isValidEmail(values.email)) {
    errors.email = "Ce n'est pas un email valide"
  }

  if (!values.phone) {
    errors.phone = 'Ce champ est requis'
  } else if (!isValidNumber(parse(values.phone))) {
    errors.phone = "Ce numéro de téléphone n'est pas valide"
  }

  if (values.hubspotId && !/^\d+$/.test(values.hubspotId)) {
    errors.hubspotId = "L'ID HubSpot doit être un nombre valide"
  } else if (values.hubspotId && values.hubspotId.length <= 5) {
    errors.hubspotId = "L'ID HubSpot doit contenir plus de 5 caractères"
  }

  return errors
}
