import { Box, Input } from '@chakra-ui/react'
import { fr } from 'date-fns/locale'
import React from 'react'
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('fr', fr)

export type DatePickerProps = ReactDatePickerProps

const DatePickerChakra = (props: DatePickerProps) => {
  return (
    <Box w='100%'>
      <DatePicker
        locale='fr'
        dateFormat='dd/MM/yyyy'
        customInput={
          <Input
            focusBorderColor='blue.500'
            errorBorderColor='red.500'
            placeholder='Sélectionnez une date'
            w='100%'
          />
        }
        {...props}
      />
    </Box>
  )
}

export default DatePickerChakra
