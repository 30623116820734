import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { LostReasonEnum, lostReasons } from '../../../../interfaces/prisma'
import { AssetProcessStagesEnum } from '../../../../shared/backShared/assetProcessStages'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import BooleanInput from '../../../../shared/components/inputs/InlineLayout/BooleanInput'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import { validateSetAsLostAction } from '../validations/validateSetAsLostAction'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'

type Props = {}

export type SetAsLostValues = {
  isLost: boolean
  lostReason?: LostReasonEnum
}

const SetAsLost = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const currentStep = assetAd.data?.processStep || null

  console.log(assetAd.data?.isLost)

  const initialValues: SetAsLostValues = {
    isLost: assetAd.data?.isLost || false,
    lostReason: assetAd.data?.lostReason || undefined,
  }

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validateSetAsLostAction}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(
            {
              ...values,
              newStage: values.isLost
                ? AssetProcessStagesEnum.CLOSED_LOST
                : AssetProcessStagesEnum.TO_PROCESS,
              id: id || '',
            },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader title='Modifier en Lost' />
              <Flex gap='24px' direction='column'>
                <BooleanInput
                  label='Status'
                  field='isLost'
                  isRequired
                  checkboxLabel={'Je confirme que le bien est en "Lost"'}
                />
              </Flex>
              {formik.values.isLost === true && (
                <SelectInput field={'lostReason'} label={'Raison du LOST'} options={lostReasons} />
              )}
            </Flex>

            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default SetAsLost
