import { Flex, Badge, CircularProgress, CircularProgressLabel, Button } from '@chakra-ui/react'
import { info } from 'console'
import React from 'react'
import { BiHome, BiUser } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types'
import { Highlight } from 'react-instantsearch-hooks-web'
import { isCommandPaletteOpenAtom } from '../../../atoms/searchAtoms'
import { AssetAdStatus } from '../../../interfaces/prisma'
import { ROUTES } from '../../../utility/routes/routeConstants'
import Bold from '../Typo/Bold'
import HelperSmall from '../Typo/Regular'
import dayjs from 'dayjs'
import {
  AssetProcessStepEnum,
  assetProcessSteps,
  getStepNumber,
  TeamsEnum,
} from '../../../shared/backShared/assetProcessSteps'
import LargeBold from '../Typo/LargeBold'
import Regular from '../Typo/Regular'
import relativeTime from 'dayjs/plugin/relativeTime'
import { HubspotScoringFormattedEnum, UserAlgolia } from '../../commandPalette/UserHit'
import { getPrettyNumber } from '../../../utility/money'

require('dayjs/locale/fr')
dayjs.extend(relativeTime)
dayjs.locale('fr')

type Props = { hit: AlgoliaHit & Partial<UserAlgolia> }

const UserHitComplete = (props: Props) => {
  const [isOpen, setIsOpen] = useAtom(isCommandPaletteOpenAtom)
  const navigate = useNavigate()

  return (
    <Flex
      border='1px solid'
      borderRadius='8px'
      borderColor={props.hit.isLost ? 'HestiaLightRed' : '#EFEFEF'}
      _hover={{ bg: 'HestiaLightGrey' }}
      //   borderColor='HestiaRed'
      //   bg='HestiaLightRed'
      cursor='pointer'
      padding='8px'
      gap='8px'
      onClick={() => {
        navigate(`/${ROUTES.USERS_BASE}/${props.hit.objectID}`)
        setIsOpen(false)
      }}
      maxW='100%'
      justifyContent='space-between'
    >
      <Flex justifyContent='center' verticalAlign='middle' alignItems='center'>
        <BiUser size='24px' color='gray' />
      </Flex>
      <Flex direction='column' w='100%' minW='0'>
        <Bold overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='100%'>
          <Highlight hit={props.hit} attribute='firstName'>
            {props.hit.firstName}
          </Highlight>{' '}
          <Highlight hit={props.hit} attribute='lastName'>
            {props.hit.lastName}
          </Highlight>
        </Bold>
        <HelperSmall overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='100%'>
          <Highlight hit={props.hit} attribute='email'>
            {props.hit.email}{' '}
          </Highlight>
        </HelperSmall>
      </Flex>
      <Flex w='100%' minW='0' justifyContent='flex-end'>
        <Flex w='100%' direction='column' justifyContent='flex-end' gap='8px'>
          <Flex w='100%' minW='0' justifyContent='flex-end' gap='8px'>
            <Badge h='fit-content'>
              <Highlight hit={props.hit} attribute='departmentWanted'>
                {props.hit.departmentWanted}
              </Highlight>
              {' ('}
              <Highlight hit={props.hit} attribute='postalCodeWanted'>
                {props.hit.postalCodeWanted}
              </Highlight>
              {')'}
            </Badge>
            {props.hit.business && <Badge h='fit-content'>Business</Badge>}
            {props.hit.userScoring && (
              <Badge h='fit-content'>{HubspotScoringFormattedEnum[props.hit.userScoring]}</Badge>
            )}
          </Flex>
          <Flex w='100%' minW='0' justifyContent='flex-end' gap='8px'>
            <Regular fontSize='14px'>
              {`${dayjs(props.hit.createdAt).locale('fr').fromNow()}`}
            </Regular>

            <Badge colorScheme='blue' h='fit-content'>
              {getPrettyNumber(props?.hit?.hestiaBudget, 0, true)}
            </Badge>

            {/* <Regular>
              Budget : <b></b>
            </Regular> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserHitComplete
