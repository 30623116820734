import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import '@fontsource/inter'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import { AuthProvider } from './auth/AuthProvider'
import Login from './auth/Login'
import AdminsList from './dashboard/admins/AdminsList'
import AssetAdAnalysis from './dashboard/assetAds/AssetAdAnalysis'
import AssetAdDetail from './dashboard/assetAds/AssetAdDetail'
import AssetAdDetailV2 from './dashboard/assetAds/AssetAdDetailV2'
import AssetAdsList from './dashboard/assetAds/AssetAdsList'
import AssetAdsListV2 from './dashboard/assetAds/AssetAdsListV2'
import DevHomePage from './dashboard/dev/DevHomePage'
import CreateFundsGroup from './dashboard/fundsGroups/CreateFundsGroup'
import FundsGroupsList from './dashboard/fundsGroups/FundsGroupsList'
import CreateLeasingProject from './dashboard/leasingProjects/CreateLeasingProject/CreateLeasingProject'
import LeasingProjectDetail from './dashboard/leasingProjects/LeasingProjectDetail'
import LeasingProjectsList from './dashboard/leasingProjects/LeasingProjectsList'
import CreatePartner from './dashboard/partners/CreatePartner'
import Partner from './dashboard/partners/partner/Partner'
import PartnersList from './dashboard/partners/PartnersList'
import Tools from './dashboard/tools/Tools'
import Analyses from './dashboard/users/analysis/Analyses'
import Analysis from './dashboard/users/analysis/Analysis'
import CreateProAccount from './dashboard/users/CreateProAccount'
import DiscoveryCall from './dashboard/users/user/DiscoveryCall'
import Files from './dashboard/users/user/files/Files'
import User from './dashboard/users/user/User'
import UserEmployment from './dashboard/users/user/UserEmployment'
import UserIncome from './dashboard/users/user/UserIncome'
import UserInformations from './dashboard/users/user/UserInformations'
import UserSummary from './dashboard/users/user/UserSummary'
import UsersList from './dashboard/users/UsersList'
import UsersListV2 from './dashboard/users/UsersListV2'
import { AnonymousRoute } from './utility/routes/AnonymousRoute'
import { ProtectedRoute } from './utility/routes/ProtectedRoute'
import { ROUTES } from './utility/routes/routeConstants'

// 2. Extend the theme to include custom colors, fonts, etc
export const HESTIA_COLORS = {
  // New colors
  neutral: {
    primary: '#1C1C1C',
    secondary: '#444748',
    light: '#747878',
    disabled: '#8E9192',
    white: '#FFFFFF',
    background: '#EFF1F1',
    border: '#E1E3E3',
  },
  brand: {
    yellow: {
      primary: '#FEC762',
      dark: '#DAA22F',
      light: '#FFEED8',
    },
    green: {
      primary: '#055448',
      light: '#E1F3F3',
    },
  },
  yellow: {
    '900': '#221B00',
    '800': '#3B2F00',
    '700': '#554500',
    '600': '#705D00',
    '500': '#8D7500',
    '400': '#AA8F12',
    '200': '#E4C44A',
    '50': '#FFF0C5',
  },
  turquoise: {
    '900': '#055448',
    '800': '#077065',
    '700': '#088174',
    '600': '#0A9185',
    '500': '#0C9E93',
    '400': '#2BADA4',
    '200': '#83D0CB',
    '50': '#E1F3F3',
  },
  blue: {
    '900': '#000F5C',
    '800': '#001E91',
    '700': '#002ECB',
    '600': '#2A4AE9',
    '500': '#4B67FF',
    '400': '#7287FF',
    '200': '#BBC3FF',
    '50': '#F0EFFF',
  },
  red: {
    '900': '#410002',
    '800': '#690005',
    '700': '#93000A',
    '600': '#BA1A1A',
    '500': '#DE3730',
    '400': '#FF5449',
    '200': '#FFB4AB',
    '50': '#FFEDEA',
  },
  // Old colors
  HestiaWhite: '#FFFFFF',
  HestiaBackground: '#FFFDF4',
  HestiaBlack: '#333333',
  HestiaForestGreen: '#055448',
  HestiaPintGreen: '#008674',
  HestiaLightGreen: '#F3FEFC',
  HestiaGrey: '#6F6F6F',
  HestiaMediumGrey: '#B9B9B9',
  HestiaBorderGrey: '#EFEFEF',
  HestiaLightGrey: '#F9F9F9',
  HestiaRed: '#C40000',
  HestiaLightRed: '#FFEBEB',
  HestiaBlue: '#1071CA',
  HestiaLightBlue: '#E8F4FE',
  HestiaYellow: '#FEC762',
  HestiaLightYellow: '#FFEED8',
  HestiaActive: 'rgba(255, 255, 255, 0.15)',
  TextLight: '#8090B2',
  green: {
    '50': '#ECF8F5',
    '100': '#CAECE4',
    '200': '#A8E0D2',
    '300': '#86D4C1',
    '400': '#64C9AF',
    '500': '#00A881',
    '600': '#35977E',
    '700': '#28715F',
    '800': '#1B4B3F',
    '900': '#0D2620',
  },
}

const queryClient = new QueryClient()

// gapi.load('client:auth2', () => {
//   gapi.client.init({
//     clientId: googleClientId,
//     plugin_name: 'chat',
//   })
// })

const theme = extendTheme({
  colors: HESTIA_COLORS,
  fonts: {
    body: 'Inter, arial',
    heading: 'Recoleta Alt SemiBold, sans-serif',
  },
})

declare global {
  interface Window {
    analytics: any
  }
}

// const ProtectedRoute: {children: ReactNode} =
//     ({ children }) => {
//   const jwt = localStorage.getItem("hestia:admin-jwt")
//
//   if (!jwt) {
//     return <Navigate to="/login" replace />;
//   }
//
//   return children;
// };

function App() {
  return (
    <div id='root'>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
          <AuthProvider>
            <Router>
              <Routes>
                <Route
                  path={'login'}
                  element={
                    <AnonymousRoute>
                      <Login />
                    </AnonymousRoute>
                  }
                />

                <Route
                  path={`${ROUTES.USERS_BASE}/old`}
                  element={
                    <ProtectedRoute>
                      <UsersList />
                    </ProtectedRoute>
                  }
                />

                {/* 
                         USERS 
                */}

                <Route path={ROUTES.USERS_BASE}>
                  <Route
                    index
                    element={
                      <ProtectedRoute>
                        <UsersListV2 />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_ANALYSIS}`}
                    element={
                      <ProtectedRoute>
                        <Analyses />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_ANALYSIS}/:analysisId`}
                    element={
                      <ProtectedRoute>
                        <Analysis />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=':id'
                    element={
                      <ProtectedRoute>
                        <User>
                          <UserSummary />
                        </User>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_INFORMATIONS}`}
                    element={
                      <ProtectedRoute>
                        <User>
                          <UserInformations />
                          <UserEmployment />
                        </User>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_BUDGET}`}
                    element={
                      <ProtectedRoute>
                        <User>
                          <UserIncome />
                        </User>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_FILES}`}
                    element={
                      <ProtectedRoute>
                        <User>
                          <Files />
                        </User>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`:id/${ROUTES.USERS_DISCOVERY}`}
                    element={
                      <ProtectedRoute>
                        <User noPadding={true} discoveryCall>
                          <DiscoveryCall />
                        </User>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=':id/createProject'
                    element={
                      <ProtectedRoute>
                        <CreateLeasingProject />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                {/*<Route*/}
                {/*  path='/users'*/}
                {/*  element={*/}
                {/*    <ProtectedRoute>*/}
                {/*      <UsersList />*/}
                {/*    </ProtectedRoute>*/}
                {/*  }*/}
                {/*/>*/}

                <Route
                  path={`${ROUTES.ASSETADS_BASE}/old`}
                  element={
                    <ProtectedRoute>
                      <AssetAdsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ASSETADS_BASE}`}
                  element={
                    <ProtectedRoute>
                      <AssetAdsListV2 />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ASSETADS_BASE}/:id/old`}
                  element={
                    <ProtectedRoute>
                      <AssetAdDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ASSETADS_BASE}/:id`}
                  element={
                    <ProtectedRoute>
                      <AssetAdDetailV2 />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ASSETADS_BASE}/:id/${ROUTES.ASSETADS_ANALYSIS}`}
                  element={
                    <ProtectedRoute>
                      <AssetAdAnalysis />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.TOOLS}`}
                  element={
                    <ProtectedRoute>
                      <Tools />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ADMINS}`}
                  element={
                    <ProtectedRoute>
                      <AdminsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.PARTNERS_BASE}`}
                  element={
                    <ProtectedRoute>
                      <PartnersList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.PARTNERS_BASE}/:id`}
                  element={
                    <ProtectedRoute>
                      <Partner />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.CREATE_PARTNER}`}
                  element={
                    <ProtectedRoute>
                      <CreatePartner />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/leasingProjects'
                  element={
                    <ProtectedRoute>
                      <LeasingProjectsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/leasingProjects/:id'
                  element={
                    <ProtectedRoute>
                      <LeasingProjectDetail />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/fundsgroups'
                  element={
                    <ProtectedRoute>
                      <FundsGroupsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/fundsgroups/create'
                  element={
                    <ProtectedRoute>
                      <CreateFundsGroup />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/proaccount/create'
                  element={
                    <ProtectedRoute>
                      <CreateProAccount />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/dev'
                  element={
                    <ProtectedRoute>
                      <DevHomePage />
                    </ProtectedRoute>
                  }
                />

                <Route path='*' element={<Navigate to='/users' replace />} />
              </Routes>
            </Router>
          </AuthProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </div>
  )
}

export default App
