import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'

export interface GetUploadUrlInputs {
  fileName: string
  fileType: string
}

export const getUploadUrl = async (data: GetUploadUrlInputs) => {
  return await authenticatedGetQuery(`/admin/files/uploadurl`, data).then((response) => response)
}
