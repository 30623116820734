import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { DiscoveryTab, discoveryTabsAtom, headerHeightAtom } from '../../../atoms/discoveryAtom'
import useVisibleHeight from '../useVisibleHeight'

const useUpdateCurrentTab = (ref: React.RefObject<HTMLElement>, tab: DiscoveryTab) => {
  const [tabsHeightVisible, setTabsHeightVisible] = useAtom(discoveryTabsAtom)
  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom)
  const visibleHeight = useVisibleHeight(ref, headerHeight)

  const updateTabsHeightVisible = useCallback(() => {
    setTabsHeightVisible((prev) => ({
      ...prev,
      [tab]: {
        visibleHeight: visibleHeight,
        ref: ref,
      },
    }))
  }, [visibleHeight, ref, tab])

  useEffect(() => {
    updateTabsHeightVisible()
  }, [updateTabsHeightVisible])
}

export default useUpdateCurrentTab
