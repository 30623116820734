import { Card, Flex, FormLabel, Textarea } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import FileUploadResult from '../components/inputs/FileUploadResult'
import DashboardLayout from '../DashboardLayout'
import UpdateNotionUrl from './analysis/UpdateNotionUrl'
import { getAssetAdById } from './queries/getAssetAdById'
import AssetAdHeader from './v2/AssetAdHeader'

type Props = {}

const AssetAdAnalysis = (props: Props) => {
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  return (
    <DashboardLayout>
      <Flex w='100%'>
        <Flex direction='column' gap='24px' w='100%'>
          <AssetAdHeader />
          <Flex gap='24px'>
            <Card w='66%'>
              <Flex direction='column' gap='8px' w='100%' p='24px'>
                <UpdateNotionUrl />
              </Flex>
            </Card>
            <Card w='34%'>
              <Flex direction='column' gap='8px' w='100%' p='24px'>
                <Flex direction='column' gap='16px'>
                  <FormLabel>Fichiers associés</FormLabel>
                  <Flex gap='0px' direction='column' maxW='800px' w='100%'>
                    {assetAd.data?.userFiles.map((f) => (
                      <FileUploadResult name={f.key.slice(37)} id={f.id} />
                    ))}
                  </Flex>
                </Flex>
                <Flex direction='column' gap='16px'>
                  <FormLabel>Notes du user</FormLabel>
                  <Flex gap='0px' direction='column' maxW='800px' w='100%'>
                    <Textarea value={assetAd.data?.notes || ''} />
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default AssetAdAnalysis
