import { UserLostReasonEnum } from '../../../interfaces/prisma'
import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface UserQualifyPayload {
  newStage?: UserProcessStagesEnum | undefined
  lostReason?: UserLostReasonEnum | undefined
  shouldResendToPartner?: boolean | undefined
}

export const userQualifyQuery = async (id: string, payload: UserQualifyPayload) => {
  return await authenticatedPostQuery(`/admin/users/${id}/action/qualify`, payload)
}
