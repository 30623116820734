import _ from 'lodash'
import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import {
  DiscoveryPatrimony,
  FinancialSavingsTypeEnum,
  OtherAssetTypeEnum,
  PatrimonyTypeEnum,
  RealEstateTypeEnum,
} from '../discovery.types'

export type UpdateDiscoveryBuyerPatrimonyPayload = {
  id: string
  patrimonyType: PatrimonyTypeEnum | null
  value: number | null
  financialSavingsType: FinancialSavingsTypeEnum | null
  housingLocation: string | null
  rent: number | null
  mortgage: boolean | null
  planToSell: boolean | null
  realEstateType: RealEstateTypeEnum | null
  otherAssetType: OtherAssetTypeEnum | null
  locationAbroad: boolean | null
}

export const getDiscoveryBuyerPatrimonies = async (userId: string, buyerId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/patrimonies`,
  )) as Promise<DiscoveryPatrimony[]>
}

export const createDiscoveryBuyerPatrimony = async (userId: string, buyerId: string) => {
  return (await authenticatedPostQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/patrimonies`,
  )) as Promise<void>
}

export const updateDiscoveryBuyerPatrimony = async (
  userId: string,
  buyerId: string,
  payload: UpdateDiscoveryBuyerPatrimonyPayload,
) => {
  const finalPayload = _.omit(payload, 'id')
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/patrimonies/${payload.id}`,
    finalPayload,
  )) as Promise<void>
}

export const deleteDiscoveryBuyerPatrimony = async (
  userId: string,
  buyerId: string,
  patrimonyId: string,
) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/patrimonies/${patrimonyId}`,
  )) as Promise<void>
}
