import React, { useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons'
import DashboardLayout from '../../DashboardLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { getUserQuery, UserResponseSingleUser } from '../../users/queries/userQuery'
import { AxiosError } from 'axios'
import Paragraph from '../../components/Typo/Paragraph'
import { useFormik } from 'formik'
import Header from '../../components/Typo/Header'
import { parseNumber } from '../../../utility/parseNumber'
import useDebounce from '../../../utility/hooks/useDebounce'
import {
  FundsGroupsResponseSingle,
  getFundsGroupsQuery,
} from '../../fundsGroups/queries/getFundsGroups'
import { useAtom } from 'jotai'
import { searchAtom } from '../../../atoms/usersList'
import { validateCreateLeasingProject } from './validateCreateLeasingProject'
import { createLeasingProject, CreateLeasingProjectInput } from '../queries/createLeasingProject'

const CreateLeasingProject = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [user, setUser] = useState<UserResponseSingleUser | undefined>(undefined)
  const toast = useToast()
  const [search, setSearch] = useAtom(searchAtom)

  const createMutation = useMutation(
    (inputs: CreateLeasingProjectInput) => createLeasingProject(inputs),
    {
      onError: (error: AxiosError) => {
        toast({
          title: 'Erreur:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        console.log(data)
        toast({
          title: 'Succès!:',
          description: 'All good',
          status: 'success',
          position: 'top-right',
        })
      },
    },
  )

  useQuery(['user', { id }], () => getUserQuery({ id }), {
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      setUser(data.user)
    },
  })

  const formik = useFormik({
    initialValues: {
      assetName: '',
      assetAddress: '',
      assetCity: '',
      assetPostalCode: 0,
      assetAcquisitionCost: 0,
      rent: 0,
      expectedDayForPayments: 1,
      futurePrice: 0,
      cashbackOnRent: 10,
      fundsGroupUUID: '',
    },
    validate: (values) => validateCreateLeasingProject(values),
    onSubmit: (values) => {
      createMutation.mutate({
        ...values,
        userId: id!,
      })
    },
  })

  const searchDebounced = useDebounce(search)

  const fundsGroupsQuery = useQuery(
    ['funds', { page: 1, searchDebounced }],
    () =>
      getFundsGroupsQuery({
        page: 1,
        search: searchDebounced,
      }),
    {
      onError: (error: AxiosError) => {
        console.log(error)
        toast({
          title: 'Erreur inconnue:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
    },
  )

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <Paragraph>
        Vous allez créer un projet immobilier pour:{' '}
        <b>
          {user?.firstName} {user?.lastName}
        </b>
      </Paragraph>
      <form onSubmit={formik.handleSubmit}>
        <Flex direction='column' gap='48px' mt='48px'>
          <Flex direction='column' gap='24px' width={['100%', '400px']}>
            <Header>Informations sur le bien</Header>
            <FormControl isInvalid={!!formik.errors.assetName && formik.touched.assetName}>
              <FormLabel htmlFor='assetName'>Nom</FormLabel>
              <Input
                placeholder='T3 meublé rue des archives'
                id='assetName'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assetName}
                autoComplete='given-name'
              />
              {formik.errors.assetName && formik.touched.assetName && (
                <FormHelperText color='red'>{formik.errors.assetName}</FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.assetAddress && formik.touched.assetAddress}>
              <FormLabel htmlFor='assetAddress'>Adresse</FormLabel>
              <Input
                placeholder='23 rue dorée'
                id='assetAddress'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assetAddress}
                autoComplete='given-name'
              />
              {formik.errors.assetAddress && formik.touched.assetAddress && (
                <FormHelperText color='red'>{formik.errors.assetAddress}</FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.assetCity && formik.touched.assetCity}>
              <FormLabel htmlFor='assetCity'>Ville</FormLabel>
              <Input
                placeholder='Paris'
                id='assetCity'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assetCity}
                autoComplete='given-name'
              />
              {formik.errors.assetCity && formik.touched.assetCity && (
                <FormHelperText color='red'>{formik.errors.assetCity}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.assetPostalCode && formik.touched.assetPostalCode}
            >
              <FormLabel htmlFor='assetPostalCode'>Code postal</FormLabel>
              <Input
                placeholder='75002'
                id='assetPostalCode'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assetPostalCode}
                autoComplete='given-name'
                as={InputMask}
                mask='99999'
                maskPlaceholder={''}
              />
              {formik.errors.assetPostalCode && formik.touched.assetPostalCode && (
                <FormHelperText color='red'>{formik.errors.assetPostalCode}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              isInvalid={
                !!formik.errors.assetAcquisitionCost && formik.touched.assetAcquisitionCost
              }
            >
              <FormLabel htmlFor='assetAcquisitionCost'>Coût d'acquisition</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color={formik.values.assetAcquisitionCost ? 'black' : 'gray.300'}
                  children='€'
                />
                <Input
                  placeholder='120 000'
                  id='assetAcquisitionCost'
                  type='string'
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue('assetAcquisitionCost', parseNumber(e.target.value))
                  }
                  value={
                    formik.values.assetAcquisitionCost !== undefined &&
                    !isNaN(formik.values.assetAcquisitionCost) &&
                    formik.values.assetAcquisitionCost !== null
                      ? formik.values.assetAcquisitionCost.toString()
                      : ''
                  }
                />
              </InputGroup>
              {formik.errors.assetAcquisitionCost && formik.touched.assetAcquisitionCost && (
                <FormHelperText color='red'>{formik.errors.assetAcquisitionCost}</FormHelperText>
              )}
            </FormControl>
          </Flex>
          <Flex direction='column' gap='24px' width={['100%', '400px']}>
            <Header>Informations sur le leasing</Header>
            <FormControl isInvalid={!!formik.errors.rent && formik.touched.rent}>
              <FormLabel htmlFor='rent'>Redevance</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color={formik.values.rent ? 'black' : 'gray.300'}
                  children='€'
                />
                <Input
                  placeholder='800€'
                  id='rent'
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue('rent', parseNumber(e.target.value))}
                  value={
                    formik.values.rent !== undefined &&
                    !isNaN(formik.values.rent) &&
                    formik.values.rent !== null
                      ? formik.values.rent.toString()
                      : ''
                  }
                />
              </InputGroup>
              {formik.errors.rent && formik.touched.rent && (
                <FormHelperText color='red'>{formik.errors.rent}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.cashbackOnRent && formik.touched.cashbackOnRent}
            >
              <FormLabel htmlFor='cashbackOnRent'>Cashback sur le loyer</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color={formik.values.cashbackOnRent ? 'black' : 'gray.300'}
                  children='%'
                />
                <Input
                  placeholder='10%'
                  id='cashbackOnRent'
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue('cashbackOnRent', parseNumber(e.target.value))
                  }
                  value={
                    formik.values.cashbackOnRent !== undefined &&
                    !isNaN(formik.values.cashbackOnRent) &&
                    formik.values.cashbackOnRent !== null
                      ? formik.values.cashbackOnRent.toString()
                      : ''
                  }
                />
              </InputGroup>
              {formik.errors.cashbackOnRent && formik.touched.cashbackOnRent && (
                <FormHelperText color='red'>{formik.errors.cashbackOnRent}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              isInvalid={
                !!formik.errors.expectedDayForPayments && formik.touched.expectedDayForPayments
              }
            >
              <FormLabel htmlFor='expectedDayForPayments'>Jour attendu pour le paiement</FormLabel>
              <Input
                placeholder='05 = Le 5 du mois'
                id='expectedDayForPayments'
                onBlur={formik.handleBlur}
                onChange={(e) =>
                  formik.setFieldValue('expectedDayForPayments', parseNumber(e.target.value))
                }
                value={
                  formik.values.expectedDayForPayments !== undefined &&
                  !isNaN(formik.values.expectedDayForPayments) &&
                  formik.values.expectedDayForPayments !== null
                    ? formik.values.expectedDayForPayments.toString()
                    : ''
                }
              />
              {formik.errors.expectedDayForPayments && formik.touched.expectedDayForPayments && (
                <FormHelperText color='red'>{formik.errors.expectedDayForPayments}</FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.futurePrice && formik.touched.futurePrice}>
              <FormLabel htmlFor='futurePrice'>Montant de l'option d'achat</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color={formik.values.futurePrice ? 'black' : 'gray.300'}
                  children='€'
                />
                <Input
                  placeholder='150 000€'
                  id='futurePrice'
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.setFieldValue('futurePrice', parseNumber(e.target.value))}
                  value={
                    formik.values.futurePrice !== undefined &&
                    !isNaN(formik.values.futurePrice) &&
                    formik.values.futurePrice !== null
                      ? formik.values.futurePrice.toString()
                      : ''
                  }
                />
              </InputGroup>
              {formik.errors.futurePrice && formik.touched.futurePrice && (
                <FormHelperText color='red'>{formik.errors.futurePrice}</FormHelperText>
              )}
            </FormControl>
          </Flex>
          <Flex direction='column' gap='24px' width={['100%', '400px']}>
            <Header>Funds Group associé</Header>
            <InputGroup>
              <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
              <Input
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                type='tel'
                placeholder='Rechercher...'
              />
            </InputGroup>
            <FormControl>
              <RadioGroup
                id='fundsGroupUUID'
                onChange={(value) => formik.setFieldValue('fundsGroupUUID', value)}
                value={formik.values.fundsGroupUUID}
              >
                <Stack direction='column'>
                  {fundsGroupsQuery.data?.fundsGroups?.map(
                    (fundsGroup: FundsGroupsResponseSingle) => (
                      <Radio value={fundsGroup.id}>{fundsGroup.name}</Radio>
                    ),
                  )}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
          <Button
            disabled={!formik.isValid}
            colorScheme='green'
            bg='HestiaForestGreen'
            type='submit'
            w='fit-content'
          >
            Enregistrer
          </Button>
        </Flex>
      </form>
    </DashboardLayout>
  )
}

export default CreateLeasingProject
