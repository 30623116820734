import React, { useEffect, useState } from 'react'
import Searchkit, { MultiMatchQuery } from '@searchkit/sdk'
import { Button, Flex, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useQuery } from 'react-query'
import { getAssetAdById } from './queries/getAssetAdById'
import Map from '../../shared/components/Map'

const AssetAdContext = () => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  if (!assetAd.data?.address) {
    return <>Pas encore d'adresse pour ce bien !</>
  }

  return (
    <Flex>
      <Flex w='100%' direction='column' gap='64px' padding='12px'>
        <Flex gap='16px'>
          <Button
            onClick={() =>
              window.open(
                // `https://www.google.com/maps/dir//@${assetAd.data?.address.lat},${assetAd.data?.address.lng}`,
                `https://www.google.com/maps/search/?api=1&query=${assetAd.data?.address.formattedAddress}&query_place_id=${assetAd.data?.address.placeId}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            colorScheme='green'
          >
            Voir sur google maps
          </Button>
          <Button
            onClick={() =>
              window.open(
                // `https://www.google.com/maps/dir//@${assetAd.data?.address.lat},${assetAd.data?.address.lng}`,
                // https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=48.857832%2C2.295226
                `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${encodeURIComponent(
                  `${assetAd.data?.address.lat},${assetAd.data?.address.lng}`,
                )}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            colorScheme='green'
          >
            Voir sur street view
          </Button>
          <Button
            onClick={() =>
              window.open(
                // `https://www.google.com/maps/dir//@${assetAd.data?.address.lat},${assetAd.data?.address.lng}`,
                // https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=48.857832%2C2.295226
                `https://earth.google.com/web/@${assetAd.data?.address.lat},${assetAd.data?.address.lng},-0.1016182,4000d`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            colorScheme='green'
          >
            Voir sur Google Earth
          </Button>
        </Flex>

        {assetAd.data?.address && (
          <Map
            location={{
              lat: assetAd.data?.address.lat,
              lng: assetAd.data?.address.lng,
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default AssetAdContext
