import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Divider,
  Flex,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import Loading from '../../shared/components/Loading'
import { useHestiaBudgetAdmin } from '../../utility/hooks/useHestiaBudgetAdmin'
import { getPrettyNumber } from '../../utility/money'
import { MortgageLimitedByEnum } from '../commonQueries/getBudgetQuery'
import { UserResponseSingleUser } from '../users/queries/userQuery'
import SavedWithHestiaChart from './charts/SavedWithHestiaChart'
import Bold from './Typo/Bold'
import Header from './Typo/Header'
import Regular from './Typo/Regular'

const UserBudgetDetails: FC<{
  user: UserResponseSingleUser
}> = ({ user }) => {
  if (!user.userInformations) {
    return <></>
  }
  const [debouncedInputs, debouncedInputsControlFunctions] = useDebounce(user.userInformations, 600)
  const navigate = useNavigate()
  const budget = useHestiaBudgetAdmin({ user })

  if (!budget.simulatorData) {
    return <Loading />
  }

  const getLimitedByLabel = (limitedBy: MortgageLimitedByEnum | undefined) => {
    switch (limitedBy) {
      case MortgageLimitedByEnum.DEPOSIT:
        return 'Son apport final doit être à 10%'
      case MortgageLimitedByEnum.DTI:
        return 'Les mensualitées de son futur prêt ne doivent pas dépasser 35% de sa capacité'
      case MortgageLimitedByEnum.MONTHLY_PAYMENTS:
        return 'Il doit pouvoir payer 10% de pénalité entre son épargne et ses mensualitées'
      case MortgageLimitedByEnum.FEES:
        return 'Il doit pouvoir payer les 3% de frais Hestia'
      default:
      case undefined:
        return 'Inconnue...'
    }
  }

  return (
    <Flex direction='column' gap='24px'>
      <SimpleGrid columns={2} spacing='24px' w='100%'>
        <Flex height='fit-content' direction='column' gap='24px'>
          <Flex
            gap='8px'
            direction='column'
            border='1px solid #EFEFEF'
            borderRadius='8px'
            padding='24px'
            w='100%'
          >
            {debouncedInputsControlFunctions.isPending() ? (
              <Loading />
            ) : (
              <>
                <Header fontSize='48px' color='HestiaPintGreen'>
                  {getPrettyNumber(budget.simulatorData?.budgetNow, 0, true)}
                </Header>
                {budget.availableDepositAfterFees < 0 && (
                  <Alert status='error' borderRadius='8px'>
                    <AlertIcon />
                    <Flex direction='column'>
                      {/* <AlertTitle> Apport insuffisant pour payer les frais</AlertTitle> */}
                      <AlertDescription>
                        Avec l'apport déclaré de <b>{getPrettyNumber(budget.savings, 0)}</b>, il
                        manque encore{' '}
                        <b>{getPrettyNumber(Math.abs(budget.availableDepositAfterFees), 0)} </b>{' '}
                        pour payer les frais qui s'élèvent à{' '}
                        <b>{getPrettyNumber(Math.abs(budget.hestiaFees), 0)}</b>
                      </AlertDescription>
                    </Flex>
                  </Alert>
                )}
              </>
            )}
            <Alert status='info' borderRadius='8px'>
              <Flex direction='column'>
                Client limité par : <b>{getLimitedByLabel(budget.simulatorData.limitedBy)}</b>
              </Flex>
            </Alert>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>
                Taux d'intérêt (Updaté le{' '}
                {dayjs(budget.simulatorData.interestRateDate).format('DD MMMM YYYY')})
              </Regular>
              <Bold>{((budget.simulatorData.interestRate || 0) * 100).toFixed(2)}%</Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Region utilisée pour le taux</Regular>
              <Bold>{budget.simulatorData.region}</Bold>
            </Flex>
            <Button
              variant='outline'
              w='fit-content'
              colorScheme='green'
              onClick={() => navigate(`/users/${user.id}`)}
            >
              Voir le user
            </Button>
          </Flex>
          <Flex
            gap='8px'
            direction='column'
            border='1px solid #EFEFEF'
            borderRadius='8px'
            padding='24px'
            w='100%'
            h='fit-content'
          >
            <Bold>Acquisition Hestia</Bold>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Budget d'achat (FAI) </Regular>
              <Bold>{getPrettyNumber(budget.firstAcquisition.priceWithoutNotaryFees, 0)}</Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Budget d'achat (AEM) </Regular>
              <Bold>{getPrettyNumber(budget.firstAcquisition.price, 0)}</Bold>
            </Flex>
            <Divider />
            <Bold>Acquisition Client</Bold>
            <Tooltip
              size='lg'
              placement='auto'
              label={
                <Flex flexDirection='column' gap='8px'>
                  <Bold color='white'>Calcul:</Bold>
                  <Regular color='white'>Prix d'achat FAI * plus value</Regular>
                </Flex>
              }
            >
              <Flex justifyContent='space-between' gap='24px'>
                <Regular>Prix de rachat (FAI)</Regular>
                <Bold>{getPrettyNumber(budget.buyerAcquisition.priceWithoutNotaryFees, 0)}</Bold>
              </Flex>
            </Tooltip>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Prix de rachat (AEM) </Regular>
              <Bold>{getPrettyNumber(budget.buyerAcquisition.price, 0)}</Bold>
            </Flex>
          </Flex>
        </Flex>
        <Flex height='fit-content' direction='column' gap='24px'>
          <Flex
            border='1px solid #EFEFEF'
            borderRadius='8px'
            padding='24px'
            direction='column'
            gap='8px'
          >
            <Bold>Prêt après Hestia</Bold>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Montant de l'emprunt</Regular>
              <Bold>{getPrettyNumber(budget.simulatorData?.mortgageAfterHestia.canBorrow, 0)}</Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Durée</Regular>
              <Bold>{budget.simulatorData?.mortgageAfterHestia.duration} ans</Bold>
            </Flex>
            <Flex justifyContent='space-between' gap='24px'>
              <Regular>Taux d'intérêt</Regular>
              <Bold>
                {((budget.simulatorData?.mortgageAfterHestia.interestRate || 0) * 100).toFixed(2)}%
              </Bold>
            </Flex>
            <Divider />
            <Tooltip
              size='lg'
              placement='auto'
              label={
                <Flex flexDirection='column' gap='8px'>
                  <Bold color='white'>Mensualité max :</Bold>
                  <Regular color='white'>
                    Revenus * Taux d'effort - Crédits = <br />
                    {getPrettyNumber(budget.monthlyIncome, 0)} *{' '}
                    {(budget.simulatorData.hypothesis.debtToIncomeRatio * 100).toFixed(0)}% -{' '}
                    {getPrettyNumber(budget.monthlyDebt, 0)} = <br />
                    {getPrettyNumber(budget.maxLoanPayment, 0)}
                  </Regular>
                </Flex>
              }
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Mensualité</Regular>
                <Bold>
                  {getPrettyNumber(budget.simulatorData?.mortgageAfterHestia.monthlyCost, 0)} par
                  mois
                </Bold>
              </Flex>
            </Tooltip>
            <Tooltip
              size='lg'
              placement='auto'
              label={
                <Flex flexDirection='column' gap='8px'>
                  <Bold color='white'>Taux d'effort:</Bold>
                  <Regular color='white'>
                    (Mensualité + Crédits) / Revenus = <br />(
                    {getPrettyNumber(budget.simulatorData?.mortgageAfterHestia.monthlyCost, 0)} +{' '}
                    {getPrettyNumber(budget.monthlyDebt, 0)}) /{' '}
                    {getPrettyNumber(budget.monthlyIncome, 0)} = <br />
                    {(budget.debtToIncomeRatioLoan * 100).toFixed(1)}%
                  </Regular>
                </Flex>
              }
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Taux d'effort</Regular>
                <Bold>{(budget.debtToIncomeRatioLoan * 100).toFixed(1)}%</Bold>
              </Flex>
            </Tooltip>
            <Divider />
          </Flex>

          <Flex
            border='1px solid #EFEFEF'
            borderRadius='8px'
            padding='24px'
            direction='column'
            gap='8px'
          >
            <Bold>Apport</Bold>
            <Tooltip
              size='lg'
              placement='auto'
              label={<Flex flexDirection='column' gap='8px'></Flex>}
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Frais de dossier upfront</Regular>
                <Bold>{getPrettyNumber(budget.simulatorData.hestiaFees)}</Bold>
              </Flex>
            </Tooltip>

            <Tooltip
              size='lg'
              placement='auto'
              label={<Flex flexDirection='column' gap='8px'></Flex>}
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Epargne Hestia mensuelle</Regular>
                <Bold>{getPrettyNumber(budget.maxLoanPayment)}</Bold>
              </Flex>
            </Tooltip>
            <Tooltip
              size='lg'
              placement='auto'
              label={
                <Flex flexDirection='column' gap='8px'>
                  <Bold color='white'>Calcul:</Bold>
                  <Regular color='white'>
                    {getPrettyNumber(budget.maxLoanPayment)} *{' '}
                    {budget.simulatorData.hypothesis.durationInMonths}
                  </Regular>
                </Flex>
              }
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Apport constitué avec épargne</Regular>
                <Bold>{getPrettyNumber(budget.simulatorData.savedWithHestia)}</Bold>
              </Flex>
            </Tooltip>
            <Tooltip
              size='lg'
              placement='auto'
              label={
                <Flex flexDirection='column' gap='8px'>
                  <Bold color='white'>Calcul:</Bold>
                  <Regular color='white'>
                    Apport avec épargne + Apport du départ - Frais de dossier =
                  </Regular>
                  <Regular color='white'>
                    {getPrettyNumber(budget.simulatorData.savedWithHestia)} +{' '}
                    {getPrettyNumber(budget.deposit)} -{' '}
                    {getPrettyNumber(budget.simulatorData.hestiaFees)}
                  </Regular>
                </Flex>
              }
            >
              <Flex justifyContent='space-between' gap='24px' w='100%'>
                <Regular>Apport total à la fin</Regular>
                <Bold>{getPrettyNumber(budget.simulatorData.totalDepositAfterFee)}</Bold>
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </SimpleGrid>
      <Flex
        border='1px solid #EFEFEF'
        borderRadius='8px'
        padding='24px'
        direction='column'
        gap='8px'
      >
        <SavedWithHestiaChart
          hestiaFees={budget.hestiaFees}
          initialSavings={budget.deposit}
          monthlySavings={budget.maxLoanPayment}
          durationInMonths={budget.simulatorData.hypothesis.durationInMonths}
        />
      </Flex>
    </Flex>
  )
}

export default UserBudgetDetails
