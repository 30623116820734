import React, { ReactNode } from 'react'
import { Badge, BadgeProps, Button, ButtonProps, Flex, useMediaQuery } from '@chakra-ui/react'
import Regular from './Typo/Regular'
import { Icon, IconProps } from '@phosphor-icons/react'
import Bold from './Typo/Bold'

export type CustomButtonProps = {
  variant?: 'primary' | 'secondary' | 'outline' | 'danger' | 'danger-outline' | 'green'
  rightIconComponent?: React.ComponentType<IconProps>
  leftIconComponent?: React.ComponentType<IconProps>
  size?: 'regular' | 'small'
  children?: ReactNode
} & ButtonProps

const CustomButton = (props: CustomButtonProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const {
    rightIconComponent: RightIconComponent,
    leftIconComponent: LeftIconComponent,
    size = 'regular',
    ...rest
  } = props

  const getSizeProps = (): Partial<ButtonProps> => {
    if (size === 'small') {
      return {
        height: '32px',
        padding: '8px 16px',
        fontSize: '12px',
      }
    }
    return {
      height: '40px',
      padding: '16px 20px',
      fontSize: '15px',
    }
  }

  const sizeProps = getSizeProps()
  const { fontSize } = sizeProps

  const getVariantProps = (): ButtonProps => {
    let buttonProps: ButtonProps = {
      textTransform: 'none',
      fontWeight: '600',
      borderRadius: '8px',
      width: isMobile ? '100%' : 'fit-content',
    }

    const variant = props.variant || 'primary'

    if (variant === 'primary') {
      buttonProps = {
        ...buttonProps,
        bg: 'HestiaYellow',
        color: 'neutral.primary',
        colorScheme: 'yellow',
        _hover: {
          bg: '#DAA22F',
        },
      }
    } else if (variant === 'secondary') {
      buttonProps = {
        ...buttonProps,
        bg: 'HestiaLightYellow',
        color: 'neutral.primary',
        colorScheme: 'yellow',
        _hover: {
          bg: '#FFDEA9',
        },
      }
    } else if (variant === 'green') {
      buttonProps = {
        ...buttonProps,
        bg: 'brand.green.primary',
        color: 'neutral.primary',
        colorScheme: 'yellow',
        _hover: {
          bg: '#FFDEA9',
        },
      }
    } else if (variant === 'outline') {
      buttonProps = {
        ...buttonProps,
        color: 'neutral.primary',
        borderColor: '#E1E3E3',
        variant: 'outline',
        _hover: {
          bg: 'neutral.background',
        },
      }
    } else if (variant === 'danger') {
      buttonProps = {
        ...buttonProps,
        color: 'neutral.white',
        bg: 'red.600',
        colorScheme: 'red',
        _hover: {
          bg: 'red.800',
        },
      }
    } else if (variant === 'danger-outline') {
      buttonProps = {
        ...buttonProps,
        color: 'red.700',
        bg: 'neutral.white',
        colorScheme: 'red',
        variant: 'outline',
        border: '1px solid',
        borderColor: 'red.700',
        _hover: {
          bg: 'red.50',
        },
      }
    } else {
      buttonProps = {
        ...buttonProps,
        bg: 'HestiaLightBlue',
        color: 'HestiaBlue',
      }
    }

    buttonProps = {
      ...buttonProps,
      ...sizeProps,
    }

    return buttonProps
  }

  const iconProps: IconProps = {
    color: 'neutral.primary',
    size: '15',
    weight: 'bold',
  }

  return (
    <Button {...getVariantProps()} {...props}>
      <Flex justifyContent='start' gap='8px' alignItems='center'>
        {LeftIconComponent && <LeftIconComponent {...iconProps} />}
        <Bold fontSize={fontSize} color={props.color || getVariantProps().color}>
          {props.children}
        </Bold>
        {RightIconComponent && <RightIconComponent {...iconProps} />}
      </Flex>
    </Button>
  )
}

export default CustomButton
