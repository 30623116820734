import {
  Button,
  Flex, ModalFooter, useModalContext
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { userProcessStages, UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages';
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh';
import SelectInput from '../../../shared/components/inputs/InlineLayout/SelectInput';
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts';
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton';
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader';
import { ChangeUserStepPayload, changeUserStepQuery } from '../queries/changeUserStepQuery';
import { getUserQuery } from '../queries/userQuery';

type Props = {}

export type ChangeUserStepValues = {
  newStep: UserProcessStagesEnum
}

const ChangeUserStep = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const initialValues: ChangeUserStepValues = {
    newStep: userQuery.data?.user.userInformations?.processStage || UserProcessStagesEnum.SIGNED_UP,
  }

  const updateMutation = useMutation((inputs: ChangeUserStepPayload) => changeUserStepQuery(id || "", inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['user', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          updateMutation.mutate(
            values,
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={userQuery.data?.user?.userInformations} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title="Changer l'étape"
                // subtitle="A n'utiliser qu'en cas de bug ou d'étape en cours de développement"
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Etape'
                  field='newStep'
                  isRequired
                  options={Object.entries(UserProcessStagesEnum).map(([key, value]) => ({
                    label: userProcessStages[value].label,
                    value: value,
                  }))}
                />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChangeUserStep
