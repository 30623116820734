import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'

export interface UpdateUserFileQueryInputs {
  id?: string
  name: string
}

export const updateUserFileQuery = async (updateUserFileQuery: UpdateUserFileQueryInputs) => {
  return await authenticatedPutQuery(`/admin/files/${updateUserFileQuery.id}/`, {
    ...updateUserFileQuery,
    id: undefined,
  })
}
