import { Input, InputProps } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'

type HiddenFieldProps = {
  field: string
} & InputProps

const HiddenField: React.FC<HiddenFieldProps> = ({ field, ...props }) => {
  const formik = useFormikContext<FormikValues>()

  return (
    <Input
      type='hidden'
      id={field}
      onBlur={formik.handleBlur}
      value={formik.values[field] || ''}
      {...props}
    />
  )
}

export default HiddenField
