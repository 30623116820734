import { AddIcon } from '@chakra-ui/icons'
import { Card, Divider, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import AssetProcessModalWrapper from '../../assetAds/v2/AssetProcessModalWrapper'
import CustomButton from '../../components/CustomButton'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import AddPersonToAgencyAction from '../actions/AddPersonToAgencyAction'
import { PartnerAgency } from '../queries/getPartnerQuery'

type Props = {
  agency: PartnerAgency
}

const Partner = (props: Props) => {
  const { id } = useParams()

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Card key={props.agency.id} gap='8px' p={6}>
      <Flex gap='8px' justifyContent='space-between' alignItems='center'>
        <Flex gap='8px'>
          <Bold>{props.agency.name}</Bold>
        </Flex>
        <Flex gap='8px'>
          <CustomButton
            variant='outline'
            size='small'
            onClick={() =>
              window.open(
                `https://app-eu1.hubspot.com/contacts/26051163/record/0-2/${props.agency.hubspotId}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Voir sur hubspot
          </CustomButton>
          <CustomButton leftIcon={<AddIcon />} size='small' variant='outline' onClick={onOpen}>
            Ajouter une personne à l'agence
          </CustomButton>
        </Flex>

        <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
          <AddPersonToAgencyAction agencyId={props.agency.id} />
        </AssetProcessModalWrapper>
      </Flex>
      <Divider />
      {props.agency.persons.map((person) => (
        <Flex key={person.id} gap='8px'>
          <Regular>
            {person.email} - {person.firstName} {person.lastName} ({person.phone})
          </Regular>
        </Flex>
      ))}
    </Card>
  )
}

export default Partner
