import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'

export type DiscoveryBankVisited = {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  name: string | null
  refusalReason: string | null
}

export const getDiscoveryBanksVisited = async (userId: string) => {
  return (await authenticatedGetQuery(`/admin/user/discovery/${userId}/banks`)) as Promise<
    DiscoveryBankVisited[]
  >
}
