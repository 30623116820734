import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Spinner, Textarea } from '@chakra-ui/react'
import { ArrowsInSimple, ArrowsOutSimple, Note } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { HESTIA_COLORS } from '../../../../App'
import Regular from '../../../../dashboard/components/Typo/Regular'
import {
  getDiscoveryMeta,
  updateDiscoveryMeta,
  UpdateDiscoveryMetaPayload,
} from '../../../../dashboard/users/user/discovery/queries/DiscoveryMetaQueries'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { countWords } from '../../../../utility/other'
import CustomBadge from '../../CustomBadge'

const StickyNotepad = () => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(true)
  const [notes, setNotes] = useState('')
  const [lastSavedAt, setLastSavedAt] = useState<Date>(dayjs().toDate())
  const [saveTimer, setSaveTimer] = useState<NodeJS.Timeout | null>(null) // To handle the 5-second autosave timer
  const selectionRef = useRef(null)
  const { id: userId } = useParams()

  const metaInformations = useQuery(
    ['discoveryMeta', { id: userId }],
    () => getDiscoveryMeta(userId || ''),
    {
      onSuccess: (data) => {
        setNotes(data.notes || '')
      },
    },
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryMetaPayload) => updateDiscoveryMeta(userId || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        setLastSavedAt(dayjs().toDate())
      },
    },
  )

  const handleToggle = () => setIsOpen(!isOpen)

  const onSaveToServer = async () => {
    if (!updateMutation.isLoading) {
      updateMutation.mutate({ notes })
    }
  }

  const handleChange = (value: string) => {
    setNotes(value)

    // Clear the previous timer if the user types again within 5 seconds
    if (saveTimer) {
      clearTimeout(saveTimer)
    }

    // Set a new timer to save after 5 seconds of inactivity
    setSaveTimer(setTimeout(onSaveToServer, 5000))
  }

  const handleBlur = () => {
    if (saveTimer) {
      clearTimeout(saveTimer) // If user loses focus, cancel the pending save timer
    }
    onSaveToServer() // Save immediately on blur
  }

  const getStatusMessage = () => {
    if (updateMutation.isLoading) {
      return (
        <>
          <Spinner color='#6F6F6F' w='16px' h='16px' />
          <Regular>Sauvegarde en cours</Regular>
        </>
      )
    } else {
      return (
        <>
          <Flex
            width='16px'
            height='16px'
            borderRadius='50%'
            border='1px solid'
            borderColor='#6F6F6F'
            justifyContent='center'
            alignItems='center'
            verticalAlign='middle'
          >
            <CheckIcon w='8px' h='8px' color='#6F6F6F' />
          </Flex>
          <Regular size='small'>
            Modifications sauvegardées à {dayjs(lastSavedAt).format('HH:mm')}
          </Regular>
        </>
      )
    }
  }

  return (
    <Box>
      <Box style={{ position: 'fixed', bottom: '24px', right: '24px', zIndex: 1000 }}>
        <Box
          bg='white'
          p={6}
          borderRadius='md'
          width='33vw'
          minW='500px'
          maxH='80vh'
          m='8px'
          border='1px solid'
          borderColor={HESTIA_COLORS.blue[600]}
          boxShadow='lg'
          ref={selectionRef}
          overflow='scroll'
          display={isOpen ? 'none' : 'block'}
        >
          <Textarea
            border='none'
            height='auto'
            minH='150px'
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={notes}
            autoFocus={true}
          />
          <Flex justifyContent='start' alignItems='center' gap={2} w='100%'>
            {getStatusMessage()}
          </Flex>
        </Box>
        <Flex
          onClick={handleToggle}
          bg={HESTIA_COLORS.neutral.white}
          borderRadius='8px'
          p={2}
          gap={2}
          justifyContent='space-between'
          alignItems='center'
          border='1px solid'
          borderColor={HESTIA_COLORS.blue[600]}
          boxShadow='dark-lg'
          color={HESTIA_COLORS.blue[600]}
          minW='250px'
          cursor='pointer'
          transition='all 0.3s ease-in-out'
          _hover={{ bg: HESTIA_COLORS.blue[600], color: HESTIA_COLORS.neutral.white }}
        >
          <Flex justifyContent='center' alignItems='center' gap={2}>
            <Note size={32} />
            Notes
            <CustomBadge variant='blue'>{countWords(notes)} mots</CustomBadge>
          </Flex>

          {isOpen ? (
            <ArrowsOutSimple size='32px' weight='bold' />
          ) : (
            <ArrowsInSimple size='32px' weight='bold' />
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default StickyNotepad
