import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { GetBugetResponse } from '../../../../commonQueries/getBudgetQuery'
import { DiscoveryBuyer } from '../discovery.types'

interface Incomes {
  totalMonthlyIncomes: number
  ponderatedMonthlyIncomes: number
}

interface Expenses {
  totalMonthlyExpenses: number
  totalMonthlyExpensesAfterHestia: number
}

interface Patrimony {
  totalPatrimony: number
  totalSavingsAvailableNow: number
  totalExpectedGains: number
}

interface Buyer {
  buyer: DiscoveryBuyer
  incomes: Incomes
  expenses: Expenses
  patrimony: Patrimony
  budget: GetBugetResponse
}

interface Total {
  incomes: Incomes
  expenses: Expenses
  patrimony: Patrimony
  budget: GetBugetResponse
}

export type DiscoveryWarning = {
  label: string
  level: 'BLOCKER' | 'P0' | 'P1' | 'P2' | 'P3'
  finalizationOnly?: boolean
}

export type DiscoveryText = {
  context: string
  buyers: {
    identity: string
    incomes: string
    expenses: string
    patrimony: string
  }[]
}

interface DiscoverySummary {
  total: Total
  buyers: Buyer[]
  warnings: DiscoveryWarning[]
  discoveryText: DiscoveryText
  documentsFormatted: string
}

export const getDiscoverySummary = async (userId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}`,
  )) as Promise<DiscoverySummary>
}

export const finalizeDiscovery = async (
  userId: string,
  analysisNeedDocsCustomMessage: string | undefined,
) => {
  return await authenticatedPostQuery(`/admin/user/discovery/${userId}/finalize`, {
    analysisNeedDocsCustomMessage,
  })
}
