import { FormControl, FormLabel, Grid, GridItem, Input, Switch } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getUserQuery } from '../queries/userQuery'

const UserEmployment: FC<{}> = ({}) => {
  const { id } = useParams()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  return (
    <Grid templateColumns='repeat(12, 1fr)' gap={[6, 12]} w='100%' mt='24px'>
      <GridItem colSpan={[12, 6]}>
        <FormControl isRequired>
          <FormLabel htmlFor='firstName'>Contract type</FormLabel>
          <Input
            disabled
            placeholder='Charlotte'
            size='lg'
            id='firstName'
            type='string'
            value={userQuery.data?.user.userInformations?.contractType}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <FormControl isRequired>
          <FormLabel htmlFor='firstName'>Sector</FormLabel>
          <Input
            disabled
            placeholder='Charlotte'
            size='lg'
            id='firstName'
            type='string'
            value={userQuery.data?.user.userInformations?.employmentSector}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <FormControl isRequired>
          <FormLabel htmlFor='firstName'>Public status</FormLabel>
          <Input
            disabled
            placeholder='Charlotte'
            size='lg'
            id='firstName'
            type='string'
            value={userQuery.data?.user.userInformations?.publicStatus}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <FormControl isRequired>
          <FormLabel htmlFor='firstName'>Trial period</FormLabel>
          <Switch
            disabled
            id='email-alerts'
            checked={!!userQuery.data?.user.userInformations?.publicStatus}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <FormControl isRequired>
          <FormLabel htmlFor='firstName'>Managerial position (cadre)</FormLabel>
          <Switch
            disabled
            id='email-alerts'
            checked={!!userQuery.data?.user.userInformations?.managerialPosition}
          />
        </FormControl>
      </GridItem>
    </Grid>
  )
}

export default UserEmployment
