import { FormikErrors } from 'formik'
import { isValidNumber, parse } from 'libphonenumber-js'
import { isValidEmail } from '../../../utility/other'
import { CreateUserActionValues } from './CreateUserAction'

export const validateCreateUserAction = (values: CreateUserActionValues) => {
  const errors: FormikErrors<CreateUserActionValues> = {}

  if (!values.firstName) {
    errors.firstName = 'Ce champ est requis'
  }
  if (!values.lastName) {
    errors.firstName = 'Ce champ est requis'
  }

  if (values.isFromPartner === true) {
    if (!values.partnerId) {
      errors.partnerId = 'Ce champ est requis'
    }
    if (!values.agencyId) {
      errors.agencyId = 'Ce champ est requis'
    }
    if (!values.personId) {
      errors.personId = 'Ce champ est requis'
    }
  }

  if (values.roundRobin === false) {
    if (!values.ownerId) {
      errors.ownerId = 'Ce champ est requis'
    }
  }

  // If invalid email

  if (!values.email) {
    errors.email = 'Ce champ est requis'
  } else if (!isValidEmail(values.email)) {
    errors.email = "Ce n'est pas un email valide"
  }

  if (!values.phone) {
    errors.phone = 'Ce champ est requis'
  } else if (!isValidNumber(parse(values.phone))) {
    errors.phone = "Ce numéro de téléphone n'est pas valide"
  }

  if (!values.postalCode) {
    errors.postalCode = 'Ce champ est requis'
  }

  return errors
}
