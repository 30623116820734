import { DeleteIcon } from '@chakra-ui/icons'
import { Divider, Flex } from '@chakra-ui/react'
import { Plus } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomCard from '../../../../components/CustomCard'
import ConfirmButton from '../../../../shared/components/ConfirmButton'
import {
  getHousingSituationItems,
  getHousingSituationsFields,
  housingSituationsInitialValues,
} from '../../../../utility/discovery/discoveryFields.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import { discoveryBuyerPersonalSituations, getLabel } from './discovery.types'
import DiscoveryDisplayValue from './DiscoveryDisplayValue'
import DiscoveryLineItem from './DiscoveryLineItem'
import {
  createDiscoveryBuyer,
  deleteDiscoveryBuyer,
  getDiscoveryBuyer,
  getDiscoveryBuyersForUser,
  updateDiscoveryBuyer,
  UpdateDiscoveryBuyerPayload,
} from './queries/DicsoveryBuyersQueries'

type Props = {
  buyerId?: string | undefined
}

const DiscoveryBuyer = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const buyers = useQuery(['discoveryContextBuyers', { id: userId }], () =>
    getDiscoveryBuyersForUser(userId || ''),
  )

  const otherBuyer = buyers.data?.find((buyer) => buyer.id !== props.buyerId)

  const currentData = useQuery(
    ['discoveryContextBuyer', { id: userId, buyerId: props.buyerId }],
    () => getDiscoveryBuyer(userId || '', props.buyerId || ''),
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryBuyerPayload) =>
      updateDiscoveryBuyer(userId || '', props.buyerId || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoveryContextBuyer'])
        queryClient.invalidateQueries(['discoveryContextBuyers'])
        successToast({ description: 'Emprunteur modifié' })
      },
    },
  )

  const deleteMutation = useMutation(
    (buyerId: string) => deleteDiscoveryBuyer(userId || '', buyerId || ''),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoveryContextBuyer'])
        queryClient.invalidateQueries(['discoveryContextBuyers'])
        successToast({ description: 'Emprunteur supprimé' })
      },
    },
  )

  const createMutation = useMutation(() => createDiscoveryBuyer(userId || ''), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['discoveryContextBuyer'])
      queryClient.invalidateQueries(['discoveryContextBuyers'])
      successToast({ description: 'Emprunteur ajouté' })
    },
  })

  return (
    <CustomCard light title='Emprunteur'>
      <Flex flexDirection='column' gap='16px' w='100%'>
        {props.buyerId && currentData.data?.id && (
          <>
            <DiscoveryLineItem
              fields={[
                {
                  fieldName: 'firstName',
                  type: 'text',
                  label: 'Prénom',
                },
                {
                  fieldName: 'lastName',
                  type: 'text',
                  label: 'Nom',
                },
                {
                  fieldName: 'age',
                  type: 'number',
                  label: 'Age',
                },
                {
                  fieldName: 'nationality',
                  type: 'text',
                  label: 'Nationalité',
                },
              ]}
              title='Identité'
              items={[
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={
                    currentData.data?.firstName &&
                    `${currentData.data?.firstName} ${currentData.data?.lastName}`
                  }
                />,
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.age && `${currentData.data?.age} ans`}
                />,
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.nationality && `${currentData.data?.nationality}`}
                />,
              ]}
              updateMutation={updateMutation}
              currentdata={currentData.data}
            />
            <Divider />
            <DiscoveryLineItem
              fields={[
                {
                  fieldName: 'personalSituation',
                  type: 'select',
                  label: '',
                  options: discoveryBuyerPersonalSituations,
                },
              ]}
              title='Situation personnelle'
              items={[
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.personalSituation}
                  label={getLabel(
                    discoveryBuyerPersonalSituations,
                    currentData.data?.personalSituation,
                  )}
                />,
              ]}
              updateMutation={updateMutation}
              currentdata={currentData.data}
              copyFieldsOptions={{
                label: `Copier ${otherBuyer?.firstName}`,
                values: otherBuyer || {},
                enabled: !!otherBuyer,
              }}
            />
            <Divider />
            <DiscoveryLineItem
              initialValues={housingSituationsInitialValues}
              getFieldsFunction={getHousingSituationsFields}
              title='Logement'
              getItemsFunction={getHousingSituationItems}
              updateMutation={updateMutation}
              currentdata={currentData.data}
              copyFieldsOptions={{
                label: `Copier ${otherBuyer?.firstName}`,
                values: otherBuyer || {},
                enabled: !!otherBuyer,
              }}
            />
            <Divider />
          </>
        )}

        {props.buyerId ? (
          <ConfirmButton
            bodyText='Cette action est définitive!'
            buttonText={'Supprimer'}
            headerText='Supprimer'
            rightIcon={<DeleteIcon />}
            onSuccessAction={() => {
              deleteMutation?.mutate(props.buyerId || '')
            }}
            variant='danger-outline'
          />
        ) : (
          <CustomButton
            variant='outline'
            leftIconComponent={Plus}
            onClick={() => createMutation.mutate()}
          >
            Ajouter un emprunteur
          </CustomButton>
        )}
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryBuyer
