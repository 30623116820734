import { Badge, Button, Flex, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { TeamsEnum } from '../../../shared/backShared/assetProcessSteps'
import {
  getStepNumber,
  userProcessStages,
  UserProcessStagesEnum,
} from '../../../shared/backShared/userProcessStages'
import StepTodo from '../../assetAds/Steps/StepTodo'
import AssetProcessModalWrapper from '../../assetAds/v2/AssetProcessModalWrapper'
import AnalysisCompletedAction from '../actions/AnalysisCompletedAction'
import QualifyAction from '../actions/QualifyAction'
import WaitingForDocs from '../actions/WaitingForDocsAction'
import { getUserQuery } from '../queries/userQuery'
require('dayjs/locale/fr')
dayjs.extend(relativeTime)
dayjs.locale('fr')

type Props = {
  currentStep: UserProcessStagesEnum
  step: UserProcessStagesEnum
}

const UserProcessStageTodo = (props: Props) => {
  const current = getStepNumber(props.currentStep) === getStepNumber(props.step)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const getTeamBadge = (team: TeamsEnum) => {
    switch (team) {
      case TeamsEnum.SUPPLY:
        return <Badge colorScheme='blue'>Supply</Badge>
      case TeamsEnum.DEMAND:
        return <Badge colorScheme='green'>Demand</Badge>
      case TeamsEnum.CREDIT:
        return <Badge colorScheme='red'>Crédit</Badge>
      case TeamsEnum.FUND:
        return <Badge colorScheme='purple'>Fund</Badge>
      default:
        return <Badge colorScheme='grey'>Unknown?</Badge>
    }
  }

  const getCurrentAction = (step: UserProcessStagesEnum) => {
    switch (step) {
      case UserProcessStagesEnum.TO_QUALIFY:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
            {userQuery.data?.user.userInformations?.processStage ===
            UserProcessStagesEnum.TO_QUALIFY ? (
              <QualifyAction />
            ) : (
              'Utilisateur déjà qualifé'
            )}
          </AssetProcessModalWrapper>
        )
      case UserProcessStagesEnum.WAITING_FOR_DOCUMENTS:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
            <WaitingForDocs />
          </AssetProcessModalWrapper>
        )
      case UserProcessStagesEnum.DOCUMENTS_SUBMITTED:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
            <AnalysisCompletedAction />
          </AssetProcessModalWrapper>
        )

      default:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            Pas encore développé !
          </AssetProcessModalWrapper>
        )
    }
  }

  // const currentProcessStepData = assetAd.data?.assetProcessSteps.find((s) => s.step === props.step)

  // console.log(currentProcessStepData)

  return (
    <>
      {getCurrentAction(props.step)}

      <StepTodo
        text={userProcessStages[props.step].label}
        done={getStepNumber(props.currentStep) > getStepNumber(props.step)}
        current={current}
        // subtitle={
        //   currentProcessStepData && (
        //     <Flex>
        //       <Regular color='HestiaGrey' fontSize='16px'>
        //         <Tooltip>
        //           <Flex gap='8px' alignItems='center'>
        //             <BiCalendar />
        //             <Text as='span'>
        //               {current
        //                 ? `Commencé ${dayjs(currentProcessStepData.startedAt)
        //                     .locale('fr')
        //                     .fromNow()}`
        //                 : `Terminé le ${dayjs(currentProcessStepData.finishedAt).format(
        //                     'DD/MM/YYYY (HH:MM)',
        //                   )}`}
        //             </Text>
        //           </Flex>
        //         </Tooltip>
        //       </Regular>
        //     </Flex>
        //   )
        // }
        rightPart={
          <Flex h='100%' alignItems='center' gap='16px'>
            {getTeamBadge(userProcessStages[props.step].team)}
            <Button
              size='sm'
              color={current ? 'white' : 'HestiaGrey'}
              // bg={current ? 'HestiaPintGreen' : 'initial'}
              colorScheme={current ? 'green' : 'gray'}
              onClick={onOpen}
            >
              Voir plus
            </Button>
          </Flex>
        }
      />
    </>
  )
}

export default UserProcessStageTodo
