import { Alert, AlertProps, Flex } from '@chakra-ui/react'
import { Warning } from '@phosphor-icons/react'
import React from 'react'
import { HESTIA_COLORS } from '../../../../App'
import Bold from '../../../components/Typo/Bold'
import Regular from '../../../components/Typo/Regular'
import { DiscoveryWarning } from './queries/DicsoveryQueries'

type Props = {
  warnings: DiscoveryWarning[]
  status: 'error' | 'warning'
}

const DiscoveryWarningAlert = (props: Props) => {
  const getAlertProps = () => {
    switch (props.status) {
      case 'error':
        return {
          status: 'error',
          bg: 'HestiaLightRed',
          borderColor: HESTIA_COLORS.red[500],
        } as AlertProps
      case 'warning':
        return {
          status: 'warning',
          bg: 'HestiaLightYellow',
          borderColor: HESTIA_COLORS.yellow[500],
        } as AlertProps
    }
  }

  const getTitle = () => {
    switch (props.status) {
      case 'error':
        return 'Bloquant'
      case 'warning':
        return "Points d'attention"
    }
  }

  return (
    <Alert {...getAlertProps()} border='1px solid' borderRadius='8px'>
      <Flex direction='column' gap='16px' w='100%'>
        <Flex w='100%' gap='8px' alignItems='center'>
          <Regular color={getAlertProps().borderColor}>
            <Warning weight='fill' />
          </Regular>
          <Bold>{getTitle()}</Bold>
        </Flex>

        <Flex direction='column' gap='6px'>
          {props.warnings.map((warning) => (
            <Regular key={warning.label}>- {warning.label}</Regular>
          ))}
        </Flex>
      </Flex>
    </Alert>
  )
}

export default DiscoveryWarningAlert
