import { Alert, Button, Flex, Kbd, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik, FormikProps, FormikValues } from 'formik'
import React, { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../../assetAds/v2/actions/ModalCustomHeader'
import CustomButton from '../../../components/CustomButton'
import Bold from '../../../components/Typo/Bold'
import Regular from '../../../components/Typo/Regular'
import { generateMessage, GenrateMessageInputs } from '../../../tools/queries/generateMessage'
import { getUserQuery } from '../../queries/userQuery'
import DiscoveryWarningAlerts from './DiscoveryWarningAlerts'
import { finalizeDiscovery, getDiscoverySummary } from './queries/DicsoveryQueries'

type FinalizationStages = 'WARNINGS' | 'DOCS' | 'FOLLOW_UP'

type Props = {
  // isOpen: boolean
  // onOpen: () => void
}

const FinalizeDiscoveryModal = (props: Props) => {
  const { onClose } = useModalContext()
  const queryClient = useQueryClient()
  const { errorToast, successToast } = useCustomToast()
  const [stage, setStage] = useState<FinalizationStages>('WARNINGS')
  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const { id: userId } = useParams()

  const userQuery = useQuery(['user', { id: userId }], () => getUserQuery({ id: userId }))

  const summary = useQuery(['discoverySummary', { id: userId }], () =>
    getDiscoverySummary(userId || ''),
  )

  const finalizeMutation = useMutation(
    (docsMessage: string) => finalizeDiscovery(userId || '', docsMessage),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryMeta'])
        successToast({ description: 'Call découverte finalisé!' })
        onClose()
      },
    },
  )

  const generateMessageMutation = useMutation(
    (inputs: GenrateMessageInputs) => generateMessage(inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        successToast({ description: 'Done!' })
      },
    },
  )

  const generateFollowUp = () => {
    const prompt = `
    Hestia is our company. We are a rent-to-own company that helps people access homeownership with our leasing model.
    Hestia is a company that offers real estate leasing, a solution that allows people to become homeowners without an initial down payment or a bank loan. With Hestia, clients can move into the home of their choice and gradually purchase it over a maximum period of 24 months.

    Client situation :
    "${summary.data?.discoveryText.context}"

    Documents needed (Copy-paste EXACTLY this in the follow-up email):
    ${formikRef.current?.values.needDocsMessage}

    You are an hestia Sales. Write a follow-up email for the first call that will thank the prospect, summarize his pains and clarify the next steps with owner and deadlines.
    The structure should be:
    - Intro: (pleasure speaking with you)
    - Summary of the situation and the issue. Don't get into the numbers if you don't need to.
    - Hestia is the solution (don't explain what hestia is, just say that this is a good fit for the situation and we can help)
    - Next step: we need their documents for our analyst to validate his profile before going further with the project. Copy paste the documents needed in the email.

    Write it in french. Only answer with the mail content, not the subject. The documents needed must appears as a list in the email, like it.
    `
    generateMessageMutation.mutateAsync({ brief: prompt })
  }

  const initialValues = {
    needDocsMessage: '',
    followUpMessage: '',
  }

  const stages: FinalizationStages[] = ['WARNINGS', 'DOCS', 'FOLLOW_UP']

  const currentStageIndex = stages.indexOf(stage)

  const handleNextStage = () => {
    if (currentStageIndex === stages.length - 1) {
      formikRef.current?.submitForm()
    } else {
      setStage(stages[currentStageIndex + 1])
    }
  }

  // Function to handle the previous stage
  const handlePreviousStage = () => {
    if (currentStageIndex === 0) {
      onClose()
    } else {
      setStage(stages[currentStageIndex - 1])
    }
  }

  // Stage content rendering
  const renderStageContent = () => {
    switch (stage) {
      case 'WARNINGS':
        return (
          <>
            {(summary.data?.warnings.filter((o) => o.level === 'BLOCKER') || []).length > 0 && (
              <DiscoveryWarningAlerts
                warnings={summary.data?.warnings.filter((o) => o.level === 'BLOCKER') || []}
                status='error'
              />
            )}
            {(summary.data?.warnings.filter((o) => o.level !== 'BLOCKER') || []).length > 0 && (
              <DiscoveryWarningAlerts
                warnings={summary.data?.warnings.filter((o) => o.level !== 'BLOCKER') || []}
                status='warning'
              />
            )}
          </>
        )
      case 'DOCS':
        return (
          <Flex direction='column' gap='16px'>
            <Alert border='1px solid' borderRadius='8px'>
              <Bold>Les documents que nous allons demander</Bold>
            </Alert>
            <TextAreaInput field='needDocsMessage'></TextAreaInput>
          </Flex>
        )
      case 'FOLLOW_UP':
        return (
          <Flex direction='column' gap='16px'>
            <Alert bg='white' border='1px solid' borderRadius='8px'>
              <Flex direction='column' gap='8px'>
                <Bold>Mail de follow-up qui va être envoyé</Bold>
                <Regular>Pour l'instant, il faut le copier/coller et l'envoyer en perso ! </Regular>
              </Flex>
            </Alert>
            <CustomButton
              variant='outline'
              onClick={() => generateFollowUp()}
              isLoading={generateMessageMutation.isLoading}
            >
              Générer le mail de follow-up
            </CustomButton>

            <TextAreaInput
              field='followUpMessage'
              isDisabled={generateMessageMutation.isLoading}
            ></TextAreaInput>
          </Flex>
        )
      default:
        return null
    }
  }

  return (
    <>
      <ModalCustomCloseButton />
      <Flex padding='32px' gap='16px' direction='column'>
        <ModalCustomHeader
          title='Valider le call découverte'
          subtitle={
            <>
              Vous pouvez également ouvrir cette fenêtre avec <Kbd color='black'>cmd</Kbd> +{' '}
              <Kbd color='black'>entrée</Kbd>
            </>
          }
        />
        <Flex gap='24px' direction='column'>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={async (values, formik) => {
              console.log(values)
              finalizeMutation.mutate(values.needDocsMessage)
            }}
          >
            {(formik) => (
              <>
                <FormikAutoRefresh
                  data={{
                    needDocsMessage: summary.data?.documentsFormatted,
                    followUpMessage: generateMessageMutation.data?.openAIResponse.content,
                  }}
                  initialValues={initialValues}
                />
                {renderStageContent()}
              </>
            )}
          </Formik>
        </Flex>
      </Flex>
      <ModalFooter>
        <Flex justifyContent='flex-end' gap='16px'>
          <Button color='HestiaGrey' onClick={handlePreviousStage}>
            {currentStageIndex === 0 ? 'Annuler' : 'Retour'}
          </Button>

          <Button
            type='submit'
            background='HestiaPintGreen'
            colorScheme='green'
            color='white'
            disabled={
              stage === 'WARNINGS' &&
              (summary.data?.warnings.filter((o) => o.level === 'BLOCKER') || []).length > 0
            }
            onClick={handleNextStage}
            isLoading={finalizeMutation.isLoading || summary.isFetching}
          >
            {stage === stages[stages.length - 1]
              ? 'Valider et passer en attente de docs'
              : 'Suivant'}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  )
}

export default FinalizeDiscoveryModal
