import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import { DiscoveryMetaInformations } from '../discovery.types'

export type UpdateDiscoveryMetaPayload = {
  isFinalized?: boolean | null
  notes?: string | null
}

export const getDiscoveryMeta = async (userId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/meta`,
  )) as Promise<DiscoveryMetaInformations>
}

export const updateDiscoveryMeta = async (userId: string, payload: UpdateDiscoveryMetaPayload) => {
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/meta`,
    payload,
  )) as Promise<void>
}
