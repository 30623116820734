import { authenticatedPutQuery } from '../../../utility/queries/authenticatedPutQuery'
import { AssetAdStatus } from '../../../interfaces/prisma'
import { TeamsEnum } from '../../../shared/backShared/assetProcessSteps'

export interface UpdateAdminInputs {
  teams?: TeamsEnum[]
  id: string
}

export const updateAdmin = async (body: UpdateAdminInputs) => {
  return await authenticatedPutQuery(`/admin/admins/${body.id}`, {
    ...body,
    id: undefined,
  }).then((data) => data.data)
}
