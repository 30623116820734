import { Button, Card, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  UserAnalaysisRejectionReasonEnum,
  userAnalysisRejectionReasons,
} from '../../../shared/backShared/userAnalysisRejectionTypes'
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh'
import AIGeneratedInput from '../../../shared/components/inputs/InlineLayout/AIGeneratedInput'
import BooleanInput from '../../../shared/components/inputs/InlineLayout/BooleanInput'
import SelectInput from '../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import { useHestiaBudgetAdmin } from '../../../utility/hooks/useHestiaBudgetAdmin'
import { getPrettyNumber } from '../../../utility/money'
import { getAssetAdById } from '../../assetAds/queries/getAssetAdById'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { UserAnalysisPayload, userAnalysisQuery } from '../queries/userAnalysisQuery'
import { getUserQuery } from '../queries/userQuery'
type Props = {}

export enum AnalysisCompletedStates {
  'ACCEPTED' = 'ACCEPTED',
  'REJECTED' = 'REJECTED',
  'NEED_DOCS' = 'NEED_DOCS',
}

export type AnalysisCompletedActionValues = {
  analysisStatus: AnalysisCompletedStates | undefined
  userAnalysisRejectionReason: UserAnalaysisRejectionReasonEnum | undefined
  customMessage: boolean | undefined
  rejectionMessage: string | undefined
  needDocsMessage: string | undefined
}

const AnalysisCompletedAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const budget = userQuery.data?.user && useHestiaBudgetAdmin({ user: userQuery.data?.user })

  const initialValues: AnalysisCompletedActionValues = {
    analysisStatus: undefined,
    userAnalysisRejectionReason: undefined,
    customMessage: undefined,
    rejectionMessage: undefined,
    needDocsMessage: undefined,
  }

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation(
    (inputs: UserAnalysisPayload) => userAnalysisQuery(id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['user', { id }])
        successToast({ description: 'All good' })
      },
    },
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validate={validateAnalysisCompletedAction}
        onSubmit={async (values, formik) => {
          console.log(values)
          const payload = {
            ..._.omit(values, ['customMessage']),
          }
          updateMutation.mutate(payload, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Analyse du client'
                subtitle='Un email sera envoyé en cas de refus.'
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Status'
                  field='analysisStatus'
                  isRequired
                  options={[
                    { label: 'Besoin de documents', value: AnalysisCompletedStates.NEED_DOCS },
                    { label: 'Accepté', value: AnalysisCompletedStates.ACCEPTED },
                    { label: 'Refusé', value: AnalysisCompletedStates.REJECTED },
                  ]}
                />
                {formik.values.analysisStatus === AnalysisCompletedStates.ACCEPTED && (
                  <>
                    <Regular>
                      Pour rappel, le budget du client validé est de{' '}
                      <b>{budget && getPrettyNumber(budget.simulatorData?.budgetNow, 0, true)}</b>
                    </Regular>
                  </>
                )}
                {formik.values.analysisStatus === AnalysisCompletedStates.REJECTED && (
                  <>
                    <SelectInput
                      field={'userAnalysisRejectionReason'}
                      label={'Raison de refus'}
                      options={userAnalysisRejectionReasons}
                    />
                    {formik.values.userAnalysisRejectionReason && (
                      <Card border='1px solid' borderColor='HestiaLightBlue' p='8px'>
                        <Flex direction='column' gap='8px'>
                          <Bold>Exemple de message qui sera envoyé avec cette raison :</Bold>
                          <Regular>
                            {
                              userAnalysisRejectionReasons.find(
                                (e) => e.value === formik.values.userAnalysisRejectionReason,
                              )?.emailContent
                            }
                          </Regular>
                        </Flex>
                      </Card>
                    )}
                    <BooleanInput
                      field={'customMessage'}
                      checkboxLabel={'Je veux envoyer un message personalisé en plus.'}
                    />
                    {formik.values.customMessage && (
                      <>
                        <AIGeneratedInput
                          brief={`Après analyse des documents envoyés et du profil du client, il n'est pas éligible à nos services.
                          Ecris un court message qui s'affichera sur la plateforme du client qui résume la raison de refus de son profil. 
                          Maximum 2 phrases.
                          La raison de refus: `}
                          field={'rejectionMessage'}
                          label={'Message personalisé'}
                        />
                      </>
                    )}
                  </>
                )}
                {formik.values.analysisStatus === AnalysisCompletedStates.NEED_DOCS && (
                  <>
                    <>
                      <AIGeneratedInput
                        brief={`Après analyse des documents envoyés et du profil du client, il nous manque des documents pour pouvoir terminer l'analyse.
                        Ecris la liste des documents manquants. Liste bien tous les documents manquants, soit exhaustif. Ne fais pas d'intro et de conclusion, juste les documents manquants.
                        Les documents manquants: `}
                        field={'needDocsMessage'}
                        label={'Message personalisé'}
                      />
                    </>
                  </>
                )}
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default AnalysisCompletedAction
