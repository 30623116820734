import React, { useState } from 'react'
import {
  Button,
  Flex,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import { ROUTES } from '../../../utility/routes/routeConstants'

const Analyses = () => {
  const toast = useToast()
  const [isCooldown, setIsCooldown] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  const analyses = useQuery(['analyses', { id }], () => getAnalysesQuery(id || ''), {
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    refetchInterval: 1000,
  })

  const createMutation = useMutation(
    (inputs: CreateAnalysisInputs) => createAnalysisQuery(inputs),
    {
      onError: (error: AxiosError, variables, context) => {
        toast({
          title: 'Erreur:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        setIsCooldown(true)
        toast({
          title: 'Succès!:',
          description: "L'analyse va apparaitre d'ici quelques minutes maximum.",
          status: 'success',
          position: 'top-right',
        })
        setTimeout(() => {
          setIsCooldown(false)
        }, 60000)
      },
    },
  )

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
        <Flex gap='13px'>
          <Button
            colorScheme='green'
            rightIcon={createMutation.isLoading || isCooldown ? <Spinner /> : <AddIcon />}
            onClick={() => createMutation.mutate({ id: id || '' })}
            disabled={createMutation.isLoading || isCooldown}
          >
            Générer une analyse Algoan
          </Button>
        </Flex>
      </Flex>

      <TableContainer>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Date de création</Th>
              <Th>Date dernier update</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {analyses.data?.analyses.map((analysis) => (
              <Tr
                onClick={() =>
                  navigate(`/${ROUTES.USERS_BASE}/${id}/${ROUTES.USERS_ANALYSIS}/${analysis.id}`)
                }
                cursor='pointer'
                _hover={{
                  background: 'HestiaLightGreen',
                }}
              >
                <Td>{analysis.id}</Td>
                <Td>{dayjs(analysis.createdAt).format('DD/MM/YYYY')}</Td>
                <Td>{dayjs(analysis.updatedAt).format('DD/MM/YYYY')}</Td>
                <Td>{analysis.status}</Td>
              </Tr>
            ))}
          </Tbody>
          {/*<Tfoot>*/}
          {/*  <Tr>*/}
          {/*    <Th>To convert</Th>*/}
          {/*    <Th>into</Th>*/}
          {/*    <Th isNumeric>multiply by</Th>*/}
          {/*  </Tr>*/}
          {/*</Tfoot>*/}
        </Table>
      </TableContainer>
    </DashboardLayout>
  )
}

export default Analyses
