import axios from 'axios'
import { apiUrl } from '../../utility/config'
import { authenticatedGetQuery } from '../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../utility/queries/authenticatedPostQuery'

export type SimulatorHypothesis = {
  savingsAvailable?: number | string
  monthlyIncome?: number | string
  monthlyDebt?: number | string
  optionYears?: number | string
  rentalYield?: number | string
  cashbackPercentage?: number | string
  savingsPercentage?: number | string
  salaryIncrease?: number | string
  requiredDeposit?: number | string
  debtToIncomeRatio?: number | string
  loanDuration?: number | string
  interestRate?: number | string
  assetIncrease?: number | string
  offerDownpayment?: number | string
}

export interface MortgageSimulatorV3SingleResponse {
  futureDeposit: number
  futureSalary: number
  maximumCanBorrowWithDeposit: number
  maximumCanBorrowWithDebtToIncomeRatio: number
  maximumCanBorrow: number
  futureDepositNeeded: number
  actualBudget: number
  maximumCanSave: number
  hestiaMonthlyRent: number
  hestiaMonthlyCashback: number
  hestiaTotalCashback: number
  complementarySavingsNeeded: number
  monthlySavingsNeeded: number
  totalMonthlyExpenses: number
  hestiaDebtToIncomeRatio: number
  isTRIok: boolean
  isResilienceOk: boolean
  isDownPaymentPayableWithSavings: boolean
  monthlyPaymentsForFutureLoan: number
  extraRent: number
  downPaymentMissing: number
  rentalYieldNeededForResilience: number
}

export interface MortgageSimulatorV3Response {
  fullResults: MortgageSimulatorV3SingleResponse[]
  userResult: MortgageSimulatorV3SingleResponse
}

export const getMortgageSimulatorResultsV3 = async (
  inputs: SimulatorHypothesis,
): Promise<MortgageSimulatorV3Response> => {
  return await authenticatedPostQuery('/admin/users/mortgagesimulator/v3', {
    hypothesis: {
      ...Object.entries(inputs).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: typeof value === 'string' ? parseFloat(value) : value,
        }),
        {},
      ),
    },
  }).then((data) => data as MortgageSimulatorV3Response)
}
