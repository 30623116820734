import React, { FC, ReactNode } from 'react'
import { Text } from '@chakra-ui/react'

const Paragraph: FC<{
  children?: ReactNode
}> = ({ children }) => {
  return (
    <Text
      color='#6F6F6F'
      maxWidth={['100%', '600px']}
      fontSize='16px'
      fontWeight='400'
      fontFamily='HKGroteskMedium'
      lineHeight='21px'
    >
      {children}
    </Text>
  )
}

export default Paragraph
