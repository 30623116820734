import { Flex, Tooltip } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { HESTIA_COLORS } from '../App'
import Regular from '../dashboard/components/Typo/Regular'

type Props = {
  leftSide: ReactNode | string
  rightSide: ReactNode | string
  tooltipContent?: ReactNode | undefined
}

const LineItem = (props: Props) => {
  return (
    <Tooltip size='lg' placement='auto' label={props.tooltipContent} hasArrow>
      <Flex w='100%' h='100%' justifyContent='space-between' alignItems='center'>
        <Regular size='small' lineHeight={1.5}>
          {props.leftSide}
        </Regular>
        <Regular
          size='small'
          color={HESTIA_COLORS.neutral.light}
          // wordBreak='keep-all'
          whiteSpace='nowrap'
        >
          {props.rightSide}
        </Regular>
      </Flex>
    </Tooltip>
  )
}

export default LineItem
