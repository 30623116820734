import React from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import AssetAdSummary from './AssetAdSummary'
import Comparables from './Comparables'
import Offer from './Offer'
import AssetAdContext from './AssetAdContext'
import AssetAdData from './AssetAdData'
import Header from '../components/Typo/Header'
import Regular from '../components/Typo/Regular'
import RegularLink from '../components/Typo/RegularLink'
import { BiCoinStack, BiLinkAlt, BiMessageDetail, BiUser } from 'react-icons/bi'
import LargeBold from '../components/Typo/LargeBold'
import AssetAdHeader from './v2/AssetAdHeader'
import AssetAdProcessList from './v2/AssetAdProcessList'
import AssetAdQuickActions from './v2/AssetAdQuickActions'
import { useQuery } from 'react-query'
import { getAssetAdById } from './queries/getAssetAdById'
import { rejectionReasons } from '../../shared/backShared/assetRejectionTypes'
import { lostReasons, LostReasonEnum } from '../../interfaces/prisma'
import Bold from '../components/Typo/Bold'
import dayjs from 'dayjs'

const AssetAdDetailV2 = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  return (
    <DashboardLayout>
      <Flex w='100%'>
        <Flex direction='column' gap='24px' w='100%'>
          <AssetAdHeader />
          {assetAd.data?.isLost && (
            <Alert
              status='error'
              bg='HestiaLightRed'
              border='1px solid'
              borderColor='HestiaRed'
              borderRadius='8px'
            >
              <Regular color='HestiaBlack'>
                Ce bien est marqué comme "Lost" <br />
                La raison :{' '}
                <b>{lostReasons.find((e) => e.value === assetAd.data?.lostReason)?.label}</b>
                {assetAd.data?.lostReason === LostReasonEnum.ANALYSIS && (
                  <>
                    <br />
                    Raison de l'analyse :{' '}
                    <b>
                      {
                        rejectionReasons.find((e) => e.value === assetAd.data?.rejectionReason)
                          ?.label
                      }
                    </b>
                  </>
                )}
              </Regular>
            </Alert>
          )}
          <Grid templateColumns='repeat(24, 1fr)' gap='24px'>
            {assetAd.data?.visitInformation?.visitDate && (
              <GridItem colSpan={12}>
                <Card>
                  <Flex direction='column' gap='8px' padding='16px'>
                    <Header>Visite</Header>
                    {assetAd.data?.visitInformation && (
                      <>
                        <Bold>Date de la visite</Bold>
                        <Regular>
                          Visité prévue {dayjs(assetAd.data?.visitInformation?.visitDate).fromNow()}{' '}
                          ({dayjs(assetAd.data?.visitInformation?.visitDate).format('DD/MM/YYYY')})
                        </Regular>
                      </>
                    )}
                    {assetAd.data?.visitInformation?.propertyRating &&
                      assetAd.data?.visitInformation?.propertyRating > 0 && (
                        <>
                          <Bold>Qu'avez-vous le plus apprécié ?</Bold>
                          <Regular>{assetAd.data?.visitInformation.favoritePartOfVisit}</Regular>
                          <Bold>Envisagez-vous de faire des travaux ? Si oui, lesquels ?</Bold>
                          <Regular>{assetAd.data?.visitInformation.plannedRenovations}</Regular>
                          <Bold>
                            Qu'appréciez-vous dans l'environnement du bien ? (écoles, transports,
                            commerces...)
                          </Bold>
                          <Regular>{assetAd.data?.visitInformation.environmentDetails}</Regular>
                          <Bold>Une note sur 10</Bold>
                          <Bold color='HestiaPintGreen'>
                            {assetAd.data?.visitInformation.propertyRating} / 10
                          </Bold>
                        </>
                      )}
                  </Flex>
                </Card>
              </GridItem>
            )}
            <GridItem colSpan={12}>
              <Card>
                <Flex direction='column' gap='8px' padding='16px'>
                  <Header>Informations complémentaires</Header>
                  <Bold>Numéro de téléphone du vendeur</Bold>
                  <Regular>{assetAd.data?.sellerPhone}</Regular>
                </Flex>
              </Card>
            </GridItem>
          </Grid>

          <Flex gap='24px'>
            <AssetAdProcessList />
            <Flex direction='column' gap='24px' w='100%'>
              <AssetAdQuickActions />
              <Card width='100%' h='200px'>
                {' '}
                Placeholder offre résumée{' '}
              </Card>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default AssetAdDetailV2
