import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export const getAlgoliaKeys = async () => {
  return (await authenticatedGetQuery(`/admin/dev/algoliakeys`).then(
    (response) => response,
  )) as Promise<{
    secret: string
    appName: string
  }>
}
