import { SearchIcon } from '@chakra-ui/icons'
import { InputGroup, InputLeftElement, Input, Flex, Divider } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web'
import { isSearchEmptyAtom } from '../../atoms/searchAtoms'
import useDebounce from '../../utility/hooks/useDebounce'

type Props = {}

const AlgoliaSearchBox = (props: UseSearchBoxProps) => {
  const { query, refine, clear } = useSearchBox(props)

  const [searchTerm, setSearchTerm] = useState('')
  const searchDebounced = useDebounce(searchTerm, 200)
  const [isSearchEmpty, setIsSearchEmpty] = useAtom(isSearchEmptyAtom)

  useEffect(() => {
    refine(searchDebounced)
  }, [searchDebounced])

  return (
    <Flex
      padding='16px'
      direction='column'
      boxShadow='0px 3px 16px rgba(0, 0, 0, 0.08)'
      border='1px solid #EFEFEF'
      borderRadius='8px'
    >
      <InputGroup size='md'>
        <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
        <Input
          size='md'
          outline='transparent solid 2px'
          outlineOffset='2px'
          borderColor='transparent'
          focusBorderColor='transparent'
          placeholder='Rechercher...'
          autoFocus={true}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      {/* {!isSearchEmpty && <Divider />} */}
    </Flex>
  )
}

export default AlgoliaSearchBox
