import { Divider, Flex, Heading, useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React, { FC } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import FileUploader from '../../../components/inputs/upload/FileUploader'
import Bold from '../../../components/Typo/Bold'
import { updateUserQuery, UpdateUserQueryInputs } from '../../queries/updateUserQuery'
import { getUserQuery } from '../../queries/userQuery'
import FilesTable from './FilesTable'
import { getUserFiles } from './queries/getUserFiles'

const Files: FC<{}> = ({}) => {
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const updateMutation = useMutation((inputs: UpdateUserQueryInputs) => updateUserQuery(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['user', { id: id }])
      queryClient.invalidateQueries(['getFolder', { id: id }])
      successToast({ description: 'Modifications enregistrées' })
    },
  })

  const toast = useToast()
  const files = useQuery(['userFiles', { id }], () => getUserFiles({ id }), {
    refetchOnWindowFocus: true,
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      console.log(data)
    },
  })

  const initialValues = {
    analysisNeedDocsCustomMessage: '',
  }

  return (
    <>
      <Flex direction='column' gap={12}>
        <Flex direction='column' gap={6}>
          <Heading size='lg'>Fichiers: </Heading>
          {/* <Alert bg='white' border='1px solid' borderRadius='8px'> */}
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, formik) => {
              console.log(values)

              updateMutation.mutate({
                analysisNeedDocsCustomMessage: values.analysisNeedDocsCustomMessage,
                id: id,
              })

              // finalizeMutation.mutate(values.needDocsMessage)
            }}
          >
            {(formik) => (
              <>
                <FormikAutoRefresh
                  initialValues={initialValues}
                  data={userQuery.data?.user?.userInformations}
                />
                <Flex direction='column' gap='8px' w='100%'>
                  <Bold>Documents demandés :</Bold>
                  <TextAreaInput w='100%' field='analysisNeedDocsCustomMessage'></TextAreaInput>
                  <Flex gap='8px' w='100%'>
                    {/* <CustomButton variant='outline'>
                      Remplir à partir du call découverte
                    </CustomButton> */}
                    <CustomButton type='submit' onClick={() => formik.handleSubmit()}>
                      Enregistrer
                    </CustomButton>
                  </Flex>
                </Flex>
              </>
            )}
          </Formik>
          {/* </Alert> */}
          <Divider />
          {/* <Alert>Attention, le client a accès à tous ces documents.</Alert> */}
          <FileUploader userId={id || ''} refetchFunction={files.refetch} />
          {userQuery.data?.user && (
            <FilesTable files={files.data || []} user={userQuery.data?.user} />
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default Files
