import React, { useEffect, useState } from 'react'
import Searchkit, { MultiMatchQuery } from '@searchkit/sdk'
import { Button, Flex, IconButton, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon, ViewIcon } from '@chakra-ui/icons'
import { useMutation, useQuery } from 'react-query'
import { getAssetAdById } from './queries/getAssetAdById'
import Map from '../../shared/components/Map'
import { updateUserQuery, UpdateUserQueryInputs } from '../users/queries/updateUserQuery'
import { AxiosError } from 'axios'
import { useCustomToast } from '../../utility/hooks/toats/useCustomToasts'
import { scrapMA } from './queries/scrapMA'
import { getScrapData, ScrapDataCastorusOnly, ScrapDataMAOnly } from './queries/getScrapData'
import CustomTable from '../components/customTable'
import { SingleAssetAdResponse } from './queries/getAssetAds'
import dayjs from 'dayjs'
import MADataDisplay from './Scrap/MADataDisplay'
import CastorusPriceChanges from './Scrap/CastorusPriceChanges'
import CastorusDescription from './Scrap/CastorusDescription'
import MADataDisplayv2 from './Scrap/MADataDisplayv2'

const AssetAdData = () => {
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()
  const { errorToast, successToast } = useCustomToast()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const scrapData = useQuery(['scrapData', { id }], () => getScrapData(id!), {
    refetchInterval: 10000,
  })

  const scrapMAMutation = useMutation(() => scrapMA(id!), {
    onError: (error: AxiosError) => errorToast(error),
    onSuccess: (data) => successToast({ description: 'Scraping en cours...' }),
  })

  if (!assetAd.data?.address) {
    return <>Pas encore d'adresse pour ce bien !</>
  }

  // const MAScrapData: MAData[] = scrapData.data.filter(d => d.type)

  console.log(scrapData.data)

  return (
    <Flex>
      <Flex w='100%' direction='column' gap='32px' padding='12px'>
        <Flex gap='16px'>
          <Button onClick={() => scrapMAMutation.mutate()} colorScheme='green'>
            Lancer le scraping
          </Button>
        </Flex>

        <CastorusDescription
          castorusData={
            scrapData.data?.filter((d) => d?.data.type === 'CASTORUS') as
              | ScrapDataCastorusOnly[]
              | undefined
          }
        />

        <MADataDisplay
          MAData={
            scrapData.data?.filter((d) => d?.data.type === 'MA') as ScrapDataMAOnly[] | undefined
          }
        />
      </Flex>
    </Flex>
  )
}

export default AssetAdData
