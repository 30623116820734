import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'
import { Partner } from './getPartnerQuery'

export interface PartnersListResponse {
  partners: Partner[]
}

export const getPartnersQuery = async (): Promise<PartnersListResponse> => {
  return (await authenticatedGetQuery('/admin/partners')) as Promise<PartnersListResponse>
}
