import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { AsYouType, isValidNumber, parse, parsePhoneNumber } from 'libphonenumber-js'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Countries from './countries.json'
import { Country, SearchOnList } from './SearchOnList'

export type PhoneNumberInputProps = {
  handleOnChange: (arg: string) => void
  onBlur: {
    (e: React.FocusEvent<any>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  value: string
}

export const PhoneNumberInput = ({ handleOnChange, value, onBlur }: PhoneNumberInputProps) => {
  const ref = useRef(null)
  const [number, setNumber] = useState('')
  const [country, setCountry] = useState('+33')
  const [countryFlag, setCountryFlag] = useState(`🇫🇷`)
  const { isOpen, onToggle, onClose } = useDisclosure()

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  useEffect(() => {
    if (number !== '') {
      handleOnChange(`${country}${number}`)
    }
  }, [country, number])

  const onCountryChange = (item: Country) => {
    setCountry(item?.dial_code)
    setCountryFlag(item?.flag)
    handleOnChange('')
    onClose()
  }

  const onPhoneNumberChange = (event: any) => {
    const value = event.target.value
    const parsedNumber = new AsYouType().input(`${country}${value}`)
    if (isValidNumber(parse(parsedNumber))) {
      console.log(parsedNumber)
      // setNumber(value)
      handleOnChange(parsedNumber)
      const parsedPhoneNumber = parsePhoneNumber(value)
      setNumber(parsedPhoneNumber.formatNational())
    } else {
      setNumber(value)
    }
  }

  useEffect(() => {
    if (!value || !isValidNumber(parse(value))) {
      // setNumber(value)
      return
    }
    console.log(value)
    const parsedPhoneNumber = parsePhoneNumber(value)
    setNumber(parsedPhoneNumber.formatNational())
    setCountry(`+${parsedPhoneNumber.countryCallingCode}` || country)
    const localCountry = Countries.find((c) => c.code === parsedPhoneNumber.country)
    setCountryFlag(localCountry?.flag || countryFlag)
  }, [value])

  return (
    <>
      <Box as='section' ref={ref} position='relative'>
        <InputGroup>
          <InputLeftElement width='5em' cursor='pointer' onClick={onToggle} h='100%'>
            <Text as='span' mr={3}>
              {countryFlag}
            </Text>
            {isOpen ? (
              <ChevronUpIcon boxSize={6} color='gray.500' />
            ) : (
              <ChevronDownIcon boxSize={6} color='gray.500' />
            )}
          </InputLeftElement>
          <Input
            size='lg'
            pl='5em'
            type='tel'
            value={number}
            placeholder='0687032312'
            onChange={onPhoneNumberChange}
          />
        </InputGroup>

        {isOpen ? <SearchOnList data={Countries} onChange={onCountryChange} /> : null}
      </Box>
    </>
  )
}
