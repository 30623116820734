interface CreateLeasingProjectErrors {
  assetName?: string
  assetAddress?: string
  assetCity?: string
  assetPostalCode?: string
  assetAcquisitionCost?: string
  rent?: string
  expectedDayForPayments?: string
  futurePrice?: string
  cashbackOnRent?: string
  fundsGroupUUID?: string
}

export const validateCreateLeasingProject = (values: {
  assetName: string
  assetAddress: string
  assetCity: string
  assetPostalCode: number
  assetAcquisitionCost: number
  rent: number
  expectedDayForPayments: number
  futurePrice: number
  cashbackOnRent: number
  fundsGroupUUID: string
}) => {
  const errors: CreateLeasingProjectErrors = {}

  if (!values.assetName) {
    errors.assetName = 'Ce champ est requis'
  }
  if (!values.assetAddress) {
    errors.assetAddress = 'Ce champ est requis'
  }
  if (!values.assetCity) {
    errors.assetCity = 'Ce champ est requis'
  }
  if (!values.assetPostalCode) {
    errors.assetPostalCode = 'Ce champ est requis'
  }
  if (!values.assetAcquisitionCost) {
    errors.assetAcquisitionCost = 'Ce champ est requis'
  }
  if (!values.rent) {
    errors.rent = 'Ce champ est requis'
  }
  if (!values.expectedDayForPayments) {
    errors.expectedDayForPayments = 'Ce champ est requis'
  }
  if (!values.futurePrice) {
    errors.futurePrice = 'Ce champ est requis'
  }
  if (!values.cashbackOnRent) {
    errors.cashbackOnRent = 'Ce champ est requis'
  }
  if (!values.fundsGroupUUID) {
    errors.fundsGroupUUID = 'Ce champ est requis'
  }

  console.log(errors)

  return errors
}
