import { Box, Editable, EditablePreview, EditableTextarea, Flex, Image } from '@chakra-ui/react'
import {
  ClockClockwise,
  DownloadSimple,
  PencilSimple,
  TrashSimple,
  XCircle,
} from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { fileDrawerAtom } from '../../../../atoms/filesAtom'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import {
  getFileNameFromKey,
  getFileType,
  isPDFOrPicture,
  SimpleFileType,
} from '../../../../utility/other'
import Bold from '../../../components/Typo/Bold'
import EditFileNameInput from './EditFileNameInput'
import { updateUserFileQuery, UpdateUserFileQueryInputs } from './queries/updateUserFile'
import { getFileExtension } from '../../../../utility/other'

type Props = {}

const FileDrawer = (props: Props) => {
  const [fileDrawer, setFileDrawer] = useAtom(fileDrawerAtom)
  const [fileType, setFileType] = useState<SimpleFileType>('other')
  const queryClient = useQueryClient()
  const { successToast, errorToast } = useCustomToast()
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState<string | undefined>(undefined)

  const updateMutation = useMutation(
    (updateInputs: UpdateUserFileQueryInputs) => updateUserFileQuery(updateInputs),
    {
      onError: (error: AxiosError, variables, context) => {
        errorToast(error)
        setIsLoading(false)
      },
      onSuccess: (data) => {
        setIsLoading(false)
        queryClient.invalidateQueries(['userFiles'])
        successToast({
          description: 'Document modifié',
        })
      },
    },
  )
  useEffect(() => {
    console.log(fileDrawer?.key)
    if (fileDrawer?.key) {
      setFileType(getFileType(fileDrawer.key))
      setFileName(fileDrawer.name)
    }
  }, [fileDrawer?.key])

  return (
    <Flex
      w='50vw'
      minW='50vw'
      maxW='50vw'
      marginRight={fileDrawer !== undefined ? '0px' : '-50vw'}
      borderLeft='1px solid #EFEFEF'
      transition='all .5s cubic-bezier(.55,0,.1,1)'
      direction='column'
      willChange='margin'
      overflow='hidden'
    >
      <Flex minH='72px' w='100%' justifyContent='flex-end' padding='16px'>
        <Flex
          w='40px'
          h='40px'
          // bg='neutral.border'
          borderRadius='51px'
          justifyContent='center'
          alignItems='center'
          cursor='pointer'
          onClick={() => setFileDrawer(undefined)}
        >
          <XCircle size={32} />
        </Flex>
      </Flex>
      <Flex
        w='100%'
        direction='column'
        alignItems='center'
        justifyContent='center'
        margin='16px 0px'
        gap='16px'
      >
        <Bold width='100%'>
          <Editable
            value={fileName}
            onChange={(e) => {
              setFileName(e)
              console.log('fname', e)
            }}
            onSubmit={() =>
              updateMutation.mutate({
                name: fileName || '',
                id: fileDrawer?.id || '',
              })
            }
          >
            <EditFileNameInput
              fileName={fileName || ''}
              fileExtension={getFileExtension(fileDrawer?.key)}
            />
          </Editable>
        </Bold>
        {/* <Flex
          alignItems='center'
          justifyContent='flex-end'
          h='100%'
          gap='16px'
          color='neutral.grey'
        >
          <Flex
            cursor='pointer'
            borderRadius='50%'
            width='40px'
            height='40px'
            backgroundColor='neutral.border'
            justifyContent='center'
            alignItems='center'
          >
            <DownloadSimple cursor='pointer' size='25px' />
          </Flex>
          <Flex
            cursor='pointer'
            borderRadius='50%'
            width='40px'
            height='40px'
            backgroundColor='neutral.border'
            justifyContent='center'
            alignItems='center'
          >
            <PencilSimple cursor='pointer' size='25px' />
          </Flex>
          <Flex
            cursor='pointer'
            borderRadius='50%'
            width='40px'
            height='40px'
            backgroundColor='neutral.border'
            justifyContent='center'
            alignItems='center'
          >
            <TrashSimple cursor='pointer' size='25px' />
          </Flex>
        </Flex> */}
      </Flex>

      {fileType === 'pdf' && (
        <Box key={fileDrawer?.id} as='iframe' src={fileDrawer?.previewUrl} height='100%' />
      )}
      {fileType === 'image' && (
        <Flex justifyContent='center' alignItems='center'>
          <Image src={fileDrawer?.previewUrl} maxWidth='400px' />
        </Flex>
      )}
      {fileType === 'other' && <p>Unable to preview</p>}
    </Flex>
  )
}

export default FileDrawer
