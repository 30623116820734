import React, { FC, ReactNode } from "react";
import { AccordionItem, AccordionPanel } from "@chakra-ui/react";
import StepButton from "./StepButton";

const StepItem: FC<{
  text: string
  children: ReactNode
  currentStep: number
  totalSteps: number
  current?: boolean
}> = ({ text, children, currentStep, totalSteps, current }) => {
  return (
    <AccordionItem w='100%' border='none'>
      {({ isExpanded }) => (
        <>
          <StepButton
            currentStep={currentStep}
            totalSteps={totalSteps}
            isExpanded={isExpanded}
            text={text}
            current={current}
          />
          <AccordionPanel
            padding={0}
            border='1px solid #EFEFEF'
            borderTop='none'
            borderRadius='0px 0px 8px 8px'
          >
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default StepItem
