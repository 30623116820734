import React, { FC, ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import * as CSS from 'csstype'
import Subtitle from '../../components/Typo/Subtitle'

type styleScheme = {
  icon: {
    color: string
    bg: string
    border?: string
    borderColor?: string
  }
  textColor: string
  border?: string
  borderBottom?: string
  borderColor?: string
  borderRadius?: string
  background?: string
  fontWeight?: string
  textDecoration?: CSS.Property.TextDecoration
}

const StepTodo: FC<{
  text: string
  current?: boolean
  done?: boolean
  rightPart?: ReactNode
  subtitle?: ReactNode
  children?: ReactNode
}> = ({ text, current, done, rightPart, subtitle, children }) => {
  const getStylesScheme = (): styleScheme => {
    if (current) {
      return {
        icon: {
          color: 'HestiaMediumGrey',
          bg: 'HestiaWhite',
          border: '2px solid',
          borderColor: 'HestiaPintGreen',
        },
        textColor: 'black',
        border: '1px solid',
        borderColor: 'HestiaPintGreen',
        background: 'HestiaLightGreen',
        borderRadius: '8px',
        fontWeight: '600',
      }
    } else if (done) {
      return {
        icon: {
          color: 'white',
          bg: 'HestiaPintGreen',
        },
        textColor: 'HestiaMediumGrey',
        borderBottom: '1px solid #EFEFEF',
        textDecoration: 'line-through',
      }
    } else {
      return {
        icon: {
          color: 'HestiaMediumGrey',
          bg: 'HestiaWhite',
          border: '2px solid',
          borderColor: 'HestiaMediumGrey',
        },
        textColor: 'HestiaGrey',
        borderBottom: '1px solid #EFEFEF',
      }
    }
  }

  return (
    <Flex
      w='100%'
      border={getStylesScheme().border}
      borderBottom={getStylesScheme().borderBottom}
      borderColor={getStylesScheme().borderColor || ''}
      background={getStylesScheme().background || ''}
      borderRadius={getStylesScheme().borderRadius || '0px'}
      _last={{
        borderBottom: current ? '' : 'none',
      }}
      _first={{
        borderTop: current ? '' : 'none',
      }}
      direction='column'
      h='100%'
    >
      <Flex direction='column' w='100%' gap='0x' h='100%'>
        <Flex direction='row' justifyContent='space-between' padding='24px'>
          <Flex justifyContent='start' alignItems='center' h='100%' w='100%' gap='24px'>
            <Flex gap='16px' alignItems='center'>
              <Flex
                align='center'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                textAlign='center'
                w='24px'
                h='24px'
                borderRadius='50%'
                color={getStylesScheme().icon.color}
                bg={getStylesScheme().icon.bg}
                border={getStylesScheme().icon.border}
                borderColor={getStylesScheme().icon.borderColor}
              >
                {done && <CheckIcon boxSize='14px' />}
              </Flex>
            </Flex>
            <Flex direction='column'>
              <Subtitle
                textDecoration={getStylesScheme().textDecoration}
                color={getStylesScheme().textColor}
                fontWeight={getStylesScheme().fontWeight}
              >
                {text}
              </Subtitle>
              {subtitle}
            </Flex>
          </Flex>
          <Flex minH='100%' flexGrow='1'>
            {rightPart}
          </Flex>
        </Flex>
      </Flex>
      {current && children && (
        <Flex padding='0px 24px 24px 24px' direction={'column'} gap='24px'>
          {children}
        </Flex>
      )}
    </Flex>
  )
}

export default StepTodo
