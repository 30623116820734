import { BadgeProps, Flex } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import Bold from '../../dashboard/components/Typo/Bold'

export type CustomBadgeProps = {
  variant: 'green' | 'blue' | 'yellow' | 'red' | 'grey'
  icon?: ReactNode
  children: ReactNode
}

const CustomBadge = (props: CustomBadgeProps) => {
  const getVariantProps = (): BadgeProps => {
    const commonProps: BadgeProps = {
      textTransform: 'none',
      fontWeight: '700',
      padding: '4px 6px',
      borderRadius: '4px',
    }

    if (props.variant === 'green') {
      return {
        ...commonProps,
        background: 'brand.green.light',
        color: 'brand.green.primary',
      }
    } else if (props.variant === 'yellow') {
      return {
        ...commonProps,
        background: 'brand.yellow.light',
        color: 'yellow.600',
      }
    } else if (props.variant === 'blue') {
      return {
        ...commonProps,
        background: 'blue.50',
        color: 'blue.600',
      }
    } else if (props.variant === 'red') {
      return {
        ...commonProps,
        background: 'red.50',
        color: 'red.600',
      }
    } else {
      return {
        ...commonProps,
        background: 'neutral.background',
        color: 'neutral.secondary',
      }
    }
  }

  return (
    <Flex {...getVariantProps()} width='fit-content'>
      <Flex gap='4px' alignItems='center' color='inherit'>
        <Bold size='small' color='inherit' whiteSpace='nowrap'>
          {props.icon}
          {props.children}
        </Bold>
      </Flex>
    </Flex>
  )
}

export default CustomBadge
