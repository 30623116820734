import { Divider, Flex, Spinner } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { default as React, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { headerHeightAtom, isFloatingBudgetStickyAtom } from '../atoms/discoveryAtom'
import FloatingCard from '../components/FloatingCard'
import LineItem from '../components/LineItem'
import LineItemDark from '../components/LineItemDark'
import CustomBadge from '../shared/components/CustomBadge'
import { formatIncomeToK, getPrettyNumber } from '../utility/money'
import { MortgageLimitedByEnum } from './commonQueries/getBudgetQuery'
import Bold from './components/Typo/Bold'
import Regular from './components/Typo/Regular'
import { getDiscoverySummary } from './users/user/discovery/queries/DicsoveryQueries'

type Props = {
  isSticky?: boolean
}

const FloatingBudget = (props: Props) => {
  const [isFloatingBudgetSticky, setIsFloatingBudgetSticky] = useAtom(isFloatingBudgetStickyAtom)
  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom)

  const { id: userId } = useParams()

  const summary = useQuery(['discoverySummary', { id: userId }], () =>
    getDiscoverySummary(userId || ''),
  )

  useEffect(() => {
    setIsFloatingBudgetSticky(!!props.isSticky)
  }, [props.isSticky])

  const getLimitedByLabel = (limitedBy: MortgageLimitedByEnum | undefined) => {
    switch (limitedBy) {
      case MortgageLimitedByEnum.DEPOSIT:
        return 'Son apport final doit être à 10%'
      case MortgageLimitedByEnum.DTI:
        return 'Les mensualitées de son futur prêt ne doivent pas dépasser 35% de sa capacité'
      case MortgageLimitedByEnum.MONTHLY_PAYMENTS:
        return 'Il doit pouvoir payer 10% de pénalité entre son épargne et ses mensualitées'
      case MortgageLimitedByEnum.FEES:
        return 'Il doit pouvoir payer les 3% de frais Hestia'
      default:
      case undefined:
        return 'Inconnue...'
    }
  }

  return (
    <Flex
      w='100%'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      h={`calc(100vh - ${headerHeight}px - 24px - 24px)`}
      overflow='scroll'
      gap={6}
    >
      <FloatingCard header='Finances'>
        {summary.isFetching ? (
          <Spinner />
        ) : (
          <>
            <LineItem
              leftSide='Annuel net'
              rightSide={formatIncomeToK(
                (summary.data?.total.incomes.ponderatedMonthlyIncomes || 0) * 12,
              )}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  <LineItemDark
                    leftSide='Annuel non pondéré'
                    rightSide={getPrettyNumber(
                      (summary.data?.total.incomes.totalMonthlyIncomes || 0) * 12,
                    )}
                  />
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Annuel net'
                        rightSide={getPrettyNumber(buyer.incomes.totalMonthlyIncomes * 12)}
                      />
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Annuel net après pondération'
                        rightSide={getPrettyNumber(buyer.incomes.ponderatedMonthlyIncomes * 12)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Mensuel net'
              rightSide={getPrettyNumber(summary.data?.total.incomes.ponderatedMonthlyIncomes || 0)}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  <LineItemDark
                    leftSide='Mensuel non pondéré'
                    rightSide={getPrettyNumber(
                      summary.data?.total.incomes.totalMonthlyIncomes || 0,
                    )}
                  />
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Mensuel net'
                        rightSide={getPrettyNumber(buyer.incomes.totalMonthlyIncomes)}
                      />
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Mensuel net après pondération'
                        rightSide={getPrettyNumber(buyer.incomes.ponderatedMonthlyIncomes)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Mensualité crédits now'
              rightSide={getPrettyNumber(summary.data?.total.expenses.totalMonthlyExpenses)}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Mensualité de charges'
                        rightSide={getPrettyNumber(buyer.expenses.totalMonthlyExpenses)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Mensualité crédits rachat'
              rightSide={getPrettyNumber(
                summary.data?.total.expenses.totalMonthlyExpensesAfterHestia,
              )}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Mensualité de charges'
                        rightSide={getPrettyNumber(buyer.expenses.totalMonthlyExpensesAfterHestia)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
          </>
        )}
      </FloatingCard>
      <FloatingCard header='Apport'>
        {summary.isFetching ? (
          <Spinner />
        ) : (
          <>
            <LineItem
              leftSide='Patrimoine total'
              rightSide={getPrettyNumber(summary.data?.total.patrimony.totalPatrimony)}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Patrimoine dispo maintenant'
                        rightSide={getPrettyNumber(buyer.patrimony.totalPatrimony)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Epargne disponible'
              rightSide={getPrettyNumber(summary.data?.total.patrimony.totalSavingsAvailableNow)}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Patrimoine dispo maintenant'
                        rightSide={getPrettyNumber(buyer.patrimony.totalSavingsAvailableNow)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Gains futurs'
              rightSide={getPrettyNumber(summary.data?.total.patrimony.totalExpectedGains)}
              tooltipContent={
                <Flex flexDirection='column' gap='8px'>
                  {summary.data?.buyers.map((buyer) => (
                    <>
                      <Bold color='white'>
                        Pour {buyer.buyer.firstName} {buyer.buyer.lastName}:
                      </Bold>
                      <LineItemDark
                        key={buyer.buyer.id}
                        leftSide='Patrimoine dispo maintenant'
                        rightSide={getPrettyNumber(buyer.patrimony.totalExpectedGains)}
                      />
                    </>
                  ))}
                </Flex>
              }
            />
            <LineItem
              leftSide='Epargne Hestia mensuelle'
              rightSide={getPrettyNumber(summary.data?.total.budget.monthlySavings)}
            />
            <LineItem
              leftSide='Epargne Hestia finale'
              rightSide={getPrettyNumber(
                (summary.data?.total.budget.monthlySavings || 0) *
                  (summary.data?.total.budget.hypothesis.durationInMonths || 0),
              )}
            />
            <LineItem
              leftSide='Apport total à la fin'
              rightSide={
                <CustomBadge variant={'blue'}>
                  {getPrettyNumber(summary.data?.total.budget.totalDepositAfterFee)}
                </CustomBadge>
              }
            />
          </>
        )}
      </FloatingCard>
      <FloatingCard header='Budget'>
        {summary.isFetching ? (
          <Spinner />
        ) : (
          <>
            <LineItem
              leftSide='Apport final'
              rightSide={getPrettyNumber(summary.data?.total.budget.totalDepositAfterFee)}
            />
            <LineItem
              leftSide='Crédit final'
              rightSide={getPrettyNumber(summary.data?.total.budget.mortgageAfterHestia.canBorrow)}
            />
            <LineItem
              leftSide='Prix de rachat (avec frais de notaire)'
              rightSide={
                <CustomBadge variant={'blue'}>
                  {getPrettyNumber(summary.data?.total.budget.budgetInTheEndWithNotaryFees)}
                </CustomBadge>
              }
            />

            <LineItem
              leftSide='Budget FAI now'
              rightSide={
                <CustomBadge variant={'green'}>
                  {getPrettyNumber(summary.data?.total.budget.budgetNow)}
                </CustomBadge>
              }
              tooltipContent={
                <Regular color='white'>
                  Limité par:{' '}
                  {summary.data?.total.budget
                    ? getLimitedByLabel(summary.data?.total.budget.limitedBy)
                    : ''}
                </Regular>
              }
            />
            {/* <Divider mt={2} mb={2} />
        <LineItem leftSide='Budget max apport' rightSide={'500 000€'} />
        <LineItem leftSide='Budget max endettement' rightSide={'450 000€'} />
        <LineItem leftSide='Budget max frais Hestia' rightSide={'700 000€'} /> */}
          </>
        )}
      </FloatingCard>
      <FloatingCard header='Alertes'>
        {summary.isFetching ? (
          <Spinner />
        ) : (
          <>
            {summary.data?.warnings
              .filter((o) => o.finalizationOnly !== true)
              .map((warning) => (
                <>
                  <LineItem leftSide={warning.label} rightSide={''} />
                  <Divider mt={2} mb={2} />
                </>
              ))}
          </>
        )}
      </FloatingCard>
    </Flex>
  )
}

export default FloatingBudget
