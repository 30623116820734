import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  TextareaProps,
  InputGroup,
  InputRightElement,
  Button,
  Flex,
} from '@chakra-ui/react'
import React from 'react'
import { Formik, FormikValues, useFormikContext } from 'formik'
import InputWrapper, { InputWrapperProps } from './InputWrapper'
import TextInput from './TextInput'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import {
  GenrateMessageInputs,
  generateMessage,
} from '../../../../dashboard/tools/queries/generateMessage'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { rejectionReasons } from '../../../backShared/assetRejectionTypes'

type Props = {
  field: string
  label: string
  brief?: string
  placeHolder?: string
  isRequired?: boolean
}

const AIGeneratedInput = (props: InputWrapperProps & TextareaProps & Props) => {
  const formikParent = useFormikContext<FormikValues>()
  const { errorToast, successToast } = useCustomToast()
  const initialValues = {
    brief: '',
  }

  const generateMessageMutation = useMutation(
    (inputs: GenrateMessageInputs) => generateMessage(inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        successToast({ description: 'Done!' })
        // TODO: Remove
        formikParent.setFieldValue(
          props.field,
          data?.openAIResponse.content.replace("L'équipe Hestia.", ''),
        )
      },
    },
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        console.log(values)
        // const brief = `
        // Voici la raison de refus : ${
        //   rejectionReasons.find((e) => e.value === formikParent.values.rejectionReason)
        //     ?.emailContent
        // }. Voici la note de nos analystes : ${
        //   values.brief
        // } \n Ecris un seul paragraphe qui résume la note de nos analystes en 50 mots maximum. `
        const brief = props.brief
          ? `${props.brief} ${values.brief}`
          : `
        Ecris un court message qui s'affichera sur la plateforme du client qui résume la raison de refus. 
        L'objectif est qu'il propose un nouveau bien qui ne soit pas refusé. 
        Maximum 2 phrases.
        La raison de refus:  ${values.brief}\n
        `
        console.log(brief)
        await generateMessageMutation.mutate({
          brief,
          communicationGuidelines: true,
          useEmojis: false,
          analysisContext: false,
          FAQ: false,
          howItWorks: true,
        })
      }}
    >
      {(formik) => (
        <>
          <InputWrapper
            field={props.field}
            label={props.label}
            isRequired={props.isRequired}
            helperText={props.helperText}
          >
            <Flex alignItems='center' gap='8px'>
              <InputGroup size='md' h='100%'>
                <Input
                  id='brief'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.brief}
                  borderRadius='8px'
                  placeholder='Prompt utilisé par ChatGPT pour générer le message'
                />
              </InputGroup>
              <Button
                disabled={generateMessageMutation.isLoading}
                size='sm'
                type='submit'
                onClick={() => formik.handleSubmit()}
              >
                Générer
              </Button>
            </Flex>

            <Textarea
              minH='200px'
              disabled={generateMessageMutation.isLoading}
              borderRadius='0 0 8px 8px'
              placeholder={props.placeHolder}
              id={props.field}
              onChange={formikParent.handleChange}
              onBlur={formikParent.handleBlur}
              value={formikParent.values[props.field]}
              {...props}
            />
          </InputWrapper>
        </>
      )}
    </Formik>
  )
}

export default AIGeneratedInput
