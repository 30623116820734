import axios from 'axios'
import { handleQueryError } from '../../../../../utility/queries/handleQueryError'

export interface UploadToS3Inputs {
  url: string
  file: File
}

export const uploadToS3 = async (data: UploadToS3Inputs) => {
  return await axios
    .put(data.url, data.file, {
      headers: {
        'Content-Type': data.file.type,
      },
    })
    .then(function (response) {
      console.log(response)
      return response.data
    })
    .catch(function (error) {
      handleQueryError(error)
    })
}
