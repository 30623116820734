import { FormikErrors } from 'formik'
import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import { GetBugetResponse } from '../../commonQueries/getBudgetQuery'
import { QualifyActionValues } from './QualifyAction'

export const validateQualifyAction = (
  values: QualifyActionValues,
  simulatorData: GetBugetResponse | undefined,
) => {
  const errors: FormikErrors<QualifyActionValues> = {}

  if (!values.newStage) {
    errors.newStage = 'Vous devez renseigner une nouvelle étape'
  }
  if (values.newStage === UserProcessStagesEnum.CLOSED_LOST && !values.lostReason) {
    errors.lostReason = 'Vous devez renseigner une raison pour le LOST'
  }

  if (
    values.newStage !== UserProcessStagesEnum.CLOSED_LOST &&
    simulatorData?.isEligible === false
  ) {
    errors.newStage = "Vous ne pouvez pas le qualifier si il n'est pas éligible"
  }
  // if (values.isLost && (!values.lostReason || values.lostReason === '')) {
  //   errors.lostReason = 'Vous devez renseigner une raison pour le LOST'
  // }

  return errors
}
