import React from 'react'
import { Button, Flex, IconButton, TableContainer, useDisclosure, useToast } from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import useDebounce from '../../utility/hooks/useDebounce'
import CustomTable from '../components/customTable'
import { useAtom } from 'jotai'
import { pageAtom, searchAtom } from '../../atoms/usersList'
import { getAdmins, AdminsResponseSingle } from './queries/getAdmins'
import Bold from '../components/Typo/Bold'
import AssetProcessModalWrapper from '../assetAds/v2/AssetProcessModalWrapper'
import EditAdminAction from './actions/EditAdminAction'

type Props = {
  admin: AdminsResponseSingle
}

const AdminsListSingle = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      key={props.admin.id}
      border='1px solid'
      borderRadius='8px'
      borderColor={'#EFEFEF'}
      _hover={{ bg: 'HestiaLightGrey' }}
      cursor='pointer'
      padding='8px'
      gap='8px'
      onClick={() => {
        onOpen()
      }}
      maxW='100%'
      justifyContent='space-between'
    >
      <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
        <EditAdminAction admin={props.admin} />
      </AssetProcessModalWrapper>
      <Bold maxW='100%' w='100%'>
        {props.admin.email}
      </Bold>
    </Flex>
  )
}

export default AdminsListSingle
