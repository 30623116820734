import axios from 'axios'
import { apiUrl } from '../../utility/config'

export interface GetBugetInputs {
  monthlyDebt: number
  monthlyIncome: number
  deposit: number
  loanDuration?: number
  durationInMonths?: number
  debtToIncomeRatio?: number
  postalCode?: string
  expectedGains: number
}
type MortgageDetails = {
  canBorrow: number
  duration: number
  interestRate: number
  monthlyCost: number
  totalInterestsCost: number
}

export type SimulatorHypothesis = {
  savingsAvailable: number
  monthlyIncome: number
  monthlyDebt: number
  optionYears: number
  rentalYield: number
  cashbackPercentage: number
  savingsPercentage: number
  salaryIncrease: number
  requiredDeposit: number
  debtToIncomeRatio: number
  loanDuration: number
  interestRate: number
  assetIncrease: number
  offerDownpayment: number
  postalCode: string
  durationInMonths: number
}

export interface GetBugetResponse {
  budgetNow: number
  budgetInTheEnd: number
  interestRate: number
  interestRateDate: string
  region: string
  mortgageNow: MortgageDetails
  mortgageAfterHestia: MortgageDetails
  savedWithHestia: number
  budgetInTheEndWithNotaryFees: number
  totalDepositAfterFee: number
  hestiaFees: number
  hypothesis: SimulatorHypothesis
  limitedBy: MortgageLimitedByEnum | undefined
  monthlySavings: number
  monthlySavingsWithoutDeposit: number
  notaryFees: number
  isEligible: boolean
  requiredDeposit: number
  initialDeposit: number
}

export enum MortgageLimitedByEnum {
  'DEPOSIT' = 'DEPOSIT',
  'DTI' = 'DTI',
  'FEES' = 'FEES',
  'MONTHLY_PAYMENTS' = 'MONTHLY_PAYMENTS',
}

export const getBudgetQuery = async (inputs: GetBugetInputs): Promise<GetBugetResponse> => {
  return await axios
    .get(`${apiUrl}/budget`, {
      params: inputs,
    })
    .then(function (response) {
      return response.data as GetBugetResponse
    })
    .catch(function (error) {
      throw error
    })
}
