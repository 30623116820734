import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserLostReasonEnum, userLostReasons } from '../../../interfaces/prisma'
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh'
import SelectInput from '../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import { SetUserAsLostPayload, setUserAsLostQuery } from '../queries/setUserAsLostQuery'
import { getUserQuery } from '../queries/userQuery'

type Props = {}

export type SetUserAsLostValues = {
  lostReason: UserLostReasonEnum | undefined
}

const SetUserAsLost = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const initialValues: SetUserAsLostValues = {
    // lostReason: assetAd.data?.lostReason || undefined,
    lostReason: undefined,
  }

  const updateMutation = useMutation(
    (inputs: SetUserAsLostPayload) => setUserAsLostQuery(id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['user', { id }])
        successToast({ description: 'All good' })
      },
    },
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validate={validateSetUserAsLostAction}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(values, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh
              data={userQuery.data?.user.userInformations}
              initialValues={initialValues}
            />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader title='Modifier en Lost' />

              <SelectInput
                field={'lostReason'}
                label={'Raison du LOST'}
                options={userLostReasons}
              />
            </Flex>

            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default SetUserAsLost
