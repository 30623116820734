import { Card, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { Building, BuildingApartment, Link, Phone } from '@phosphor-icons/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getPrettyNumber } from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { getUserQuery } from '../queries/userQuery'

type Props = {}

const UserPartnerInformations = (props: Props) => {
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const navigate = useNavigate()

  return (
    <Card>
      <Flex direction='column' gap={2} padding='16px'>
        <Bold>Partenaire</Bold>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing={2}>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <BuildingApartment />
              <Text as='span'>
                Partenaire:{' '}
                <b>
                  {userQuery.data?.user.partnerUserInformations?.partnerSource?.name || 'inconnu'}
                </b>
              </Text>
            </Flex>
          </Regular>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Building />
              <Text as='span'>
                Agence:{' '}
                <b>
                  {userQuery.data?.user.partnerUserInformations?.agencySource?.name || 'inconnu'}
                </b>
              </Text>
            </Flex>
          </Regular>
        </SimpleGrid>
        <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
          <Flex gap='8px' alignItems='center'>
            <Phone />
            <Text as='span'>
              Contact pro:{' '}
              <b>
                {userQuery.data?.user.partnerUserInformations?.personSource?.firstName}{' '}
                {userQuery.data?.user.partnerUserInformations?.personSource?.lastName}
              </b>
            </Text>
          </Flex>
        </Regular>
        <Divider />
        <Bold>Annonce</Bold>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing={2}>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Text as='span'>
                Titre: <b>{userQuery.data?.user.partnerUserInformations?.adTitle || 'inconnu'}</b>
              </Text>
            </Flex>
          </Regular>
          <Regular
            color='HestiaGrey'
            fontSize='16px'
            whiteSpace='nowrap'
            cursor='pointer'
            onClick={() =>
              window.open(
                userQuery.data?.user.partnerUserInformations?.adUrl || 'inconnu',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            <Flex gap='8px' alignItems='center'>
              <Link />
              <Text as='span'>
                <b>Lien de l'annonce</b>
              </Text>
            </Flex>
          </Regular>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Text as='span'>
                Prix:{' '}
                <b>
                  {getPrettyNumber(userQuery.data?.user.partnerUserInformations?.adPrice || 0, 0)}
                </b>
              </Text>
            </Flex>
          </Regular>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Text as='span'>
                DPE:{' '}
                <b>
                  {userQuery.data?.user.partnerUserInformations?.adEnergyPerformanceCertificate ||
                    'inconnu'}
                </b>
              </Text>
            </Flex>
          </Regular>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Text as='span'>
                <b>{userQuery.data?.user.partnerUserInformations?.adRooms || 0} pièces</b>
              </Text>
            </Flex>
          </Regular>
          <Regular color='HestiaGrey' fontSize='16px' whiteSpace='nowrap'>
            <Flex gap='8px' alignItems='center'>
              <Text as='span'>
                <b>{userQuery.data?.user.partnerUserInformations?.adSize || 0} m2</b>
              </Text>
            </Flex>
          </Regular>
        </SimpleGrid>

        {/* <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiCoinStack />
                <Text as='span'>{getPrettyNumber(budget?.simulatorData?.budgetNow, 0, true)}</Text>
              </Flex>
            </Regular> */}
      </Flex>
    </Card>
  )
}

export default UserPartnerInformations
