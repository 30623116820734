import React, { FC, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { AiOutlineRight } from 'react-icons/ai'
import { nanoid } from 'nanoid'
import FolderSingleFileUploader from './FolderSingleFileUploader'
import Bold from '../../Typo/Bold'
import { useFormikContext } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useAuth } from '../../../../auth/AuthProvider'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../CustomButton'
import { Plus } from '@phosphor-icons/react'

const FileUploader: FC<{
  userId: string
  refetchFunction?: () => void
}> = ({ refetchFunction, userId }) => {
  const [files, setFiles] = useState<File[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'success' | 'error'>('idle')
  const authContext = useAuth()
  const { errorToast, successToast } = useCustomToast()

  const onDrop = (acceptedFiles: File[]) => {
    console.log(files, acceptedFiles)
    setFiles([...files, ...acceptedFiles])
    // Here, you might want to also handle the upload to a server
    // Once upload is complete, you can setUploadStatus to 'success' or 'error' based on the result
  }

  useEffect(() => {
    if (files.length > 0) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [files])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/zip': [],
    },
    maxFiles: 50,
    multiple: true,
  })

  return (
    <Flex gap='0px' direction='column' w='100%'>
      {/* <Flex
        p='8px 12px'
        cursor={'pointer'}
        bg='white'
        // transition='background-color 0.2s ease'
        borderRadius='8px'
        justifyContent='space-between'
        alignItems='center'
        gap='8px'
        border='1px solid'
        borderColor='HestiaForestGreen'
        {...getRootProps()}
        w='100%'
        _first={{ borderRadius: '8px 8px 0px 0px' }}
        color='HestiaMediumGrey'
      >
        <input {...getInputProps()} />
        <Bold color='HestiaForestGreen'>Ajouter un document</Bold>
        <AiOutlineRight size='20px' />
      </Flex> */}
      <Modal isOpen={isLoading} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent background='brand.green.light' width='334px' height='240px'>
          <Flex
            h='100%'
            flexDirection='column'
            gap='16px'
            justifyContent='center'
            alignItems='center'
          >
            <Spinner color='brand.green.primary' size='xl' />
            <Bold size='large'>Upload en cours...</Bold>
          </Flex>
        </ModalContent>
      </Modal>
      <Flex justifyContent='space-between'>
        <Flex>Documents</Flex>
        <CustomButton variant='primary' leftIcon={<Plus />} {...getRootProps()}>
          <input {...getInputProps()} />
          Ajouter un document
        </CustomButton>
      </Flex>

      {files.map((file, index) => (
        <FolderSingleFileUploader
          key={index}
          callbackWhenUploaded={() => {
            successToast({ description: 'Fichier uploadé!' })
            setFiles([...files.filter((f) => f !== file)])
            setUploadStatus('success')
            refetchFunction && refetchFunction()
          }}
          file={file}
          id={nanoid()}
          userId={userId}
        />
      ))}
    </Flex>
  )
}

export default FileUploader
