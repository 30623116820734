export enum DiscoveryContextBlocker {
  PROPERTY_SALE = 'PROPERTY_SALE',
  CLIENT_AGE = 'CLIENT_AGE',
  TRIAL_PERIOD = 'TRIAL_PERIOD',
  NO_PERMANENT_CONTRACT = 'NO_PERMANENT_CONTRACT',
  CURRENT_CONSUMER_LOAN = 'CURRENT_CONSUMER_LOAN',
  YOUNG_ENTREPRENEUR = 'YOUNG_ENTREPRENEUR',
  ONGOING_DIVORCE = 'ONGOING_DIVORCE',
  APPROXIMATE_ACCOUNT_MANAGEMENT = 'APPROXIMATE_ACCOUNT_MANAGEMENT',
  LACK_OF_DOWN_PAYMENT = 'LACK_OF_DOWN_PAYMENT',
  OTHER = 'OTHER',
}

export const discoveryContextBlockers: {
  value: DiscoveryContextBlocker
  label: string
}[] = [
  { value: DiscoveryContextBlocker.PROPERTY_SALE, label: 'Vente de propriété' },
  { value: DiscoveryContextBlocker.CLIENT_AGE, label: 'Âge du client' },
  { value: DiscoveryContextBlocker.TRIAL_PERIOD, label: "Période d'essai" },
  { value: DiscoveryContextBlocker.NO_PERMANENT_CONTRACT, label: 'Absence de contrat permanent' },
  {
    value: DiscoveryContextBlocker.CURRENT_CONSUMER_LOAN,
    label: 'Crédit à la consommation en cours',
  },
  { value: DiscoveryContextBlocker.YOUNG_ENTREPRENEUR, label: 'Jeune entrepreneur' },
  { value: DiscoveryContextBlocker.ONGOING_DIVORCE, label: 'Divorce en cours' },
  {
    value: DiscoveryContextBlocker.APPROXIMATE_ACCOUNT_MANAGEMENT,
    label: 'Gestion approximative du compte',
  },
  { value: DiscoveryContextBlocker.LACK_OF_DOWN_PAYMENT, label: "Manque d'apport" },
  { value: DiscoveryContextBlocker.OTHER, label: 'Autre' },
]

export enum DiscoveryContextMotivation {
  FAMILY_EXPANSION = 'FAMILY_EXPANSION',
  FAMILY_REDUCTION = 'FAMILY_REDUCTION',
  PROFESSIONAL_PROJECT = 'PROFESSIONAL_PROJECT',
  DESIRE_TO_MOVE = 'DESIRE_TO_MOVE',
  BECOME_OWNER = 'BECOME_OWNER',
  REGION_CHANGE = 'REGION_CHANGE',
  OTHER = 'OTHER',
}
export const discoveryContextMotivations: {
  value: DiscoveryContextMotivation
  label: string
}[] = [
  { value: DiscoveryContextMotivation.FAMILY_EXPANSION, label: 'Expansion familiale' },
  { value: DiscoveryContextMotivation.FAMILY_REDUCTION, label: 'Réduction familiale' },
  { value: DiscoveryContextMotivation.PROFESSIONAL_PROJECT, label: 'Projet professionnel' },
  { value: DiscoveryContextMotivation.DESIRE_TO_MOVE, label: 'Désir de déménager' },
  { value: DiscoveryContextMotivation.BECOME_OWNER, label: 'Devenir propriétaire' },
  { value: DiscoveryContextMotivation.REGION_CHANGE, label: 'Changement de région' },
  { value: DiscoveryContextMotivation.OTHER, label: 'Autre' },
]

export const getLabel = (
  array: { value: string; label: string }[],
  value?: string | undefined | null,
) => {
  return array.find((item) => item.value === value)?.label
}

export enum DiscoveryBuyerPersonalSituation {
  SINGLE = 'SINGLE',
  PARTNERED = 'PARTNERED',
  CIVIL_UNION = 'CIVIL_UNION',
  MARRIED_COMMUNITY = 'MARRIED_COMMUNITY',
  MARRIED_SEPARATION = 'MARRIED_SEPARATION',
  DIVORCED = 'DIVORCED',
}

export const discoveryBuyerPersonalSituations: {
  value: DiscoveryBuyerPersonalSituation
  label: string
}[] = [
  { value: DiscoveryBuyerPersonalSituation.SINGLE, label: 'Célibataire' },
  { value: DiscoveryBuyerPersonalSituation.PARTNERED, label: 'En couple' },
  { value: DiscoveryBuyerPersonalSituation.CIVIL_UNION, label: 'Pacsé(e)' },
  { value: DiscoveryBuyerPersonalSituation.MARRIED_COMMUNITY, label: 'Marié(e) - communauté' },
  {
    value: DiscoveryBuyerPersonalSituation.MARRIED_SEPARATION,
    label: 'Marié(e) - séparation des biens',
  },
  { value: DiscoveryBuyerPersonalSituation.DIVORCED, label: 'Divorcé(e)' },
]

export enum DiscoveryBuyerHousingSituation {
  TENANT = 'TENANT',
  OWNER = 'OWNER',
  HOSTED = 'HOSTED',
}

export const discoveryBuyerHousingSituations: {
  value: DiscoveryBuyerHousingSituation
  label: string
}[] = [
  { value: DiscoveryBuyerHousingSituation.TENANT, label: 'Locataire' },
  { value: DiscoveryBuyerHousingSituation.OWNER, label: 'Propriétaire' },
  { value: DiscoveryBuyerHousingSituation.HOSTED, label: 'Hébergé(e)' },
]

export enum DiscoveryIncomeType {
  JOB = 'JOB',
  INDEPENDANT = 'INDEPENDANT',
  RENTAL = 'RENTAL',
  DIVIDENDS = 'DIVIDENDS',
  PENSION = 'PENSION',
  ANNUITY = 'ANNUITY',
  BONUS_INTEREST = 'BONUS_INTEREST',
  OTHER = 'OTHER',
}

export enum ProfessionalStatusEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  INDEPENDANT = 'INDEPENDANT',
}

export enum ContractTypeEnum {
  CDI = 'CDI',
  CDD = 'CDD',
  TEMPORARY_CONTRACT = 'TEMPORARY_CONTRACT',
}

export enum ExpenseTypeEnum {
  REAL_ESTATE_LOAN = 'REAL_ESTATE_LOAN',
  LOAN = 'LOAN',
  AUTO_LEASING = 'AUTO_LEASING',
  OTHER = 'OTHER',
}

export enum RealEstateLoanTypeEnum {
  PRIMARY_RESIDENCE = 'PRIMARY_RESIDENCE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  INVESTMENT = 'INVESTMENT',
}

export enum LoanPurposeEnum {
  WORK = 'WORK',
  CAR = 'CAR',
  TREASURY = 'TREASURY',
  OTHER = 'OTHER',
}

export enum PatrimonyTypeEnum {
  FINANCIAL_SAVINGS = 'FINANCIAL_SAVINGS',
  REAL_ESTATE = 'REAL_ESTATE',
  OTHER_ASSETS = 'OTHER_ASSETS',
}

export enum FinancialSavingsTypeEnum {
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
  STOCKS = 'STOCKS',
  OTHER = 'OTHER',
}

export enum RealEstateTypeEnum {
  PRIMARY_RESIDENCE = 'PRIMARY_RESIDENCE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  INVESTMENT_PROPERTY = 'INVESTMENT_PROPERTY',
}

export enum OtherAssetTypeEnum {
  WATCHES = 'WATCHES',
  CARS = 'CARS',
  JEWELRY = 'JEWELRY',
  BOATS = 'BOATS',
  OTHER_ABROAD = 'OTHER_ABROAD',
}

export const discoveryIncomeTypes: {
  value: DiscoveryIncomeType
  label: string
}[] = [
  { value: DiscoveryIncomeType.JOB, label: 'Salaire' },
  { value: DiscoveryIncomeType.INDEPENDANT, label: 'Indépendant' },
  { value: DiscoveryIncomeType.RENTAL, label: 'Revenus locatifs' },
  { value: DiscoveryIncomeType.DIVIDENDS, label: 'Dividendes' },
  { value: DiscoveryIncomeType.PENSION, label: 'Pension' },
  { value: DiscoveryIncomeType.ANNUITY, label: 'Rente' },
  { value: DiscoveryIncomeType.BONUS_INTEREST, label: 'Bonus et intérêts' },
  { value: DiscoveryIncomeType.OTHER, label: 'Autre' },
]

export const professionalStatuses: {
  value: ProfessionalStatusEnum
  label: string
}[] = [
  { value: ProfessionalStatusEnum.PRIVATE, label: 'Privé' },
  { value: ProfessionalStatusEnum.PUBLIC, label: 'Public' },
  { value: ProfessionalStatusEnum.INDEPENDANT, label: 'Indépendant' },
]

export const contractTypes: {
  value: ContractTypeEnum
  label: string
}[] = [
  { value: ContractTypeEnum.CDI, label: 'CDI' },
  { value: ContractTypeEnum.CDD, label: 'CDD' },
  { value: ContractTypeEnum.TEMPORARY_CONTRACT, label: 'Contrat temporaire' },
]

export const expenseTypes: {
  value: ExpenseTypeEnum
  label: string
}[] = [
  { value: ExpenseTypeEnum.REAL_ESTATE_LOAN, label: 'Prêt immobilier' },
  { value: ExpenseTypeEnum.LOAN, label: 'Prêt personnel' },
  { value: ExpenseTypeEnum.AUTO_LEASING, label: 'Location auto' },
  { value: ExpenseTypeEnum.OTHER, label: 'Autre' },
]

export const realEstateLoanTypes: {
  value: RealEstateLoanTypeEnum
  label: string
}[] = [
  { value: RealEstateLoanTypeEnum.PRIMARY_RESIDENCE, label: 'Résidence principale' },
  { value: RealEstateLoanTypeEnum.SECONDARY_RESIDENCE, label: 'Résidence secondaire' },
  { value: RealEstateLoanTypeEnum.INVESTMENT, label: 'Investissement locatif' },
]

export const loanPurposes: {
  value: LoanPurposeEnum
  label: string
}[] = [
  { value: LoanPurposeEnum.WORK, label: 'Travaux' },
  { value: LoanPurposeEnum.CAR, label: 'Voiture' },
  { value: LoanPurposeEnum.TREASURY, label: 'Trésorerie' },
  { value: LoanPurposeEnum.OTHER, label: 'Autre' },
]

export const patrimonyTypes: {
  value: PatrimonyTypeEnum
  label: string
}[] = [
  { value: PatrimonyTypeEnum.FINANCIAL_SAVINGS, label: 'Épargne financière' },
  { value: PatrimonyTypeEnum.REAL_ESTATE, label: 'Immobilier' },
  { value: PatrimonyTypeEnum.OTHER_ASSETS, label: 'Autres actifs' },
]

export const financialSavingsTypes: {
  value: FinancialSavingsTypeEnum
  label: string
}[] = [
  { value: FinancialSavingsTypeEnum.LIFE_INSURANCE, label: 'Assurance-vie' },
  { value: FinancialSavingsTypeEnum.SAVINGS_ACCOUNT, label: 'Compte épargne' },
  { value: FinancialSavingsTypeEnum.STOCKS, label: 'Actions' },
  { value: FinancialSavingsTypeEnum.OTHER, label: 'Autre' },
]

export const realEstateTypes: {
  value: RealEstateTypeEnum
  label: string
}[] = [
  { value: RealEstateTypeEnum.PRIMARY_RESIDENCE, label: 'Résidence principale' },
  { value: RealEstateTypeEnum.SECONDARY_RESIDENCE, label: 'Résidence secondaire' },
  { value: RealEstateTypeEnum.INVESTMENT_PROPERTY, label: "Propriété d'investissement" },
]

export const otherAssetTypes: {
  value: OtherAssetTypeEnum
  label: string
}[] = [
  { value: OtherAssetTypeEnum.WATCHES, label: 'Montres' },
  { value: OtherAssetTypeEnum.CARS, label: 'Voitures' },
  { value: OtherAssetTypeEnum.JEWELRY, label: 'Bijoux' },
  { value: OtherAssetTypeEnum.BOATS, label: 'Bateaux' },
  { value: OtherAssetTypeEnum.OTHER_ABROAD, label: "Autre à l'étranger" },
]

export type DiscoveryBuyer = {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  firstName: string | null
  lastName: string | null
  age: number | null
  nationality: string | null
  personalSituation: DiscoveryBuyerPersonalSituation | null
  housingSituation: DiscoveryBuyerHousingSituation | null
  housingLocation: string | null
  rent: number | null
  mortgage: boolean | null
  planToSell: boolean | null
  host: string | null
}

export type DiscoveryChild = {
  id: string
  createdAt: Date
  updatedAt: Date
  buyerId: string
  name: string | null
  age: number | null
}

export type DiscoveryIncome = {
  id: string
  createdAt: Date
  updatedAt: Date
  buyerId: string
  incomeType: DiscoveryIncomeType | null
  professionalStatus: ProfessionalStatusEnum | null
  contractType: ContractTypeEnum | null
  name: string | null
  employerName: string | null
  startDate: Date | null
  trialPeriod: boolean | null
  annualNetIncome: number | null
  annualNetBonus: number | null
  endDate: Date | null
  businessType: string | null
  details: string | null
}

export type DiscoveryExpense = {
  id: string
  createdAt: Date
  updatedAt: Date
  buyerId: string
  name: string | null
  monthlyPayment: number | null
  expenseType: ExpenseTypeEnum | null
  crd: number | null
  endDate: Date | null
  realEstateLoanType: RealEstateLoanTypeEnum | null
  loanPurpose: LoanPurposeEnum | null
  details: string | null
}

export type DiscoveryPatrimony = {
  id: string
  createdAt: Date
  updatedAt: Date
  buyerId: string
  patrimonyType: PatrimonyTypeEnum | null
  value: number | null
  financialSavingsType: FinancialSavingsTypeEnum | null
  housingLocation: string | null
  rent: number | null
  mortgage: boolean | null
  planToSell: boolean | null
  realEstateType: RealEstateTypeEnum | null
  otherAssetType: OtherAssetTypeEnum | null
  locationAbroad: boolean | null
}

export type DiscoveryMetaInformations = {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  isFinalized: boolean | null
  notes: string | null
}
