import { authenticatedPutQuery } from '../../../utility/queries/authenticatedPutQuery'

export interface UpdateLeasingProjectInputs {
  rentAmount: number
  expectedDayForPayments: number
  futurePrice: number
  cashbackOnRent: number
  id: string
}

export const updateLeasingProject = async (body: UpdateLeasingProjectInputs) => {
  return await authenticatedPutQuery(`/admin/leasingProjects/${body.id}`, {
    ...body,
    id: undefined,
  }).then((data) => data.data)
}
