import { Flex, Tooltip } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import Regular from '../dashboard/components/Typo/Regular'

type Props = {
  leftSide: ReactNode | string
  rightSide: ReactNode | string
  tooltipContent?: ReactNode | undefined
}

const LineItemDark = (props: Props) => {
  return (
    <Tooltip size='lg' placement='auto' label={props.tooltipContent} hasArrow>
      <Flex w='100%' h='100%' justifyContent='space-between' alignItems='center' gap='16px'>
        <Regular size='small' lineHeight={1.5} color='white'>
          {props.leftSide}
        </Regular>
        <Regular size='small' color='white' whiteSpace='nowrap'>
          {props.rightSide}
        </Regular>
      </Flex>
    </Tooltip>
  )
}

export default LineItemDark
