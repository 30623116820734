import React from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import DashboardLayout from '../DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Header from '../components/Typo/Header'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { createBusinessAccount, CreateBusinessAccountInputs } from './queries/createBusinessAccount'

const CreateProAccount = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const createMutation = useMutation(
    (inputs: CreateBusinessAccountInputs) => createBusinessAccount(inputs),
    {
      onError: (error: AxiosError) => {
        toast({
          title: 'Erreur:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        console.log(data)
        toast({
          title: 'Succès!:',
          description: 'All good',
          status: 'success',
          position: 'top-right',
        })
      },
    },
  )

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      createMutation.mutate({ email: values.email })
    },
  })

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <form onSubmit={formik.handleSubmit}>
        <Flex direction='column' gap='48px' mt='48px'>
          <Flex direction='column' gap='24px' width={['100%', '400px']}>
            <Header>Créer un compte Pro </Header>
            <Alert status='info' maxWidth={['100%', '800px']}>
              <AlertIcon />
              Créer un compte déclenche l'envoi d'e-mails
            </Alert>
            <FormControl>
              <FormLabel htmlFor='name'>Email</FormLabel>
              <Input
                placeholder='pro@orpi.com'
                id='email'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                autoComplete='given-name'
              />
            </FormControl>
          </Flex>
          <Button
            disabled={createMutation.isLoading}
            colorScheme='green'
            bg='HestiaForestGreen'
            type='submit'
            w='fit-content'
          >
            Créer un compte
          </Button>
        </Flex>
      </form>
    </DashboardLayout>
  )
}

export default CreateProAccount
