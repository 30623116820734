import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import { DiscoveryContextBlocker, DiscoveryContextMotivation } from '../discovery.types'

export type DiscoveryContextPayload = {
  motivation?: DiscoveryContextMotivation | null
  currentBlockers?: DiscoveryContextBlocker | null
  leaseDurationMonths?: number | null
  projectDeadlineMonths?: number | null
  desiredBudget?: number | null
  desiredMonthlySavings?: number | null
  desiredFutureMonthlyPayment?: number | null
}

export const updateDiscoveryContext = async (userId: string, payload: DiscoveryContextPayload) => {
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/context`,
    payload,
  )) as Promise<void>
}
