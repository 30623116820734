import React, { FC, useEffect } from 'react'
import { AsyncSelect } from 'chakra-react-select'
import { useDebouncedCallback } from 'use-debounce'
import AutocompletePrediction = google.maps.places.AutocompletePrediction

export interface GoogleAddressOutput {
  label: string
  value: AutocompletePrediction
}

const GoogleAddressInput: FC<{
  place: GoogleAddressOutput | undefined
  setPlace: (place: GoogleAddressOutput | undefined) => void
  defaultValue?: {
    placeId?: string
    formattedAddress?: string
  }
}> = ({ place, setPlace, defaultValue }) => {
  useEffect(() => {
    console.log(place, defaultValue)
  }, [place, defaultValue])

  useEffect(() => {
    if (defaultValue) {
      setPlace({
        label: defaultValue.formattedAddress || '',
        value: {
          place_id: defaultValue.placeId || '',
          description: defaultValue.formattedAddress || '',
          matched_substrings: [],
          structured_formatting: {
            main_text: defaultValue.formattedAddress || '',
            secondary_text: '',
            main_text_matched_substrings: [],
          },
          terms: [],
          types: [],
        },
      })
    }
  }, [])

  const debouncedFetchSuggestions = useDebouncedCallback((inputValue, callback) => {
    new window.google.maps.places.AutocompleteService().getPlacePredictions(
      { input: inputValue, componentRestrictions: { country: 'fr' }, types: ['address'] },
      (suggestions) => {
        callback(
          (suggestions || []).map((suggestion) => ({
            label: `${suggestion.structured_formatting.main_text} ${suggestion.structured_formatting.secondary_text}`,
            value: suggestion,
          })),
        )
      },
    )
  }, 500)

  return (
    <>
      <AsyncSelect
        value={place}
        onChange={(currentValue) => {
          currentValue ? setPlace(currentValue) : setPlace(undefined)
        }}
        className='select-container'
        name='address'
        placeholder='Adresse du logement'
        loadOptions={debouncedFetchSuggestions}
      />
    </>
  )
}

export default GoogleAddressInput
