import { AccountStateEnum, FolderStatusEnum } from '../../../interfaces/user'
import { authenticatedPutQuery } from '../../../utility/queries/authenticatedPutQuery'

export interface UpdateUserQueryInputs {
  id?: string
  accountState?: AccountStateEnum
  monthlyIncome?: number
  monthlyIncomeCobuyer?: number
  monthlyDebt?: number
  expectedGains?: number
  deposit?: number
  loanDuration?: number
  durationInMonths?: number
  debtToIncomeRatio?: number
  showFolder?: boolean
  deleted?: boolean
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  folderStatus?: FolderStatusEnum
  customBudgetOverride?: number
  analysisNeedDocsCustomMessage?: string
}

export interface UserResponseSingleUser {
  id: string
  email: string
  firstName: string
  lastName: string
  phone: string
  country: string
  verified: string
  userInformations: {
    accountState: string
    monthlyRent: number
    monthlyIncome: number
    monthlyDebt: number
    deposit: number
    postalCodeWanted: string
    contractType: string
    employmentSector: string
    publicStatus: string
    dateRange: string
    cityWanted: string
    trialPeriod: boolean
    managerialPosition: boolean
    folderStatus: FolderStatusEnum | undefined
  }
}

export interface UsersResponse {
  user: UserResponseSingleUser
}

export const updateUserQuery = async <usersResponse>(updateUserQuery: UpdateUserQueryInputs) => {
  return await authenticatedPutQuery(`/admin/users/${updateUserQuery.id}`, {
    ...updateUserQuery,
    id: undefined,
  })
}
// await (await fetch("http://localhost:3004/users")).json();
