import axios from 'axios'
import { apiUrl } from '../../utility/config'

export interface LoginQueryInputs {
  googleToken: string
}

export interface SignupQueryResponse {
  tokenData: {
    expiresIn: number
    token: string
  }
}

export const loginQuery = async <Promise>(loginQueryInputs: LoginQueryInputs) => {
  return await axios
    .post<SignupQueryResponse>(apiUrl + '/admin/auth/login', loginQueryInputs)
    .then(function (response) {
      console.log(response)
      return response.data
    })
    .catch(function (error) {
      if (error.response.data.message) {
        throw new Error(error.response.data.message)
      } else {
        throw new Error(error.message)
      }
    })
}
// await (await fetch("http://localhost:3004/users")).json();
