import { FormikErrors } from 'formik'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import { isNotionUrl } from '../../../../utility/urls'
import { AnalysisActionValues } from '../actions/AnalysisAction'
import { SetAsLostValues } from '../actions/SetAsLostAction'

export const validateSetAsLostAction = (values: SetAsLostValues) => {
  const errors: FormikErrors<SetAsLostValues> = {}

  if (values.isLost && (!values.lostReason || values.lostReason === '')) {
    errors.lostReason = 'Vous devez renseigner une raison pour le LOST'
  }

  return errors
}
