import React, { FC, ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react'

const LargeBold: FC<
  TextProps & {
    children?: ReactNode
    color?: string
  }
> = ({ children, color = '#33333', ...props }) => {
  return (
    <Text
      color={color}
      maxWidth={['100%', '600px']}
      fontSize='20px'
      fontWeight='600'
      fontFamily='HKGroteskMedium'
      lineHeight='26px'
      {...props}
    >
      {children}
    </Text>
  )
}

export default LargeBold
