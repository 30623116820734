import _ from 'lodash'
import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import {
  DiscoveryExpense,
  ExpenseTypeEnum,
  LoanPurposeEnum,
  RealEstateLoanTypeEnum,
} from '../discovery.types'

export type UpdateDiscoveryBuyerExpensePayload = {
  id: string
  name: string | null
  monthlyPayment: number | null
  expenseType: ExpenseTypeEnum | null
  crd: number | null
  endDate: Date | null
  realEstateLoanType: RealEstateLoanTypeEnum | null
  loanPurpose: LoanPurposeEnum | null
  details: string | null
}

export const getDiscoveryBuyerExpenses = async (userId: string, buyerId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/expenses`,
  )) as Promise<DiscoveryExpense[]>
}

export const createDiscoveryBuyerExpense = async (userId: string, buyerId: string) => {
  return (await authenticatedPostQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/expenses`,
  )) as Promise<void>
}

export const updateDiscoveryBuyerExpense = async (
  userId: string,
  buyerId: string,
  payload: UpdateDiscoveryBuyerExpensePayload,
) => {
  const finalPayload = _.omit(payload, 'id')
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/expenses/${payload.id}`,
    finalPayload,
  )) as Promise<void>
}

export const deleteDiscoveryBuyerExpense = async (
  userId: string,
  buyerId: string,
  expenseId: string,
) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/expenses/${expenseId}`,
  )) as Promise<void>
}
