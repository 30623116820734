import { Divider, Flex } from '@chakra-ui/react'
import { Plus } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomCard from '../../../../components/CustomCard'
import {
  expenseInitialValues,
  getExpenseFields,
  getExpenseItems,
  getExpenseTitle,
} from '../../../../utility/discovery/discoveryFields.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import CustomButton from '../../../components/CustomButton'
import { DiscoveryBuyer } from './discovery.types'
import DiscoveryLineItem from './DiscoveryLineItem'
import {
  createDiscoveryBuyerExpense,
  deleteDiscoveryBuyerExpense,
  getDiscoveryBuyerExpenses,
  updateDiscoveryBuyerExpense,
  UpdateDiscoveryBuyerExpensePayload,
} from './queries/DiscoveryBuyerExpenseQueries'

type Props = {
  buyer?: DiscoveryBuyer | undefined
}

const DiscoveryExpense = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const currentData = useQuery(
    ['discoveryBuyerExpenses', { id: userId, buyerId: props.buyer?.id }],
    () => getDiscoveryBuyerExpenses(userId || '', props.buyer?.id || ''),
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryBuyerExpensePayload) =>
      updateDiscoveryBuyerExpense(userId || '', props.buyer?.id || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerExpense'])
        queryClient.invalidateQueries(['discoveryBuyerExpenses'])
        successToast({ description: 'Charge ajoutée' })
      },
    },
  )

  const deleteMutation = useMutation(
    ({ id }: { id: string }) =>
      deleteDiscoveryBuyerExpense(userId || '', props.buyer?.id || '', id),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerExpense'])
        queryClient.invalidateQueries(['discoveryBuyerExpenses'])
        successToast({ description: 'Charge supprimée' })
      },
    },
  )

  const createMutation = useMutation(
    () => createDiscoveryBuyerExpense(userId || '', props.buyer?.id || ''),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryBuyerExpense'])
        queryClient.invalidateQueries(['discoveryBuyerExpenses'])
        successToast({ description: 'Charge ajoutée' })
      },
    },
  )

  console.log(currentData.data)

  return (
    <CustomCard light title={`${props.buyer?.firstName} ${props.buyer?.lastName}`}>
      <Flex flexDirection='column' gap='16px' w='100%'>
        {currentData.data?.map((expense) => (
          <>
            <DiscoveryLineItem
              initialValues={expenseInitialValues}
              getFieldsFunction={getExpenseFields}
              title={getExpenseTitle(expense) || 'Charge'}
              getItemsFunction={getExpenseItems}
              updateMutation={updateMutation}
              currentdata={expense}
              canDelete
              deleteMutation={deleteMutation}
            />
            <Divider />
          </>
        ))}

        <CustomButton
          variant='outline'
          leftIconComponent={Plus}
          onClick={() => createMutation.mutate()}
        >
          Ajouter une charge
        </CustomButton>
      </Flex>
    </CustomCard>
  )
}

export default DiscoveryExpense
