import React, { FC } from 'react'
import { Spinner } from '@chakra-ui/react'
import HelperMedium from '../../dashboard/components/Typo/HelperMedium'

const Loading: FC = () => {
  return (
    <>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='HestiaForestGreen'
        size='xl'
      />
      <HelperMedium>Chargement...</HelperMedium>
    </>
  )
}

export default Loading
