import { TeamsEnum } from './assetProcessSteps'

export enum AssetProcessStagesEnum {
  'TO_PROCESS' = 'TO_PROCESS',
  'MARKET_VALUE_COMPLETED' = 'MARKET_VALUE_COMPLETED',
  'IN_NEGOTIATION' = 'IN_NEGOTIATION',
  'AGREEMENT_FOUND' = 'AGREEMENT_FOUND',
  'CLOSED_WON' = 'CLOSED_WON',
  'CLOSED_LOST' = 'CLOSED_LOST',
}

export interface AssetProcessStage {
  label: string
  team: TeamsEnum
}

export const assetProcessStages: Record<AssetProcessStagesEnum, AssetProcessStage> = {
  [AssetProcessStagesEnum.TO_PROCESS]: {
    label: 'À traiter',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStagesEnum.MARKET_VALUE_COMPLETED]: {
    label: 'Valeur vénale complétée',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStagesEnum.IN_NEGOTIATION]: {
    label: 'En négociation',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStagesEnum.AGREEMENT_FOUND]: {
    label: 'Accord trouvé',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStagesEnum.CLOSED_WON]: {
    label: 'Fermé - Gagné',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStagesEnum.CLOSED_LOST]: {
    label: 'Fermé - Perdu',
    team: TeamsEnum.SUPPLY,
  },
}

// Categories

export enum AssetProcessStageCategoryEnum {
  'PROCESS' = 'PROCESS',
}

export interface AssetProcessStageCategory {
  label: string
  steps: AssetProcessStagesEnum[]
}

export const assetProcessStageCategories: Record<
  AssetProcessStageCategoryEnum,
  AssetProcessStageCategory
> = {
  [AssetProcessStageCategoryEnum.PROCESS]: {
    label: 'Validation du budget',
    steps: [
      AssetProcessStagesEnum.TO_PROCESS,
      AssetProcessStagesEnum.MARKET_VALUE_COMPLETED,
      AssetProcessStagesEnum.IN_NEGOTIATION,
      AssetProcessStagesEnum.AGREEMENT_FOUND,
      AssetProcessStagesEnum.CLOSED_WON,
    ],
  },
}

export const getStepNumber = (step: AssetProcessStagesEnum) => {
  return Object.keys(assetProcessStages).findIndex((o) => o === step) + 1
}
