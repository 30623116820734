import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface Partner {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  internalName: string
  defaultUnsubscribed: boolean
  agencies: PartnerAgency[]
}

export interface PartnerAgency {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  partnerId: string
  persons: PartnerPerson[]
  hubspotId: string
}

export interface PartnerPerson {
  id: string
  createdAt: Date
  updatedAt: Date
  firstName: string
  lastName: string
  email: string
  phone: string
  agencyId: string
  hubspotId: string
}

export const getPartnerQuery = async (partnerId?: string | undefined): Promise<Partner> => {
  return await authenticatedGetQuery(`/admin/partners/${partnerId}`)
}
