import { DeleteIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { UseMutationResult } from 'react-query'
import { currentEditFieldAtom } from '../../../../atoms/discoveryAtom'
import ConfirmButton from '../../../../shared/components/ConfirmButton'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import BooleanInput from '../../../../shared/components/inputs/InlineLayout/BooleanInput'
import DatePickerInput from '../../../../shared/components/inputs/InlineLayout/DatePickerInput'
import HiddenField from '../../../../shared/components/inputs/InlineLayout/HiddenField'
import InputWrapper from '../../../../shared/components/inputs/InlineLayout/InputWrapper'
import SelectInput, {
  SelectOption,
} from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import { usePreviousValue } from '../../../../utility/hooks/usePreviousValue'
import { hashObject } from '../../../../utility/other'
import CustomButton from '../../../components/CustomButton'
import Bold from '../../../components/Typo/Bold'
import Regular from '../../../components/Typo/Regular'

interface CurrentData {
  [key: string]: any
}

type BaseField = {
  label: string
  fieldName: string
}

type CurrencyField = BaseField & {
  type: 'currency'
}

type BooleanField = BaseField & {
  type: 'boolean'
}

type TextField = BaseField & {
  type: 'text'
}

type HiddenField = BaseField & {
  type: 'hidden'
}

type NumberField = BaseField & {
  type: 'number'
}

type DateField = BaseField & {
  type: 'date'
}

type SelectField = BaseField & {
  type: 'select'
  options: SelectOption[]
}

export type DiscoveryLineItemField =
  | CurrencyField
  | TextField
  | NumberField
  | DateField
  | SelectField
  | HiddenField
  | BooleanField

export type CopyFieldsOptions = {
  label: string
  values: FormikValues
  enabled: boolean
}

type Props = {
  title: string
  items?: React.ReactNode[]
  fields?: DiscoveryLineItemField[]
  initialValues?: { [key: string]: any }
  getFieldsFunction?: (values: FormikValues) => DiscoveryLineItemField[]
  getItemsFunction?: (values: FormikValues) => React.ReactNode[]
  updateMutation?: UseMutationResult<any, any, any, any>
  deleteMutation?: UseMutationResult<any, any, any, any>
  currentdata?: CurrentData | undefined
  canDelete?: boolean
  copyFieldsOptions?: CopyFieldsOptions
}

const DiscoveryLineItem = ({
  title,
  items,
  fields,
  initialValues: initialValuesOverride,
  getFieldsFunction,
  getItemsFunction,
  updateMutation,
  deleteMutation,
  currentdata,
  canDelete,
  copyFieldsOptions,
}: Props) => {
  const [isEditActive, setIsEditActive] = useState(false)
  const [lastSubmittedValues, setLastSubmittedValue] = useState<FormikValues>({})
  const [currentEditField, setCurrentEditField] = useAtom(currentEditFieldAtom)
  const prevEditField = usePreviousValue(currentEditField)

  const uniqueId = hashObject({ title, id: currentdata?.id })
  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const initialValues =
    initialValuesOverride ||
    fields?.reduce((acc, field) => {
      acc[field.fieldName] = undefined
      return acc
    }, {} as any)

  const getItemsWithSeparators = (values: FormikValues) => {
    let tempItems: React.ReactNode[] = []
    if (typeof getItemsFunction === 'function') {
      tempItems = getItemsFunction(values)
    } else if (!items) {
      return 'No items'
    } else {
      tempItems = items
    }

    return tempItems.reduce((acc: React.ReactNode[], item, index) => {
      if (index > 0 && !!item) {
        acc.push(<span key={`separator-${index}`}> - </span>)
      }
      acc.push(<React.Fragment key={index}>{item}</React.Fragment>)
      return acc
    }, [])
  }

  const getEditFields = (values: FormikValues) => {
    let fieldsToProcess: DiscoveryLineItemField[] = []

    if (typeof getFieldsFunction === 'function') {
      const transformedFields = getFieldsFunction(values)
      fieldsToProcess = transformedFields
    } else {
      if (!fields) {
        return <Regular color='red'>No field</Regular>
      }
      fieldsToProcess = fields
    }

    const nodes = fieldsToProcess.map((field, index) => {
      let tempNode: React.ReactNode = null
      const isFirstField = field === fieldsToProcess.filter((o) => o.type !== 'hidden')[0] // Check if this is the first field

      switch (field.type) {
        case 'number':
          tempNode = (
            <TextInput
              autoFocus={!!isFirstField}
              key={index}
              field={field.fieldName}
              type='number'
              label={''}
              number
            />
          )
          break
        case 'text':
          tempNode = (
            <TextInput autoFocus={!!isFirstField} key={index} field={field.fieldName} label={''} />
          )
          break
        case 'currency':
          tempNode = (
            <TextInput
              autoFocus={!!isFirstField}
              key={index}
              field={field.fieldName}
              label={''}
              type='currency'
              number
            />
          )
          break
        case 'date':
          tempNode = (
            <DatePickerInput
              autoFocus={!!isFirstField}
              key={index}
              field={field.fieldName}
              label={''}
            />
          )
          break
        case 'hidden':
          tempNode = <HiddenField field={field.fieldName} />
          break
        case 'select':
          tempNode = (
            <SelectInput
              autoFocus={!!isFirstField}
              key={index}
              field={field.fieldName}
              label={''}
              options={field.options}
            />
          )
          break
        case 'boolean':
          tempNode = (
            <BooleanInput
              autoFocus={!!isFirstField}
              key={index}
              field={field.fieldName}
              label={''}
            />
          )
          break
        default:
          return <Bold color='red'>Unknown type</Bold>
      }
      return (
        <InputWrapper key={index} field={field.fieldName} label={field.label}>
          {tempNode}
        </InputWrapper>
      )
    })
    return nodes
  }

  useEffect(() => {
    if (uniqueId === currentEditField) {
      setIsEditActive(true)
    } else {
      setIsEditActive(false)
    }

    if (prevEditField !== currentEditField && prevEditField === uniqueId) {
      formikRef.current?.submitForm()
    }
  }, [currentEditField, uniqueId])

  return (
    <Flex
      direction='column'
      gap='8px'
      cursor={isEditActive ? 'initial' : 'pointer'}
      onClick={() => {
        setCurrentEditField(uniqueId)
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          if (updateMutation && lastSubmittedValues !== values) {
            await updateMutation.mutateAsync(values, {
              onSuccess: (data) => {
                setLastSubmittedValue(values)
                if (currentEditField === uniqueId) {
                  setCurrentEditField(undefined)
                }
                setIsEditActive(false)
              },
            })
          }
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={currentdata} initialValues={initialValues} />
            {isEditActive ? (
              <form onSubmit={formik.handleSubmit}>
                <Flex gap='8px' direction='column'>
                  <Bold>{title}</Bold>
                  {getEditFields(formik.values)}
                  <Flex gap='8px' alignItems='center' verticalAlign='middle'>
                    <CustomButton variant='primary' type='submit'>
                      Sauvegarder
                    </CustomButton>
                    {copyFieldsOptions && copyFieldsOptions.enabled && (
                      <CustomButton
                        variant='outline'
                        size='small'
                        onClick={() => {
                          const values = _.pick(
                            copyFieldsOptions.values,
                            Object.keys(initialValues),
                          )
                          formik.setValues(values)
                        }}
                      >
                        {copyFieldsOptions.label || "Copier l'autre emprunteur"}
                      </CustomButton>
                    )}
                    {canDelete && (
                      <ConfirmButton
                        bodyText='Cette action est définitive!'
                        buttonText='Supprimer'
                        headerText='Supprimer'
                        rightIcon={<DeleteIcon />}
                        onSuccessAction={() => {
                          deleteMutation?.mutate({ id: formik.values.id })
                        }}
                        variant='danger-outline'
                      />
                    )}
                  </Flex>
                </Flex>
              </form>
            ) : (
              <>
                <Flex gap='8px' alignItems='center'>
                  {/* {isMissingData && <Warning color={HESTIA_COLORS.red[600]} weight="fill" />} */}
                  <Bold>{title}</Bold>
                </Flex>
                <Flex gap='8px'>{getItemsWithSeparators(formik.values)}</Flex>
              </>
            )}
          </>
        )}
      </Formik>
    </Flex>
  )
}

export default DiscoveryLineItem
