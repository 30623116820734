import { FormikErrors } from 'formik'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import { isNotionUrl } from '../../../../utility/urls'
import { AnalysisActionValues } from '../actions/AnalysisAction'
export const validateAnalysisAction = (values: AnalysisActionValues) => {
  const errors: FormikErrors<AnalysisActionValues> = {}

  // if (
  //   values.hestiaStatus === AssetAdStatus.ACCEPTED &&
  //   (!values.notionUrl || !isNotionUrl(values.notionUrl))
  // ) {
  //   errors.notionUrl = 'URL Notion invalide'
  // }

  return errors
}
