import { Button, ButtonProps, useClipboard } from '@chakra-ui/react'
import React from 'react'

interface CopyButtonProps extends ButtonProps {
  code: string
  label?: string
}

function CopyButton({ code, label = 'Copier le code', ...props }: CopyButtonProps) {
  const { hasCopied, onCopy } = useClipboard(code)

  return (
    <Button
      size='sm'
      textTransform='uppercase'
      colorScheme='teal'
      fontSize='xs'
      height='24px'
      {...props}
      onClick={onCopy}
      w='fit-content'
    >
      {hasCopied ? 'Copié!' : label}
    </Button>
  )
}

export default CopyButton
