import { useConnector, UseSearchBoxProps } from 'react-instantsearch-hooks-web'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'
import HelperSmall from '../Typo/HelperSmall'
import React from 'react'
import type {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'

type UseStatsProps = StatsConnectorParams

function useStats(props: StatsConnectorParams) {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props)
}

function AlgoliaStats(props: UseSearchBoxProps) {
  const { nbHits } = useStats(props)

  return (
    <>
      <HelperSmall>{nbHits} résulats trouvés.</HelperSmall>
    </>
  )
}

export default AlgoliaStats
