import React, { FC, ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react'

const RegularLink: FC<
  TextProps & {
    children?: ReactNode
  }
> = ({ children, ...props }) => {
  return (
    <Text
      color='#6F6F6F'
      fontSize='16px'
      fontWeight='400'
      fontFamily='HKGroteskMedium'
      textDecoration='underline'
      cursor='pointer'
      {...props}
    >
      {children}
    </Text>
  )
}

export default RegularLink
