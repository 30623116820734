import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface GetFundsGroupsQueryInputs {
  page: number
  search: string | undefined
}

export interface FundsGroupsResponseSingle {
  createdAt: string
  id: string
  name: string
}

export interface FundsGroupsResponse {
  fundsGroups: FundsGroupsResponseSingle[]
}

export const getFundsGroupsQuery = async <fundsGroupsResponse>(
  getFundsGroupsQueryInputs: GetFundsGroupsQueryInputs,
) => {
  return await authenticatedGetQuery('/admin/fundsGroups', {
    page: getFundsGroupsQueryInputs.page,
    search: getFundsGroupsQueryInputs.search,
  })
}
