import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import TextAreaInput from '../../shared/components/inputs/InlineLayout/TextAreaInput'
import { useCustomToast } from '../../utility/hooks/toats/useCustomToasts'
import Bold from '../components/Typo/Bold'
import Regular from '../components/Typo/Regular'
import { generateMessage, GenrateMessageInputs } from './queries/generateMessage'
import BooleanInput from '../../shared/components/inputs/InlineLayout/BooleanInput'
import Emoji from 'react-emoji-render'

type Props = {}

const GenerateMessage = (props: Props) => {
  const navigate = useNavigate()
  const { errorToast, successToast } = useCustomToast()

  const initialValues = {
    brief: '',
    communicationGuidelines: true,
    useEmojis: true,
    analysisContext: false,
    FAQ: false,
    howItWorks: true,
  }

  const generateMessageMutation = useMutation(
    (inputs: GenrateMessageInputs) => generateMessage(inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        successToast({ description: 'Done!' })
      },
    },
  )
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        console.log(values)
        await generateMessageMutation.mutate({ ...values })
      }}
    >
      {(formik) => (
        <>
          <Card w='100%' p='32px'>
            <Flex gap='8px' w='100%' direction='column'>
              <Flex gap='8px' w='100%'>
                <Flex w='50%' direction='column' gap='16px'>
                  <TextAreaInput
                    field='brief'
                    label='Brief'
                    minH='300px'
                    placeHolder="Rédige un message pour relancer un client qui n'a pas encore proposé de biens mais qui s'est inscrit il y a 2 jours. L'objectif est de lui faire proposer un bien."
                  />
                  <FormControl display='flex' alignItems='center' gap='8px'>
                    <Switch
                      id='communicationGuidelines'
                      onChange={formik.handleChange}
                      isChecked={formik.values.communicationGuidelines}
                    />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Inclure nos guidelines de communication
                    </FormLabel>
                  </FormControl>
                  <FormControl display='flex' alignItems='center' gap='8px'>
                    <Switch
                      id='useEmojis'
                      onChange={formik.handleChange}
                      isChecked={formik.values.useEmojis}
                    />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Je veux des emojis, max 1 par paragraphe
                    </FormLabel>
                  </FormControl>
                  <FormControl display='flex' alignItems='center' gap='8px'>
                    <Switch
                      id='analysisContext'
                      onChange={formik.handleChange}
                      isChecked={formik.values.analysisContext}
                    />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Inclure le contexte de nos critères
                    </FormLabel>
                  </FormControl>
                  <FormControl display='flex' alignItems='center' gap='8px'>
                    <Switch id='FAQ' onChange={formik.handleChange} isChecked={formik.values.FAQ} />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Inclure la FAQ détaillée
                    </FormLabel>
                  </FormControl>
                  <FormControl display='flex' alignItems='center' gap='8px'>
                    <Switch
                      id='howItWorks'
                      onChange={formik.handleChange}
                      isChecked={formik.values.howItWorks}
                    />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                      Inclure le fonctionnement du leasing
                    </FormLabel>
                  </FormControl>
                  <Bold>Exemples :</Bold>
                  <Flex gap='8px' flexWrap='wrap'>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue('howItWorks', true)
                        formik.setFieldValue(
                          'brief',
                          "Rédige un message pour relancer un client qui s'est inscrit il y a 7 jours mais qui n'a toujours pas proposé d'annonce sur la plateforme.",
                        )
                      }}
                    >
                      Mail de relance
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue('howItWorks', false)
                        formik.setFieldValue(
                          'brief',
                          "Trouve le nom d'un article qui présente les 5 meilleurs quartiers à Brest pour y habiter",
                        )
                      }}
                    >
                      Titre d'article
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue('howItWorks', true)
                        formik.setFieldValue(
                          'brief',
                          "Ecris un post linkedin qui annonce notre partenariat avec Luko, startup d'assurance habitation. Il doit faire courts 3 paragraphes. Chaque paragraphe ne doit pas faire plus de 2 phrases et commencer par un emoji.",
                        )
                      }}
                    >
                      Post linkedin
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue('howItWorks', true)
                        formik.setFieldValue(
                          'brief',
                          `Détaille ce brief en user stories : "Je veux faire en sorte que nos agents immobiliers partenaires puissent nous proposer des biens en incluant le lien, une note et des documents. Après il doivent pouvoir suivre aussi notre décision d'analyse."`,
                        )
                      }}
                    >
                      User stories
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', false)
                        formik.setFieldValue('useEmojis', false)
                        formik.setFieldValue('howItWorks', true)
                        formik.setFieldValue('analysisContext', true)
                        formik.setFieldValue(
                          'brief',
                          `Comment faire pour créer une carte dynamique qui reprend toutes les villes de plus de 40000 habitants et les villes autour à 10km ? Je veux le faire sans coder, donne moi un processus clair et actionnable.`,
                        )
                      }}
                    >
                      Carte dynamique
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue('howItWorks', true)
                        formik.setFieldValue('analysisContext', false)
                        formik.setFieldValue('FAQ', true)
                        formik.setFieldValue(
                          'brief',
                          `Dans un mail, que répondre à un client qui me dit "Votre startup est trop jeune, je n'ai pas confiance" ?`,
                        )
                      }}
                    >
                      Objection (mail) - Trop jeune
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', false)
                        formik.setFieldValue('howItWorks', false)
                        formik.setFieldValue('analysisContext', false)
                        formik.setFieldValue('FAQ', true)
                        formik.setFieldValue(
                          'brief',
                          `A l'oral au téléphone, que répondre à un client qui me dit "Qu'est ce qu'il se passe si je ne peux plus vous achter le logement" ?`,
                        )
                      }}
                    >
                      Question (téléphone) - Non levée ?
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', true)
                        formik.setFieldValue('useEmojis', true)
                        formik.setFieldValue(
                          'brief',
                          `Trouve 10 idées de noms pour un nouveau service que Hestia va proposer à des courtiers partenaires pour distribuer notre solution. Il doit être précé de "Hestia" (Par exemple : "Hestia Pro")`,
                        )
                      }}
                    >
                      Noms de services
                    </Button>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        formik.resetForm()
                        formik.setFieldValue('communicationGuidelines', false)
                        formik.setFieldValue('useEmojis', false)
                        formik.setFieldValue('analysisContext', true)
                        formik.setFieldValue(
                          'brief',
                          `Un client a proposé un bien et nous devons savoir si nous l'achetons pour lui. Analyse l'annonce pour un bien suivante et donne moi les 3 points négatifs et 3 points positifs :
                          Superbe T2 vendu entièrement meublé et décoré dans une copropriété entretenue, calme et sécurisée situé au 1 Rue du Général André.
                          Il se compose d’une entrée, un salon séjour, une cuisine équipée une grande chambre de type suite parentale avec une très belle salle de douche.
                          
                          Cet appartement est actuellement loué à 920€ par mois. La fin du bail est au 31/08/2023, il peut être vendu loué ou libre d’occupation.
                          
                          Idéal 1er achat ou investisseur.
                          
                          Le prix de vente est de 184 500€.
                          
                          Agence merci de vous abstenir.`,
                        )
                      }}
                    >
                      Analyse d'une annonce
                    </Button>
                  </Flex>

                  <Button
                    disabled={generateMessageMutation.isLoading}
                    type='submit'
                    onClick={() => formik.handleSubmit()}
                    colorScheme='green'
                    bg='HestiaForestGreen'
                    w='fit-content'
                  >
                    Générer le message.
                  </Button>
                </Flex>
                <Flex w='50%' direction='column' gap='8px'>
                  {generateMessageMutation.isLoading && <Spinner />}
                  {generateMessageMutation.data?.openAIResponse.content && (
                    <Tabs>
                      <TabList>
                        <Tab>Résultat</Tab>
                        <Tab>Prompt utilisé</Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          <Regular whiteSpace='pre-line'>
                            <Emoji>{generateMessageMutation.data?.openAIResponse.content}</Emoji>
                          </Regular>
                        </TabPanel>
                        <TabPanel>
                          <Regular whiteSpace='pre-line'>
                            <Emoji>{generateMessageMutation.data?.prompt}</Emoji>
                          </Regular>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </>
      )}
    </Formik>
  )
}

export default GenerateMessage
