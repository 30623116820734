import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'

export interface GetUsersQueryInputs {
  page: number
  search: string | undefined
  searchPostalCode: string | undefined
  hasDocument: boolean
  hasSyncedAccount: boolean
  isBusiness: boolean
}

export interface UsersResponseSingleUser {
  assetAdsCount: number
  business: boolean
  email: string
  firstName: string
  lastName: string
  id: string
  createdAt: string
  accountsCount: number
  documentsCount: number
  userInformations?: {
    inseePostalCode?: {
      id: string
      postalCode: string
      latitude: number
      longitude: number
      region: string
      department: string
    }
  }
}

export interface UsersResponse {
  users: UsersResponseSingleUser[]
}

export const getUsersQuery = async <usersResponse>(getUsersQueryInputs: GetUsersQueryInputs) => {
  return await authenticatedGetQuery('/admin/users', {
    page: getUsersQueryInputs.page,
    search: getUsersQueryInputs.search,
    searchPostalCode: getUsersQueryInputs.searchPostalCode,
    hasDocument: getUsersQueryInputs.hasDocument,
    hasSyncedAccount: getUsersQueryInputs.hasSyncedAccount,
    isBusiness: getUsersQueryInputs.isBusiness,
  })
}
