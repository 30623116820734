import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { TeamsEnum } from '../../../shared/backShared/assetProcessSteps'
import FormikAutoRefresh from '../../../shared/components/inputs/FormikAutoRefresh'
import MultiSelectInput from '../../../shared/components/inputs/InlineLayout/MultiSelectInput'
import TextInput from '../../../shared/components/inputs/InlineLayout/TextInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import { AdminsResponseSingle } from '../queries/getAdmins'
import { updateAdmin, UpdateAdminInputs } from '../queries/updateAdmin'

type Props = {
  admin: AdminsResponseSingle
}

export type EditAdminActionValues = {
  teams: TeamsEnum[]
  slackId: string | undefined
}

const EditAdminAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: EditAdminActionValues = {
    teams: [],
    slackId: undefined,
  }

  // const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation((inputs: UpdateAdminInputs) => updateAdmin(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['admins'])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          const payload = {
            ...values,
            id: props.admin.id,
          }
          await updateMutation.mutate(payload, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={props.admin} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader title='Editer un admin' subtitle={props.admin.email} />
              <Flex gap='24px' direction='column'>
                <MultiSelectInput
                  label='Team(s)'
                  field='teams'
                  isRequired
                  options={[
                    { label: 'Demand', value: TeamsEnum.DEMAND },
                    { label: 'Supply', value: TeamsEnum.SUPPLY },
                    { label: 'Credit', value: TeamsEnum.CREDIT },
                    { label: 'Fund', value: TeamsEnum.FUND },
                    { label: 'Marketing', value: TeamsEnum.MARKETING },
                    { label: 'Partnerships', value: TeamsEnum.PARTNERSHIPS },
                    { label: 'Dev', value: TeamsEnum.DEV },
                    { label: 'Exec', value: TeamsEnum.EXEC },
                  ]}
                />
                <TextInput label='Slack ID' field='slackId' />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditAdminAction
