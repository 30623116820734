import React, { FC, useState } from 'react'
import {
  Button,
  Code,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { impersonateQuery } from '../queries/impersonateQuery'
import { AxiosError } from 'axios'
import Header from '../../components/Typo/Header'
import Regular from '../../components/Typo/Regular'
import RegularLink from '../../components/Typo/RegularLink'
import CopyButton from '../../components/Code/CopyButton'

const ImpersonateCTA: FC<{
  userId: string
}> = ({ userId }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [tokenCode, setTokenCode] = useState('')

  const impersonateMutation = useMutation((id: string) => impersonateQuery(id), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      setTokenCode(`localStorage.setItem('hestia:jwt', '${data.token}')`)
      onOpen()
    },
  })

  return (
    <>
      <span onClick={() => impersonateMutation.mutate(userId)}>Se connecter en tant que</span>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent padding='24px' paddingBottom='0px' gap='24px' width='fit-content'>
          <Header>Se connecter en tant que</Header>
          <Regular>
            Pour vous connecter en tant que, merci de copier coller le code suivant dans la console
            sur{' '}
            <RegularLink onClick={() => window.open('https://app.heyhestia.com', '_blank')}>
              app.heyhestia.com
            </RegularLink>
          </Regular>
          <Flex direction='column' gap='12px'>
            <CopyButton code={tokenCode} />
            <Code display='block' children={tokenCode} />
          </Flex>
          <ModalFooter>
            <Flex justifyContent='flex-end' gap='16px'>
              <Button color='HestiaGrey' onClick={onClose}>
                Sortir
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImpersonateCTA
