import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import { getBudgetQuery } from '../../dashboard/commonQueries/getBudgetQuery'
import { UserResponseSingleUser } from '../../dashboard/users/queries/userQuery'

export const useHestiaBudgetAdmin = ({ user }: { user: UserResponseSingleUser }) => {
  const [debouncedInputs, debouncedInputsControlFunctions] = useDebounce(user.userInformations, 600)

  const simulatorResults = useQuery(
    [
      'getBudgetQuery',
      user.id,
      debouncedInputs,
      user.userInformations?.inseePostalCode?.postalCode,
    ],
    () =>
      getBudgetQuery({
        monthlyIncome:
          (debouncedInputs?.monthlyIncome || 0) + (debouncedInputs?.monthlyIncomeCobuyer || 0),
        monthlyDebt: debouncedInputs?.monthlyDebt || 0,
        deposit: debouncedInputs?.deposit || 0,
        expectedGains: debouncedInputs?.expectedGains || 0,
        postalCode: user.userInformations?.inseePostalCode?.postalCode,
        loanDuration: debouncedInputs?.loanDuration || 0,
        durationInMonths: debouncedInputs?.durationInMonths || 0,
        debtToIncomeRatio: debouncedInputs?.debtToIncomeRatio || 0,
      }),
  )

  const hestiaFees = (simulatorResults.data?.budgetNow || 0) * 0.03
  const savings = (debouncedInputs?.deposit || 0) + (debouncedInputs?.expectedGains || 0)
  const availableDepositAfterFees = savings - hestiaFees
  const monthlyIncome =
    (debouncedInputs?.monthlyIncome || 0) + (debouncedInputs?.monthlyIncomeCobuyer || 0)
  const monthlyDebt = debouncedInputs?.monthlyDebt || 0
  const deposit = (debouncedInputs?.deposit || 0) + (debouncedInputs?.expectedGains || 0)

  const maxLoanPayment =
    monthlyIncome * (simulatorResults.data?.hypothesis.debtToIncomeRatio || 0) - monthlyDebt

  const debtToIncomeRatioLoan =
    ((simulatorResults.data?.mortgageAfterHestia.monthlyCost || 0) + monthlyDebt) / monthlyIncome

  const firstAcquisition = {
    priceWithoutNotaryFees: simulatorResults.data?.budgetNow || 0,
    price: (simulatorResults.data?.budgetNow || 0) * 1.08,
  }

  const buyerAcquisition = {
    priceWithoutNotaryFees: simulatorResults.data?.budgetInTheEnd || 0,
    price: simulatorResults.data?.budgetInTheEndWithNotaryFees || 0,
  }

  return {
    simulatorData: simulatorResults.data,
    hestiaFees,
    savings,
    availableDepositAfterFees,
    monthlyIncome,
    monthlyDebt,
    deposit,
    maxLoanPayment,
    debtToIncomeRatioLoan,
    buyerAcquisition,
    firstAcquisition,
  }
}
