import React from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import DashboardLayout from '../DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Header from '../components/Typo/Header'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { createPartner, CreatePartnerInput } from './queries/createPartnersQuery'

const CreatePartner = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const createMutation = useMutation((inputs: CreatePartnerInput) => createPartner(inputs), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
    onSuccess: (data) => {
      console.log(data)
      toast({
        title: 'Succès!:',
        description: 'All good',
        status: 'success',
        position: 'top-right',
      })
    },
  })

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    // validate: (values) => validateInformations(values),
    onSubmit: (values) => {
      createMutation.mutate({ name: values.name })
    },
  })

  return (
    <DashboardLayout>
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <form onSubmit={formik.handleSubmit}>
        <Flex direction='column' gap='48px' mt='48px'>
          <Flex direction='column' gap='24px' width={['100%', '400px']}>
            <Header>Créer un partenaire</Header>
            <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
              <FormLabel htmlFor='name'>Nom</FormLabel>
              <Input
                placeholder='Monemprunt.com'
                id='name'
                type='string'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                autoComplete='given-name'
              />
              {formik.errors.name && formik.touched.name && (
                <FormHelperText color='red'>{formik.errors.name}</FormHelperText>
              )}
            </FormControl>
          </Flex>
          <Button
            disabled={createMutation.isLoading}
            colorScheme='green'
            bg='HestiaForestGreen'
            type='submit'
            w='fit-content'
          >
            Enregistrer
          </Button>
        </Flex>
      </form>
    </DashboardLayout>
  )
}

export default CreatePartner
