import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'
import { Address, AssetAd, VisitInformations } from '../../../interfaces/prisma'
import { UserResponseSingleUser } from '../../users/queries/userQuery'
import { UserFile } from '../../users/user/files/queries/getUserFiles'

export type AssetAdDetails = AssetAd & {
  user: UserResponseSingleUser
  address: Address
  userFiles: UserFile[]
  visitInformation?: VisitInformations
}

export const getAssetAdById = async (id: string) => {
  return (await authenticatedGetQuery(`/admin/assetads/${id}`)) as AssetAdDetails
}
