import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface CreateLeasingProjectInput {
  assetName: string
  assetAddress: string
  assetCity: string
  assetPostalCode: number
  assetAcquisitionCost: number
  rent: number
  expectedDayForPayments: number
  futurePrice: number
  cashbackOnRent: number
  fundsGroupUUID: string
  userId: string
}

export const createLeasingProject = async (body: CreateLeasingProjectInput) => {
  return await authenticatedPostQuery(`/admin/leasingProjects/`, body).then((data) => data.data)
}
