import _ from 'lodash'
import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'
import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../../../../utility/queries/authenticatedPostQuery'
import { authenticatedPutQuery } from '../../../../../utility/queries/authenticatedPutQuery'
import { DiscoveryChild } from '../discovery.types'

export type UpdateDiscoveryBuyerChildPayload = {
  id: string
  name: string | null
  age: number | null
}

export const getDiscoveryBuyerChildren = async (userId: string, buyerId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/children`,
  )) as Promise<DiscoveryChild[]>
}

export const createDiscoveryBuyerChild = async (userId: string, buyerId: string) => {
  return (await authenticatedPostQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/children`,
  )) as Promise<void>
}

export const updateDiscoveryBuyerChild = async (
  userId: string,
  buyerId: string,
  payload: UpdateDiscoveryBuyerChildPayload,
) => {
  const finalPayload = _.omit(payload, 'id')
  return (await authenticatedPutQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/children/${payload.id}`,
    finalPayload,
  )) as Promise<void>
}

export const deleteDiscoveryBuyerChild = async (
  userId: string,
  buyerId: string,
  childId: string,
) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/buyers/${buyerId}/children/${childId}`,
  )) as Promise<void>
}
