import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'
import { AssetAd } from '../../../interfaces/prisma'

export interface GetAssetAdsQueryInputs {
  page: number
  search: string | undefined
}

export type SingleAssetAdResponse = AssetAd & {
  user: {
    business: boolean
    email: string
  }
}

export interface AssetAdsResponse {
  assetAds: SingleAssetAdResponse[]
}

export const getAssetAdsQuery = async <AssetAdsResponse>(
  getAssetAdsQueryInputs: GetAssetAdsQueryInputs,
) => {
  return await authenticatedGetQuery('/admin/assetAds', {
    page: getAssetAdsQueryInputs.page,
    search: getAssetAdsQueryInputs.search,
  })
}
