import { Divider, Flex, SimpleGrid } from '@chakra-ui/react'
import { Bank } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import React, { useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { DiscoveryTab } from '../../../../atoms/discoveryAtom'
import CustomCard from '../../../../components/CustomCard'
import useUpdateCurrentTab from '../../../../utility/hooks/discovery/useUpdateCurrentTab'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getPrettyNumber } from '../../../../utility/money'
import { discoveryContextBlockers, discoveryContextMotivations, getLabel } from './discovery.types'
import DiscoveryContextBanksVisited from './DiscoveryContextBanksVisited'
import DiscoveryDisplayValue from './DiscoveryDisplayValue'
import DiscoveryLineItem from './DiscoveryLineItem'
import { getDiscoveryContext } from './queries/getDiscoveryContext'
import { DiscoveryContextPayload, updateDiscoveryContext } from './queries/updateDiscoveryContext'

type Props = {}

const DiscoveryContext = (props: Props) => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const { id: userId } = useParams()

  const currentData = useQuery(['discoveryContext', { id: userId }], () =>
    getDiscoveryContext(userId || ''),
  )

  const updateMutation = useMutation(
    (inputs: DiscoveryContextPayload) => updateDiscoveryContext(userId || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['discoverySummary'])
        queryClient.invalidateQueries(['discoveryContext'])
        successToast({ description: 'Contexte modifié' })
      },
    },
  )

  const ref = useRef<HTMLDivElement | null>(null)
  useUpdateCurrentTab(ref, DiscoveryTab.PROJECT)

  return (
    <CustomCard title={`Projet`} icon={<Bank size={32} color='white' />}>
      <SimpleGrid columns={2} gap='16px' w='100%' ref={ref}>
        <CustomCard light title='Contexte'>
          <Flex flexDirection='column' gap='16px' w='100%'>
            <DiscoveryLineItem
              title='Motivations'
              fields={[
                {
                  fieldName: 'motivation',
                  type: 'select',
                  label: '',
                  options: discoveryContextMotivations,
                },
                {
                  fieldName: 'currentBlockers',
                  type: 'select',
                  label: "Qu'est-ce qui bloque le crédit ?",
                  options: discoveryContextBlockers,
                },
              ]}
              items={[
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.motivation}
                  label={`LOA pour ${getLabel(
                    discoveryContextMotivations,
                    currentData.data?.motivation,
                  )}`}
                />,
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.currentBlockers}
                  label={`Refus pour ${getLabel(
                    discoveryContextBlockers,
                    currentData.data?.currentBlockers,
                  )}`}
                />,
              ]}
              updateMutation={updateMutation}
              currentdata={currentData.data}
            />
            <Divider />

            <DiscoveryLineItem
              title='Durées'
              fields={[
                {
                  fieldName: 'leaseDurationMonths',
                  type: 'select',
                  label: 'Durée de leasing souhaitée',
                  options: [
                    {
                      value: 9,
                      label: '9 mois',
                    },
                    {
                      value: 12,
                      label: '12 mois',
                    },
                    {
                      value: 15,
                      label: '15 mois',
                    },
                    {
                      value: 18,
                      label: '18 mois',
                    },
                    {
                      value: 24,
                      label: '24 mois',
                    },
                  ],
                },
                {
                  fieldName: 'projectDeadlineMonths',
                  type: 'select',
                  label: 'Date d’emménagement souhaitée',
                  options: [
                    {
                      value: 3,
                      label: 'Dans 3 mois',
                    },
                    {
                      value: 6,
                      label: 'Dans 6 mois',
                    },
                    {
                      value: 9,
                      label: 'Dans 9 mois',
                    },
                    {
                      value: 12,
                      label: 'Dans 12 mois',
                    },
                    {
                      value: 24,
                      label: 'Dans 24 mois',
                    },
                  ],
                },
              ]}
              items={[
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.leaseDurationMonths}
                  label={`${currentData.data?.leaseDurationMonths} mois de leasing`}
                />,
                <DiscoveryDisplayValue
                  key='1'
                  type='regular'
                  value={currentData.data?.projectDeadlineMonths}
                  label={`Emménagement dans ${currentData.data?.projectDeadlineMonths} mois`}
                />,
              ]}
              updateMutation={updateMutation}
              currentdata={currentData.data}
            />

            <Divider />
            <DiscoveryLineItem
              title='Budget souhaité'
              fields={[
                {
                  fieldName: 'desiredBudget',
                  type: 'currency',
                  label: 'Budget souhaité',
                },
                {
                  fieldName: 'desiredMonthlySavings',
                  type: 'currency',
                  label: 'Loyer max souhaité (Hestia)',
                },
              ]}
              items={[
                <DiscoveryDisplayValue
                  key='1'
                  type='badge'
                  value={currentData.data?.desiredBudget}
                  label={`${getPrettyNumber(currentData.data?.desiredBudget || 0)} de budget`}
                />,
                <DiscoveryDisplayValue
                  key='1'
                  type='badge'
                  customBadgeProps={{ variant: 'green' }}
                  value={currentData.data?.desiredMonthlySavings}
                  label={`${getPrettyNumber(
                    currentData.data?.desiredMonthlySavings || 0,
                  )} par mois max`}
                />,
              ]}
              updateMutation={updateMutation}
              currentdata={currentData.data}
            />
          </Flex>
        </CustomCard>
        <DiscoveryContextBanksVisited />
      </SimpleGrid>
    </CustomCard>
  )
}

export default DiscoveryContext
