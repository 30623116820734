import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import { Button, Flex, IconButton, TableContainer, useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { pageAtom } from '../../atoms/usersList'
import { ROUTES } from '../../utility/routes/routeConstants'
import CustomTable from '../components/customTable'
import DashboardLayout from '../DashboardLayout'
import { Partner } from './queries/getPartnerQuery'
import { getPartnersQuery } from './queries/getPartnersQuery'

const PartnersList = () => {
  const toast = useToast()
  const [page, setPage] = useAtom(pageAtom)
  const pageSize = 10
  const navigate = useNavigate()

  const partnersQuery = useQuery(['partners'], () => getPartnersQuery(), {
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  return (
    <DashboardLayout>
      <Flex direction='column' gap='16px'>
        <Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='end'>
          <Button
            colorScheme='green'
            rightIcon={<AddIcon />}
            onClick={() => navigate(`/${ROUTES.CREATE_PARTNER}`)}
          >
            Créer un partenaire API
          </Button>
        </Flex>
      </Flex>
      <TableContainer mt='16px'>
        <CustomTable
          headers={['Nom']}
          total={partnersQuery.data?.partners.length}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          managedPagination={false}
          content={
            partnersQuery.data?.partners
              ? partnersQuery.data?.partners.map((partner: Partner) => {
                  return {
                    name: partner.name,
                    details: (
                      <div onClick={() => navigate(`/${ROUTES.PARTNERS_BASE}/${partner.id}`)}>
                        <IconButton aria-label='Voir' icon={<ViewIcon />} />
                      </div>
                    ),
                  }
                })
              : []
          }
        />
      </TableContainer>
    </DashboardLayout>
  )
}

export default PartnersList
