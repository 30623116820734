import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  IconButton,
  Input,
  useEditableControls,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getFileNameFromKey, getFileExtension } from '../../../../utility/other'
import Bold from '../../../components/Typo/Bold'
import { DeleteUserFileQueryInputs, deleteUserFileQuery } from './queries/deleteUserFile'

type Props = {
  fileName: string
  fileExtension: string
}

const EditFileNameInput = (props: Props) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } =
    useEditableControls()

  return (
    <Flex gap='8px' flexDirection='column' alignItems='center' justifyContent='center' width='100%'>
      {!isEditing && (
        <Flex justifyContent='center' alignItems='center'>
          <EditablePreview width='fit-content' />.{props.fileExtension}
        </Flex>
      )}
      <Input as={EditableInput} width='80%' />
      <Flex justifyContent='center' gap='8px'>
        {isEditing ? (
          <>
            <IconButton
              aria-label='Submit'
              size='sm'
              icon={<CheckIcon />}
              {...getSubmitButtonProps()}
            />
            <IconButton
              aria-label='Cancel'
              size='sm'
              icon={<CloseIcon />}
              {...getCancelButtonProps()}
            />
          </>
        ) : (
          <>
            <IconButton aria-label='Edit' size='sm' icon={<EditIcon />} {...getEditButtonProps()} />
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default EditFileNameInput
