import {
  Button,
  Card,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Switch,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { usePreviousValue } from '../../../utility/hooks/usePreviousValue'
import { parseNumber } from '../../../utility/parseNumber'
import Bold from '../../components/Typo/Bold'
import UserFinancialSummary from '../../components/UserFinancialSummary'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { getUserQuery } from '../queries/userQuery'

const UserInformations: FC<{}> = ({}) => {
  const toast = useToast()
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  const prevUser = usePreviousValue(userQuery.data?.user)

  const formik = useFormik({
    initialValues: {
      firstName: userQuery.data?.user.firstName,
      lastName: userQuery.data?.user.lastName,
      email: userQuery.data?.user.email,
      phoneNumber: userQuery.data?.user.phone,
      accountState: userQuery.data?.user.userInformations?.accountState,
      deleted: userQuery.data?.user.deleted,
      customBudgetOverride: userQuery.data?.user.userInformations?.customBudgetOverride,
    },
    onSubmit: (values) => {
      console.log(values)
      updateMutation.mutate({
        id,
        accountState: values.accountState,
        deleted: values.deleted,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        customBudgetOverride: values.customBudgetOverride,
      })
    },
  })

  useEffect(() => {
    if (userQuery.data?.user !== prevUser) {
      formik.setFieldValue('firstName', userQuery.data?.user.firstName)
      formik.setFieldValue('lastName', userQuery.data?.user.lastName)
      formik.setFieldValue('email', userQuery.data?.user.email)
      formik.setFieldValue('phoneNumber', userQuery.data?.user.phone)
      formik.setFieldValue('accountState', userQuery.data?.user.userInformations?.accountState)
      formik.setFieldValue('deleted', userQuery.data?.user.deleted)
      formik.setFieldValue(
        'customBudgetOverride',
        userQuery.data?.user.userInformations?.customBudgetOverride,
      )
    }
  }, [userQuery.data])

  const updateMutation = useMutation(
    (updateUserInputs: UpdateUserQueryInputs) => updateUserQuery(updateUserInputs),
    {
      onError: (error: AxiosError, variables, context) => {
        toast({
          title: 'Erreur:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
      onSuccess: (data) => {
        console.log(data)
        toast({
          title: 'Succès!:',
          description: 'All good',
          status: 'success',
          position: 'top-right',
        })
      },
    },
  )

  return (
    <>
      <Flex gap='24px'>
        <Flex w='50%'>
          <form onSubmit={formik.handleSubmit} style={{ height: '100%', width: '100%' }}>
            <Grid templateColumns='repeat(12, 1fr)' gap={[6, 12]} w='100%'>
              <GridItem colSpan={[12, 6]}>
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>Prénom</FormLabel>
                  <Input
                    placeholder='Charlotte'
                    size='lg'
                    id='firstName'
                    type='string'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>Nom</FormLabel>
                  <Input
                    placeholder='Garcia'
                    size='lg'
                    id='lastName'
                    type='string'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>Email</FormLabel>
                  <Input
                    placeholder='Email'
                    size='lg'
                    id='email'
                    type='string'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>Téléphone</FormLabel>
                  <Input
                    placeholder='Charlotte'
                    size='lg'
                    id='phoneNumber'
                    type='string'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </GridItem>
              {userQuery.data?.user.userInformations && (
                <>
                  <GridItem colSpan={[12, 6]}>
                    <FormControl isRequired>
                      <FormLabel htmlFor='firstName'>Postalcode wanted</FormLabel>
                      <Input
                        disabled
                        placeholder='Charlotte'
                        size='lg'
                        id='firstName'
                        type='string'
                        value={`${userQuery.data?.user.userInformations?.inseePostalCode?.postalCode} (${userQuery.data?.user.userInformations?.inseePostalCode?.department})`}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[12, 6]}>
                    <FormControl isRequired>
                      <FormLabel htmlFor='firstName'>Date wanted</FormLabel>
                      <Input
                        disabled
                        placeholder='Charlotte'
                        size='lg'
                        id='firstName'
                        type='string'
                        value={userQuery.data?.user.userInformations.dateRange}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={[12, 6]}>
                    <FormControl isRequired>
                      <FormLabel htmlFor='firstName'>Date de naissance</FormLabel>

                      <Input
                        disabled
                        placeholder='Charlotte'
                        size='lg'
                        id='firstName'
                        type='string'
                        value={`${dayjs(userQuery.data?.user.userInformations.dob).format(
                          'DD/MM/YYYY',
                        )} (${dayjs().diff(
                          dayjs(userQuery.data?.user.userInformations.dob),
                          'years',
                        )} ans)`}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              <GridItem colSpan={[12, 6]}>
                <FormControl h='100%'>
                  <FormLabel htmlFor='firstName'>Budget override </FormLabel>
                  <Input
                    placeholder=''
                    size='md'
                    type='string'
                    id='customBudgetOverride'
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue('customBudgetOverride', parseNumber(e.target.value))
                    }
                    value={
                      formik.values.customBudgetOverride &&
                      !isNaN(formik.values.customBudgetOverride)
                        ? formik.values.customBudgetOverride.toString()
                        : 0
                    }
                  />
                  <FormHelperText>
                    Modifie le montant sur hubspot, 0 = budget calculé
                  </FormHelperText>
                </FormControl>
              </GridItem>
              {/* <GridItem colSpan={[12, 6]}>
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>Account state</FormLabel>
                  <Select
                    placeholder='Tink Market'
                    size='lg'
                    id='tinkMarket'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountState}
                  >
                    <option value='NL'>{accountState}</option>
                  </Select>
                </FormControl>
              </GridItem> */}
              <GridItem colSpan={[12, 6]}>
                <FormControl>
                  <FormLabel htmlFor='firstName'>Deleted</FormLabel>
                  <Switch
                    id='email-alerts'
                    isChecked={formik.values.deleted}
                    onChange={(e) => formik.setFieldValue('deleted', e.target.checked)}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl>
                  <FormLabel htmlFor='firstName'>Prêt relais</FormLabel>
                  <Switch
                    id='email-alerts'
                    isChecked={userQuery.data?.user.userInformations?.bridgeLoan}
                    disabled
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Card
                  p='8px'
                  justifyContent='center'
                  alignItems='center'
                  onClick={() => {
                    window.open(
                      `https://app-eu1.hubspot.com/contacts/26051163/record/0-1/${userQuery.data?.user.userInformations?.hubspotId}` ||
                        '',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }}
                  _hover={{ background: 'lightgrey' }}
                  cursor='pointer'
                  transition='background-color 0.2s ease'
                >
                  Hubspot user id<Bold>{userQuery.data?.user.userInformations?.hubspotId}</Bold>
                </Card>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Card
                  p='8px'
                  justifyContent='center'
                  alignItems='center'
                  onClick={() => {
                    window.open(
                      `https://app-eu1.hubspot.com/contacts/26051163/record/0-3/${userQuery.data?.user.userInformations?.hubspotDealId}` ||
                        '',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }}
                  _hover={{ background: 'lightgrey' }}
                  cursor='pointer'
                  transition='background-color 0.2s ease'
                >
                  Hubspot deal id<Bold>{userQuery.data?.user.userInformations?.hubspotDealId}</Bold>
                </Card>
              </GridItem>
            </Grid>
            <Flex mt='12'>
              <Button
                disabled={false}
                colorScheme='green'
                bg='HestiaForestGreen'
                type='submit'
                size='lg'
                // w={['100%', 'initial']}
              >
                Enregistrer
              </Button>
            </Flex>
          </form>
        </Flex>
        <Flex w='50%'>
          {userQuery.data?.user && <UserFinancialSummary user={userQuery.data?.user} />}
        </Flex>
      </Flex>
    </>
  )
}

export default UserInformations
