import axios from 'axios'
import { apiUrl } from '../config'
import { getAuthToken } from '../../auth/AuthProvider'
import { handleQueryError } from './handleQueryError'

export const authenticatedPutQuery = async (path: string, data?: any) => {
  return await axios
    .put(apiUrl + path, data, {
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    })
    .then(function (response) {
      console.log(response)
      return response.data
    })
    .catch(function (error) {
      handleQueryError(error)
    })
}
