import React, { ReactNode } from 'react'
import { Divider, Flex, useModalContext } from '@chakra-ui/react'
import { GrClose } from 'react-icons/gr'
import Header from '../../../components/Typo/Header'
import Regular from '../../../components/Typo/Regular'

type Props = {
  title: string
  subtitle?: string | ReactNode
}

const ModalCustomHeader = (props: Props) => {
  return (
    <>
      <Flex w='100%' gap='16px' textAlign='center' direction='column'>
        <Header>{props.title}</Header>
        <Regular color='HestiaBlack'>{props.subtitle}</Regular>
      </Flex>
    </>
  )
}

export default ModalCustomHeader
