import React, { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import {
  CategoryGroup,
  IncomesV2,
  Loans,
} from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import { useAtom } from 'jotai'
import { isTransactionsModalOpenedAtom, transactionModalContentAtom } from '../../../atoms/algoan'

const AlgoanIncomeDetails = ({ categoryGroup }: { categoryGroup: CategoryGroup | undefined }) => {
  const [isOpen, setIsOpen] = useAtom(isTransactionsModalOpenedAtom)
  const [transactionsModalContent, setTransactionsModalContent] = useAtom(
    transactionModalContentAtom,
  )

  return (
    <Flex border='1px solid #EFEFEF' borderRadius='8px' bg='HestiaLightGrey' flex='1 1 0'>
      <Flex
        padding='16px'
        direction='column'
        gap='8px'
        justifyContent='start'
        alignItems='middle'
        w='100%'
        h='100%'
        cursor='pointer'
        onClick={() => {
          setTransactionsModalContent({
            title: categoryGroup?.name || '',
            transactions: categoryGroup?.transactions || [],
          })
          setIsOpen(true)
        }}
      >
        <Header fontSize='16px'>{categoryGroup?.name}</Header>
        <Header whiteSpace='nowrap'>
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {getPrettyNumber(categoryGroup?.monthlyAmount)}
          </Box>{' '}
          <Box as='span' fontSize='16px'>
            / month
          </Box>
        </Header>
      </Flex>
    </Flex>
  )
}

export default AlgoanIncomeDetails
