import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface AddAgencyToPartnerInput {
  name: string | undefined
  hubspotId: string | undefined
  id: string | undefined
}

export const addAgencyToPartnerQuery = async (data: AddAgencyToPartnerInput) => {
  return await authenticatedPostQuery(`/admin/partners/${data.id}/agency`, {
    name: data.name,
    hubspotId: data.hubspotId,
  }).then((data) => data.data)
}
