import {
  FileImage,
  FilePdf,
  FileText,
  File,
  IconProps,
  FileJpg,
  FilePng,
  FileCsv,
  FileZip,
} from '@phosphor-icons/react'
import React from 'react'

type FileIconProps = {
  fileName: string
} & IconProps

const FileIcon: React.FC<FileIconProps> = ({ fileName, ...iconProps }) => {
  const getIconForExtension = (extension: string) => {
    switch (extension) {
      case 'csv':
        return <FileCsv {...iconProps} />
      case 'zip':
        return <FileZip {...iconProps} />
      case 'doc':
      case 'docx':
        return <FilePdf {...iconProps} />
      case 'pdf':
        return <FilePdf {...iconProps} />
      case 'jpg':
      case 'jpeg':
        return <FileJpg {...iconProps} />
      case 'png':
        return <FilePng {...iconProps} />
      case 'gif':
        return <FileImage {...iconProps} />
      case 'txt':
        return <FileText {...iconProps} />
      // Add more cases for other file types as needed
      default:
        return <File {...iconProps} /> // Generic file icon for unknown types
    }
  }

  const fileExtension = fileName.split('.').pop()?.toLowerCase() || ''
  const IconComponent = getIconForExtension(fileExtension)

  return <div>{IconComponent}</div>
}

export default FileIcon
