import React from 'react'
import {
  Badge,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  TableContainer,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { SearchIcon, ViewIcon } from '@chakra-ui/icons'
import useDebounce from '../../utility/hooks/useDebounce'
import CustomTable from '../components/customTable'
import { useAtom } from 'jotai'
import { pageAtom, searchAtom } from '../../atoms/usersList'
import { getAssetAdsQuery, SingleAssetAdResponse } from './queries/getAssetAds'
import { AssetAdStatus } from '../../interfaces/prisma'
import dayjs from 'dayjs'
import algoliasearch from 'algoliasearch'
import {
  InstantSearch,
  Configure,
  Index,
  Pagination,
  RefinementList,
  SortBy,
  InfiniteHits,
  useConnector,
  ToggleRefinement,
} from 'react-instantsearch-hooks-web'
import { isSearchEmptyAtom } from '../../atoms/searchAtoms'
import AlgoliaSearchBox from '../commandPalette/AlgoliaSearchBox'
import AssetHit from '../commandPalette/AssetHit'
import CommandDivider from '../commandPalette/CommandDivider'
import CustomHits from '../commandPalette/CustomHits'
import { getAlgoliaKeys } from '../commandPalette/queries/getAlgoliaKeys'
import UserHit from '../commandPalette/UserHit'
import AlgoliaCommonSearchBox from '../components/Algolia/AlgoliaCommonSearchBox'
import { ASSSETS_INDEX_NAME } from '../../utility/algolia'
import Bold from '../components/Typo/Bold'
import AlgoliaStats from '../components/Algolia/AlgoliaStats'
import AssetHitComplete from '../components/Algolia/AssetHitComplete'
import { ROUTES } from '../../utility/routes/routeConstants'
import 'instantsearch.css/themes/satellite.css'

const AssetAdsListV2 = () => {
  const algoliaKeys = useQuery(['getAlgoliaKeys'], () => getAlgoliaKeys(), {
    refetchInterval: 15000,
  })
  const navigate = useNavigate()

  if (!algoliaKeys.data?.secret) {
    return <Spinner />
  }

  const algoliaClient = algoliasearch(algoliaKeys.data?.appName, algoliaKeys.data?.secret)

  return (
    <DashboardLayout noPadding>
      <InstantSearch indexName={ASSSETS_INDEX_NAME} searchClient={algoliaClient} routing={true}>
        {/* Widgets */}
        <Configure analytics={false} hitsPerPage={10} />
        <Flex direction='column' gap='0'>
          <Flex
            alignItems='flex-start'
            direction='row'
            justifyContent='flex-start'
            position='sticky'
            top='0'
            bg='white'
            zIndex='99'
            w='100%'
            pl='12'
            pr='12'
            pt='12'
            pb='8'
            boxShadow='0px 3px 16px rgba(0, 0, 0, 0.08)'
          >
            <AlgoliaCommonSearchBox />
          </Flex>

          <Flex gap='16px' padding='12' paddingTop='4'>
            <Flex
              direction='column'
              // gap='16px'
              // bg='white'
              // h='fit-content'
              // position='sticky'
              gap='16px'
            >
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Afficher également les lost</Bold>
                <ToggleRefinement
                  attribute='isLost'
                  label='Affichage des losts'
                  off={false}
                  on={[false, true]}
                />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Equipe concernée par l'étape</Bold>
                <RefinementList attribute='processStepTeam' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Status dans le process</Bold>
                <RefinementList attribute='processStep' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Status de l'analyse</Bold>
                <RefinementList attribute='hestiaStatus' />
                <Divider />
              </Flex>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Réponse du user</Bold>
                <RefinementList attribute='userStatus' />
                <Divider />
              </Flex>
              <Button
                variant='outline'
                colorScheme='gray'
                size='sm'
                w='fit-content'
                onClick={() => navigate(`/${ROUTES.ASSETADS_BASE}/old`)}
              >
                Voir l'ancienne liste
              </Button>
              <Flex direction='column' gap='8px' whiteSpace='nowrap'>
                <Bold>Email de l'utilisateur</Bold>
                <RefinementList searchable={true} attribute='userEmail' />
              </Flex>
            </Flex>
            <Flex direction='column' w='100%'>
              {/* <InfiniteHits hitComponent={AssetHit} /> */}
              <CustomHits HitComponent={AssetHitComplete} />
              <Flex gap='8px' alignItems='center'>
                <Pagination />
                <AlgoliaStats />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </InstantSearch>
    </DashboardLayout>
  )
}

export default AssetAdsListV2
