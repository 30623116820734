import React, { FC, useState, useEffect } from 'react'
import {
  Box,
  Flex,
  IconButton,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { ViewIcon } from '@chakra-ui/icons'
import CustomTable from '../../../components/customTable'
import dayjs from 'dayjs'
import { UserFile } from './queries/getUserFiles'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import Regular from '../../../components/Typo/Regular'
import { DownloadSimple, TrashSimple } from '@phosphor-icons/react'
import FilesTableActions from './FilesTableActions'
import { useAtom } from 'jotai'
import { fileDrawerAtom } from '../../../../atoms/filesAtom'
import { getFileExtension, getFileNameFromKey } from '../../../../utility/other'
import FileIcon from './FileIcon'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import {
  DeleteOpenBankingQueryInputs,
  deleteOpenBankingQuery,
} from '../../queries/deleteOpenBankingDataQuery'
import { deleteUserFileQuery, DeleteUserFileQueryInputs } from './queries/deleteUserFile'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { useAuth } from '../../../../auth/AuthProvider'
import { UserResponseSingleUser } from '../../queries/userQuery'

export type FilesTableRow = {
  id: string
  key: string
  name: string
  createdAt: string
  previewUrl: string
  downloadUrl: string
}

const FilesTable: FC<{
  files: UserFile[]
  user: UserResponseSingleUser
}> = ({ files, user }) => {
  const [rowData, setRowData] = useState<FilesTableRow[]>([])
  const [fileDrawer, setFileDrawer] = useAtom(fileDrawerAtom)

  useEffect(() => {
    setRowData(
      files.map((file) => ({
        id: file.id,
        key: file.key,
        name: file.name || '',
        createdAt: dayjs(file.createdAt).format('MMMM D, YYYY').toString(),
        previewUrl: file.previewUrl,
        downloadUrl: file.downloadUrl,
        rowPinned: 'top',
      })),
    )
  }, [files])

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
      field: 'name',
      headerName: 'Nom',
      cellRenderer: ({ value, data }: { value: string; data: FilesTableRow }) => (
        <Flex
          cursor='pointer'
          padding='8px 0px'
          gap='8px'
          alignItems='center'
          onClick={() => setFileDrawer(files.find((file) => file.id === data.id))}
        >
          <Flex color='brand.green.primary'>
            <FileIcon fileName={data.key} size='25px' />
          </Flex>

          <Regular>
            {value}.{getFileExtension(data.key)}
          </Regular>
        </Flex>
      ),
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Ajouté le',
      flex: 1,
      cellRenderer: ({ value, data }: { value: string; data: FilesTableRow }) => (
        <Flex
          cursor='pointer'
          alignItems='center'
          h='100%'
          onClick={() => setFileDrawer(files.find((file) => file.id === data.id))}
        >
          <Regular size='small' color='neutral.disabled'>
            {value}
          </Regular>
        </Flex>
      ),
    },
    {
      field: '',
      resizable: false,
      cellRenderer: ({ value, data }: { value: string; data: FilesTableRow }) => (
        <FilesTableActions value={value} data={data} user={user} />
      ),
    },
  ])

  return (
    <>
      <Box className='ag-theme-quartz' height='500px' width='100%'>
        {/* The AG Grid component */}
        <AgGridReact
          // onRowSelected={(e) => setFileDrawer(files.find((file) => file.id === e.data.id))}
          onRowClicked={(e) => setFileDrawer(files.find((file) => file.id === e.data.id))}
          rowData={rowData}
          columnDefs={colDefs}
          autoSizeStrategy={{
            type: 'fitGridWidth',
            defaultMinWidth: 100,
          }}
          rowSelection='single'
          // rowStyle={{ cursor: 'pointer' }}
          domLayout='autoHeight'
        />
      </Box>

      {/* <CustomTable
        headers={['Date', 'Nom', 'Détails']}
        content={
          files
            ? files.map((file) => {
                return {
                  date: dayjs(file.createdAt).format('MMMM D, YYYY'),
                  name: file.key.replace(
                    /^[\da-f]{8}-([\da-f]{4})-([\da-f]{4})-([\da-f]{4})-([\da-f]{12})-/,
                    '',
                  ),
                  // amount: getAmountBadge(
                  //   transaction.amountUnscaledValue,
                  //   transaction.amountScaleValue,
                  // ),
                  // merchantName: getTransactionStatusBadge(transaction.status),
                  // type: getTransactionTypeBadge(transaction.type),
                  details: (
                    <div
                      onClick={() => {
                        window.open(file.url, '_blank', 'noopener,noreferrer')
                      }}
                    >
                      <IconButton aria-label='Télécharger' icon={<ViewIcon />} />
                    </div>
                  ),
                }
              })
            : []
        }
      /> */}
    </>
  )
}

export default FilesTable
