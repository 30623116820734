import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface GenrateMessageInputs {
  brief: string
  communicationGuidelines?: boolean
  useEmojis?: boolean
  analysisContext?: boolean
  FAQ?: boolean
  howItWorks?: boolean
}
export interface GenrateMessageResponse {
  openAIResponse: {
    role: 'assistant' | 'user'
    content: 'string'
  }
  prompt: string
}

export const generateMessage = async (body: GenrateMessageInputs) => {
  return await authenticatedPostQuery(`/admin/dev/generateMessage`, {
    ...body,
  }).then((data) => data as GenrateMessageResponse)
}
