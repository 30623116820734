import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import { CashFlowV2 } from '../../../utility/algoan/types/CreditInsights.interface'
import {
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingFn,
  sortingFns,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import {
  cashFlowsColumnsVisibilityAtom,
  isTransactionsModalOpenedAtom,
  transactionModalContentAtom,
} from '../../../atoms/algoan'
import { useAtom } from 'jotai'
import { RankingInfo, rankItem, compareItems } from '@tanstack/match-sorter-utils'
import Regular from '../../components/Typo/Regular'
import Bold from '../../components/Typo/Bold'
import CashflowCategoryBadge from './CashflowCategoryBadge'
import CashflowTypeBadge from './CashflowTypeBadge'
// declare module '@tanstack/table-core' {
//   interface FilterFns {
//     fuzzy: FilterFn<unknown>
//   }
//   interface FilterMeta {
//     itemRank: RankingInfo
//   }
// }
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const CashflowsTable = ({ cashflows }: { cashflows: CashFlowV2[] }) => {
  const [isOpen, setIsOpen] = useAtom(isTransactionsModalOpenedAtom)
  const [transactionsModalContent, setTransactionsModalContent] = useAtom(
    transactionModalContentAtom,
  )

  const columnHelper = createColumnHelper<CashFlowV2>()
  const COLUMNS = [
    columnHelper.accessor('label', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('type', {
      cell: (info) => <CashflowTypeBadge type={info.getValue()} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('category', {
      cell: (info) => <CashflowCategoryBadge category={info.getValue()} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalAmount', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalRejectedAmount', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.dueDays', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.interval.min', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.interval.max', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.meanNumberOfDaysInInterval', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.accuracy', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.periodicity', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('regularity.repartition', {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = useAtom(cashFlowsColumnsVisibilityAtom)
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )
  const table = useReactTable({
    columns,
    data: cashflows,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      globalFilter,
      pagination,
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onPaginationChange: setPagination,
  })

  return (
    <Flex direction='column' gap='16px' mt='16px'>
      <Accordion
        allowToggle
        border='1px solid'
        borderRadius='8px'
        borderColor='HestiaPintGreen'
        bg='HestiaLightGreen'
      >
        <AccordionItem border='0'>
          <h2>
            <AccordionButton border='0'>
              <Box as='span' flex='1' textAlign='left'>
                Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex flexWrap='wrap' gap='8px'>
              <label>
                <input
                  {...{
                    type: 'checkbox',
                    checked: table.getIsAllColumnsVisible(),
                    onChange: table.getToggleAllColumnsVisibilityHandler(),
                  }}
                />
                Toggle All
              </label>
              {table.getAllLeafColumns().map((column) => {
                return (
                  <label>
                    <input
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{' '}
                    {column.id}
                  </label>
                )
              })}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <TableContainer
        maxW='100%'
        // style={{
        //   width: table.getCenterTotalSize(),
        // }}
      >
        <Table
          size='sm'
          variant='striped'
          w='100%'
          // style={{
          //   width: table.getCenterTotalSize(),
          // }}
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} style={{ width: header.getSize() }}>
                    <Flex justifyContent='space-between' h='100%'>
                      <Flex onClick={header.column.getToggleSortingHandler()} cursor='pointer'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                      {/*<Box*/}
                      {/*  onMouseDown={header.getResizeHandler()}*/}
                      {/*  onTouchStart={header.getResizeHandler()}*/}
                      {/*  height='5px'*/}
                      {/*  width='5px'*/}
                      {/*  bg='HestiaPintGreen'*/}
                      {/*  userSelect='none'*/}
                      {/*  cursor='col-resize'*/}
                      {/*/>*/}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: row.original.label,
                    transactions: row.original.transactions,
                  })
                  setIsOpen(true)
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {table.getPrePaginationRowModel().rows.length > pageSize && (
        <Box>
          <Flex gap='8px' verticalAlign='middle' alignItems='center' h='fit-content'>
            <button
              className='border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <Regular>Page</Regular>
            <Bold>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </Bold>
            | Go to page:
            <Input
              w='100px'
              type='number'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
            />
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Flex>
          <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
        </Box>
      )}
    </Flex>
  )
}

export default CashflowsTable
