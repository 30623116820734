import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import ConfirmButton from '../../../shared/components/ConfirmButton'
import TextInput from '../../../shared/components/inputs/InlineLayout/TextInput'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import ModalCustomCloseButton from '../../assetAds/v2/actions/ModalCustomCloseButton'
import ModalCustomHeader from '../../assetAds/v2/actions/ModalCustomHeader'
import {
  AddAgencyToPartnerInput,
  addAgencyToPartnerQuery,
} from '../queries/addAgencyToPartnerQuery'
import { validateAddAgency } from './validateAddAgency'

type Props = {}

export type AddAgencyToPartnerValues = {
  name: string | undefined
  hubspotId: string | undefined
}

const AddAgencyToPartnerAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: AddAgencyToPartnerValues = {
    name: undefined,
    hubspotId: undefined,
  }

  const updateMutation = useMutation(
    (inputs: AddAgencyToPartnerInput) => addAgencyToPartnerQuery(inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['partner', { id }])
        successToast({ description: 'All good' })
      },
    },
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validateAddAgency}
        onSubmit={async (values, formik) => {
          console.log(values)
          updateMutation.mutate(
            {
              ...values,
              id: id,
            },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Créer une agence'
                // subtitle='Un email sera envoyé pour créer son mot de passe.'
              />
              <Flex gap='24px' direction='column'>
                <TextInput label='Nom' placeholder='Tarbes' field={'name'} required />
                <TextInput label='ID Hubspot' placeholder='10754891' field={'hubspotId'} />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <ConfirmButton
                  bodyText="Comme il n'y a pas d'ID Hubspot, cela va créer une nouvelle entreprise dans Hubspot. Êtes-vous sûr qu'elle n'existe pas déjà ?"
                  buttonText={'Créer l’agence'}
                  headerText="L'entreprise n'existe pas dans Hubspot ?"
                  disabled={!!formik.values.hubspotId && formik.values.hubspotId !== ''}
                  isActionDisabled={!formik.isValid}
                  onSuccessAction={() => {
                    formik.submitForm()
                  }}
                />
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default AddAgencyToPartnerAction
