import { FormikValues, useFormikContext } from 'formik'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { usePreviousValue } from '../../../utility/hooks/usePreviousValue'

const FormikAutoRefresh = ({
  data,
  initialValues,
}: {
  data?: object | undefined
  initialValues: FormikValues
}) => {
  const formik = useFormikContext()
  const prevValues = usePreviousValue(data)

  useEffect(() => {
    // console.log(data, prevValues)
    if (_.isEqual(data, prevValues)) return
    const values = _.pick(data, Object.keys(initialValues))
    formik.setValues({ ...initialValues })
    formik.setValues({ ...initialValues, ...values })
  }, [data])

  return <></>
}

export default FormikAutoRefresh
