import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'

export interface ChangeUserStepPayload {
  newStep: UserProcessStagesEnum
}

export const changeUserStepQuery = async (id: string, payload: ChangeUserStepPayload) => {
  return await authenticatedPostQuery(`/admin/users/${id}/action/changestep`, payload)
}
