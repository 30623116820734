import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'
import { DiscoveryContextBlocker, DiscoveryContextMotivation } from '../discovery.types'

export type DiscoveryContext = {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  motivation: DiscoveryContextMotivation | null
  currentBlockers: DiscoveryContextBlocker | null
  leaseDurationMonths: number | null
  projectDeadlineMonths: number | null
  desiredBudget: number | null
  desiredMonthlySavings: number | null
  desiredFutureMonthlyPayment: number | null
}

export const getDiscoveryContext = async (userId: string) => {
  return (await authenticatedGetQuery(
    `/admin/user/discovery/${userId}/context`,
  )) as Promise<DiscoveryContext>
}
