import { AddIcon } from '@chakra-ui/icons'
import { Divider, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import CustomBadge from '../../../shared/components/CustomBadge'
import AssetProcessModalWrapper from '../../assetAds/v2/AssetProcessModalWrapper'
import CustomButton from '../../components/CustomButton'
import Header from '../../components/Typo/Header'
import DashboardLayout from '../../DashboardLayout'
import AddAgencyToPartnerAction from '../actions/AddAgencyToPartnerAction'
import { getPartnerQuery } from '../queries/getPartnerQuery'
import AgencyLineItem from './AgencyLineItem'

type Props = {}

const Partner = (props: Props) => {
  const { id } = useParams()
  const partnerQuery = useQuery(['partner', { id }], () => getPartnerQuery(id))

  const {
    isOpen: isAddAgencyOpen,
    onOpen: onAddAgencyOpen,
    onClose: onAddAgencyClose,
  } = useDisclosure()

  return (
    <DashboardLayout>
      <Flex direction='column' gap='24px'>
        <Flex gap='8px'>
          <Header size='lg'>{partnerQuery.data?.name}</Header>
          <CustomBadge variant='grey'>{partnerQuery.data?.internalName}</CustomBadge>
        </Flex>
        <Divider />
        <Flex gap='8px' justifyContent='space-between'>
          <Header size='md'>Agences</Header>
          <Flex gap='8px'>
            <CustomButton leftIcon={<AddIcon />} variant='primary' onClick={onAddAgencyOpen}>
              Ajouter une agence
            </CustomButton>
          </Flex>

          <AssetProcessModalWrapper isOpen={isAddAgencyOpen} onClose={onAddAgencyClose} size='xl'>
            <AddAgencyToPartnerAction />
          </AssetProcessModalWrapper>
        </Flex>

        {partnerQuery.data?.agencies.map((agency) => (
          <AgencyLineItem key={agency.id} agency={agency} />
        ))}
      </Flex>
    </DashboardLayout>
  )
}

export default Partner
