import { authenticatedGetQuery } from '../../../../../utility/queries/authenticatedGetQuery'

export interface GetFileUrlInputs {
  id: string
}

export const getFileUrl = async (data: GetFileUrlInputs) => {
  return await authenticatedGetQuery(`/admin/files/${data.id}/url`).then(
    (response: { fileUrl: string }) => response as { fileUrl: string },
  )
}
