import React, { useEffect, useState } from 'react'
import {
  Button,
  Flex,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import CreditScore from './CreditScore'
import Loans from './Loans'
import AlgoanLoans from './Loans'
import AlgoanIncomes from './Incomes'
import AlgoanExpenses from './Expenses'
import AlgoanRiskIndicators from './RiskFactors'
import { cashFlowsSelectedAtom } from '../../../atoms/algoan'
import { useAtom } from 'jotai'
import TransactionsModal from './TransactionsModal'
import AlgoanSummary from './Summary'

const Analysis = () => {
  const toast = useToast()
  const [page, setPage] = useState(1)
  const [user, setUser] = useState<UserResponseSingleUser | undefined>(undefined)
  const { id, analysisId } = useParams()
  const pageSize = 10
  const navigate = useNavigate()
  const [cashflowsSelected, setCashflowsSelected] = useAtom(cashFlowsSelectedAtom)

  const analyses = useQuery(['analyses', { id }], () => getAnalysesQuery(id || ''), {
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      console.log(error)
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  const currentAnalysis = analyses?.data?.analyses.find((o) => o.id === analysisId)

  useEffect(() => {
    setCashflowsSelected(currentAnalysis?.creditInsights?.cashFlows || [])
  }, [currentAnalysis])

  return (
    <DashboardLayout>
      <TransactionsModal />
      <Flex justifyContent='space-between' w='100%' mb={4}>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
          Retour
        </Button>
      </Flex>
      <Flex gap='12px' direction='column'>
        <Flex gap='12px' flexWrap='wrap' direction='row'>
          {currentAnalysis?.scores?.creditScore && (
            <CreditScore creditScore={currentAnalysis?.scores.creditScore} />
          )}
          <AlgoanSummary metaData={currentAnalysis?.metadata} />
        </Flex>
        <Flex
          position='sticky'
          top='0'
          pt='24px'
          pb='24px'
          gap='12px'
          direction='column'
          bg='white'
          border='1px solid #EFEFEF'
          borderRight='none'
          borderLeft='none'
        >
          <Flex gap='12px'>
            {currentAnalysis?.creditInsights?.expenses && (
              <AlgoanExpenses expenses={currentAnalysis?.creditInsights?.expenses} />
            )}
            {currentAnalysis?.creditInsights?.incomes && (
              <AlgoanIncomes incomes={currentAnalysis?.creditInsights?.incomes} />
            )}
          </Flex>
          <Flex gap='12px'>
            {currentAnalysis?.creditInsights?.loans && (
              <AlgoanLoans loans={currentAnalysis?.creditInsights?.loans} />
            )}
            {currentAnalysis?.creditInsights?.risks && (
              <AlgoanRiskIndicators riskIndicators={currentAnalysis?.creditInsights?.risks} />
            )}
          </Flex>
        </Flex>
        {currentAnalysis?.creditInsights && <CashflowsTable cashflows={cashflowsSelected} />}

        {/*Expenses*/}

        {/*Incomes*/}
      </Flex>
    </DashboardLayout>
  )
}

export default Analysis
