import { Flex, Kbd } from '@chakra-ui/react'
import { IdentificationCard } from '@phosphor-icons/react'
import { useAtom } from 'jotai'
import React from 'react'
import { AiOutlineGroup } from 'react-icons/ai'
import { BiBuildingHouse } from 'react-icons/bi'
import { FaMagic } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { IoHomeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { isCommandPaletteOpenAtom } from '../atoms/searchAtoms'
import { useKonamiCode } from '../utility/hooks/useKonamiCode'
import Logo from '../utility/Logos/Logo'
import { ROUTES } from '../utility/routes/routeConstants'
import MenuItem from './components/Sidebar/MenuItem'
import Regular from './components/Typo/Regular'

const SideBar = () => {
  const konami = useKonamiCode()
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useAtom(isCommandPaletteOpenAtom)

  return (
    <Flex
      padding='40px'
      w='100%'
      direction='column'
      justifyContent='space-between'
      alignItems='flex-start'
      left='5'
      position='sticky'
      top='0'
      h='100vh'
      bg='HestiaForestGreen'
    >
      <Flex
        flexDirection='column'
        gap='40px'
        justifyContent='space-between'
        alignItems='start'
        verticalAlign='middle'
        w='100%'
      >
        <Logo brand={false} />
        <Flex as='nav' align='left' direction='column' alignItems='flex-start' gap='8px' w='100%'>
          <Flex w='100%'>
            {/* <NovuProvider
              subscriberId='ff924314-6411-4ee5-8561-c5a0eeab008d'
              applicationIdentifier='vL75OV_bH20Q'
              // styles={{ layout: { root: { zIndex: 9999999 } } }}
            >
              <PopoverNotificationCenter colorScheme='light' position='right-start'>
                {({ unseenCount }) => <NotificationCenterSidebar />}
              </PopoverNotificationCenter>
            </NovuProvider> */}
          </Flex>
          <Flex
            // flexDir='column'
            // gap='16px'
            // borderRadius='8px'
            // bg={true ? 'rgba(255, 255, 255, 0.15)' : 'initial'}
            w='100%'
          >
            <Link to='/users'>
              <MenuItem text='Users' current={false} icon={<FiUsers />} />
            </Link>

            {/* <Flex flexDir='column' gap='8px' padding='8px' marginLeft={['28px', '36px']}>
              <Bold color='white'>Summary</Bold>
              <Bold color='white'>Fichiers</Bold>
              <Bold color='white'>Call découverte bis</Bold>
            </Flex> */}
          </Flex>
          <Link to='/assetads'>
            <MenuItem text='Asset Ads' current={false} icon={<BiBuildingHouse />} />
          </Link>
          <Link to='/leasingProjects'>
            <MenuItem text='Leasing Projects' current={false} icon={<IoHomeOutline />} />
          </Link>
          <Link to='/fundsgroups'>
            <MenuItem text='Funds Groups' current={false} icon={<AiOutlineGroup />} />
          </Link>
          <Link to='/tools'>
            <MenuItem text='AI Playground' current={false} icon={<FaMagic />} />
          </Link>

          {konami && (
            <>
              <Link to='/dev'>
                <MenuItem text='DEV' current={false} icon={<AiOutlineGroup />} />
              </Link>
              <Link to={`/${ROUTES.ADMINS}`}>
                <MenuItem text='Admins' current={false} icon={<IdentificationCard size={32} />} />
              </Link>
            </>
          )}
          <Link to={`/${ROUTES.PARTNERS_BASE}`}>
            <MenuItem text='Partners' current={false} icon={<IdentificationCard size={32} />} />
          </Link>
        </Flex>
      </Flex>
      <Regular color='white' onClick={() => setIsCommandPaletteOpen(true)} cursor='pointer'>
        To open Omnisearch, click here or : <br />
        <Kbd color='black'>cmd</Kbd> + <Kbd color='black'>k</Kbd>
      </Regular>
    </Flex>
  )
}

export default SideBar
