import React, { ComponentType } from 'react'
import UserHit from './UserHit'
import { UserAlgolia } from './UserHit'
import { useHits, UseHitsProps, Hits } from 'react-instantsearch-hooks-web'
import { Flex } from '@chakra-ui/react'
import { AssetAlgolia } from './AssetHit'
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types'

type Props = UseHitsProps & {
  HitComponent: ComponentType<{ hit: AlgoliaHit }>
}

function CustomHits(props: Props) {
  const { hits } = useHits(props)

  return (
    <Flex direction='column' gap='8px' padding='8px'>
      {hits.map((hit, index) => (
        <props.HitComponent key={hit.objectID} hit={hit} />
      ))}
    </Flex>
  )
}

export default CustomHits
