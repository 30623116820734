import { Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import React from 'react'
import { HESTIA_COLORS } from '../App'
import { DiscoveryTab, discoveryTabsAtom, headerHeightAtom } from '../atoms/discoveryAtom'
import Bold from '../dashboard/components/Typo/Bold'
import { getMaxPercentageVisibleTab, scrollToElement } from '../utility/other'

type Props = {
  title: string
  icon: React.ReactNode
  tab: DiscoveryTab
}

const DiscoveryHeaderTab = (props: Props) => {
  const [discoveryTabs, setDiscoveryTabs] = useAtom(discoveryTabsAtom)
  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom)

  const currentTab = getMaxPercentageVisibleTab(discoveryTabs)

  const isActive = currentTab === props.tab

  return (
    <Flex
      gap='1'
      justifyContent='center'
      alignItems='center'
      verticalAlign='middle'
      color={isActive ? HESTIA_COLORS.brand.green.primary : 'black'}
      cursor='pointer'
      onClick={() => {
        if (!discoveryTabs) return
        const tabData = discoveryTabs[props.tab]
        if (tabData && tabData.ref.current) {
          const element = tabData.ref.current
          if (element instanceof HTMLElement) {
            scrollToElement(element, headerHeight + 16 * 8) // Replace 100 with the desired offset
          }
        }
      }}
    >
      {props.icon}
      <Bold
        color={isActive ? HESTIA_COLORS.brand.green.primary : 'black'}
        textDecor={isActive ? 'underline' : 'initial'}
      >
        {props.title}
      </Bold>
    </Flex>
  )
}

export default DiscoveryHeaderTab
