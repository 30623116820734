import React from 'react'
import {
  Badge,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  TableContainer,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { SearchIcon, ViewIcon } from '@chakra-ui/icons'
import useDebounce from '../../utility/hooks/useDebounce'
import CustomTable from '../components/customTable'
import { useAtom } from 'jotai'
import { pageAtom, searchAtom } from '../../atoms/usersList'
import { getAssetAdsQuery, SingleAssetAdResponse } from './queries/getAssetAds'
import { AssetAdStatus } from '../../interfaces/prisma'
import dayjs from 'dayjs'

const AssetAdsList = () => {
  const toast = useToast()
  const [page, setPage] = useAtom(pageAtom)
  const [search, setSearch] = useAtom(searchAtom)
  const pageSize = 10
  const navigate = useNavigate()

  const searchDebounced = useDebounce(search, 300)

  const assetAdssQuery = useQuery(
    ['assetAds', { page, searchDebounced }],
    () =>
      getAssetAdsQuery({
        page,
        search: searchDebounced,
      }),
    {
      onError: (error: AxiosError) => {
        console.log(error)
        toast({
          title: 'Erreur inconnue:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
    },
  )

  const getStatusBadge = (status: AssetAdStatus) => {
    if (status === AssetAdStatus.PENDING) {
      return <Badge colorScheme='blue'>Pending</Badge>
    } else if (status === AssetAdStatus.REJECTED) {
      return <Badge colorScheme='red'>Rejected</Badge>
    } else {
      return <Badge colorScheme='green'>Validated</Badge>
    }
  }

  const getAccountTypeBadge = (business: boolean) => {
    return business ? (
      <Badge fontSize='l' colorScheme='green'>
        BUSINESS
      </Badge>
    ) : (
      <Badge fontSize='l' colorScheme='blue'>
        PERSONAL
      </Badge>
    )
  }

  return (
    <DashboardLayout>
      <InputGroup>
        <InputLeftElement pointerEvents='none' children={<SearchIcon color='gray.300' />} />
        <Input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          type='tel'
          placeholder='Rechercher...'
        />
      </InputGroup>
      <TableContainer mt='16px'>
        <CustomTable
          headers={['Nom', 'User', 'Postal code', 'Hestia Status', 'User Status', 'Details']}
          widths={['128px', 'initial', 'initial', 'initial', 'initial', 'initial']}
          total={assetAdssQuery.data?.total}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          managedPagination={false}
          content={
            assetAdssQuery.data?.assetAds
              ? assetAdssQuery.data?.assetAds.map((assetAd: SingleAssetAdResponse) => {
                  return {
                    date: dayjs(assetAd.createdAt).format('MMMM D, YYYY'),
                    name: assetAd.name,
                    userEmail: (
                      <div>
                        {assetAd.user.email} <br />
                        {getAccountTypeBadge(assetAd.user.business)}
                      </div>
                    ),
                    hestiaStatus: getStatusBadge(assetAd.hestiaStatus),
                    userStatus: getStatusBadge(assetAd.userStatus),
                    details: (
                      <div onClick={() => navigate(`/assetAds/${assetAd.id}`)}>
                        <IconButton aria-label='Voir' icon={<ViewIcon />} />
                      </div>
                    ),
                  }
                })
              : []
          }
        />
      </TableContainer>
    </DashboardLayout>
  )
}

export default AssetAdsList
