import React, { useEffect, useState } from 'react'
import Searchkit, { MultiMatchQuery } from '@searchkit/sdk'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  useToast,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIcon, ViewIcon } from '@chakra-ui/icons'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useCustomToast } from '../../../utility/hooks/toats/useCustomToasts'
import {
  getScrapData,
  MADataType,
  ScrapData,
  ScrapDataCastorusOnly,
  ScrapDataMAOnly,
} from '../queries/getScrapData'
import { useAtom } from 'jotai'
import { pageAtom } from '../../../atoms/usersList'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import Header from '../../components/Typo/Header'
import Bold from '../../components/Typo/Bold'
import HelperSmall from '../../components/Typo/HelperSmall'
import Regular from '../../components/Typo/Regular'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

const CastorusPriceChanges = ({
  castorusData,
}: {
  castorusData: ScrapDataCastorusOnly[] | undefined
}) => {
  // const MAScrapData: MAData[] = scrapData.data.filter(d => d.type)

  if (!castorusData) {
    return <></>
  }

  const data = castorusData[0].data.priceChanges
    ? castorusData[0].data.priceChanges.map((priceChange) => {
        return {
          x: dayjs(priceChange.date).valueOf(),
          // dateString: dayjs(priceChange.date).format('DD/MM/YYYY'),
          y: priceChange.prix,
          label: `Prix: ${priceChange.prix}€ \n Date: ${dayjs(priceChange.date).format(
            'DD/MM/YYYY',
          )}`,
        }
      })
    : []

  console.log(data)

  return (
    <Flex direction='column' gap='16px' maxW='1000px'>
      {data && data.length > 0 && (
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={20}
          padding={{ left: 75, bottom: 30, right: 20 }}
          width={800}
          height={200}
          containerComponent={<VictoryVoronoiContainer voronoiDimension='x' />}
        >
          <VictoryLine
            style={{
              data: { stroke: '#c43a31' },
              // parent: { border: '1px solid #ccc' },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            data={data}
            interpolation='monotoneX'
            // labels={({ datum }) => `Prix: ${datum.y}€`}
            labelComponent={
              <VictoryTooltip renderInPortal cornerRadius={0} flyoutStyle={{ fill: 'white' }} />
            }
          />
          <VictoryAxis dependentAxis={true} tickFormat={(price) => `${price}€`} />
          <VictoryAxis tickFormat={(date) => dayjs(date).format('DD/MM/YYYY')} />
          <VictoryScatter
            data={data}
            size={5}
            labelComponent={<></>}
            // style={{ data: { fill: "#c43a31" } }}
          />
        </VictoryChart>
      )}
      {/*<LineChart width={800} height={300} data={data}>*/}
      {/*  <Line type='monotone' dataKey='prix' stroke='#8884d8' activeDot={{ r: 8 }} />*/}
      {/*  <CartesianGrid strokeDasharray='3 3' />*/}
      {/*  <XAxis dataKey='date' tickFormatter={(date) => dayjs(date).format('DD/MM/YYYY')} />*/}
      {/*  <Tooltip content={(props) => <CustomTooltip />} />*/}
      {/*  <YAxis dataKey='prix' domain={['dataMin - 10000', 'dataMax + 10000']} />*/}
      {/*</LineChart>*/}
    </Flex>
  )
}

export default CastorusPriceChanges
