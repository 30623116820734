import { authenticatedDeleteQuery } from '../../../../../utility/queries/authenticatedDeleteQuery'

export type DeleteDiscoveryBankPayload = {
  id: string
}

export const deleteDiscoveryBankVisited = async (
  userId: string,
  payload: DeleteDiscoveryBankPayload,
) => {
  return (await authenticatedDeleteQuery(
    `/admin/user/discovery/${userId}/banks/${payload.id}`,
  )) as Promise<void>
}
