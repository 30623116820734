import { SearchIcon } from '@chakra-ui/icons'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useHandleOpenCommandPalette } from '../../utility/hooks/useHandleOpenCommandPalette'
import OmniSearchResults from './OmniSearchResults'
import { isCommandPaletteOpenAtom } from '../../atoms/searchAtoms'
import { useAtom } from 'jotai'

type Props = {}

const CommandPalette = (props: Props) => {
  const [isOpen, setIsOpen] = useAtom(isCommandPaletteOpenAtom)

  useHandleOpenCommandPalette(setIsOpen)

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='3xl'>
      <ModalOverlay />
      <ModalContent padding='0'>
        <ModalBody padding='0'>
          <OmniSearchResults />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CommandPalette
