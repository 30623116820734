import React from 'react'
import CustomBadge, { CustomBadgeProps } from '../../../../shared/components/CustomBadge'
import { getPrettyNumber } from '../../../../utility/money'
import Regular from '../../../components/Typo/Regular'

type DiscoveryDisplayValueRegular = {
  type: 'regular'
  label?: string
  value?: string | boolean | number | undefined | null
}

type DiscoveryDisplayValueCurrency = {
  type: 'currency'
  value?: number | undefined | null
}

type DiscoveryDisplayValueBadge = {
  type: 'badge'
  value?: number | string | boolean | undefined | null
  label?: string
  customBadgeProps?: Partial<CustomBadgeProps>
}

type Props =
  | DiscoveryDisplayValueRegular
  | DiscoveryDisplayValueCurrency
  | DiscoveryDisplayValueBadge

const DiscoveryDisplayValue = (props: Props) => {
  if (props.value !== false && !props.value) {
    // return <></>
    return (
      <CustomBadge key='unknown' variant='yellow'>
        Non renseigné
      </CustomBadge>
    )
  }

  switch (props.type) {
    case 'regular':
      return <Regular>{props.label || props.value}</Regular>
    case 'currency':
      return (
        <CustomBadge key={props.value} variant='blue'>
          {getPrettyNumber(props.value || 0)}
        </CustomBadge>
      )
    case 'badge':
      return (
        <CustomBadge key={props.label} variant='blue' {...props.customBadgeProps}>
          {props.label}
        </CustomBadge>
      )
  }
}

export default DiscoveryDisplayValue
