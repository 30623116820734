export enum AssetProcessStepEnum {
  'ANALYSIS' = 'ANALYSIS',
  'SEND_USER_OFFER' = 'SEND_USER_OFFER',
  'USER_ANSWERED_OFFER' = 'USER_ANSWERED_OFFER',
  'FOLDER_SENT' = 'FOLDER_SENT',
  'FOLDER_ACCEPTED' = 'FOLDER_ACCEPTED',
  'NEGOTIATION' = 'NEGOTIATION',

  'SEND_PURCHASE_OFFER' = 'SEND_PURCHASE_OFFER',
  'SIGNED_PURCHASE_OFFER' = 'SIGNED_PURCHASE_OFFER',
  'SEND_USER_CONTRACT' = 'SEND_USER_CONTRACT',
  'SIGNED_USER_CONTRACT' = 'SIGNED_USER_CONTRACT',
  'DOWN_PAYMENT' = 'DOWN_PAYMENT',

  'VISIT_BOOKING' = 'VISIT_BOOKING',
  'VISIT_SUMMARY_RECEIVED' = 'VISIT_SUMMARY_RECEIVED',
  'VISIT_SUMMARY_REVIEWED' = 'VISIT_SUMMARY_REVIEWED',
  'NOTARY_CONNECTION' = 'NOTARY_CONNECTION',
  'NOTARY_AUDIT_REQUEST' = 'NOTARY_AUDIT_REQUEST',
  'NOTARY_AUDIT_RECEIVED' = 'NOTARY_AUDIT_RECEIVED',
  'NOTARY_AUDIT_REVIEWED' = 'NOTARY_AUDIT_REVIEWED',

  'PROMISE_WRITING' = 'PROMISE_WRITING',
  'PROMISE_RECEIVED' = 'PROMISE_RECEIVED',
  'PROMISE_DATE_FIXING' = 'PROMISE_DATE_FIXING',
  'PROMISE_SIGNING' = 'PROMISE_SIGNING',
}

export const TeamsEnum = {
  SUPPLY: 'SUPPLY',
  DEMAND: 'DEMAND',
  CREDIT: 'CREDIT',
  FUND: 'FUND',
  MARKETING: 'MARKETING',
  DEV: 'DEV',
  PARTNERSHIPS: 'PARTNERSHIPS',
  EXEC: 'EXEC',
  ALL: 'ALL',
}

export type TeamsEnum = typeof TeamsEnum[keyof typeof TeamsEnum]

export interface AssetProcessStep {
  averageHoursNeeded: number
  label: string
  team: TeamsEnum
}

export const assetProcessSteps: Record<AssetProcessStepEnum, AssetProcessStep> = {
  [AssetProcessStepEnum.ANALYSIS]: {
    averageHoursNeeded: 5,
    label: 'Analyse du bien',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.SEND_USER_OFFER]: {
    averageHoursNeeded: 5,
    label: "Envoi de la proposition à l'utilisateur",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.USER_ANSWERED_OFFER]: {
    averageHoursNeeded: 5,
    label: "Proposition acceptée par l'utilisateur",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.FOLDER_SENT]: {
    averageHoursNeeded: 5,
    label: 'Envoi du dossier',
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.FOLDER_ACCEPTED]: {
    averageHoursNeeded: 5,
    label: 'Validation du dossier',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.NEGOTIATION]: {
    averageHoursNeeded: 5,
    label: 'Négociation',
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.SEND_PURCHASE_OFFER]: {
    averageHoursNeeded: 5,
    label: "Envoi de l'offre d'achat",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.SIGNED_PURCHASE_OFFER]: {
    averageHoursNeeded: 5,
    label: "Signature de l'offre d'achat",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.SEND_USER_CONTRACT]: {
    averageHoursNeeded: 5,
    label: "Envoi du pré-contrat à l'utilisateur",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.SIGNED_USER_CONTRACT]: {
    averageHoursNeeded: 5,
    label: 'Signature du pré-contrat',
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.DOWN_PAYMENT]: {
    averageHoursNeeded: 5,
    label: "Paiement de l'apport",
    team: TeamsEnum.DEMAND,
  },
  [AssetProcessStepEnum.VISIT_BOOKING]: {
    averageHoursNeeded: 5,
    label: 'Book du check & visit',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.VISIT_SUMMARY_RECEIVED]: {
    averageHoursNeeded: 5,
    label: 'Réception du check & visit',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.VISIT_SUMMARY_REVIEWED]: {
    averageHoursNeeded: 5,
    label: 'Analyse du rapport check & visit',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.NOTARY_CONNECTION]: {
    averageHoursNeeded: 5,
    label: 'Mise en relation des notaires',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.NOTARY_AUDIT_REQUEST]: {
    averageHoursNeeded: 5,
    label: "Demande de l'audit des notaires",
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.NOTARY_AUDIT_RECEIVED]: {
    averageHoursNeeded: 5,
    label: "Réception de l'audit des notaires",
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.NOTARY_AUDIT_REVIEWED]: {
    averageHoursNeeded: 5,
    label: "Review de l'audit des notaires",
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.PROMISE_WRITING]: {
    averageHoursNeeded: 5,
    label: 'Ecriture du projet de promesse',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.PROMISE_RECEIVED]: {
    averageHoursNeeded: 5,
    label: 'Réception du projet de promesse ',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.PROMISE_DATE_FIXING]: {
    averageHoursNeeded: 5,
    label: 'Fixation de la date de promesse',
    team: TeamsEnum.SUPPLY,
  },
  [AssetProcessStepEnum.PROMISE_SIGNING]: {
    averageHoursNeeded: 5,
    label: 'Signature de la promesse',
    team: TeamsEnum.SUPPLY,
  },
}

// Categories

export enum AssetProcessCategoryEnum {
  'PRE_OFFER' = 'PRE_OFFER',
  'PURCHASE_OFFER' = 'PURCHASE_OFFER',
  'PRE_PROMISE' = 'PRE_PROMISE',
  'PROMISE' = 'PROMISE',
}

export interface AssetProcessCategory {
  label: string
  steps: AssetProcessStepEnum[]
}

export const assetProcessCategories: Record<AssetProcessCategoryEnum, AssetProcessCategory> = {
  [AssetProcessCategoryEnum.PRE_OFFER]: {
    label: 'Pré-offre',
    steps: [
      AssetProcessStepEnum.ANALYSIS,
      AssetProcessStepEnum.SEND_USER_OFFER,
      AssetProcessStepEnum.USER_ANSWERED_OFFER,
      AssetProcessStepEnum.FOLDER_SENT,
      AssetProcessStepEnum.FOLDER_ACCEPTED,
      AssetProcessStepEnum.NEGOTIATION,
    ],
  },
  [AssetProcessCategoryEnum.PURCHASE_OFFER]: {
    label: "Offre d'achat",
    steps: [
      AssetProcessStepEnum.SEND_PURCHASE_OFFER,
      AssetProcessStepEnum.SIGNED_PURCHASE_OFFER,
      AssetProcessStepEnum.SEND_USER_CONTRACT,
      AssetProcessStepEnum.SIGNED_USER_CONTRACT,
      AssetProcessStepEnum.DOWN_PAYMENT,
    ],
  },
  [AssetProcessCategoryEnum.PRE_PROMISE]: {
    label: 'Pré-promesse',
    steps: [
      AssetProcessStepEnum.VISIT_BOOKING,
      AssetProcessStepEnum.VISIT_SUMMARY_RECEIVED,
      AssetProcessStepEnum.VISIT_SUMMARY_REVIEWED,
      AssetProcessStepEnum.NOTARY_CONNECTION,
      AssetProcessStepEnum.NOTARY_AUDIT_REQUEST,
      AssetProcessStepEnum.NOTARY_AUDIT_RECEIVED,
      AssetProcessStepEnum.NOTARY_AUDIT_REVIEWED,
    ],
  },
  [AssetProcessCategoryEnum.PROMISE]: {
    label: 'Promesse',
    steps: [
      AssetProcessStepEnum.PROMISE_WRITING,
      AssetProcessStepEnum.PROMISE_RECEIVED,
      AssetProcessStepEnum.PROMISE_DATE_FIXING,
      AssetProcessStepEnum.PROMISE_SIGNING,
    ],
  },
}

export const getStepNumber = (step: any) => {
  return Object.keys(assetProcessSteps).findIndex((o) => o === step) + 1
}
