import { Flex, CircularProgress, CircularProgressLabel, Text, Button } from '@chakra-ui/react'
import React from 'react'
import { BiLinkAlt, BiUser, BiMessageDetail, BiCoinStack } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { getPrettyNumber } from '../../../utility/money'
import { ROUTES } from '../../../utility/routes/routeConstants'
import Header from '../../components/Typo/Header'
import LargeBold from '../../components/Typo/LargeBold'
import Regular from '../../components/Typo/Regular'
import { getAssetAdById } from '../queries/getAssetAdById'
import { getStepNumber, assetProcessSteps } from '../../../shared/backShared/assetProcessSteps'
import HelperSmall from '../../components/Typo/HelperSmall'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { getMortgageSimulatorResultsV3 } from '../../commonQueries/getMortgageSimulatorResultsV3'
import { getBudgetQuery } from '../../commonQueries/getBudgetQuery'
import { useHestiaBudgetAdmin } from '../../../utility/hooks/useHestiaBudgetAdmin'
import Loading from '../../../shared/components/Loading'

type Props = {}

const AssetAdHeader = (props: Props) => {
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const navigate = useNavigate()
  const location = useLocation()

  if (!assetAd.data?.user.userInformations) {
    return <Loading />
  }
  // const budget = assetAd?.data?.user.userInformations
  //   ? useHestiaBudgetAdmin({ user: assetAd?.data?.user })
  //   : undefined

  const completionPercent = assetAd.data?.processStep
    ? (getStepNumber(assetAd.data?.processStep) / Object.keys(assetProcessSteps).length) * 100
    : 0

  return (
    <Flex direction='column' gap='8px'>
      {location.pathname !== `/${ROUTES.ASSETADS_BASE}/${id}` && (
        <Button
          w='fit-content'
          size='sm'
          variant='outline'
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/${ROUTES.ASSETADS_BASE}/${id}`)}
        >
          {'Retour'}
        </Button>
      )}

      <Flex direction='row' gap='8px' alignItems='center'>
        <CircularProgress
          value={completionPercent}
          size='72px'
          color='HestiaPintGreen'
          thickness='6px'
        >
          <CircularProgressLabel>
            <LargeBold fontSize='20px'>{completionPercent.toFixed(0)}%</LargeBold>
          </CircularProgressLabel>
        </CircularProgress>
        <Flex gap='8px' direction='column'>
          <Header>{assetAd.data?.name}</Header>
          <Flex gap='24px' alignItems='center'>
            <Regular
              color='HestiaGrey'
              fontSize='16px'
              cursor='pointer'
              onClick={() =>
                window.open(
                  /^https?:\/\//i.test(assetAd.data?.url || '')
                    ? assetAd.data?.url || ''
                    : `https://${assetAd.data?.url || ''}`,
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            >
              <Flex gap='8px' alignItems='center'>
                <BiLinkAlt />
                <Text as='span'>{assetAd.data?.url}</Text>
              </Flex>
            </Regular>
          </Flex>
          <Flex
            gap='24px'
            cursor='pointer'
            onClick={() => navigate(`/${ROUTES.USERS_BASE}/${assetAd.data?.user.id}`)}
          >
            <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiUser />
                <Text as='span'>
                  Proposé par{' '}
                  <b>
                    {assetAd.data?.user.firstName} {assetAd.data?.user.lastName}
                  </b>
                </Text>
              </Flex>
            </Regular>
            <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiMessageDetail />
                <Text as='span'>{assetAd.data?.user.email}</Text>
              </Flex>
            </Regular>
            {/* <Regular color='HestiaGrey' fontSize='16px'>
              <Flex gap='8px' alignItems='center'>
                <BiCoinStack />
                <Text as='span'>{getPrettyNumber(budget?.simulatorData?.budgetNow, 0, true)}</Text>
              </Flex>
            </Regular> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AssetAdHeader
