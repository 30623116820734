import React from 'react'
import { Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import AssetAdSummary from './AssetAdSummary'
import Comparables from './Comparables'
import Offer from './Offer'
import AssetAdContext from './AssetAdContext'
import AssetAdData from './AssetAdData'

const AssetAdDetail = () => {
  const navigate = useNavigate()

  return (
    <DashboardLayout>
      <Flex w='100%'>
        <Flex direction='column' gap='64px' padding='12px' w='100%'>
          <Flex justifyContent='space-between' gap='16px'>
            <Tabs w='100%'>
              <TabList borderBottom='none'>
                <Flex mr='32px'>
                  <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                    Retour
                  </Button>
                </Flex>
                <Tab>Summary</Tab>
                <Tab>Offer</Tab>
                <Tab>Environnement</Tab>
                <Tab>Data</Tab>
              </TabList>

              <TabPanels w='100%'>
                <TabPanel w='100%'>
                  <AssetAdSummary />
                </TabPanel>
                <TabPanel w='100%'>
                  <Offer />
                </TabPanel>
                <TabPanel w='100%'>
                  <AssetAdContext />
                </TabPanel>
                <TabPanel w='100%'>
                  <AssetAdData />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Flex>
    </DashboardLayout>
  )
}

export default AssetAdDetail
