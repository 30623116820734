import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React, { ReactNode } from 'react'

export type InputWrapperProps = {
  field: string
  label?: string
  placeHolder?: string
  helperText?: string
  isRequired?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xs'
}

const InputWrapper = (props: InputWrapperProps & { children: ReactNode }) => {
  const formik = useFormikContext<FormikValues>()

  const errorMesage: string = formik.errors[props.field]
    ? (formik.errors[props.field] as string)
    : ''

  return (
    <FormControl
      isRequired={props.isRequired}
      isInvalid={formik.touched[props.field] && !!formik.errors[props.field]}
      size={props.size}
    >
      {props.label && (
        <FormLabel size={props.size} htmlFor='firstName'>
          {props.label}
        </FormLabel>
      )}
      {props.children}
      {formik.errors[props.field] && formik.touched[props.field] && (
        <FormHelperText color='red'>{errorMesage || ''}</FormHelperText>
      )}
      {props.helperText && <FormHelperText color='TextLight'>{props.helperText}</FormHelperText>}
    </FormControl>
  )
}

export default InputWrapper
