import { useEffect, useState } from 'react'

const useVisibleHeight = (ref: React.RefObject<HTMLElement>, headerSize?: number): number => {
  const [visibleHeight, setVisibleHeight] = useState(0)

  useEffect(() => {
    const updateVisibleHeight = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const rect = entry.boundingClientRect
          const visiblePart =
            Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, headerSize || 0)

          setVisibleHeight(Math.max(visiblePart, 0))
        } else {
          setVisibleHeight(0)
        }
      })
    }

    const observer = new IntersectionObserver(updateVisibleHeight, {
      threshold: Array.from({ length: 101 }, (_, i) => i / 100),
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref, headerSize])

  return visibleHeight
}

export default useVisibleHeight
