import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  assetProcessStages,
  AssetProcessStagesEnum,
} from '../../../../shared/backShared/assetProcessStages'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'

type Props = {}

export type ChangeStepActionValues = {
  newStage: AssetProcessStagesEnum | undefined
}

const ChangeStepAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const initialValues: ChangeStepActionValues = {
    newStage: assetAd.data?.processStage,
  }

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(
            { ...values, id: id || '' },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title="Changer l'étape"
                subtitle="A n'utiliser qu'en cas de bug ou d'étape en cours de développement"
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Etape'
                  field='newStage'
                  isRequired
                  options={[
                    {
                      label: assetProcessStages[AssetProcessStagesEnum.TO_PROCESS].label,
                      value: AssetProcessStagesEnum.TO_PROCESS,
                    },
                    {
                      label:
                        assetProcessStages[AssetProcessStagesEnum.MARKET_VALUE_COMPLETED].label,
                      value: AssetProcessStagesEnum.MARKET_VALUE_COMPLETED,
                    },
                    {
                      label: assetProcessStages[AssetProcessStagesEnum.IN_NEGOTIATION].label,
                      value: AssetProcessStagesEnum.IN_NEGOTIATION,
                    },
                    {
                      label: assetProcessStages[AssetProcessStagesEnum.AGREEMENT_FOUND].label,
                      value: AssetProcessStagesEnum.AGREEMENT_FOUND,
                    },
                    {
                      label: assetProcessStages[AssetProcessStagesEnum.CLOSED_WON].label,
                      value: AssetProcessStagesEnum.CLOSED_WON,
                    },
                  ]}
                />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChangeStepAction
