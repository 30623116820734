import { Card, Flex } from '@chakra-ui/react'
import React from 'react'
import { HESTIA_COLORS } from '../App'
import Header from '../dashboard/components/Typo/Header'

type Props = {
  title: string
  subTitle?: React.ReactNode
  icon?: React.ReactNode
  children: React.ReactNode
  light?: boolean
}

const CustomCard = (props: Props) => {
  const textColor = props.light ? 'black' : 'white'
  return (
    <Card bg='white' borderRadius='8px' w='100%'>
      <Flex
        bg={props.light ? HESTIA_COLORS.neutral.background : HESTIA_COLORS.brand.green.primary}
        p={6}
        borderRadius='8px 8px 0 0'
        gap='16px'
        w='100%'
        borderBottom='1px solid'
        borderBottomColor={HESTIA_COLORS.neutral.border}
      >
        {props.icon}
        {props.title && (
          <Header color={textColor} size='H3' fontSize={props.light ? '20px' : '24px'}>
            {props.title}
          </Header>
        )}
        {props.subTitle && (
          <>
            <Header color={textColor} size='H3'>
              -
            </Header>
            <Header color={textColor} size='H3' fontFamily='Recoleta Alt Light'>
              {props.subTitle}
            </Header>
          </>
        )}
      </Flex>
      <Flex p={6}>{props.children}</Flex>
    </Card>
  )
}

export default CustomCard
