import { Textarea, TextareaProps } from '@chakra-ui/react'
import { FormikValues, useFormikContext } from 'formik'
import React, { useEffect, useRef } from 'react'
import InputWrapper, { InputWrapperProps } from './InputWrapper'

type Props = {
  field: string
  label?: string
  placeHolder?: string
  isRequired?: boolean
}

const TextAreaInput = (props: InputWrapperProps & TextareaProps & Props) => {
  const formik = useFormikContext<FormikValues>()
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }, [formik.values[props.field]])

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
    formik.handleChange(event)
  }

  return (
    <InputWrapper
      field={props.field}
      label={props.label}
      isRequired={props.isRequired}
      helperText={props.helperText}
    >
      <Textarea
        ref={textareaRef}
        placeholder={props.placeHolder}
        id={props.field}
        onChange={handleInput}
        onBlur={formik.handleBlur}
        value={formik.values[props.field]}
        {...props}
      />
    </InputWrapper>
  )
}

export default TextAreaInput
