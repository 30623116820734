import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Progress,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore, CreditScoreIndicators } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'

const CreditScoreIndicator = ({ label, value }: { label: string; value: number }) => {
  const getColor = () => {
    if (value < -2) {
      return 'HestiaRed'
    } else if (value < 2) {
      return 'orange'
    } else {
      return 'HestiaPintGreen'
    }
  }

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      bg='HestiaLightGrey'
      flex='1 1 0'
      minWidth='200px'
    >
      <Box height='100%' width='3px' bg={getColor()} borderRadius='8px'></Box>
      <Flex
        padding='16px'
        direction='column'
        gap='8px'
        justifyContent='center'
        alignItems='middle'
        w='100%'
      >
        {/*<Regular>*/}
        {/*  {label}: <Progress value={80} />*/}
        {/*  <Box as='span' color='HestiaPintGreen' fontSize='16px'>*/}
        {/*    {value}*/}
        {/*  </Box>*/}
        {/*</Regular>*/}
        <Regular>{label}</Regular>
        <Flex direction='column' gap='6px'>
          <Box as='span' color={getColor()} fontSize='18px' textAlign='center'>
            {value}
          </Box>
          <Box pb={2} w='100%'>
            <Slider
              aria-label='slider-ex-6'
              isDisabled={true}
              value={value}
              min={-5}
              max={5}
              borderColor={getColor()}
            >
              <SliderMark value={-5} {...labelStyles}>
                -5
              </SliderMark>
              <SliderMark value={0} {...labelStyles}>
                0
              </SliderMark>
              <SliderMark value={5} {...labelStyles}>
                5
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack bg={getColor()} />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CreditScoreIndicator
