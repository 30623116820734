import React, { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import { IncomesV2, Loans } from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import AlgoanIncomeDetails from './IncomeDetails'
import { AnalysisMetadata } from '../../../utility/algoan/types/Analysis.interface'

const AlgoanSummary = ({ metaData }: { metaData: AnalysisMetadata | undefined }) => {
  return (
    <Flex border='1px solid #EFEFEF' borderRadius='8px' padding='24px' direction='column' gap='8px'>
      <Regular>
        <b>
          Transactions analysées :{' '}
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {metaData?.totalTransactionCount}
          </Box>
        </b>
      </Regular>
      <Regular>
        <b>
          Nombre de comptes (Checkings) :{' '}
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {metaData?.numberOfCheckings}
          </Box>
        </b>
      </Regular>
      <Regular>
        <b>
          Profondeur de jours :{' '}
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {metaData?.historicalDepth} jours
          </Box>
        </b>
      </Regular>
      <Regular>
        <b>
          Première transaction :{' '}
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {dayjs(metaData?.firstTransactionDate).format('DD/MM/YYYY')}
          </Box>
        </b>
      </Regular>
      <Regular>
        <b>
          Analyse créée le :{' '}
          <Box as='span' color='HestiaPintGreen' fontSize='16px'>
            {dayjs(metaData?.createdAt).format('DD/MM/YYYY')}
          </Box>
        </b>
      </Regular>
    </Flex>
  )
}

export default AlgoanSummary
