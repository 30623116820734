import React, { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserQuery, UserResponseSingleUser } from '../queries/userQuery'
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateUserQuery, UpdateUserQueryInputs } from '../queries/updateUserQuery'
import { authenticatedPostQuery } from '../../../utility/queries/authenticatedPostQuery'
import { CreateAnalysisInputs, createAnalysisQuery } from './queries/createAnalysis'
import { getAnalysesQuery } from './queries/getAnalyses'
import dayjs from 'dayjs'
import Header from '../../components/Typo/Header'
import HelperMedium from '../../components/Typo/HelperMedium'
import CashflowsTable from './CashflowsTable'
import { CreditScore } from '../../../utility/algoan/types/Score.interface'
import Regular from '../../components/Typo/Regular'
import CreditScoreIndicator from './CreditScoreIndicator'
import { IncomesV2, Loans, Risks } from '../../../utility/algoan/types/CreditInsights.interface'
import { getPrettyNumber } from '../../../utility/money'
import AlgoanIncomeDetails from './IncomeDetails'
import { useAtom } from 'jotai'
import { isTransactionsModalOpenedAtom, transactionModalContentAtom } from '../../../atoms/algoan'

const AlgoanRiskIndicators = ({ riskIndicators }: { riskIndicators: Risks | undefined }) => {
  const [isOpen, setIsOpen] = useAtom(isTransactionsModalOpenedAtom)
  const [transactionsModalContent, setTransactionsModalContent] = useAtom(
    transactionModalContentAtom,
  )

  const getColor = (count: number) => {
    if (count > 0) {
      return 'HestiaRed'
    } else {
      return 'HestiaPintGreen'
    }
  }

  const totalRisks = riskIndicators
    ? (riskIndicators.gambling.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.overdraft.duration > 0 ? 1 : 0) +
      (riskIndicators.wageAdvance.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.debtCollection.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.incidents.paymentRejections.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.incidents.interventionFees.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.incidents.checkRejections.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.heavyFailures.directRecoveryOfDebt.transactions.length > 0 ? 1 : 0) +
      (riskIndicators.heavyFailures.bankAccountSeizure.transactions.length > 0 ? 1 : 0)
    : 0

  return (
    <Flex
      border='1px solid #EFEFEF'
      borderRadius='8px'
      padding='24px'
      direction='column'
      gap='8px'
      w='100%'
    >
      <Accordion allowToggle w='100%'>
        <AccordionItem w='100%' border='0'>
          <AccordionButton textAlign='left' p='0' w='100%'>
            <Flex direction='column' gap='8px' w='100%'>
              <Header fontSize='24px'>Risks Indicators </Header>
              <Regular>
                <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                  {totalRisks}
                </Box>{' '}
                <Box as='span' fontSize='12px'>
                  risques identifié(s)
                </Box>
              </Regular>
            </Flex>

            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex direction='column' gap='8px'>
              <Flex gap='8px' flex='1 1 0px' flexWrap='wrap'>
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(riskIndicators?.overdraft.duration || 0)}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Overdraft</b>
                    </Regular>
                    <Regular>
                      <Box as='span' fontSize='16px'>
                        Minimum Balance Reached :{' '}
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.overdraft.minimumBalanceReached)}
                        </Box>
                      </Box>
                    </Regular>
                    <Regular>
                      <Box as='span' fontSize='16px'>
                        Overdraft duration :{' '}
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {riskIndicators?.overdraft.duration} days
                        </Box>
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap='8px'
                flex='1 1 0px'
                flexWrap='wrap'
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: 'Gambling',
                    transactions: riskIndicators?.gambling.transactions || [],
                  })
                  setIsOpen(true)
                }}
              >
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(riskIndicators?.gambling.transactions.length || 0)}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Gambling</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.gambling.totalAmount)}
                        </Box>
                        )
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap='8px'
                flex='1 1 0px'
                flexWrap='wrap'
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: 'Wage Advance',
                    transactions: riskIndicators?.wageAdvance.transactions || [],
                  })
                  setIsOpen(true)
                }}
              >
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(riskIndicators?.wageAdvance.transactions.length || 0)}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Wage advance</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.wageAdvance.totalAmount)}
                        </Box>
                        )
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap='8px'
                flex='1 1 0px'
                flexWrap='wrap'
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: 'Debt collection',
                    transactions: riskIndicators?.debtCollection.transactions || [],
                  })
                  setIsOpen(true)
                }}
              >
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(riskIndicators?.debtCollection.transactions.length || 0)}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Debt collection</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.debtCollection.totalAmount)}
                        </Box>
                        )
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap='8px'
                flex='1 1 0px'
                flexWrap='wrap'
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: 'Heavy Failures',
                    transactions: [
                      ...(riskIndicators?.heavyFailures.bankAccountSeizure.transactions || []),
                      ...(riskIndicators?.heavyFailures.directRecoveryOfDebt.transactions || []),
                    ],
                  })
                  setIsOpen(true)
                }}
              >
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(
                      (riskIndicators?.heavyFailures.directRecoveryOfDebt.transactions.length ||
                        0) +
                        (riskIndicators?.heavyFailures.bankAccountSeizure.transactions.length || 0),
                    )}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Direct recovery of debt</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(
                            riskIndicators?.heavyFailures.directRecoveryOfDebt.totalAmount,
                          )}
                        </Box>
                        )
                      </Box>
                    </Regular>
                    <Regular>
                      <b>Bank account seizure</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(
                            riskIndicators?.heavyFailures.bankAccountSeizure.totalAmount,
                          )}
                        </Box>
                        )
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap='8px'
                flex='1 1 0px'
                flexWrap='wrap'
                cursor='pointer'
                onClick={() => {
                  setTransactionsModalContent({
                    title: 'Incidents',
                    transactions: [
                      ...(riskIndicators?.incidents.paymentRejections.transactions || []),
                      ...(riskIndicators?.incidents.interventionFees.transactions || []),
                      ...(riskIndicators?.incidents.checkRejections.transactions || []),
                    ],
                  })
                  setIsOpen(true)
                }}
              >
                <Flex
                  border='1px solid #EFEFEF'
                  borderRadius='8px'
                  bg='HestiaLightGrey'
                  flex='1 1 0'
                  minWidth='200px'
                >
                  <Box
                    height='100%'
                    width='3px'
                    bg={getColor(
                      (riskIndicators?.incidents.paymentRejections.transactions.length || 0) +
                        (riskIndicators?.incidents.interventionFees.transactions.length || 0) +
                        (riskIndicators?.incidents.checkRejections.transactions.length || 0),
                    )}
                    borderRadius='8px'
                  />
                  <Flex
                    padding='16px'
                    direction='column'
                    gap='8px'
                    justifyContent='center'
                    alignItems='middle'
                    w='100%'
                  >
                    <Regular>
                      <b>Payments Rejections</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.incidents.paymentRejections.totalAmount)}
                        </Box>
                        / month)
                      </Box>
                    </Regular>
                    <Regular>
                      <b>Intervention fees</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.incidents.interventionFees.totalAmount)}
                        </Box>
                        / month)
                      </Box>
                    </Regular>
                    <Regular>
                      <b>Check rejections</b>{' '}
                      <Box as='span' fontSize='16px'>
                        (
                        <Box as='span' color='HestiaPintGreen' fontSize='16px'>
                          {getPrettyNumber(riskIndicators?.incidents.checkRejections.totalAmount)}
                        </Box>
                        / month)
                      </Box>
                    </Regular>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default AlgoanRiskIndicators
