import React from 'react'
import { Flex, IconButton, TableContainer, useToast } from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { ViewIcon } from '@chakra-ui/icons'
import useDebounce from '../../utility/hooks/useDebounce'
import CustomTable from '../components/customTable'
import { useAtom } from 'jotai'
import { pageAtom, searchAtom } from '../../atoms/usersList'
import {
  getLeasingProjectsQuery,
  LeasingProjectsResponseSingle,
} from './queries/getLeasingProjects'

const LeasingProjectsList = () => {
  const toast = useToast()
  const [page, setPage] = useAtom(pageAtom)
  const [search, setSearch] = useAtom(searchAtom)
  const pageSize = 10
  const navigate = useNavigate()

  const searchDebounced = useDebounce(search)

  const leasingProjectsQuery = useQuery(
    ['leasingProject', { page, searchDebounced }],
    () =>
      getLeasingProjectsQuery({
        page,
        search: searchDebounced,
      }),
    {
      onError: (error: AxiosError) => {
        console.log(error)
        toast({
          title: 'Erreur inconnue:',
          description: error.message,
          status: 'error',
          position: 'top-right',
        })
      },
    },
  )

  return (
    <DashboardLayout>
      <Flex direction='column' gap='16px'>
        {/*<Flex gap='16px' alignItems='center' verticalAlign='middle' justifyContent='end'>*/}
        {/*  <Button*/}
        {/*    colorScheme='green'*/}
        {/*    rightIcon={<AddIcon />}*/}
        {/*    onClick={() => navigate(`/fundsgroups/create`)}*/}
        {/*  >*/}
        {/*    Créer un Funds Group*/}
        {/*  </Button>*/}
        {/*</Flex>*/}
      </Flex>
      <TableContainer mt='16px'>
        <CustomTable
          headers={['Email du user', 'Montant loyer', 'Funds Group']}
          total={leasingProjectsQuery.data?.total}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          managedPagination={false}
          content={
            leasingProjectsQuery.data?.leasingProjects
              ? leasingProjectsQuery.data?.leasingProjects.map(
                  (leasingProject: LeasingProjectsResponseSingle) => {
                    return {
                      email: leasingProject.user.email,
                      rent: leasingProject.rentAmount,
                      fundsGroupName: leasingProject.fundsGroup.name,
                      details: (
                        <div onClick={() => navigate(`/leasingProjects/${leasingProject.id}`)}>
                          <IconButton aria-label='Voir' icon={<ViewIcon />} />
                        </div>
                      ),
                    }
                  },
                )
              : []
          }
        />
      </TableContainer>
    </DashboardLayout>
  )
}

export default LeasingProjectsList
