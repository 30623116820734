import React from 'react'
import { Button, Flex, IconButton, TableContainer, useDisclosure, useToast } from '@chakra-ui/react'
import DashboardLayout from '../DashboardLayout'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import useDebounce from '../../utility/hooks/useDebounce'
import CustomTable from '../components/customTable'
import { useAtom } from 'jotai'
import { pageAtom, searchAtom } from '../../atoms/usersList'
import { getAdmins } from './queries/getAdmins'
import Bold from '../components/Typo/Bold'
import AssetProcessModalWrapper from '../assetAds/v2/AssetProcessModalWrapper'
import EditAdminAction from './actions/EditAdminAction'
import AdminsListSingle from './AdminsListSingle'

const AdminsList = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const admins = useQuery(['admins'], () => getAdmins(), {
    onError: (error: AxiosError) => {
      toast({
        title: 'Erreur inconnue:',
        description: error.message,
        status: 'error',
        position: 'top-right',
      })
    },
  })

  return (
    <DashboardLayout>
      <TableContainer mt='16px'>
        <Flex direction='column' gap='8px' padding='8px'>
          {admins.data?.admins.map((admin) => (
            <AdminsListSingle admin={admin} />
          ))}
        </Flex>
      </TableContainer>
    </DashboardLayout>
  )
}

export default AdminsList
